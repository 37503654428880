import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';

import { useFormatMessage } from 'hooks/useFormatMessage';
import { BASE_URL, axiosConfig } from 'utils/api';

import './user-engagement.scss';

const UserEngagementRecentDays = ({ selectedOrg }) => {
  const startDate = moment()
    .subtract(20, 'days')
    .format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');
  const [userEngagementData, setUserEngagementData] = useState(
    Array(20)
      .fill({})
      .map((value, i) => ({
        index: i,
        date: moment()
          .subtract(20 - i, 'days')
          .format('YYYY-DD-MM'),
        activeUsers: 0
      }))
  );
  const t = useFormatMessage();

  async function fetchUserEngagementData() {
    const endpoint = `${BASE_URL}/organizations/${selectedOrg}/stats/activeUsers?startDate=${startDate}&endDate=${endDate}`;
    const response = await axios.get(endpoint, axiosConfig('application/json'));
    if (response?.data) {
      setUserEngagementData(response.data || []);
    }
  }

  useEffect(() => {
    fetchUserEngagementData();
  }, [selectedOrg]);

  return (
    <div className="stats-last-20-days">
      <div className="stats-head-middle">{t('user-engagement/user-engagement-in-the-last-20-days')}</div>

      <div className="stats-box-engagement">
        {userEngagementData.map(item => (
          <div key={item.index}>
            <div className="engagement-bg">
              <div className="eng-bar" style={{ height: `${(item.activeUsers / item.totalUsers) * 100}%` }}></div>
            </div>
            <div className="progress-subtext">{item.date ? moment(item.date).format('D') : ''}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

UserEngagementRecentDays.propTypes = {
  selectedOrg: PropTypes.string.isRequired
};
export default UserEngagementRecentDays;
