import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import {
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_RESET_TOKEN,
  VERIFY_RESET_TOKEN_SUCCESS,
  VERIFY_RESET_TOKEN_FAILURE
} from 'utils/constants';

export const signin = ({ email, password, organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/auth/signin`,
    method: 'POST',
    body: JSON.stringify({ email, password, organizationId }),
    headers: { 'Content-Type': 'application/json' },
    types: [SIGNIN_USER, SIGNIN_USER_SUCCESS, SIGNIN_USER_FAILURE]
  }
});

export const resetPassword = ({ email, organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/auth/resetPassword`,
    method: 'POST',
    body: JSON.stringify({ email, organizationId }),
    headers: { 'Content-Type': 'application/json' },
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE]
  }
});

export const verifyResetToken = ({ resetToken }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/auth/verifyToken`,
    method: 'POST',
    body: JSON.stringify({ resetToken }),
    headers: { 'Content-Type': 'application/json' },
    types: [VERIFY_RESET_TOKEN, VERIFY_RESET_TOKEN_SUCCESS, VERIFY_RESET_TOKEN_FAILURE]
  }
});
