import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import SettingsDashboard from './SettingsDashboard';
import './user-settings.scss';

const UserSettings = () => {
  return (
    <React.Fragment>
      <Container className="pr-0">
        <Row className="user-setting__tabs justify-content-center">
          <Col xs={12}>
            <SettingsDashboard className="pr-0" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

UserSettings.propTypes = {};

UserSettings.defaultProps = {};

const mapStateToProps = () => {};

const mapDispatchToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSettings));
