import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row, TabContainer, TabContent, TabPane, Nav, NavItem } from 'react-bootstrap';
import { get } from 'lodash';

import { BASE_URL } from 'utils/api';
import { useFormatMessage } from 'hooks/useFormatMessage';
import ModuleStatistics from './ModuleStatistics';
import UserStatus from './UserStatus';
import ModuleContents from './ModuleContents';
import { ContentsPropTypesShape } from 'utils/core-proptypes';
import './module-preference-below-tabs.scss';

const ModulePreferenceBelowTabs = ({ authToken, orgId, moduleId, contents, localeLanguages, currentOrganization, dispatcher }) => {
  const t = useFormatMessage();
  const [currentActiveKey, setCurrentActiveKey] = useState('module-content-tab');

  return (
    <React.Fragment>
      <Container style={{ padding: 0 }}>
        <Row className="content-create__tabs justify-content-center mt-4">
          <Col>
            <TabContainer defaultActiveKey="module-content-tab" onSelect={e => setCurrentActiveKey(e)} activeKey={currentActiveKey} mountOnEnter sid="content-create-tab">
              <Nav style={{ justifyContent: 'space-between' }} className="flex-row content-create__tabs">
                <Row style={{ margin: 0 }}>
                  <NavItem id="module-content-tab">
                    <Nav.Link eventKey="module-content-tab">{t('module-preference/module-content')}</Nav.Link>
                  </NavItem>
                  <NavItem id="user-attempts-tab">
                    <Nav.Link eventKey="user-attempts-tab">{t('module-preference/user-attempts')}</Nav.Link>
                  </NavItem>
                  <NavItem id="user-status-tab">
                    <Nav.Link eventKey="user-status-tab">{t('module-preference/user-status')}</Nav.Link>
                  </NavItem>
                </Row>
                <Row style={{ margin: 0 }}>
                  <a className="download-link" target="_blank" rel="noopener noreferrer" href={`${BASE_URL}/organizations/${orgId}/stats/download?moduleId=${moduleId}&token=${authToken}`}>
                    {t('statstics/download-advanced-statistics')}
                  </a>
                </Row>
              </Nav>
              <TabContent>
                <TabPane className="tab1" eventKey="module-content-tab">
                  <Row>
                    <Col>
                      <ModuleContents orgId={orgId} moduleId={moduleId} contents={contents} localeLanguages={localeLanguages} currentOrganization={currentOrganization} dispatcher={dispatcher} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane className="tab1" eventKey="user-attempts-tab">
                  <Row>
                    <Col>
                      <ModuleStatistics orgId={orgId} moduleId={moduleId} contents={contents} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane className="tab1" eventKey="user-status-tab">
                  <Row>
                    <Col>
                      <UserStatus moduleId={moduleId} orgId={orgId} contents={contents} />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </TabContainer>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

ModulePreferenceBelowTabs.propTypes = {
  authToken: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  moduleId: PropTypes.string,
  contents: ContentsPropTypesShape,
  localeLanguages: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentOrganization: PropTypes.shape({}),
  dispatcher: PropTypes.func.isRequired
};

ModulePreferenceBelowTabs.defaultProps = {
  localeLanguages: []
};

const mapStateToProps = ({ userState, adminState }) => {
  return {
    localeLanguages: adminState.localLanguages,
    authToken: get(userState, 'user.authToken', null)
  };
};
const mapDispatchToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModulePreferenceBelowTabs));
