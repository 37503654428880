import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import {
  ORGANIZATION,
  ORGANIZATION_SUCCESS,
  ORGANIZATION_FAILURE,
  ALLOWED_ROLES,
  ALLOWED_ROLES_SUCCESS,
  ALLOWED_ROLES_FAILURE,
  GROUP_LIST,
  GROUP_LIST_SUCCESS,
  GROUP_LIST_FAILURE,
  ORGANIZATION_DETAILS,
  ORGANIZATION_DETAILS_SUCCESS,
  ORGANIZATION_DETAILS_FAILURE,
  GET_ALL_TIMEZONE,
  GET_ALL_TIMEZONE_SUCCESS,
  GET_ALL_TIMEZONE_FAILURE,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  FETCH_LOCALE_LANGUAGE,
  FETCH_LOCALE_LANGUAGE_SUCCESS,
  FETCH_LOCALE_LANGUAGE_FAILURE,
  FETCH_PUBLICATION,
  FETCH_PUBLICATION_SUCCESS,
  FETCH_PUBLICATION_FAILURE,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE
} from 'utils/constants';

export const getOrganisationList = () => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [ORGANIZATION, ORGANIZATION_SUCCESS, ORGANIZATION_FAILURE]
  }
});

export const getOrganisationDetails = orgId => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${orgId}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [ORGANIZATION_DETAILS, ORGANIZATION_DETAILS_SUCCESS, ORGANIZATION_DETAILS_FAILURE]
  }
});

export const getAllowedRoles = () => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/allowedRoles`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [ALLOWED_ROLES, ALLOWED_ROLES_SUCCESS, ALLOWED_ROLES_FAILURE]
  }
});
export const getGroupList = orgId => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${orgId}/groups`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [GROUP_LIST, GROUP_LIST_SUCCESS, GROUP_LIST_FAILURE]
  }
});

export const getAllTimezones = () => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/timezones`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [GET_ALL_TIMEZONE, GET_ALL_TIMEZONE_SUCCESS, GET_ALL_TIMEZONE_FAILURE]
  }
});

export const updateOrganization = ({ payload, orgId }) => {
  return {
    [RSAA]: {
      endpoint: `${BASE_URL}/organizations/${orgId}`,
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json' },
      types: [UPDATE_ORGANIZATION, UPDATE_ORGANIZATION_SUCCESS, UPDATE_ORGANIZATION_FAILURE]
    }
  };
};

export const fetchLocaleLanguages = () => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/locales`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [FETCH_LOCALE_LANGUAGE, FETCH_LOCALE_LANGUAGE_SUCCESS, FETCH_LOCALE_LANGUAGE_FAILURE]
  }
});

export const fetchPublications = () => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/publications`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [FETCH_PUBLICATION, FETCH_PUBLICATION_SUCCESS, FETCH_PUBLICATION_FAILURE]
  }
});

export const updateUserSettings = payload => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/settings`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
    types: [UPDATE_SETTINGS, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAILURE]
  }
});
