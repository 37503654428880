import React from 'react';
import PropTypes from 'prop-types';

import IconWrapper from 'atoms/IconWrapper';
import ContentSelectorModal from '../../../../ModalElements/ContentSelectorModal';
import './module-content-add-button.scss';

const blockname = 'module-content-add-button-container';

const ModuleContentAddButton = ({ orgId, showContentSelector, setShowContentSelector, assignedContentSet, handleAddContents }) => {
  return (
    <div className={`${blockname}`}>
      <IconWrapper type="Plus" onClick={() => setShowContentSelector(true)} />
      <ContentSelectorModal orgId={orgId} show={showContentSelector} setShow={setShowContentSelector} assignedContentSet={assignedContentSet} onAddSelected={handleAddContents} />
    </div>
  );
};

ModuleContentAddButton.propTypes = {
  orgId: PropTypes.string.isRequired,
  showContentSelector: PropTypes.bool.isRequired,
  setShowContentSelector: PropTypes.func.isRequired,
  assignedContentSet: PropTypes.shape({}),
  handleAddContents: PropTypes.func.isRequired
};

ModuleContentAddButton.defaultProps = {};

export default ModuleContentAddButton;
