import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import { get } from 'lodash';

import { Radio, RadioGroup } from 'atoms/Radio';
import ImageThumbnail from 'atoms/ImageThumbnail';
import Feedback from 'atoms/Feedback';
import Modal from 'atoms/Modal';
import Button from 'atoms/Button';
import ThreeDots from 'atoms/ThreeDots';
import Input from 'atoms/Input';
import { fetcher } from 'hooks/useFetcher';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { AllowedMediaTypeSource, getMediaPayload } from 'utils/calculationUtilities';
import NoPPTImage from 'assets/icons/nopresentation.svg';
import { createModuleData, updateModuleData, dispatchCustomAction } from './actions';
import './ModuleData.scss';

const reducer = (state, action) => {
  const { key, value } = action;
  switch (action.type) {
    case 'inputChange':
    case 'inputRadio':
    case 'dropDown':
      return {
        ...state,
        ...{ [key]: value },
        modulePreferenceAddLanguagePristine: false
      };
    case 'setMedia': {
      const { media } = action;
      return {
        ...state,
        media,
        modulePreferenceAddLanguagePristine: false,
        mediaPristine: true
      };
    }
    case 'initialState': {
      return {
        ...state,
        ...action.payload,
        modulePreferenceAddLanguagePristine: true,
        mediaPristine: false
      };
    }
    default:
      throw new Error();
  }
};

const ModuleData = ({ computedMatch, ...props }) => {
  const t = useFormatMessage();
  const { orgid } = computedMatch.params;
  const [state, dispatcher] = useReducer(reducer, {});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    langCode,
    moduleData,
    currentModule: { _id: moduleId, defaultLangCode }
  } = props;

  const { moduleTitle, description, setDefaultLanguage, media, moduleDataId, modulePreferenceAddLanguagePristine } = state;

  useEffect(() => {
    const defaultLanguage = defaultLangCode && defaultLangCode.toUpperCase();
    dispatcher({
      type: 'initialState',
      payload: { ...moduleData, setDefaultLanguage: defaultLanguage }
    });
  }, [moduleData]);

  const handleUpsertModuleData = () => {
    const defaultLanguage = state.setDefaultLanguage === langCode;
    const payload = { ...state, langCode, setDefaultLanguage: defaultLanguage, ...getMediaPayload(state.media, state.mediaPristine, 'media') };
    if (moduleDataId) {
      props.updateModuleData({
        payload,
        moduleId,
        moduleDataId,
        orgid
      });
    } else props.createModuleData({ payload, moduleId, orgid });
  };

  const handleSaveMedia = media => {
    dispatcher({
      type: 'setMedia',
      media
    });
  };

  const handleDelete = async () => {
    try {
      if (orgid && moduleId && moduleDataId) {
        const result = await fetcher(`/organizations/${orgid}/modules/${moduleId}/moduleData/${moduleDataId}`, 'DELETE', {}, JSON.stringify({}));
        if (result.errors) {
          dispatchCustomAction('DELETE_MODULE_LANG_DATA_FAILURE');
          return;
        }
        dispatchCustomAction('DELETE_MODULE_LANG_DATA_SUCCESS');
        setShowDeleteModal(false);
      }
      props.onDelete(langCode);
    } catch (err) {
      dispatchCustomAction('DELETE_MODULE_LANG_DATA_FAILURE');
    }
  };

  const getOptions = () => [
    {
      name: t('common/delete'),
      handler: () => {
        setShowDeleteModal(true);
      }
    }
  ];

  return (
    <React.Fragment>
      <Container className="module-preference-lang">
        <Col className="col-md-8" style={{ padding: '0' }}>
          <Col className="module-preference-lang__row">
            <div className="module-preference-lang__row__title">{t('common/content-title')}</div>
            <Input
              className="module-preference-lang__row__input-title"
              value={moduleTitle}
              placeholder={t('common/content-title')}
              onChange={e => {
                dispatcher({
                  type: 'inputChange',
                  key: 'moduleTitle',
                  value: e.target.value
                });
              }}
            />
          </Col>
          <Col className="module-preference-lang__row">
            <div className="module-preference-lang__row__title">{t('common/description')}</div>
            <Input
              className="module-preference-lang__row__input-title"
              value={description}
              placeholder={t('common/description')}
              as="textarea"
              onChange={e => {
                dispatcher({
                  type: 'inputChange',
                  key: 'description',
                  value: e.target.value
                });
              }}
            />
          </Col>
          <Col className="module-preference-lang__row">
            <RadioGroup
              name="mcq-preference-default-lang"
              onSelect={value => {
                dispatcher({
                  type: 'inputRadio',
                  key: 'setDefaultLanguage',
                  value: value.value
                });
              }}
            >
              <Radio
                labelClass="module-preference-lang__default-lang"
                value={langCode}
                checked={setDefaultLanguage === langCode}
                description={t('common/set-as-default-language-description')}
                label={t('common/set-as-default-language-title')}
              />
            </RadioGroup>
          </Col>
          <Row className="module-preference-lang__row" style={{ paddingTop: '40px' }}>
            <Button style={{ width: '95px' }} onClick={handleUpsertModuleData} notPristine={modulePreferenceAddLanguagePristine}>
              {t('common/save')}
            </Button>
            <ThreeDots options={getOptions(moduleDataId)} />
          </Row>
        </Col>

        <Col className="module-preference__thumbnail">
          <div style={{ marginTop: '15px' }}>
            {t('common/video-text')}
            <ImageThumbnail
              placeholderIcon={NoPPTImage}
              defaultMedia={media}
              allowedFileType={[AllowedMediaTypeSource.FILE, AllowedMediaTypeSource.VIMEO, AllowedMediaTypeSource.YOUTUBE]}
              handleSave={handleSaveMedia}
            />
          </div>
        </Col>
      </Container>
      <Feedback
        allowedTypesKey="ModuleData"
        message={props.message.CREATE_MODULE_LANG_DATA_SUCCESS || props.message.UPDATE_MODULE_LANG_DATA_SUCCESS}
        errorList={props.errors.CREATE_MODULE_LANG_DATA_FAILURE || props.errors.UPDATE_MODULE_LANG_DATA_FAILURE}
      />
      <Modal
        heading={t('common/modal/please-confirm')}
        modalBody={<p>{t('common/modal/delete-confirmation-text')}</p>}
        show={showDeleteModal}
        isAlert
        onSave={handleDelete}
        onHide={() => setShowDeleteModal(false)}
      />
    </React.Fragment>
  );
};

ModuleData.propTypes = {
  createModuleData: PropTypes.func.isRequired,
  updateModuleData: PropTypes.func.isRequired,
  dispatchCustomAction: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  currentModule: PropTypes.shape({
    _id: PropTypes.string,
    defaultLangCode: PropTypes.string
  }),
  moduleData: PropTypes.shape({}),
  langCode: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    CREATE_MODULE_LANG_DATA_FAILURE: PropTypes.string,
    UPDATE_MODULE_LANG_DATA_FAILURE: PropTypes.bool
  }).isRequired,
  message: PropTypes.shape({
    CREATE_MODULE_LANG_DATA_SUCCESS: PropTypes.string,
    UPDATE_MODULE_LANG_DATA_SUCCESS: PropTypes.bool
  }).isRequired,
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({
      orgid: PropTypes.string
    })
  })
};

ModuleData.defaultProps = {
  currentModule: {},
  moduleData: null,
  onDelete: () => {}
};

const mapStateToProps = ({ modulesState }) => {
  const errors = get(modulesState, 'errors', []);
  const message = get(modulesState, 'message', []);
  const currentModule = get(modulesState, 'currentModule', []);
  return {
    currentModule,
    errors,
    message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createModuleData: bindActionCreators(createModuleData, dispatch),
    updateModuleData: bindActionCreators(updateModuleData, dispatch),
    dispatchCustomAction: bindActionCreators(dispatchCustomAction, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleData);
