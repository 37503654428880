import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import AppContext from '../../app-context';

import { UserPropTypesShape } from 'utils/core-proptypes';
import UserDashboard from './UserDashboard';
import AdminView from './AccountManagement';
import RoleCheck from '../RoleCheck';
import { getOrganisationList, getGroupList, getAllowedRoles } from '../../actions';
import './users-and-groups.scss';

const UsersAndGroups = props => {
  const context = useContext(AppContext);
  const { user: { user: { role = '', organizationId = null }, authToken } = {} } = props;

  useEffect(() => {
    if (!context.selectedOrg) {
      context.setContextState({ selectedOrg: organizationId });
    }
  }, []);
  const selectedOrg = context.selectedOrg || organizationId;

  // const { parentOrganizationId = null } = props.organizationList.find(organization => organization._id === selectedOrg) || {};
  // const allowPartnersToManageUsers = parentOrganizationId === organizationId || selectedOrg === organizationId;
  const allowPartnersToManageUsers = true;

  useEffect(() => {
    props.getAllowedRoles();
    if (role === 'ADMIN' || role === 'PARTNER') {
      props.getOrganisationList();
    }
    if (selectedOrg) {
      props.getGroupList(selectedOrg);
    }
  }, [selectedOrg, props.organizationList.length, props.deleteGroupSuccess]);

  return (
    <React.Fragment>
      <Container fluid="lg">
        <Row className="justify-content-center">
          <Col xs="12">
            <RoleCheck allowed={['ADMIN', 'PARTNER']} component={<AdminView selectedOrg={selectedOrg} />} />
            <Row className="users-and-groups__tabs justify-content-center">
              <Col xs={12} />
              <RoleCheck
                allowed={['ADMIN', 'PARTNER', 'MANAGER']}
                component={<UserDashboard selectedOrg={selectedOrg} role={role} authToken={authToken} allowPartnersToManageUsers={allowPartnersToManageUsers} {...props} />} // default organisationId in case of no selectedOrg and selected org valid only for admin
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

UsersAndGroups.propTypes = {
  getOrganisationList: PropTypes.func.isRequired,
  getGroupList: PropTypes.func.isRequired,
  getAllowedRoles: PropTypes.func.isRequired,
  deleteGroupSuccess: PropTypes.bool,
  organizationList: PropTypes.arrayOf(PropTypes.object),
  user: UserPropTypesShape
};

UsersAndGroups.defaultProps = {
  organizationList: [],
  deleteGroupSuccess: false
};

const mapStateToProps = ({ adminState, userState, groupState }) => {
  return {
    organizationList: adminState.organizations,
    user: userState.user,
    deleteGroupSuccess: groupState.deleteGroupSuccess
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getOrganisationList: bindActionCreators(getOrganisationList, dispatch),
    getAllowedRoles: bindActionCreators(getAllowedRoles, dispatch),
    getGroupList: bindActionCreators(getGroupList, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersAndGroups));
