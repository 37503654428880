import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import Feedback from 'atoms/Feedback';
import Input from 'atoms/Input';
import { useFormatMessage } from 'hooks/useFormatMessage';
import './modal-elements.scss';

const ChangePassword = ({ dispatcher, changePassword, errors: serverErrors, successMessage }) => {
  const t = useFormatMessage();
  const { oldPassword, newPassword, errors } = changePassword;
  return (
    <Form>
      <Form.Group>
        <Form.Label>{t('common/modal-body/old-password')}</Form.Label>
        <Input
          type="password"
          value={oldPassword}
          onChange={e => {
            return dispatcher({
              type: 'inputChange',
              key: 'oldPassword',
              value: e.target.value
            });
          }}
          placeholder={t('common/modal-body/old-password')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('common/modal-body/new-password')}</Form.Label>
        <Input
          type="password"
          value={newPassword}
          onChange={e =>
            dispatcher({
              type: 'inputChange',
              key: 'newPassword',
              value: e.target.value
            })
          }
          placeholder={t('common/modal-body/new-password')}
        />
      </Form.Group>
      <Feedback message={successMessage} errorList={serverErrors || errors} allowedTypesKey="MyAccountChangePassword" />
    </Form>
  );
};

ChangePassword.propTypes = {
  changePassword: PropTypes.shape({
    oldPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }),
  dispatcher: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  successMessage: PropTypes.arrayOf(PropTypes.shape({}))
};

ChangePassword.defaultProps = {
  changePassword: {}
};

export default ChangePassword;
