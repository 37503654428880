import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const ModuleContentSetupContext = React.createContext();

const defaultLayout = localStorage.getItem('layout') || 'grid';
const defaultSort = localStorage.getItem('sortType') || 'alphabetical';

const ModuleContentSetupProvider = ({ children }) => {
  const [layout, setLayout] = useState(defaultLayout);
  const [sortType, setSortType] = useState(defaultSort);

  const handleSetSortType = sortType => {
    localStorage.setItem('sortType', sortType);
    setSortType(sortType);
  };

  const handleSetLayout = layout => {
    localStorage.setItem('layout', layout);
    setLayout(layout);
  };

  return (
    <ModuleContentSetupContext.Provider
      value={{
        layout,
        sortType,
        setLayout: handleSetLayout,
        setSortType: handleSetSortType
      }}
    >
      <>{children}</>
    </ModuleContentSetupContext.Provider>
  );
};

export const useModuleContentSetupState = () => useContext(ModuleContentSetupContext);

ModuleContentSetupProvider.propTypes = {
  children: PropTypes.element
};

export default ModuleContentSetupProvider;
