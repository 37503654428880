let prefixes = {};

export function resetId() {
  prefixes = {};
}

export function uniqueId(prefix = '') {
  if (typeof prefixes[prefix] !== 'undefined') {
    prefixes[prefix] += 1;
  } else {
    prefixes[prefix] = 0;
  }

  return `${prefix}${prefixes[prefix]}`;
}
