import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import { ASSIGN_GROUP, ASSIGN_GROUP_SUCCESS, ASSIGN_GROUP_FAILURE, SHOW_REMINDER_SUCCESS } from 'utils/constants';

export const dispatchUserMessage = actionType => {
  return { type: actionType };
};

export const assignGroup = ({ payload, orgId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${orgId}/users`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [ASSIGN_GROUP, ASSIGN_GROUP_SUCCESS, ASSIGN_GROUP_FAILURE]
  }
});

export const showReminderSuccess = () => ({
  type: SHOW_REMINDER_SUCCESS
});
