import React from 'react';
import PropTypes from 'prop-types';

const Accordion = ({ children }) => {
  const childrenWithExtraProp = React.Children.map(children, child => {
    return React.cloneElement(child, {});
  });
  return <div className="accordion">{childrenWithExtraProp}</div>;
};
export default Accordion;

Accordion.propTypes = {
  children: PropTypes.node.isRequired
};
