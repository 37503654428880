import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import {
  INITIALIZE_CURRENT_MODULE,
  INITIALIZE_CURRENT_MODULE_SUCCESS,
  INITIALIZE_CURRENT_MODULE_FAILURE,
  CREATE_MODULE,
  CREATE_MODULE_SUCCESS,
  CREATE_MODULE_FAILURE,
  CREATE_MODULE_LANG_DATA,
  CREATE_MODULE_LANG_DATA_SUCCESS,
  CREATE_MODULE_LANG_DATA_FAILURE,
  UPDATE_MODULE_LANG_DATA,
  UPDATE_MODULE_LANG_DATA_SUCCESS,
  UPDATE_MODULE_LANG_DATA_FAILURE
} from 'utils/constants';

export const initializeCurrentModule = ({ selectedOrg: organizationId, moduleId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/modules/${moduleId}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [INITIALIZE_CURRENT_MODULE, INITIALIZE_CURRENT_MODULE_SUCCESS, INITIALIZE_CURRENT_MODULE_FAILURE]
  }
});

export const createModule = ({ payload, orgid: organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/modules`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [CREATE_MODULE, CREATE_MODULE_SUCCESS, CREATE_MODULE_FAILURE]
  }
});

export const createModuleData = ({ payload, moduleId, orgid: organizationId } = {}) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/modules/${moduleId}/moduleData`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [CREATE_MODULE_LANG_DATA, CREATE_MODULE_LANG_DATA_SUCCESS, CREATE_MODULE_LANG_DATA_FAILURE]
  }
});

export const updateModuleData = ({ payload, moduleId, moduleDataId, orgid: organizationId } = {}) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/modules/${moduleId}/moduleData/${moduleDataId}`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [UPDATE_MODULE_LANG_DATA, UPDATE_MODULE_LANG_DATA_SUCCESS, UPDATE_MODULE_LANG_DATA_FAILURE]
  }
});

export const dispatchCustomAction = (actionType, payload) => {
  return { type: actionType, payload };
};
