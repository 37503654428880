import React, { useRef, useMemo, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import get from 'lodash/get';

import IconWrapper from 'atoms/IconWrapper';
import ThreeDots from 'atoms/ThreeDots';
import { mediaSelector } from 'utils/selectors';
import Button from 'atoms/Button';
import './thumbnail-card-info.scss';
import { useFormatMessage } from 'hooks/useFormatMessage';

const blockname = 'thumbnail-info-container';
const rowBlockName = 'thumbnail-info-container-row-layout';

const ThumbnailCardInfo = ({ layout, ...props }) => {
  return layout === 'grid' ? <GridView {...props} /> : <ListView {...props} />;
};

const GridView = ({ cardInfo, options, previewOffset, renderPreview }) => {
  const t = useFormatMessage();
  const [previewing, setPreviewing] = useState(false);
  const [previewHeight, setPreviewHeight] = useState(0);
  const cardFrontBody = useRef(null);
  const { thumbnailImage, heading, details = [], status, warning } = cardInfo;

  const thumbnailUrl = useMemo(() => (thumbnailImage ? mediaSelector({ media: thumbnailImage }) : null), [thumbnailImage]);
  const statusIconType = useMemo(() => (status === 'ACTIVE' ? 'GreenDot' : 'RedDot'), [status]);

  useLayoutEffect(() => {
    if (cardFrontBody && cardFrontBody.current) {
      setPreviewHeight(cardFrontBody.current.clientHeight + previewOffset);
    }
  }, [cardFrontBody]);

  return (
    <Col xs={12} sm={6} md={4} lg={3} className="p-0">
      <div
        tabIndex="0"
        className={`${blockname}`}
        onBlur={e => {
          e.preventDefault();
          setPreviewing(false);
        }}
      >
        <div className={`${blockname}__body`}>
          <IconWrapper srcUrl={thumbnailUrl} type="NoImage" className={`${blockname}__thumbnail`} />
          {warning && <IconWrapper type="Warning" className={`${blockname}__warning`} />}
          <div ref={cardFrontBody} className={`${blockname}__front`}>
            <h3 className={`${blockname}__heading`}>{heading}</h3>
            <div className={`${blockname}__details-container`}>
              {details.map(item => (
                <div key={item.type} className={`${blockname}__details-row`}>
                  <IconWrapper type={item.type} className={`${blockname}__details-icon`} />
                  <div className={`${blockname}__details-row__details-text`}>{item.value}</div>
                </div>
              ))}
            </div>
          </div>
          {renderPreview && (
            <div style={{ minHeight: `${previewHeight}px` }} className={`${blockname}__back ${blockname}__back--${previewing ? 'show' : 'hide'}`}>
              <div className={`${blockname}__preview ${blockname}__preview--${previewing ? 'show' : 'hide'}`}>{renderPreview}</div>
            </div>
          )}
        </div>
        <div className={`${blockname}__footer`}>
          <div className={`${blockname}__footer-status`}>
            <IconWrapper type={statusIconType} />
            <div>{status && t(`common/${status.toLowerCase()}`)}</div>
          </div>
          {renderPreview && (
            <div className={`${blockname}__footer-show-preview`}>
              <IconWrapper type={previewing ? 'PreviewHide' : 'Preview'} onClick={() => setPreviewing(!previewing)} />
            </div>
          )}
          <div className={`${blockname}__footer-three-dots`}>
            <ThreeDots options={options.sort((a, b) => get(a, 'order.grid', 0) - get(b, 'order.grid', 0))} />
          </div>
        </div>
      </div>
    </Col>
  );
};

const ListView = ({ type, cardInfo, options }) => {
  const t = useFormatMessage();
  const { heading, status } = cardInfo;

  const statusIconType = useMemo(() => (status === 'ACTIVE' ? 'GreenDot' : 'RedDot'), [status]);

  return (
    <div className={`${rowBlockName}`}>
      <div className={`${rowBlockName}__front`}>
        <div className={`${rowBlockName}__front__heading`}>
          <IconWrapper type={statusIconType} />
          <p className={`${rowBlockName}__front__heading_text`}>{heading}</p>
        </div>

        {['module'].includes(type) && <p className={`${rowBlockName}__front__details-text`}>{`${cardInfo.usersAlloted} ${t('common/users')}`}</p>}
        {['content', 'shared-content'].includes(type) && <p className={`${rowBlockName}__front__details-text`}>{cardInfo.description}</p>}

        <div className={`${rowBlockName}__front__action-row`}>
          {options
            .filter(x => x.showInListView)
            .sort((a, b) => get(a, 'order.list', 0) - get(b, 'order.list', 0))
            .map(option => (
              <Button onClick={() => option.handler()} key={option.name} style={{ lineHeight: '1', color: '#00aaa4', display: 'block' }} className="p-0" size="sm" variant="link">
                {option.name}
              </Button>
            ))}
        </div>
      </div>
    </div>
  );
};

ThumbnailCardInfo.propTypes = {
  cardInfo: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    usersAlloted: PropTypes.number,
    details: PropTypes.arrayOf(PropTypes.shape({})),
    warning: PropTypes.bool,
    thumbnailImage: PropTypes.shape({}),
    status: PropTypes.string
  }),
  layout: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      order: PropTypes.shape({
        grid: PropTypes.number,
        list: PropTypes.number
      }),
      showInListView: PropTypes.bool,
      handler: PropTypes.func
    })
  ),
  dateOption: PropTypes.shape({}),
  previewOffset: PropTypes.number,
  renderPreview: PropTypes.node
};

ThumbnailCardInfo.defaultProps = {
  layout: 'grid',
  cardInfo: {},
  options: [],
  dateOption: null,
  renderPreview: null,
  previewOffset: 40
};

GridView.propTypes = {
  cardInfo: ThumbnailCardInfo.propTypes.cardInfo,
  options: ThumbnailCardInfo.propTypes.options,
  dateOption: ThumbnailCardInfo.propTypes.dateOption,
  previewOffset: ThumbnailCardInfo.propTypes.previewOffset,
  renderPreview: ThumbnailCardInfo.propTypes.renderPreview
};

ListView.propTypes = {
  type: ThumbnailCardInfo.propTypes.type,
  cardInfo: ThumbnailCardInfo.propTypes.cardInfo,
  options: ThumbnailCardInfo.propTypes.options,
  renderPreview: ThumbnailCardInfo.propTypes.renderPreview
};

export default ThumbnailCardInfo;
