import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { get, sortBy } from 'lodash';

import Modal from 'atoms/Modal';
import Feedback from 'atoms/Feedback';
import IconWrapper from 'atoms/IconWrapper';
import DropDown from 'atoms/DropDown';

import { useFormatMessage } from 'hooks/useFormatMessage';
import { LanguagesPropTypesShape } from 'utils/core-proptypes';
import './content-list-language-selector.scss';

const LanguageSelector = ({ defaultLangCode, content, languages, onHide, onSelect, userErrorActivityErrors }) => {
  const t = useFormatMessage();
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLangCode);

  const contentDataList = get(content, 'content.data', []);
  const languageMapFromContent = sortBy(
    contentDataList.map(contentData => {
      const { langCode } = contentData;
      const langObject = languages.find(lang => lang.id === contentData.langCode) || {};
      return { id: langCode, value: langObject.value };
    }),
    'value'
  );

  const multipleLanguageBody = (
    <div className="multiple-language">
      <Row>
        <Col style={{ flexGrow: 6 }}>
          <DropDown
            placeholder={t('common/select')}
            onSelect={eventkey => {
              setSelectedLanguage(eventkey.id);
            }}
            heading={selectedLanguage}
            values={languageMapFromContent}
          />
        </Col>
        <Col className="pl-0">
          <IconWrapper className="language-selector__list__language-select pl-10" onClick={() => onSelect(selectedLanguage)} type="GoButton" alt="select language" />
        </Col>
      </Row>
    </div>
  );

  const modalBody = (
    <div className="language-selector__list">
      {languageMapFromContent.length > 1 && multipleLanguageBody}
      {userErrorActivityErrors.length > 0 && <Feedback errorList={userErrorActivityErrors} allowedTypesKey="LanguageSelector" />}
    </div>
  );

  const selector = (
    <div>
      <Modal
        heading={languageMapFromContent.length === 1 ? '' : t('common/modal/select-your-preferred-language-for-the-content')}
        modalBody={modalBody}
        show
        showSave={false}
        showCancel={false}
        onHide={() => onHide()}
        isAlert
        className="language-selector"
      />
    </div>
  );

  return selector;
};

LanguageSelector.propTypes = {
  defaultLangCode: PropTypes.string.isRequired,
  content: PropTypes.shape({}).isRequired,
  userErrorActivityErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  languages: LanguagesPropTypesShape
};

export default LanguageSelector;
