// import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, find } from 'lodash';
import { UserPropTypesShape } from 'utils/core-proptypes';

const RoleCheck = props => {
  const { userProfile } = props;
  const role = get(userProfile, 'user.role');
  const Comp = props.component;
  const FallBackComp = props.fallbackComp;
  if (
    find(props.allowed, r => {
      return r === role;
    })
  ) {
    return Comp;
  }
  if (FallBackComp) return FallBackComp;
  return null;
};

RoleCheck.propTypes = {
  component: PropTypes.element.isRequired,
  fallbackComp: PropTypes.element,
  userProfile: UserPropTypesShape,
  allowed: PropTypes.arrayOf(PropTypes.string)
};

RoleCheck.defaultProps = {
  userProfile: {},
  allowed: [],
  fallbackComp: undefined
};

const mapStateToProps = ({ userState }) => {
  return {
    userProfile: userState.user
  };
};

export default withRouter(connect(mapStateToProps, {})(RoleCheck));
