import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import sn from 'classnames';
import { Popover, Overlay, Col, Row } from 'react-bootstrap';
import { PDFReader } from 'reactjs-pdf-reader';
import MindzeedPlaceholder from './mindzeed_placeholder.png';
import Button from 'atoms/Button';
import Input from 'atoms/Input';
import IconWrapper from 'atoms/IconWrapper';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { BASE_URL, axiosConfig } from 'utils/api';
import { isYoutubeUrl, isVimeoUrl, AllowedMediaTypeSource } from 'utils/calculationUtilities';
import VideoPlayer from '../VideoPlayer';
import './file-uploader.scss';

const FileUploader = ({ show, shouldUploadMindzeedPlaceholderImage = false, target, onClickSave, allowedFileType, allowedExtension, dispatcher, defaultActiveTab, thumbnailImage }) => {
  const t = useFormatMessage();
  const [active, setActive] = useState(defaultActiveTab);
  const [file, setFile] = useState([]);
  const [vimeoUrl, setvimeoUrl] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [notPristineVimeo, setNotPristineVimeo] = useState(false);
  const [notPristineYoutube, setNotPristineYoutube] = useState(false);

  useEffect(() => {
    if (shouldUploadMindzeedPlaceholderImage) {
      fetch(MindzeedPlaceholder)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], 'defaultImage.jpg', { type: blob.type });
          uploadPlaceholderThumbnail(file);
        });
    }
  }, [shouldUploadMindzeedPlaceholderImage]);

  const handleFiles = files => {
    setFile(files[0]);
    dispatcher({
      type: 'setThumbnailImage',
      payload: URL.createObjectURL(files[0])
    });
  };

  const handleOptionSelect = id => {
    setActive(id);
  };

  const handleVimeoInput = url => {
    setvimeoUrl(url);
    if (isVimeoUrl(url)) {
      setNotPristineVimeo(true);
    } else {
      setNotPristineVimeo(false);
    }
  };

  const handleYoutubeInput = url => {
    setYoutubeUrl(url);
    if (isYoutubeUrl(url)) {
      setNotPristineYoutube(true);
    } else {
      setNotPristineYoutube(false);
    }
  };

  const getFileExtension = file => {
    var re = /(?:\.([^.]+))?$/;
    if (file.metaData) {
      return re.exec(file.metaData.key)[1];
    }
    return re.exec(file.key)[1];
  };

  const getBlobPreview = () => {
    const ext = getFileExtension({ key: file.name || thumbnailImage });
    switch (ext) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png': {
        return <IconWrapper style={{ width: '200px' }} src={thumbnailImage} />;
      }
      case 'pdf': {
        return (
          <div style={{ overflow: 'scroll', height: 150 }}>
            <PDFReader url={thumbnailImage} showAllPage width={250} />
          </div>
        );
      }
      case 'mp4':
      case 'webm': {
        return <VideoPlayer embed={false} defaultUrl={thumbnailImage} />;
      }
      default: {
        return null;
      }
    }
  };

  const uploadThumbnail = async () => {
    dispatcher({
      type: 'setShow',
      payload: { show: false, target: null, name: file.name }
    });

    dispatcher({ type: 'setUploadingStatus', payload: 'loading' });
    if (active === AllowedMediaTypeSource.FILE) {
      const endpoint = `${BASE_URL}/upload`;
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post(endpoint, formData, axiosConfig('multipart/form-data'));
      onClickSave({ type: 'FILE', url: response.data.file, metaData: response.data.metaData });
    } else if (active === AllowedMediaTypeSource.VIMEO) {
      onClickSave({ type: active, url: vimeoUrl });
      dispatcher({ type: 'setMedia', payload: { url: vimeoUrl, type: AllowedMediaTypeSource.VIMEO } });
    } else if (active === AllowedMediaTypeSource.YOUTUBE) {
      onClickSave({ type: active, url: youtubeUrl });
      dispatcher({ type: 'setMedia', payload: { url: youtubeUrl, type: AllowedMediaTypeSource.YOUTUBE } });
    }
    dispatcher({ type: 'setUploadingStatus', payload: 'loaded' });
  };

  const uploadPlaceholderThumbnail = async file => {
    const endpoint = `${BASE_URL}/upload`;
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(endpoint, formData, axiosConfig('multipart/form-data'));
    onClickSave({ type: 'FILE', url: response.data.file, metaData: response.data.metaData }, true);
  };

  return (
    <div>
      <Overlay
        rootClose
        onHide={() => {
          dispatcher({ type: 'setShow', payload: { show: false, target: null } });
        }}
        show={show}
        target={target}
        placement="bottom"
      >
        <Popover id="give-it-some-id">
          <Row className="upload" md="12">
            <Col className="upload__left-pane">
              {allowedFileType.map(item => (
                <Row key={item} className={sn('upload__left-pane__item', item === active ? 'upload__active' : '')} id={item} onClick={e => handleOptionSelect(e.target.id)}>
                  {item}
                </Row>
              ))}
            </Col>
            <Col md="8" className="upload__right-pane">
              {active === AllowedMediaTypeSource.FILE && (
                <label htmlFor="input-file">
                  {t('file-uploader/click-to-upload-new-file')}
                  <input
                    id="input-file"
                    type="file"
                    accept={allowedExtension}
                    className="upload__right-pane__file"
                    onChange={e => {
                      handleFiles(e.target.files);
                    }}
                  />
                </label>
              )}
              {active === AllowedMediaTypeSource.FILE && thumbnailImage && getBlobPreview(thumbnailImage)}
              {active === AllowedMediaTypeSource.VIMEO && (
                <Input onChange={e => handleVimeoInput(e.target.value)} style={{ marginTop: '43px' }} value={vimeoUrl} placeholder={t('placeholder/enter-vimeo-url')} />
              )}
              {active === AllowedMediaTypeSource.YOUTUBE && (
                <Input onChange={e => handleYoutubeInput(e.target.value)} style={{ marginTop: '43px' }} value={youtubeUrl} placeholder={t('placeholder/enter-youtube-url')} />
              )}
            </Col>
          </Row>
          <Row className="action-row" md="12">
            <Button
              onClick={() => {
                dispatcher({ type: 'setShow', payload: { show: false, target: null } });
              }}
              variant="secondary"
            >
              {t('common/cancel')}
            </Button>
            <Button
              notPristine={
                active === AllowedMediaTypeSource.VIMEO || active === AllowedMediaTypeSource.YOUTUBE
                  ? !((active === AllowedMediaTypeSource.VIMEO && notPristineVimeo) || (active === AllowedMediaTypeSource.YOUTUBE && notPristineYoutube))
                  : false
              }
              onClick={uploadThumbnail}
              variant="primary"
            >
              {t('common/save')}
            </Button>
          </Row>
        </Popover>
      </Overlay>
    </div>
  );
};

FileUploader.propTypes = {
  show: PropTypes.bool,
  shouldUploadMindzeedPlaceholderImage: PropTypes.bool,
  target: PropTypes.object,
  thumbnailImage: PropTypes.string,
  onClickSave: PropTypes.func,
  dispatcher: PropTypes.func.isRequired,
  allowedFileType: PropTypes.arrayOf(PropTypes.string),
  allowedExtension: PropTypes.string,
  defaultActiveTab: PropTypes.string
};

FileUploader.defaultProps = {
  show: false,
  target: null,
  defaultActiveTab: AllowedMediaTypeSource.FILE,
  onClickSave: () => {},
  allowedFileType: [AllowedMediaTypeSource.FILE],
  allowedExtension: 'file'
};

const mapStateToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, {})(FileUploader));
