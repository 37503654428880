import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import ReactContentLoader from 'react-content-loader';
import { get } from 'lodash';

import { checkNaNPercent } from 'utils/calculationUtilities';
import { ContentsPropTypesShape } from 'utils/core-proptypes';
import { useFormatMessage } from 'hooks/useFormatMessage';
import useFetcher from 'hooks/useFetcher';
import './module-statistics.scss';

const Stastics = ({ orgId, moduleId, contents = [] }) => {
  const t = useFormatMessage();
  const { loading, data: modules } = useFetcher(`/organizations/${orgId}/modules/${moduleId}?progress=true`, 1);

  const contentProgress = get(modules, '0', { contents }).contents.map(content => {
    const {
      preferences: { contentName }
    } = get(content, 'content', {});
    const progress = get(content, 'content.progress.attempts', {});
    return { progress, contentName };
  });

  return (
    <div>
      <Container className="progress-container">
        <Col>
          <Row className="progress-container__progress-description">
            <Row style={{ alignItems: 'center' }}>
              <div className="progress-container__progress-description__one-attempt" />
              {t('statstics/one-attempts')}
              <div className="progress-container__progress-description__two-attempt" />
              {t('statstics/two-attempts')}
              <div className="progress-container__progress-description__three-attempt" />
              {t('statstics/three-or-more-attempts')}
            </Row>
          </Row>
          {contentProgress.map(content => {
            const { oneAttempt, twoAttempt, threeOrPlusAttempt, notCompleted } = content.progress;
            const { contentName } = content;
            const oneAttemptPercent = checkNaNPercent((oneAttempt / (oneAttempt + twoAttempt + threeOrPlusAttempt + notCompleted)) * 100);
            const twoAttemptPercent = checkNaNPercent((twoAttempt / (oneAttempt + twoAttempt + threeOrPlusAttempt + notCompleted)) * 100);
            const threeOrPlusAttemptPercent = checkNaNPercent((threeOrPlusAttempt / (oneAttempt + twoAttempt + threeOrPlusAttempt + notCompleted)) * 100);
            const completedPercent = checkNaNPercent(((oneAttempt + twoAttempt + threeOrPlusAttempt) / (oneAttempt + twoAttempt + threeOrPlusAttempt + notCompleted)) * 100);
            const showCompletePercent = completedPercent ? `${completedPercent}%` : t('statstics/no-data-yet');
            return (
              <Row key={contentName}>
                {`${contentName} - ${showCompletePercent}`}
                {loading ? (
                  <ReactContentLoader
                    speed={2}
                    style={{
                      width: 'calc(100% - 20px)',
                      height: 'calc(100% - 20px)',
                      backgroundColor: '#ffffff',
                      marginRight: 20,
                      marginBottom: 20
                    }}
                    viewBox="0 0 1120 15"
                    backgroundColor="#dcdcdc"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
                  </ReactContentLoader>
                ) : (
                  <div className="progress-container__progress-indicator">
                    <div className="progress-container__progress-indicator__one-attempt" style={{ width: `${oneAttemptPercent}%` }} />
                    <div className="progress-container__progress-indicator__two-attempt" style={{ width: `${twoAttemptPercent}%` }} />
                    <div className="progress-container__progress-indicator__three-attempt" style={{ width: `${threeOrPlusAttemptPercent}%` }} />
                  </div>
                )}
              </Row>
            );
          })}
        </Col>
      </Container>
    </div>
  );
};

Stastics.propTypes = {
  orgId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  contents: ContentsPropTypesShape
};

export default Stastics;
