import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import './explanation.scss';

const Explanation = ({ explanation }) => {
  return explanation ? <Card.Text className="explanation">{explanation}</Card.Text> : null;
};

Explanation.propTypes = {
  explanation: PropTypes.string
};

Explanation.defaultProps = {
  explanation: ''
};

export default Explanation;
