import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';
import { get } from 'lodash';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import useFetcher, { getSearchStringFromObject, fetcher } from 'hooks/useFetcher';

import Modal from 'atoms/Modal';
import ThreeDots from 'atoms/ThreeDots';
import IconWrapper from 'atoms/IconWrapper';
import ThumbnailCardInfo from 'atoms/ThumbnailCardInfo';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { HistoryPropTypesShape } from 'utils/core-proptypes';
import ContentTypePreview from './../ContentPreview';
import ShareContent from '../../SharedContent/ShareContent';
import ShareContentCopy from '../../ShareContentCopy';
import ContentCardPreview from '../ContentCardPreview';
import ContentAddCard from './ContentAddCard';
import ModuleContentActionRow from '../ModuleContentActionRow';
import { contentMap } from './utils';
import { dispatchCustomAction, initializeCurrentContent, getContentSharedToOrgs, updateContentSharedToOrgs, shareContentCopy } from './actions';
import { useModuleContentSetupState } from '../ModuleContentProvider';
import { ContentCardLoader, ContentListLoader } from './ContentLoader';
import './content-setup-view.scss';

const ContentSetupView = ({ selectedOrg, user, organizationList, ...props }) => {
  const query = { owned: true, shared: false, data: false };
  const t = useFormatMessage();
  const { layout, setLayout, sortType, setSortType } = useModuleContentSetupState();
  const [showContentPreview, setShowContentPreview] = useState(false);
  const [contentDeleteModal, setContentDeleteModal] = useState(false);
  const [contentStatusActivateModal, setContentStatusActivateModal] = useState(false);
  const [contentStatusDeactivateModal, setContentStatusDeactivateModal] = useState(false);
  const [shareContentModal, setShareContentModal] = useState(false);
  const [shareContentCopyModal, setShareContentCopyModal] = useState(false);
  const [shareContentModalOrgs, setShareContentModalOrgs] = useState([]);
  const [contentStatusId, setContentStatusId] = useState('false');
  const [deleteContentId, setDeleteContentId] = useState(null);
  const [activePreviewContentId, setActivePreviewContentId] = useState(null);
  const { loading, data: contents, showLoadMore: hasNextPage, error: itemsError, loadMoreData: loadMore, doFetch, updateData } = useFetcher(
    `/organizations/${selectedOrg}/contents?${getSearchStringFromObject({ sortType: sortType, ...query })}`,
    12,
    true,
    1
  );

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!itemsError,
    rootMargin: '0px 0px 100px 0px'
  });

  useEffect(() => {
    doFetch(`/organizations/${selectedOrg}/contents${getSearchStringFromObject({ sort: sortType, ...query })}`, true);
  }, [selectedOrg, sortType]);

  const { user: { role = '' } = {} } = user;
  const selectedOrgDetails = organizationList.find(org => org._id === selectedOrg);
  const accountType = selectedOrgDetails ? selectedOrgDetails.accountType : '';
  const modifiedData = contents.map(content => contentMap(content, t));

  const handlePreview = ({ _id }) => {
    setActivePreviewContentId(_id);
    setShowContentPreview(true);
  };

  const handleEdit = ({ _id, contentType }) => {
    switch (contentType) {
      case 'mcq':
      case 'MCQ': {
        props.history.push(`/org/${selectedOrg}/content/mcq/${_id}`);
        break;
      }
      case 'presentation':
      case 'PRESENTATION': {
        props.history.push(`/org/${selectedOrg}/content/presentation/${_id}`);
        break;
      }
      case 'carousel':
      case 'CAROUSEL': {
        props.history.push(`/org/${selectedOrg}/content/carousel/${_id}`);
        break;
      }
      case 'story':
      case 'STORY': {
        props.history.push(`/org/${selectedOrg}/content/story/${_id}`);
        break;
      }
      case 'mixed':
      case 'MIXED': {
        props.history.push(`/org/${selectedOrg}/content/mixed/${_id}`);
        break;
      }
      default:
        break;
    }
  };

  // Make this using fetcher
  const saveShareContentCopy = () => {
    props.shareContentCopy({
      contentId: contentStatusId,
      selectedOrg,
      payload: { organizations: shareContentModalOrgs.filter(org => org.isChecked).map(org => org._id) }
    });
    setShareContentCopyModal(false);
  };

  const activateContentStatus = () => {
    handleStatusToggle({ status: 'ACTIVE' });
    setContentStatusActivateModal(false);
  };

  const deactivateContentStatus = () => {
    handleStatusToggle({ status: 'INACTIVE' });
    setContentStatusDeactivateModal(false);
  };

  const handleStatusToggle = async ({ status }) => {
    try {
      const result = await fetcher(`/organizations/${selectedOrg}/contents/${contentStatusId}`, 'PUT', {}, JSON.stringify({ status }));
      if (result.errors) {
        props.dispatchCustomAction('UPDATE_PREFERENCE_FAILURE');
      } else {
        updateData(contents.map(item => (item._id === result.content._id ? { ...item, status: result.content.status } : item)));
        props.dispatchCustomAction('UPDATE_PREFERENCE_SUCCESS');
      }
    } catch (err) {
      props.dispatchCustomAction('UPDATE_PREFERENCE_FAILURE');
    }
  };

  const handleContentDelete = contentId => {
    setDeleteContentId(contentId);
    setContentDeleteModal(true);
  };

  const contentDeleteModalSave = async () => {
    if (deleteContentId) {
      try {
        const result = await fetcher(`/organizations/${selectedOrg}/contents/${deleteContentId}`, 'DELETE', {}, JSON.stringify({}));
        if (result.errors) {
          if (['CANNOT_DELETE_SHARED_OR_ASSIGNED_CONTENT'].includes(result.errors[0].code)) {
            props.dispatchCustomAction('CANNOT_DELETE_SHARED_OR_ASSIGNED_CONTENT');
          } else {
            props.dispatchCustomAction('DELETE_CONTENT_FAILURE');
          }
        } else {
          updateData(contents.filter(item => item._id !== result.deletedContentId));
          props.dispatchCustomAction('DELETE_CONTENT_SUCCESS');
        }
      } catch (err) {
        props.dispatchCustomAction('DELETE_CONTENT_FAILURE');
      }
    }
    setContentDeleteModal(false);
  };

  const getOptions = ({ _id, contentType, status, warning }) => [
    {
      name: t('common/edit'),
      order: { grid: 1, list: 4 },
      showInListView: true,
      handler: () => {
        handleEdit({ _id, contentType });
      }
    },
    {
      name: t('common/delete'),
      order: { grid: 2, list: 3 },
      showInListView: true,
      handler: () => {
        handleContentDelete(_id);
      }
    },
    ...(warning
      ? []
      : [
          {
            name: t('common/preview'),
            order: { grid: 3, list: 2 },
            showInListView: true,
            handler: () => {
              handlePreview({ _id });
            }
          },
          {
            name: status === 'INACTIVE' ? t('common/activate') : t('common/deactivate'),
            order: { grid: 4, list: 1 },
            showInListView: true,
            handler: () => {
              status === 'INACTIVE' ? setContentStatusActivateModal(true) : setContentStatusDeactivateModal(true);
              setContentStatusId(_id);
            }
          },
          ...(role === 'ADMIN' || (role === 'PARTNER' && accountType === 'PARTNER')
            ? [
                {
                  name: t('common/share'),
                  order: { grid: 5, list: -1 },
                  showInListView: false,
                  handler: () => {
                    props.getContentSharedToOrgs({ selectedOrg, contentId: _id });
                    setShareContentModal(true);
                    setContentStatusId(_id);
                  }
                },
                {
                  name: t('common/share-copy'),
                  order: { grid: 6, list: -1 },
                  showInListView: false,
                  handler: () => {
                    setShareContentCopyModal(true);
                    // set org details
                    setShareContentModalOrgs(
                      organizationList.map(org => ({
                        name: org.name,
                        _id: org._id,
                        isChecked: false
                      }))
                    );
                    setContentStatusId(_id);
                  }
                }
              ]
            : [])
        ])
  ];

  const handleAddContent = event => {
    props.dispatchCustomAction('INITIALIZE_CURRENT_CONTENT');
    props.history.push(`/org/${selectedOrg}/content/${event}`);
  };

  const handleView = layout => {
    setLayout(layout);
  };

  const handleSort = sortType => {
    setSortType(sortType);
  };

  return (
    <React.Fragment>
      <ModuleContentActionRow
        layout={layout}
        sortType={sortType}
        handleChangeLayout={handleView}
        handleChangeSort={handleSort}
        left={
          <ThreeDots
            style={{ padding: 0, margin: 0, width: 'fit-content' }}
            onSelect={handleAddContent}
            width="300px"
            placement="bottom"
            options={[
              {
                name: t('content-setup/add-mcq'),
                id: 'mcq'
              },
              {
                name: t('content-setup/add-presentation'),
                id: 'presentation'
              },
              {
                name: t('content-setup/add-carousel'),
                id: 'carousel'
              },
              {
                name: t('content-setup/add-story'),
                id: 'story'
              },
              {
                name: t('content-setup/add-mixed'),
                id: 'mixed'
              }
            ]}
          >
            <IconWrapper type="Plus" />
          </ThreeDots>
        }
      />

      <Container className={`content-setup-view-container ${layout}`} style={{ flexDirection: layout == 'list' ? 'column' : 'row' }}>
        {showContentPreview ? (
          <div className="lessonPreview__overlay">
            <div className="lessonPreview__toolbar">
              <div className="preview">{t('common/preview')}</div>
              <div className="closePreview">{t('common/close-preview')}</div>
              <div
                className="closeIcon"
                onClick={() => {
                  setShowContentPreview(false);
                  setActivePreviewContentId(null);
                }}
              >
                <IconWrapper type="CloseIcon" />
              </div>
            </div>
            {activePreviewContentId && <ContentTypePreview organizationId={selectedOrg} contentId={activePreviewContentId} />}
          </div>
        ) : null}
        {modifiedData.map(content => (
          <ThumbnailCardInfo
            key={content._id}
            type="content"
            layout={layout}
            cardInfo={content}
            options={getOptions(content)}
            renderPreview={<ContentCardPreview heading={content.heading} description={content.description} difficultyLevel={content.difficultyLevel} estimatedTime={content.estimatedTime} />}
          />
        ))}
        {layout === 'grid' && (!loading || !!modifiedData.length) && <ContentAddCard selectedOrg={selectedOrg} />}
        {(loading || hasNextPage) && <div ref={sentryRef}></div>}
      </Container>
      {loading && layout === 'grid' && (
        <Container className={`content-setup-view-container ${layout}`} style={{ marginTop: 0 }}>
          <ContentCardLoader />
          <ContentCardLoader />
          <ContentCardLoader />
          <ContentCardLoader />
        </Container>
      )}
      {loading && layout === 'list' && (
        <Container className={`content-setup-view-container ${layout}`} style={{ marginTop: 0 }}>
          <ContentListLoader />
          <ContentListLoader />
          <ContentListLoader />
          <ContentListLoader />
          <ContentListLoader />
          <ContentListLoader />
          <ContentListLoader />
          <ContentListLoader />
          <ContentListLoader />
          <ContentListLoader />
        </Container>
      )}

      <Modal
        heading={t('common/modal/please-confirm')}
        modalBody={<p>{t('common/modal/activate-content-text')}</p>}
        show={contentStatusActivateModal}
        isAlert
        onSave={activateContentStatus}
        onHide={() => setContentStatusActivateModal(false)}
      />
      <Modal
        heading={t('common/modal/please-confirm')}
        modalBody={<p>{t('common/modal/deactivate-content-text')}</p>}
        show={contentStatusDeactivateModal}
        isAlert
        onSave={deactivateContentStatus}
        onHide={() => setContentStatusDeactivateModal(false)}
      />
      <Modal
        heading={`${t('common/modal/share-content')} : ${get(
          contents.find(content => content._id === contentStatusId),
          'preferences.contentName'
        )}`}
        modalBody={<ShareContent contentId={contentStatusId} updateContentSharedToOrgs={props.updateContentSharedToOrgs} selectedOrg={selectedOrg} />}
        showFooter={false}
        show={shareContentModal}
        onHide={() => setShareContentModal(false)}
      />
      <Modal
        heading={`${t('common/modal/share-content-copy')} : ${get(
          contents.find(content => content._id === contentStatusId),
          'preferences.contentName'
        )}`}
        modalBody={<ShareContentCopy shareContentModalOrgs={shareContentModalOrgs} setShareContentModalOrgs={setShareContentModalOrgs} />}
        show={shareContentCopyModal}
        onSave={saveShareContentCopy}
        onHide={() => setShareContentCopyModal(false)}
      />
      <Modal
        heading={t('common/modal/please-confirm-text')}
        modalBody={<p>{t(`content-delete/confirm`)}</p>}
        show={contentDeleteModal}
        isAlert
        onSave={contentDeleteModalSave}
        onHide={() => setContentDeleteModal(false)}
      />
    </React.Fragment>
  );
};

ContentSetupView.propTypes = {
  getContentSharedToOrgs: PropTypes.func.isRequired,
  updateContentSharedToOrgs: PropTypes.func.isRequired,
  shareContentCopy: PropTypes.func.isRequired,
  initializeCurrentContent: PropTypes.func.isRequired,
  dispatchCustomAction: PropTypes.func.isRequired,
  history: HistoryPropTypesShape,
  selectedOrg: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
  organizationList: PropTypes.arrayOf(PropTypes.object)
};

ContentSetupView.defaultProps = {};

const mapStateToProps = ({ userState, adminState }) => {
  return {
    user: userState.user,
    organizationList: adminState.organizations
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getContentSharedToOrgs: bindActionCreators(getContentSharedToOrgs, dispatch),
    updateContentSharedToOrgs: bindActionCreators(updateContentSharedToOrgs, dispatch),
    shareContentCopy: bindActionCreators(shareContentCopy, dispatch),
    initializeCurrentContent: bindActionCreators(initializeCurrentContent, dispatch),
    dispatchCustomAction: bindActionCreators(dispatchCustomAction, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentSetupView));
