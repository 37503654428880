import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import axios from 'axios';

import MostUsedContent from './most-used-contents';
import MostUsedModule from './most-used-module';
import MostActiveUsers from './most-active-users';
import MostActiveUserGroups from './most-active-user-groups';
import { BASE_URL, axiosConfig } from 'utils/api';
import { useFormatMessage } from 'hooks/useFormatMessage';

import './user-activity.scss';
const UserActivityRecentDays = ({ selectedOrg }) => {
  const [dashboardData, setDashboardData] = useState({});
  const t = useFormatMessage();

  async function fetchUserActivityData() {
    const startDate = moment()
      .subtract(7, 'days')
      .format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');

    const endpoint = `${BASE_URL}/organizations/${selectedOrg}/stats/topThree?startDate=${startDate}&endDate=${endDate}`;
    const response = await axios.get(endpoint, axiosConfig('application/json'));
    if (response?.data) {
      setDashboardData(response.data[0]);
    }
  }

  useEffect(() => {
    fetchUserActivityData();
  }, [selectedOrg]);

  const { topUsers = [], topGroups = [], topContents = [], topModules = [] } = dashboardData;
  const reducedHeight = !topUsers.length && !topGroups.length && !topContents.length && !topModules.length;
  return (
    <div className="user-stats-last-7-days">
      <div className="stats-head-middle">{t('user-activity/user-activity-in-the-last-7-days')}</div>
      <div className={`stats-middle ${reducedHeight && 'reduced-height'}`}>
        <MostActiveUsers topUsers={topUsers} />
        <MostActiveUserGroups topGroups={topGroups} />
        <MostUsedContent topContents={topContents} />
        <MostUsedModule topModules={topModules} />
      </div>
    </div>
  );
};

UserActivityRecentDays.propTypes = {
  selectedOrg: PropTypes.string.isRequired
};
export default UserActivityRecentDays;
