import React, { useContext, useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { find } from 'lodash';
import AppContext from '../../../app-context';

import Button from 'atoms/Button';
import DropDown from 'atoms/DropDown';
import Modal from 'atoms/Modal';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { ErrorsPropTypesShape } from 'utils/core-proptypes';
import EditAccountModalBody from '../../ModalElements/EditAccountModalBody';
import { updateOrganization } from '../../../actions';
import { addOrganizationAndRegisterManager, clearAccountError } from './actions';
import '../users-and-groups.scss';

const reducer = (state, action) => {
  const { key, value } = action;
  const { eventKey: { id: dropDownKey } = {} } = action;
  switch (action.type) {
    case 'setOrg':
    case 'addOrg':
      return {
        ...state,
        adminUser: {
          ...action.payload,
          allowedAccountTypes: action.allowedAccountTypes
        },
        accountModalPristine: true
      };
    case 'inputChange':
      return {
        ...state,
        adminUser: { ...state.adminUser, [key]: value },
        accountModalPristine: false
      };
    case 'dropDownChange':
      return {
        ...state,
        adminUser: {
          ...state.adminUser,
          [key]: dropDownKey
        },
        accountModalPristine: false
      };
    case 'checkboxChange':
      return {
        ...state,
        adminUser: {
          ...state.adminUser,
          [key]: state.adminUser.status ? 'ACTIVE' : 'INACTIVE'
        },
        accountModalPristine: false
      };
    default:
      throw new Error();
  }
};

const AdminView = ({ selectedOrg: orgId, currentUser, allowedRoles, ...props }) => {
  const context = useContext(AppContext);
  const selectedOrg = context.selectedOrg || orgId;

  const t = useFormatMessage();
  const [modalShow, setModalShow] = useState(false);
  const [state, dispatcher] = useReducer(reducer, {});

  useEffect(() => {
    if (props.addUpdateOrganizationAndRegisterManagerSuccess) {
      setModalShow(false);
    }
  }, [props.addUpdateOrganizationAndRegisterManagerSuccess]); // Only re-run the effect if modalShow changes

  const modalClose = () => {
    if (props.error.length > 0) props.clearAccountError();
    setModalShow(false);
  };

  const modalOpen = id => {
    const payload = find(props.organizationList, org => org._id === id);
    const currentUserOrganization = find(props.organizationList, org => org._id === currentUser.organizationId);
    const allowedAccountTypes = currentUserOrganization.accountType === 'MINDZEED' ? allowedRoles.mindzeedOrgCanManageOrg : allowedRoles.partnerOrgCanManageOrg;

    if (id) {
      dispatcher({ type: 'setOrg', payload, allowedAccountTypes });
    } else {
      dispatcher({ type: 'addOrg', payload: {}, allowedAccountTypes });
    }
    setModalShow(true);
  };

  const handleSelect = eventKey => {
    context.setContextState({ selectedOrg: eventKey.id });
  };

  const handleModalSave = () => {
    if (state.adminUser && state.adminUser._id)
      props.updateOrganization({
        payload: state.adminUser,
        orgId: selectedOrg
      });
    else props.addOrganizationAndRegisterManager({ payload: state.adminUser });
  };

  return (
    <Row className="justify-content-end">
      <Col lg={6} className="users-and-groups__Organisation-actions">
        {props.currentOrganizationId && (
          <React.Fragment>
            <Row style={{ margin: '0' }}>
              <div>
                <h3 className="users-and-groups__Organisation-actions__header">{t('account-management/organization')}</h3>
                <DropDown
                  onSelect={handleSelect}
                  isSearchable={true}
                  heading={selectedOrg || props.currentOrganizationId}
                  placeholder={t('common/select-organization')}
                  values={props.organizationList.map(org => {
                    return { value: org.name, id: org._id };
                  })}
                />
              </div>
              <Button
                disabled={currentUser.role === 'ADMIN' ? false : selectedOrg === currentUser.organizationId}
                onClick={() => modalOpen(selectedOrg)}
                className="users-and-groups__Organisation-actions__edit-add-button ml-2"
                variant="secondary"
              >
                {t('common/edit')}
              </Button>
              <Button onClick={() => modalOpen()} className="users-and-groups__Organisation-actions__edit-add-button ml-2" variant="secondary">
                {t('common/add')}
              </Button>
            </Row>
          </React.Fragment>
        )}
      </Col>
      <Modal
        heading={state.adminUser && state.adminUser._id ? t('account-management/edit-account') : t('account-management/add-account')}
        modalBody={<EditAccountModalBody dispatcher={dispatcher} error={props.error} adminUser={state.adminUser} />}
        show={modalShow}
        notPristine={state.accountModalPristine}
        onSave={handleModalSave}
        onHide={modalClose}
      />
    </Row>
  );
};

AdminView.propTypes = {
  organizationList: PropTypes.arrayOf(PropTypes.object),
  setSelectedOrg: PropTypes.func.isRequired,
  addOrganizationAndRegisterManager: PropTypes.func.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  selectedOrg: PropTypes.string.isRequired,
  addUpdateOrganizationAndRegisterManagerSuccess: PropTypes.bool,
  clearAccountError: PropTypes.func,
  currentOrganizationId: PropTypes.string,
  currentUser: PropTypes.object,
  allowedRoles: PropTypes.object,
  error: PropTypes.arrayOf(ErrorsPropTypesShape)
};

AdminView.defaultProps = {
  organizationList: [],
  error: [],
  addUpdateOrganizationAndRegisterManagerSuccess: false,
  currentOrganizationId: '',
  clearAccountError: () => {}
};

const mapStateToProps = ({ adminState, userState }) => {
  return {
    addUpdateOrganizationAndRegisterManagerSuccess: adminState.addUpdateOrganizationAndRegisterManagerSuccess,
    error: adminState.error,
    organizationList: adminState.organizations,
    currentOrganizationId: userState.user.user.organizationId,
    currentUser: userState.user.user,
    allowedRoles: adminState.allowedRoles
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addOrganizationAndRegisterManager: bindActionCreators(addOrganizationAndRegisterManager, dispatch),
    updateOrganization: bindActionCreators(updateOrganization, dispatch),
    clearAccountError: bindActionCreators(clearAccountError, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminView);
