import { ASSIGNED_MODULES, ASSIGNED_MODULES_SUCCESS, ASSIGNED_MODULES_FAILURE } from 'utils/constants';

const initialState = {
  modules: [],
  assignedModuleSuccess: false
};

const homeState = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGNED_MODULES:
      return {
        ...state,
        assignedModuleSuccess: false
      };

    case ASSIGNED_MODULES_SUCCESS:
      return { ...state, ...action.payload, assignedModuleSuccess: true };

    case ASSIGNED_MODULES_FAILURE:
      return {
        ...state,
        assignedModuleSuccess: false
      };

    default:
      return state;
  }
};
export default homeState;
