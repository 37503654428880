import { FETCH_MODULE_START, FETCH_MODULE_START_SUCCESS, FETCH_CONTENT_START, FETCH_CONTENT_START_SUCCESS, FETCH_CONTENT_START_FAILURE, CLEAR_LANGUAGE_SELECTOR_ERROR } from 'utils/constants';

const initialState = {
  currentModule: {},
  currentContent: {},
  errorMessage: []
};

const userActivityState = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MODULE_START: {
      return {
        ...state,
        currentModule: {},
        errorMessage: []
      };
    }
    case FETCH_MODULE_START_SUCCESS: {
      return {
        ...state,
        currentModule: action.payload,
        errorMessage: []
      };
    }
    case FETCH_CONTENT_START: {
      return {
        ...state,
        errorMessage: []
      };
    }
    case FETCH_CONTENT_START_SUCCESS: {
      return {
        ...state,
        currentContent: action.payload,
        errorMessage: []
      };
    }
    case FETCH_CONTENT_START_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload.response.errors
      };
    }
    case CLEAR_LANGUAGE_SELECTOR_ERROR: {
      return {
        ...state,
        errorMessage: []
      };
    }
    default:
      return state;
  }
};
export default userActivityState;
