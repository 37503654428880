import React, { useContext } from 'react';
import sn from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import expandIcon from 'assets/icons/plus_20.png';
import moveGreen from 'assets/icons/move_green.png';
import moveWhite from 'assets/icons/move_rev.png';
import collapseIcon from 'assets/icons/minus_rev.png';
import { uniqueId } from 'utils/unique-id';
import { AccordionItemContext } from './AccordionItem';
import './accordion.scss';

const AccordionItemTitle = ({ children, accordinTitleplaceholder }) => {
  const { isCollapsed, onCollapse } = useContext(AccordionItemContext);
  return (
    <div className={sn('card-header', { collapsed: isCollapsed })}>
      <h2 className="mb-0">
        <Row>
          <Col lg="11" md="10" sm="9">
            <button
              className="btn btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              onClick={() => {
                onCollapse();
              }}
            >
              {children ? children : <label className="placeholder-label">{accordinTitleplaceholder}</label>}
            </button>
          </Col>
          <Col className="justify-content-end d-flex">
            <div className="icons">
              {!isCollapsed && [
                <img
                  onClick={() => {
                    onCollapse();
                  }}
                  key={uniqueId('accordion-icon')}
                  src={expandIcon}
                  alt="collapse icon"
                />,
                <img key={uniqueId('accordion-icon')} className="accordion-sort" src={moveGreen} alt="Sort icon" />
              ]}
              {isCollapsed && [
                <img
                  onClick={() => {
                    onCollapse();
                  }}
                  key={uniqueId('accordion-icon')}
                  src={collapseIcon}
                  alt="collapse icon"
                />,
                <img key={uniqueId('accordion-icon')} className="accordion-sort" src={moveWhite} alt="Sort icon" />
              ]}
            </div>
          </Col>
        </Row>
      </h2>
    </div>
  );
};
export default AccordionItemTitle;

AccordionItemTitle.propTypes = {
  children: PropTypes.node.isRequired,
  accordinTitleplaceholder: PropTypes.string
};
