import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row, TabContainer, TabContent, TabPane, Nav, NavItem } from 'react-bootstrap';
import { get, orderBy, isEmpty } from 'lodash';
import sn from 'classnames';

import ThreeDots from 'atoms/ThreeDots';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { CurrentContentPropTypesShape, ComputedMatchPropTypesShape, HistoryPropTypesShape } from 'utils/core-proptypes';
import MCQPreference from './Mcq/McqPreference';
import MCQCreate from './Mcq/McqCreate';
import CarouselCreate from './CarouselCreate/CarouselInfoCreate';
import CarouselPreference from './CarouselCreate/CarouselPreference';
import PresentationPreference from './Presentation/PresentationPreference';
import PresentationCreate from './Presentation/PresentationCreate';
import MixedPreference from './Mixed/MixedPreference';
import MixedCreate from './Mixed/MixedCreate';
import StoryPreference from './Story/StoryPreference';
import StoryCreate from './Story/StoryCreate';
import { fetchAllContent, initializeCurrentContent, dispatchCustomAction } from '../ContentSetup/actions';
import { fetchLocaleLanguages } from '../../../actions';
import './content-create.scss';

const ContentCreate = ({ computedMatch, localeLanguages, currentContent, ...props }) => {
  const t = useFormatMessage();
  const { type, id: selectedContentID, orgid } = computedMatch.params;
  const contentId = selectedContentID || currentContent._id;
  const [currentActiveKey, setCurrentActiveKey] = useState('preferences');

  const [listOfTabs, setlistOfTabs] = useState([]);

  useEffect(() => {
    if (isEmpty(localeLanguages)) {
      props.fetchLocaleLanguages();
    }
    if (contentId) {
      props.initializeCurrentContent({ contentId, selectedOrg: orgid });
    } else {
      props.dispatchCustomAction('CREATE_CONTENT_ERROR');
    }
  }, []);

  useEffect(() => {
    const langCodes = get(currentContent, 'data', []).map(d => ({
      eventKey: `content-lang-${d.langCode}`,
      title: `${d.langCode.toUpperCase()}`
    }));
    setlistOfTabs(langCodes || []);
  }, [currentContent]);

  useEffect(() => {
    const activeTab = get(props, 'preferences.activeTab');
    if (activeTab) setCurrentActiveKey(activeTab);
  }, [props.preferences]);

  const handleAddLanguageDropDown = langCode => {
    const id = langCode.toUpperCase();
    const isAlreadyThere = listOfTabs && listOfTabs.find(tab => tab.title === id); // only add content who are not already selected

    if (!isAlreadyThere) {
      setlistOfTabs([...listOfTabs, { eventKey: `content-lang-${langCode}`, title: `${id}` }]);
    }
    setCurrentActiveKey(`content-lang-${langCode}`);
  };

  const languagesMap = orderBy(
    localeLanguages.map(lang => ({
      id: lang.langCode,
      name: `${lang.language} (${lang.langCode.toUpperCase()})`,
      handler: () => {},
      value: `${lang.language} (${lang.langCode.toUpperCase()})`
    })),
    ['value'],
    ['asc']
  );

  const renderContentPreference = () => {
    switch (type.toUpperCase()) {
      case 'MCQ': {
        return <MCQPreference currentContent={currentContent} computedMatch={computedMatch} />;
      }
      case 'PRESENTATION': {
        return <PresentationPreference currentContent={currentContent} computedMatch={computedMatch} />;
      }
      case 'CAROUSEL': {
        return <CarouselPreference currentContent={currentContent} computedMatch={computedMatch} />;
      }
      case 'STORY': {
        return <StoryPreference currentContent={currentContent} computedMatch={computedMatch} />;
      }
      case 'MIXED': {
        return <MixedPreference currentContent={currentContent} computedMatch={computedMatch} />;
      }
      default:
        return null;
    }
  };

  const renderContentDataTabPane = langCode => {
    switch (type) {
      case 'mcq':
      case 'MCQ': {
        return <MCQCreate orgid={orgid} contentId={contentId} langCode={langCode} defaultLangCode={currentContent.defaultLangCode} currentContent={currentContent} onDelete={deleteContentData} />;
      }
      case 'presentation':
      case 'PRESENTATION': {
        return (
          <PresentationCreate
            orgid={orgid}
            contentId={contentId}
            langCode={langCode}
            currentContent={currentContent}
            computedMatch={computedMatch}
            defaultLangCode={currentContent.defaultLangCode}
            onDelete={deleteContentData}
          />
        );
      }
      case 'carousel':
      case 'CAROUSEL': {
        return (
          <CarouselCreate
            orgid={orgid}
            contentId={contentId}
            langCode={langCode}
            currentContent={currentContent}
            computedMatch={computedMatch}
            defaultLangCode={currentContent.defaultLangCode}
            onDelete={deleteContentData}
          />
        );
      }
      case 'story':
      case 'STORY': {
        return (
          <StoryCreate
            orgid={orgid}
            contentId={contentId}
            langCode={langCode}
            currentContent={currentContent}
            computedMatch={computedMatch}
            defaultLangCode={currentContent.defaultLangCode}
            onDelete={deleteContentData}
          />
        );
      }
      case 'mixed':
      case 'MIXED': {
        return (
          <MixedCreate
            orgid={orgid}
            contentId={contentId}
            langCode={langCode}
            currentContent={currentContent}
            computedMatch={computedMatch}
            defaultLangCode={currentContent.defaultLangCode}
            onDelete={deleteContentData}
          />
        );
      }
      default:
        return null;
    }
  };

  const deleteContentData = langCode => {
    const newContentTabs = listOfTabs.filter(tab => tab.title !== langCode.toUpperCase());
    setlistOfTabs(newContentTabs);
    setCurrentActiveKey('preferences');
  };

  return (
    <React.Fragment>
      <Container style={{ padding: '0' }}>
        <Row className="content-create__tabs justify-content-center mt-4">
          <Col>
            <TabContainer defaultActiveKey="preferences" onSelect={e => setCurrentActiveKey(e)} activeKey={currentActiveKey} sid="content-create-tab">
              <Nav className="flex-row content-create__tabs">
                {/* Content Preference Tab Title */}
                <NavItem id="preference-tab">
                  <Nav.Link eventKey="preferences">{t('common/preferences')}</Nav.Link>
                </NavItem>
                {/* Content Data Tabs Title */}
                {listOfTabs.map(tab => (
                  <NavItem key={tab.title} id={`tab-${tab.title}`}>
                    <Nav.Link as="div" eventKey={tab.eventKey}>
                      {tab.title}
                    </Nav.Link>
                  </NavItem>
                ))}
                <NavItem>
                  <div
                    className={sn('content-create__tabs__add-lang', {
                      'content-create__tabs__add-lang--disable': !contentId
                    })}
                  >
                    <ThreeDots
                      label={t('common/add-language')}
                      width="300px"
                      disallow={isEmpty(currentContent)}
                      onNotAllowedClick={() => {
                        props.dispatchCustomAction('CREATE_CONTENT_ERROR');
                      }}
                      onSelect={handleAddLanguageDropDown}
                      placement="bottom"
                      options={languagesMap}
                    />
                  </div>
                </NavItem>
              </Nav>
              <TabContent>
                {/* Preference Tab */}
                <TabPane className="tab1" eventKey="preferences">
                  <Row>
                    <Col>{renderContentPreference()}</Col>
                  </Row>
                </TabPane>
                {/* Content Data Tabs */}
                {listOfTabs.map(tab => (
                  <TabPane key={tab.eventKey} eventKey={tab.eventKey} id={tab.eventKey}>
                    <Row>
                      <Col>{renderContentDataTabPane(tab.title)}</Col>
                    </Row>
                  </TabPane>
                ))}
              </TabContent>
            </TabContainer>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

ContentCreate.propTypes = {
  computedMatch: ComputedMatchPropTypesShape,
  localeLanguages: PropTypes.arrayOf(PropTypes.shape({})),
  history: HistoryPropTypesShape,
  fetchAllContent: PropTypes.func.isRequired,
  initializeCurrentContent: PropTypes.func.isRequired,
  dispatchCustomAction: PropTypes.func.isRequired,
  preferences: PropTypes.shape({
    activeTab: PropTypes.string
  }).isRequired,
  currentContent: CurrentContentPropTypesShape,
  fetchLocaleLanguages: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired
};

ContentCreate.defaultProps = {
  computedMatch: {},
  history: null,
  localeLanguages: []
};
const mapStateToProps = ({ adminState, contentState }) => {
  const currentContent = get(contentState, 'currentContent', {});
  const contentId = get(contentState, 'currentContent._id', null);
  const preferences = get(contentState, 'preferences', {});

  return {
    localeLanguages: adminState.localLanguages,
    preferences,
    contentId,
    currentContent
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchAllContent: bindActionCreators(fetchAllContent, dispatch),
    initializeCurrentContent: bindActionCreators(initializeCurrentContent, dispatch),
    dispatchCustomAction: bindActionCreators(dispatchCustomAction, dispatch),
    fetchLocaleLanguages: bindActionCreators(fetchLocaleLanguages, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentCreate));
