import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import IconWrapper from 'atoms/IconWrapper';
import { useFormatMessage } from 'hooks/useFormatMessage';
import './date-picker.scss';

const blockname = 'date-picker-container';

const DatePicker = ({
  id,
  date,
  label,
  labelAlign = 'vertical',
  placeholder,
  displayFormat,
  showClearDate = false,
  iconType,
  iconClass = '',
  iconStyles = {},
  isOutsideRange = () => false,
  onChange = () => {}
}) => {
  const t = useFormatMessage();
  const [focused, setFocused] = useState(false);

  const defaultIconType = date ? 'Calendar' : 'CalendarGrey';
  return (
    <div className={`${blockname}`}>
      <div className={`${blockname}__calendar`}>
        <IconWrapper
          type={iconType || defaultIconType}
          className={`${blockname}__calendar__calendar-icon ${iconClass}`}
          style={{ width: 30, height: 40, ...iconStyles }}
          onClick={() => setFocused(!focused)}
        />
        <div className={`${blockname}__calendar__input-row ${blockname}__calendar__input-row--${labelAlign}`}>
          {label && <label className={`${blockname}__calendar__input-row__label`}>{label || ''}</label>}
          <SingleDatePicker
            noBorder
            small
            id={id}
            showClearDate={showClearDate}
            firstDayOfWeek={1}
            numberOfMonths={1}
            focused={focused}
            isOutsideRange={isOutsideRange}
            placeholder={placeholder || t('date-picker/not-set')}
            displayFormat={displayFormat || 'DD/MM/YYYY'}
            date={date ? moment(date) : date}
            onDateChange={e => onChange(e, id)}
            onFocusChange={() => setFocused(!focused)}
          />
        </div>
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string,
  label: PropTypes.string,
  labelAlign: PropTypes.oneOf(['horizontal', 'vertical']),
  placeholder: PropTypes.string,
  displayFormat: PropTypes.string,
  iconType: PropTypes.string,
  iconClass: PropTypes.string,
  iconStyles: PropTypes.object,
  showClearDate: PropTypes.bool,
  isOutsideRange: PropTypes.func,
  onChange: PropTypes.func
};

export default DatePicker;
