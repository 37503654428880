import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'atoms/Switch';
import { Container, Col, Row } from 'react-bootstrap';
import './shared-content.scss';

const SharedOrganizations = ({ shared = [], handleShareToggle }) => {
  return (
    <Container style={{ padding: '0' }}>
      {shared.map(org => (
        <Row key={org.organizationId} className="shared-content__row">
          <Col className="shared-content__row__switch-label">{org.name}</Col>
          <Col className="shared-content__row__switch">
            <Switch id={org.organizationId} onSelect={handleShareToggle} value={org.active} />
          </Col>
        </Row>
      ))}
    </Container>
  );
};

SharedOrganizations.propTypes = {
  shared: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleShareToggle: PropTypes.func.isRequired
};
SharedOrganizations.defaultProps = {};

export default SharedOrganizations;
