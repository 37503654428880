import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';

import { randomizeArray } from 'utils/calculationUtilities';
import { fetchContentStart } from '../../ContentList/actions';
import VideoViewer from '../MCQVideoView';
import MCQBottom from './MCQBottom';
import { mcqSubmitAction } from './actions';
import './mcq-view.scss';

const MCQView = ({ contentData, currentContent, contentSpecificPreferences, ...props }) => {
  const [showVideo, setShowVideo] = useState(true);
  const [videoPlayed, setVideoPlayed] = useState(false);

  useEffect(() => {
    const content = isEmpty(contentData.details) ? currentContent : contentData;
    const media = get(content, 'details.media', {});
    if (isEmpty(media)) {
      setVideoPlayed(true);
    } else {
      setVideoPlayed(false);
    }
    setShowVideo(true);
  }, [props.attemptId]);

  const preferences = props.preferences || contentSpecificPreferences;
  const content = isEmpty(contentData.details) ? currentContent : contentData;
  const media = get(content, 'details.media', {});

  let mcqList = content?.mcqList ? [...content.mcqList] : [];
  if (props.isPreview && preferences.showContentInOrder === 'false') {
    mcqList = randomizeArray(mcqList);
  }

  return (
    <Fragment>
      <div className="mcq-view">
        {
          <VideoViewer
            {...{ contentData: { ...contentData, mcqList }, currentContent }}
            media={media}
            preferences={preferences}
            showVideo={showVideo}
            setShowVideo={setShowVideo}
            videoPlayed={videoPlayed}
            onVideoEnd={() => setVideoPlayed(true)}
          />
        }
        <MCQBottom {...{ contentData: { ...contentData, mcqList }, currentContent }} {...props} preferences={preferences} showVideo={showVideo && !isEmpty(media)} videoPlayed={videoPlayed} />
      </div>
    </Fragment>
  );
};

MCQView.propTypes = {
  contentData: PropTypes.shape({
    _id: PropTypes.string,
    details: PropTypes.shape({}),
    mcqList: PropTypes.array
  }),
  currentContent: PropTypes.shape({}),
  isPreview: PropTypes.bool,
  attemptId: PropTypes.string,
  preferences: PropTypes.shape({ showContentInOrder: true }),
  contentSpecificPreferences: PropTypes.shape({ showContentInOrder: true })
};

MCQView.defaultProps = {
  contentData: {
    details: {},
    mcqList: []
  },
  currentContent: null,
  preferences: null
};

const mapStateToProps = ({ userState, userActivityState }) => {
  const isAuthenticated = get(userState, 'user.user._id', false);
  const currentContent = userActivityState.currentContent && get(userActivityState, 'currentContent.content.data', {});
  const moduleId = get(userActivityState, 'currentModule._id', '');
  const contentId = get(userActivityState, 'currentContent.content.contentId', '');
  const langCode = get(userActivityState, 'currentContent.content.langCode', '');
  const attemptId = get(userActivityState, 'currentContent.attemptId', '');
  const trackingId = get(userActivityState, 'currentContent.trackingId', '');
  const contentSpecificPreferences = get(userActivityState, 'currentContent.content.data.preferences', {});

  return {
    isAuthenticated,
    currentContent,
    contentId,
    moduleId,
    attemptId,
    trackingId,
    langCode,
    contentSpecificPreferences
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mcqSubmit: bindActionCreators(mcqSubmitAction, dispatch),
    fetchContentStart: bindActionCreators(fetchContentStart, dispatch)
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MCQView));
