import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';

import {
  UPDATE_ACCOUNT_SETTINGS,
  UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  UPDATE_ACCOUNT_SETTINGS_FAILURE,
  UPDATE_ACCOUNT_SSO_CONFIG,
  UPDATE_ACCOUNT_SSO_CONFIG_SUCCESS,
  UPDATE_ACCOUNT_SSO_CONFIG_FAILURE,
  UPDATE_ACCOUNT_WEBHOOK_SETTINGS,
  UPDATE_ACCOUNT_WEBHOOK_SETTINGS_SUCCESS,
  UPDATE_ACCOUNT_WEBHOOK_SETTINGS_FAILURE,
  DELETE_MINDZEED_ACCOUNT,
  DELETE_MINDZEED_ACCOUNT_SUCCESS,
  DELETE_MINDZEED_ACCOUNT_FAILURE
} from 'utils/constants';

export const updateAccountSettings = ({ payload, organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/settings`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [UPDATE_ACCOUNT_SETTINGS, UPDATE_ACCOUNT_SETTINGS_SUCCESS, UPDATE_ACCOUNT_SETTINGS_FAILURE]
  }
});

export const updateAccountSsoConfig = ({ payload, organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/sso-config`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [UPDATE_ACCOUNT_SSO_CONFIG, UPDATE_ACCOUNT_SSO_CONFIG_SUCCESS, UPDATE_ACCOUNT_SSO_CONFIG_FAILURE]
  }
});

export const updateAccountWebhookSettings = ({ payload, organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/webhook`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [UPDATE_ACCOUNT_WEBHOOK_SETTINGS, UPDATE_ACCOUNT_WEBHOOK_SETTINGS_SUCCESS, UPDATE_ACCOUNT_WEBHOOK_SETTINGS_FAILURE]
  }
});

export const deleteMindzeedAccount = ({ organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    types: [DELETE_MINDZEED_ACCOUNT, DELETE_MINDZEED_ACCOUNT_SUCCESS, DELETE_MINDZEED_ACCOUNT_FAILURE]
  }
});
