import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import sn from 'classnames';

import ColorTile from 'atoms/ColorTile';
import Input from 'atoms/Input';
import './dropdown.scss';

const blockName = 'dropdown-container';

const DropDown = ({ isSearchable, isStaticHeading, isColored, heading, placeholder, variant, values: options = [], onSelect, ...props }) => {
  const [headingNew, setHeadingNew] = useState();
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    initializeHeading();
  }, [options, heading]);

  const initializeHeading = () => {
    const selectedOption = options.find(option => option.id === heading) || {};
    setHeadingNew(selectedOption.value);
  };

  const handleDropDownSelect = stringfiedEventKey => {
    const eventKey = JSON.parse(stringfiedEventKey);
    const { value } = eventKey;
    if (!isStaticHeading) setHeadingNew(value);
    onSelect(eventKey);
  };

  const hasMatch = ({ option, searchText }) => {
    const optionText = `${option.value}`
      .trim()
      .replace(' ', '')
      .toLowerCase();
    return optionText.includes(
      searchText
        .trim()
        .replace(' ', '')
        .toLowerCase()
    );
  };
  const handleSearch = searchText => {
    setFilteredOptions(options.filter(option => hasMatch({ option, searchText })));
    setHeadingNew(searchText);
  };

  return (
    <span className={sn(blockName)}>
      <Dropdown
        onSelect={eventKey => {
          handleDropDownSelect(eventKey);
        }}
        {...props}
      >
        <Dropdown.Toggle disabled={props.disabled} variant={variant} id="dropdown-basic">
          {isSearchable ? (
            <Input
              style={{ border: 0, padding: 0, height: '100%' }}
              onChange={e => handleSearch(e.target.value)}
              value={headingNew}
              onBlur={() => {
                initializeHeading();
              }}
            />
          ) : (
            <span className="heading">{headingNew || placeholder}</span>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {filteredOptions.map(dropDownItem => {
            return (
              <Dropdown.Item key={dropDownItem.id} disabled={dropDownItem.disabled} eventKey={JSON.stringify(dropDownItem)}>
                {isColored ? (
                  <React.Fragment>
                    <ColorTile
                      style={{
                        height: '16px',
                        width: '16px',
                        background: `${dropDownItem.color}`,
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '10px',
                        borderRadius: '2px'
                      }}
                      isSelect={false}
                    />
                    <span>{dropDownItem.value}</span>
                  </React.Fragment>
                ) : (
                  dropDownItem.value
                )}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </span>
  );
};

DropDown.propTypes = {
  isColored: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isStaticHeading: PropTypes.bool,
  values: PropTypes.array,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onSelect: PropTypes.func,
  disabled: PropTypes.bool
};

DropDown.defaultProps = {
  isColored: false,
  isSearchable: false,
  isStaticHeading: false,
  variant: '',
  heading: '',
  values: [],
  disabled: false,
  onSelect: () => {}
};

export default DropDown;
