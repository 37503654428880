export const COOKIE = {
  LOGGED_IN: 'logged_in',
  TOKEN_NAME: '_mindzeed-token'
};

export const DEFAULT_MODULE_PREFERENCES = {
  moduleName: 'My New Module'
};

export const DEFAULT_CONTENT_PREFERENCES = {
  MCQ: {
    contentName: 'My New Content',
    difficultyLevel: 'MIDDLE',
    estimatedTime: '6-10',
    passPercent: 80,
    showRightAnswer: 'true',
    showContentInOrder: 'true',
    noOfTestAttempts: 'UNLIMITED',
    showExplanation: 'SHOW_EXP',
    displayQuestionMode: 'ALL',
    showQuestionsAfterVideo: 'false',
    showContentOnDiploma: 'true',
    noOfQuestionsToDisplay: 50
  },
  PRESENTATION: {
    contentName: 'My New Content',
    difficultyLevel: 'MIDDLE',
    estimatedTime: '6-10',
    noOfTimeCanBeAccessed: 'UNLIMITED',
    askForConfirmation: 'true',
    downloadable: 'true',
    showContentOnDiploma: 'true'
  },
  STORY: {
    contentName: 'My New Content',
    difficultyLevel: 'MIDDLE',
    estimatedTime: '6-10',
    noOfTimeCanBeAccessed: 'UNLIMITED',
    askForConfirmation: 'true',
    showContentOnDiploma: 'true'
  },
  MIXED: {
    contentName: 'My New Content',
    difficultyLevel: 'MIDDLE',
    estimatedTime: '6-10',
    passPercent: 80,
    showRightAnswer: 'true',
    askForConfirmation: 'true',
    shuffleAnswerChoices: 'true',
    showExplanation: 'SHOW_EXP',
    displayQuestionMode: 'ALL',
    showContentOnDiploma: 'true',
    noOfTestAttempts: 'UNLIMITED',
    mandatoryContent: 'true'
  },
  CAROUSEL: {
    contentName: 'My New Content',
    difficultyLevel: 'MIDDLE',
    estimatedTime: '6-10',
    showContentOnDiploma: 'true',
    noOfTimeCanBeAccessed: 'UNLIMITED',
    askForConfirmation: 'true'
  }
};

export const BASE_URL = process.env.BASE_URL;

// Action Types
export const ASSIGNED_MODULES = 'ASSIGNED_MODULES';
export const ASSIGNED_MODULES_SUCCESS = 'ASSIGNED_MODULES_SUCCESS';
export const ASSIGNED_MODULES_FAILURE = 'ASSIGNED_MODULES_FAILURE';

export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';

export const REAUTH = 'REAUTH';
export const REAUTH_SUCCESS = 'REAUTH_SUCCESS';
export const REAUTH_FAILURE = 'REAUTH_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const VERIFY_RESET_TOKEN = 'VERIFY_RESET_TOKEN';
export const VERIFY_RESET_TOKEN_SUCCESS = 'VERIFY_RESET_TOKEN_SUCCESS';
export const VERIFY_RESET_TOKEN_FAILURE = 'VERIFY_RESET_TOKEN_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const ORGANIZATION = 'ORGANIZATION';
export const ORGANIZATION_SUCCESS = 'ORGANIZATION_SUCCESS';
export const ORGANIZATION_FAILURE = 'ORGANIZATION_FAILURE';

export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const ADD_ORGANIZATION_FAILURE = 'ADD_ORGANIZATION_FAILURE';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';

export const GROUP_LIST = 'GROUP_LIST';
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS';
export const GROUP_LIST_FAILURE = 'GROUP_LIST_FAILURE';

export const ALLOWED_ROLES = 'ALLOWED_ROLES';
export const ALLOWED_ROLES_SUCCESS = 'ALLOWED_ROLES_SUCCESS';
export const ALLOWED_ROLES_FAILURE = 'ALLOWED_ROLES_FAILURE';

export const ASSIGN_GROUP = 'ASSIGN_GROUP';
export const ASSIGN_GROUP_SUCCESS = 'ASSIGN_GROUP_SUCCESS';
export const ASSIGN_GROUP_FAILURE = 'ASSIGN_GROUP_FAILURE';

export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

export const GET_COLOR = 'GET_COLOR';
export const GET_COLOR_SUCCESS = 'GET_COLOR_SUCCESS';
export const GET_COLOR_FAILURE = 'GET_COLOR_FAILURE';

export const CLEAR_GROUP_ERROR = 'CLEAR_GROUP_ERROR';
export const CLEAR_ACCOUNT_ERROR = 'CLEAR_ACCOUNT_ERROR';

export const ADD_PREFERENCE = 'ADD_PREFERENCE';
export const ADD_PREFERENCE_SUCCESS = 'ADD_PREFERENCE_SUCCESS';
export const ADD_PREFERENCE_FAILURE = 'ADD_PREFERENCE_FAILURE';

export const UPDATE_PREFERENCE = 'UPDATE_PREFERENCE';
export const UPDATE_PREFERENCE_SUCCESS = 'UPDATE_PREFERENCE_SUCCESS';
export const UPDATE_PREFERENCE_FAILURE = 'UPDATE_PREFERENCE_FAILURE';

export const ADD_CONTENT_DATA = 'ADD_CONTENT_DATA';
export const ADD_CONTENT_DATA_SUCCESS = 'ADD_CONTENT_DATA_SUCCESS';
export const ADD_CONTENT_DATA_FAILURE = 'ADD_CONTENT_DATA_FAILURE';

export const UPDATE_CONTENT_DATA = 'UPDATE_CONTENT_DATA';
export const UPDATE_CONTENT_DATA_SUCCESS = 'UPDATE_CONTENT_DATA_SUCCESS';
export const UPDATE_CONTENT_DATA_FAILURE = 'UPDATE_CONTENT_DATA_FAILURE';

export const FETCH_ALL_CONTENT = 'FETCH_ALL_CONTENT';
export const FETCH_ALL_CONTENT_SUCCESS = 'FETCH_ALL_CONTENT_SUCCESS';
export const FETCH_ALL_CONTENT_FAILURE = 'FETCH_ALL_CONTENT_FAILURE';

export const FETCH_LOCALE_LANGUAGE = 'FETCH_LOCALE_LANGUAGE';
export const FETCH_LOCALE_LANGUAGE_SUCCESS = 'FETCH_LOCALE_LANGUAGE_SUCCESS';
export const FETCH_LOCALE_LANGUAGE_FAILURE = 'FETCH_LOCALE_LANGUAGE_FAILURE';

export const FETCH_PUBLICATION = 'FETCH_PUBLICATION';
export const FETCH_PUBLICATION_SUCCESS = 'FETCH_PUBLICATION_SUCCESS';
export const FETCH_PUBLICATION_FAILURE = 'FETCH_PUBLICATION_FAILURE';

export const FETCH_MODULE_START = 'FETCH_MODULE_START';
export const FETCH_MODULE_START_SUCCESS = 'FETCH_MODULE_START_SUCCESS';
export const FETCH_MODULE_START_FAILURE = 'FETCH_MODULE_START_FAILURE';

export const FETCH_CONTENT_START = 'FETCH_CONTENT_START';
export const FETCH_CONTENT_START_SUCCESS = 'FETCH_CONTENT_START_SUCCESS';
export const FETCH_CONTENT_START_FAILURE = 'FETCH_CONTENT_START_FAILURE';

export const CLEAR_LANGUAGE_SELECTOR_ERROR = 'CLEAR_LANGUAGE_SELECTOR_ERROR';

export const DELETE_CONTENT = 'DELETE_CONTENT';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAILURE = 'DELETE_CONTENT_FAILURE';

export const CREATE_MODULE = 'CREATE_MODULE';
export const CREATE_MODULE_SUCCESS = 'CREATE_MODULE_SUCCESS';
export const CREATE_MODULE_FAILURE = 'CREATE_MODULE_FAILURE';

export const UPDATE_MODULE = 'UPDATE_MODULE';
export const UPDATE_MODULE_SUCCESS = 'UPDATE_MODULE_SUCCESS';
export const UPDATE_MODULE_FAILURE = 'UPDATE_MODULE_FAILURE';

export const CLONE_MODULE = 'CLONE_MODULE';
export const CLONE_MODULE_SUCCESS = 'CLONE_MODULE_SUCCESS';
export const CLONE_MODULE_FAILURE = 'CLONE_MODULE_FAILURE';

export const CREATE_MODULE_LANG_DATA = 'CREATE_MODULE_LANG_DATA';
export const CREATE_MODULE_LANG_DATA_SUCCESS = 'CREATE_MODULE_LANG_DATA_SUCCESS';
export const CREATE_MODULE_LANG_DATA_FAILURE = 'CREATE_MODULE_LANG_DATA_FAILURE';

export const UPDATE_MODULE_LANG_DATA = 'UPDATE_MODULE_LANG_DATA';
export const UPDATE_MODULE_LANG_DATA_SUCCESS = 'UPDATE_MODULE_LANG_DATA_SUCCESS';
export const UPDATE_MODULE_LANG_DATA_FAILURE = 'UPDATE_MODULE_LANG_DATA_FAILURE';

export const INITIALIZE_CURRENT_MODULE = 'INITIALIZE_CURRENT_MODULE';
export const INITIALIZE_CURRENT_MODULE_SUCCESS = 'INITIALIZE_CURRENT_MODULE_SUCCESS';
export const INITIALIZE_CURRENT_MODULE_FAILURE = 'INITIALIZE_CURRENT_MODULE_FAILURE';
export const INITIALIZE_CURRENT_CONTENT = 'INITIALIZE_CURRENT_CONTENT';
export const INITIALIZE_CURRENT_CONTENT_SUCCESS = 'INITIALIZE_CURRENT_CONTENT_SUCCESS';
export const INITIALIZE_CURRENT_CONTENT_FAILURE = 'INITIALIZE_CURRENT_CONTENT_FAILURE';

export const MCQ_SUBMIT = 'MCQ_SUBMIT';
export const MCQ_SUBMIT_SUCCESS = 'MCQ_SUBMIT_SUCCESS';
export const MCQ_SUBMIT_FAILURE = 'MCQ_SUBMIT_FAILURE';

export const MIXED_SUBMIT = 'MIXED_SUBMIT';
export const MIXED_SUBMIT_SUCCESS = 'MIXED_SUBMIT_SUCCESS';
export const MIXED_SUBMIT_FAILURE = 'MIXED_SUBMIT_FAILURE';

export const GET_ALL_DIPLOMAS = 'GET_ALL_DIPLOMAS';
export const GET_ALL_DIPLOMAS_SUCCESS = 'GET_ALL_DIPLOMAS_SUCCESS';
export const GET_ALL_DIPLOMAS_FAILURE = 'GET_ALL_DIPLOMAS_FAILURE';

export const GET_ALL_TIMEZONE = 'GET_ALL_TIMEZONE';
export const GET_ALL_TIMEZONE_SUCCESS = 'GET_ALL_TIMEZONE_SUCCESS';
export const GET_ALL_TIMEZONE_FAILURE = 'GET_ALL_TIMEZONE_FAILURE';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';

export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS';
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS = 'UPDATE_ACCOUNT_SETTINGS_SUCCESS';
export const UPDATE_ACCOUNT_SETTINGS_FAILURE = 'UPDATE_ACCOUNT_SETTINGS_FAILURE';

export const UPDATE_ACCOUNT_SSO_CONFIG = 'UPDATE_ACCOUNT_SSO_CONFIG';
export const UPDATE_ACCOUNT_SSO_CONFIG_SUCCESS = 'UPDATE_ACCOUNT_SSO_CONFIG_SUCCESS';
export const UPDATE_ACCOUNT_SSO_CONFIG_FAILURE = 'UPDATE_ACCOUNT_SSO_CONFIG_FAILURE';

export const UPDATE_ACCOUNT_WEBHOOK_SETTINGS = 'UPDATE_ACCOUNT_WEBHOOK_SETTINGS';
export const UPDATE_ACCOUNT_WEBHOOK_SETTINGS_SUCCESS = 'UPDATE_ACCOUNT_WEBHOOK_SETTINGS_SUCCESS';
export const UPDATE_ACCOUNT_WEBHOOK_SETTINGS_FAILURE = 'UPDATE_ACCOUNT_WEBHOOK_SETTINGS_FAILURE';

export const DELETE_MINDZEED_ACCOUNT = 'DELETE_MINDZEED_ACCOUNT';
export const DELETE_MINDZEED_ACCOUNT_SUCCESS = 'DELETE_MINDZEED_ACCOUNT_SUCCESS';
export const DELETE_MINDZEED_ACCOUNT_FAILURE = 'DELETE_MINDZEED_ACCOUNT_FAILURE';

export const ORGANIZATION_DETAILS = 'ORGANIZATION_DETAILS';
export const ORGANIZATION_DETAILS_SUCCESS = 'ORGANIZATION_DETAILS_SUCCESS';
export const ORGANIZATION_DETAILS_FAILURE = 'ORGANIZATION_DETAILS_FAILURE';

export const UPDATE_ORGANIZATION_SETTINGS = 'UPDATE_ORGANIZATION_SETTINGS';
export const UPDATE_ORGANIZATION_SETTINGS_SUCCESS = 'UPDATE_ORGANIZATION_SETTINGS_SUCCESS';
export const UPDATE_ORGANIZATION_SETTINGS_FAILURE = 'UPDATE_ORGANIZATION_SETTINGS_FAILURE';

export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE';
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'ADD_EMAIL_TEMPLATE_SUCCESS';
export const ADD_EMAIL_TEMPLATE_FAILURE = 'ADD_EMAIL_TEMPLATE_FAILURE';

export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS';
export const UPDATE_EMAIL_TEMPLATE_FAILURE = 'UPDATE_EMAIL_TEMPLATE_FAILURE';

export const GET_EMAIL_TEMPLATE = 'GET_EMAIL_TEMPLATE';
export const GET_EMAIL_TEMPLATE_SUCCESS = 'GET_EMAIL_TEMPLATE_SUCCESS';
export const GET_EMAIL_TEMPLATE_FAILURE = 'GET_EMAIL_TEMPLATE_FAILURE';

export const GET_SHARED_CONTENT = 'GET_SHARED_CONTENT';
export const GET_SHARED_CONTENT_SUCCESS = 'GET_SHARED_CONTENT_SUCCESS';
export const GET_SHARED_CONTENT_FAILURE = 'GET_SHARED_CONTENT_FAILURE';

export const UPDATE_SHARED_CONTENT = 'UPDATE_SHARED_CONTENT';
export const UPDATE_SHARED_CONTENT_SUCCESS = 'UPDATE_SHARED_CONTENT_SUCCESS';
export const UPDATE_SHARED_CONTENT_FAILURE = 'UPDATE_SHARED_CONTENT_FAILURE';

export const TRACK_CONTENT_PRESENTATION = 'TRACK_CONTENT_PRESENTATION';
export const TRACK_CONTENT_PRESENTATION_SUCCESS = 'TRACK_CONTENT_PRESENTATION_SUCCESS';
export const TRACK_CONTENT_PRESENTATION_FAILURE = 'TRACK_CONTENT_PRESENTATION_FAILURE';

export const TRACK_CONTENT_CAROUSEL = 'TRACK_CONTENT_CAROUSEL';
export const TRACK_CONTENT_CAROUSEL_SUCCESS = 'TRACK_CONTENT_CAROUSEL_SUCCESS';
export const TRACK_CONTENT_CAROUSEL_FAILURE = 'TRACK_CONTENT_CAROUSEL_FAILURE';

export const SHOW_MANAGER_BROWSER_MESSAGE = 'SHOW_MANAGER_BROWSER_MESSAGE';
export const SHOW_REMINDER_SUCCESS = 'SHOW_REMINDER_SUCCESS';

export const SHARE_CONTENT_COPY = 'SHARE_CONTENT_COPY';
export const SHARE_CONTENT_COPY_SUCCESS = 'SHARE_CONTENT_COPY_SUCCESS';
export const SHARE_CONTENT_COPY_FAILURE = 'SHARE_CONTENT_COPY_FAILURE';

export const SHARE_MODULE_COPY = 'SHARE_MODULE_COPY';
export const SHARE_MODULE_COPY_SUCCESS = 'SHARE_MODULE_COPY_SUCCESS';
export const SHARE_MODULE_COPY_FAILURE = 'SHARE_MODULE_COPY_FAILURE';
