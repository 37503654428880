import {
  ORGANIZATION_DETAILS,
  ORGANIZATION_DETAILS_SUCCESS,
  ORGANIZATION_DETAILS_FAILURE,
  UPDATE_ACCOUNT_SETTINGS,
  UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  UPDATE_ACCOUNT_SETTINGS_FAILURE,
  DELETE_MINDZEED_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_SSO_CONFIG,
  UPDATE_ACCOUNT_SSO_CONFIG_SUCCESS,
  UPDATE_ACCOUNT_SSO_CONFIG_FAILURE,
  UPDATE_ACCOUNT_WEBHOOK_SETTINGS,
  UPDATE_ACCOUNT_WEBHOOK_SETTINGS_SUCCESS,
  UPDATE_ACCOUNT_WEBHOOK_SETTINGS_FAILURE
} from 'utils/constants';
import uuidv4 from 'uuid/v4';

const initialState = {
  selectedOrgDetails: {}
};

const AccountSettings = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_DETAILS:
      return {
        ...state
      };

    case ORGANIZATION_DETAILS_SUCCESS:
      return {
        ...state,
        selectedOrgDetails: {
          ...action.payload[0]
        }
      };

    case ORGANIZATION_DETAILS_FAILURE:
      return {
        ...state
      };
    case UPDATE_ACCOUNT_SETTINGS:
      return {
        ...state,
        hasSelectedOrgUpdated: false
      };

    case UPDATE_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        hasSelectedOrgUpdated: true,
        accoutUpdateId: uuidv4()
      };

    case UPDATE_ACCOUNT_SETTINGS_FAILURE:
      return {
        ...state,
        hasSelectedOrgUpdated: false
      };
    case DELETE_MINDZEED_ACCOUNT_SUCCESS:
      return {
        ...state,
        accoutUpdateId: uuidv4()
      };

    case UPDATE_ACCOUNT_SSO_CONFIG:
      return {
        ...state,
        hasSelectedOrgUpdated: false
      };

    case UPDATE_ACCOUNT_SSO_CONFIG_SUCCESS:
      return {
        ...state,
        hasSelectedOrgUpdated: true,
        accoutUpdateId: uuidv4()
      };

    case UPDATE_ACCOUNT_SSO_CONFIG_FAILURE:
      return {
        ...state,
        hasSelectedOrgUpdated: false
      };
    case UPDATE_ACCOUNT_WEBHOOK_SETTINGS:
      return {
        ...state,
        hasSelectedOrgUpdated: false
      };

    case UPDATE_ACCOUNT_WEBHOOK_SETTINGS_SUCCESS:
      return {
        ...state,
        hasSelectedOrgUpdated: true,
        accoutUpdateId: uuidv4()
      };

    case UPDATE_ACCOUNT_WEBHOOK_SETTINGS_FAILURE:
      return {
        ...state,
        hasSelectedOrgUpdated: false
      };

    default:
      return state;
  }
};
export default AccountSettings;
