import { keyBy } from 'lodash';

export const reducer = (state, action) => {
  const { key, value, index } = action;

  switch (action.type) {
    case 'inputChange':
    case 'dropDown':
    case 'inputRadio': {
      return {
        ...state,
        ...{ [key]: value },
        storyContentCreateUpdatePristine: false
      };
    }
    case 'addBlock':
      return {
        ...state,
        storyPages: {
          ...state.storyPages,
          [index]: {
            textColor: '#333333',
            backgroundColor: '#FFFFFF'
          }
        },
        storyContentCreateUpdatePristine: false
      };
    case 'addItem':
      return {
        ...state,
        storyPages: {
          ...state.storyPages,
          [index]: {
            ...state.storyPages[index],
            [key]: value
          }
        },
        storyContentCreateUpdatePristine: false
      };

    case 'deleteQuestion': {
      const { index } = action;
      const filteredItems = Object.keys(state.storyPages)
        .filter(x => x !== index)
        .reduce((obj, key) => {
          obj[key] = state.storyPages[key];
          return obj;
        }, {});
      return {
        ...state,
        storyPages: filteredItems,
        components: state.storyPages.components && state.storyPages.components.filter(x => x !== index),
        storyContentCreateUpdatePristine: false
      };
    }

    case 'setItemMedia': {
      const { media } = action;
      return {
        ...state,
        storyPages: {
          ...state.storyPages,
          [index]: {
            ...state.storyPages[index],
            media: { ...media, upload: true }
          }
        },
        storyContentCreateUpdatePristine: false
      };
    }
    case 'sortStoryList': {
      return {
        ...state,
        storyPages: action.payload,
        storyContentCreateUpdatePristine: false
      };
    }
    case 'initialState': {
      const { storyPages } = action.payload;
      const pages = storyPages.map(storyData => ({
        textColor: '#333333',
        backgroundColor: '#FFFFFF',
        ...storyData
      }));

      const mstoryPages = keyBy(pages, item => item._id);
      return {
        ...state,
        ...action.payload,
        storyPages: keyBy(mstoryPages, '_id'),
        storyContentCreateUpdatePristine: true
      };
    }
    default:
      throw new Error();
  }
};
