import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { get } from 'lodash';
import ModuleContentsContainer from './ModuleContentsContainer';
import MarkContentComplete from './MarkContentComplete';

import { ContentsPropTypesShape } from 'utils/core-proptypes';
import ModuleContentAddButton from './ModuleContentAddButton';
import './module-contents.scss';

const ModuleContents = ({ orgId, moduleId, contents, localeLanguages, currentOrganization, dispatcher }) => {
  const [showContentSelector, setShowContentSelector] = useState(false);

  const handleAddContents = selectedContents => {
    dispatcher({ type: 'addContent', selectedContents });
    setShowContentSelector(false);
  };

  const handleDateChange = (momentDate, _id) => {
    dispatcher({
      type: 'dateChange',
      date: momentDate ? moment(momentDate).format('YYYY-MM-DD') : null,
      contentId: _id
    });
  };

  return moduleId ? (
    <div>
      <Container style={{ padding: 0 }}>
        <Row className="module-preference__row">
          <div className="module-contents__row">
            <ModuleContentAddButton
              orgId={orgId}
              showContentSelector={showContentSelector}
              setShowContentSelector={setShowContentSelector}
              assignedContentSet={new Set(contents.map(item => item.settings._id))}
              handleAddContents={handleAddContents}
            />
            <MarkContentComplete orgId={orgId} moduleId={moduleId} contents={contents} locales={localeLanguages} />
          </div>
          <ModuleContentsContainer contents={contents} orgDateFormat={get(currentOrganization, 'settings.dateFormat', 'DD/MM/YYYY')} dispatcher={dispatcher} handleDateChange={handleDateChange} />
        </Row>
      </Container>
    </div>
  ) : null;
};

ModuleContents.propTypes = {
  orgId: PropTypes.string.isRequired,
  moduleId: PropTypes.string,
  contents: ContentsPropTypesShape,
  localeLanguages: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentOrganization: ContentsPropTypesShape,
  dispatcher: PropTypes.func
};

ModuleContents.defaultProps = {
  moduleId: null
};

export default withRouter(ModuleContents);
