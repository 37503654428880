import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { Container, Col, Row, TabContainer, TabContent, TabPane, Nav, NavItem } from 'react-bootstrap';

import { useFormatMessage } from 'hooks/useFormatMessage';
import { formatLanguages } from 'utils/calculationUtilities';
import ThreeDots from 'atoms/ThreeDots';
import { HistoryPropTypesShape, ComputedMatchPropTypesShape, CurrentModulepropTypesShape, UserPropTypesShape } from 'utils/core-proptypes';
import { initializeCurrentModule, dispatchCustomAction } from './actions';
import { getGroupList, fetchLocaleLanguages } from '../../../actions';
import ModulePreference from './ModulePreference';
import ModuleData from './ModuleData';
import './module-create.scss';
import useFetcher from 'hooks/useFetcher';

const ModuleCreate = ({ computedMatch, selectedOrg, localeLanguages, currentModule, ...props }) => {
  const { id: moduleId, orgid: organizationId } = computedMatch.params;
  const { data: organizations = [] } = useFetcher(`/organizations/${organizationId}`, 1, ...(selectedOrg._id ? [true, 1, [selectedOrg]] : []));

  const t = useFormatMessage();
  const [currentActiveKey, setCurrentActiveKey] = useState('preferences');
  const [listOfTabs, setlistOfTabs] = useState([]);

  const { data: moduleLangData = [] } = currentModule;
  const currentOrganization = organizations[0];

  useEffect(() => {
    if (isEmpty(localeLanguages)) {
      props.fetchLocaleLanguages();
    }
    props.getGroupList(organizationId);
    if (moduleId) {
      props.initializeCurrentModule({ moduleId, selectedOrg: organizationId });
    } else {
      props.dispatchCustomAction('CREATE_MODULE_ERROR');
    }
  }, []);

  useEffect(() => {
    const langCodes = moduleLangData.map(d => ({
      eventKey: `content-lang-${d.langCode}`,
      title: `${d.langCode.toUpperCase()}`,
      moduleData: {
        moduleDataId: d._id,
        moduleTitle: d.moduleTitle,
        description: d.description,
        setDefaultLanguage: d.setDefaultLanguage,
        media: d.media
      }
    }));
    setlistOfTabs(langCodes || []);
  }, [currentModule]);

  const onDeleteModuleData = langCode => {
    const newModuleDataTabs = listOfTabs.filter(tab => tab.title !== langCode.toUpperCase());
    setlistOfTabs(newModuleDataTabs);
    setCurrentActiveKey('preferences');
  };

  const onAddModuleData = idInLowerCase => {
    const id = idInLowerCase.toUpperCase();
    const isAlreadyThere = listOfTabs && listOfTabs.find(tab => tab.title === id); // only add content who are not already selected

    if (!isAlreadyThere) {
      setlistOfTabs([...listOfTabs, { eventKey: `content-lang-${idInLowerCase}`, title: `${id}` }]);
    }
    setCurrentActiveKey(`content-lang-${idInLowerCase}`);
  };

  const localLanguagesModified = formatLanguages(localeLanguages);
  return (
    <React.Fragment>
      <Container style={{ padding: '0' }}>
        <Row className="content-create__tabs justify-content-center mt-4">
          <Col>
            <TabContainer defaultActiveKey="preferences" onSelect={e => setCurrentActiveKey(e)} activeKey={currentActiveKey} sid="content-create-tab">
              <Nav className="flex-row content-create__tabs">
                <NavItem id="preference-tab">
                  <Nav.Link eventKey="preferences">{t('common/preferences')}</Nav.Link>
                </NavItem>
                {/* Content Tab */}
                {listOfTabs.map(tab => (
                  <NavItem key={tab.title} id={`tab-${tab.title}`}>
                    <Nav.Link as="div" eventKey={tab.eventKey}>
                      {tab.title}
                    </Nav.Link>
                  </NavItem>
                ))}
                <NavItem>
                  <ThreeDots
                    label={t('common/add-language')}
                    width="300px"
                    disallow={isEmpty(currentModule)}
                    onNotAllowedClick={() => {
                      props.dispatchCustomAction('CREATE_MODULE_ERROR');
                    }}
                    onSelect={onAddModuleData}
                    placement="bottom"
                    options={localLanguagesModified}
                  />
                </NavItem>
              </Nav>
              <TabContent>
                <TabPane className="tab1" eventKey="preferences">
                  <Row>
                    <Col>
                      <ModulePreference computedMatch={computedMatch} currentOrganization={currentOrganization} />
                    </Col>
                  </Row>
                </TabPane>
                {listOfTabs.map(tab => (
                  <TabPane key={tab.eventKey} eventKey={tab.eventKey} id={tab.eventKey}>
                    <Row>
                      <Col>
                        <ModuleData computedMatch={computedMatch} langCode={tab.title} moduleData={tab.moduleData} onDelete={onDeleteModuleData} />
                      </Col>
                    </Row>
                  </TabPane>
                ))}
              </TabContent>
            </TabContainer>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

ModuleCreate.propTypes = {
  history: HistoryPropTypesShape,
  computedMatch: ComputedMatchPropTypesShape,
  localeLanguages: PropTypes.arrayOf(PropTypes.shape({})),
  user: UserPropTypesShape,
  groupsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentModule: CurrentModulepropTypesShape,
  selectedOrg: PropTypes.object,
  fetchLocaleLanguages: PropTypes.func.isRequired,
  dispatchCustomAction: PropTypes.func.isRequired,
  getGroupList: PropTypes.func.isRequired,
  initializeCurrentModule: PropTypes.func.isRequired
};

ModuleCreate.defaultProps = {
  match: {},
  history: null,
  computedMatch: {},
  localeLanguages: [],
  currentModule: null
};
const mapStateToProps = ({ adminState, modulesState, userState, groupState, accountSettings }) => {
  const currentModule = get(modulesState, 'currentModule', {});

  return {
    localeLanguages: adminState.localLanguages,
    user: userState.user,
    groupsList: groupState.groupsList,
    selectedOrg: accountSettings.selectedOrgDetails,
    currentModule
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchLocaleLanguages: bindActionCreators(fetchLocaleLanguages, dispatch),
    dispatchCustomAction: bindActionCreators(dispatchCustomAction, dispatch),
    getGroupList: bindActionCreators(getGroupList, dispatch),
    initializeCurrentModule: bindActionCreators(initializeCurrentModule, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleCreate));
