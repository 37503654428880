import React from 'react';
import PlaceholderImage from './placeholder.jpg';
import './place-holder.scss';

const Placeholder = () => {
  return (
    <div style={{ marginTop: '1.5rem' }}>
      <div className={`placeholder-container__overview `}>
        <div style={{ paddingTop: '56.25%', position: 'relative' }}>
          <img src={PlaceholderImage} alt={'placeholder'} className="img-fluid assigned-module-card-container__card-image rounded-top" />
        </div>
        <div className="placeholder-container__content-container">
          <h3 className="placeholder-container__content-container__h3" />
          <div className="placeholder-container__content-container__languages-front" />
          <div className="placeholder-container__content-container__row-overview-state" />
        </div>
      </div>
    </div>
  );
};

Placeholder.propTypes = {};

Placeholder.defaultProps = {};
export default Placeholder;
