import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Radio } from 'atoms/Radio';
import Modal from 'atoms/Modal';
import Input from 'atoms/Input';
import Loader from 'atoms/Loader';
import Feedback from 'atoms/Feedback';
import useDebounce from 'hooks/useDebounce';
import { useFormatMessage } from 'hooks/useFormatMessage';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import useFetcher, { getSearchStringFromObject } from 'hooks/useFetcher';
import searchIcon from 'assets/icons/search.png';

import './modal-elements.scss';

const ContentSelectorModal = ({ orgId, assignedContentSet, onAddSelected, show, setShow }) => {
  const [selected, setSelected] = useState([]);
  const t = useFormatMessage();
  return (
    <Modal
      modalBody={<ContentSelectorModalBody orgId={orgId} assignedContentSet={assignedContentSet} selected={selected} setSelected={setSelected} />}
      showFooter={false}
      showSaveFooter={true}
      noBodyPadding={true}
      heading={t('module-add-content-popover/select-content')}
      saveText={t('module-add-content-popover/add-selected')}
      className={'content-selector-modal'}
      show={show}
      onSave={() => {
        onAddSelected(selected);
        setSelected([]);
      }}
      onHide={() => setShow(false)}
    />
  );
};

ContentSelectorModal.propTypes = {
  orgId: PropTypes.string.isRequired,
  assignedContentSet: PropTypes.shape({
    has: PropTypes.func.isRequired
  }),
  onAddSelected: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired
};

const ContentSelectorModalBody = ({ orgId, assignedContentSet, selected, setSelected }) => {
  const [filterBy, setFilterBy] = useState('');
  const [inputLoader, setInputLoader] = useState({ show: false, lastFetchAt: new Date() });
  const searchText = useDebounce(filterBy, 1000);

  const { loading, data: items, showLoadMore: hasNextPage, error: itemsError, loadMoreData: loadMore, doFetch } = useFetcher(
    `/organizations/${orgId}/contents${getSearchStringFromObject({ searchText: searchText, shared: true, owned: true, data: false })}`,
    50,
    true
  );

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!itemsError,
    rootMargin: '0px 0px 100px 0px'
  });

  useEffect(() => {
    const millisecondsPassed = new Date().getTime() - inputLoader.lastFetchAt.getTime();
    const delay = Math.max(0, 2000 - millisecondsPassed);
    setTimeout(() => {
      setInputLoader(inputLoader => ({ ...inputLoader, show: false }));
    }, delay);
    doFetch(`/organizations/${orgId}/contents${getSearchStringFromObject({ searchText: searchText, shared: true, owned: true, data: false })}`, true);
  }, [searchText]);

  const handleSearch = value => {
    setInputLoader({ show: true, lastFetchAt: new Date() });
    setFilterBy(value);
  };
  const isLoading = loading || inputLoader.show;
  return (
    <div className="content-selector-modal-body">
      <Form className="content-selector-modal-form">
        <Form.Group style={{ marginBottom: 0 }}>
          <Input value={filterBy} iconImage={searchIcon} onChange={ev => handleSearch(ev.target.value)} isClearable onClear={() => handleSearch('')} />
        </Form.Group>
        {!inputLoader.show &&
          items
            .filter(content => content.defaultLangCode && content.status === 'ACTIVE')
            .map(content => <ContentRow key={content._id} assignedContentSet={assignedContentSet} selected={selected} setSelected={setSelected} content={content} />)}
        {!isLoading && items && items.length === 0 && <Feedback errorList={[{ code: 'NO_CONTENT_MATCH_FOUND' }]} allowedTypesKey="ContentSelector" />}
        {isLoading && (
          <div className="spinner-div">
            <Loader />
          </div>
        )}
      </Form>
      {(loading || hasNextPage) && <div ref={sentryRef}></div>}
    </div>
  );
};

const ContentRow = ({ content, selected, setSelected, assignedContentSet }) => {
  const isAlreadyAssigned = assignedContentSet.has(content._id);
  return (
    <div className="content-row" disabled={isAlreadyAssigned}>
      <span className="content-row__text" style={{ color: isAlreadyAssigned && '#d2d3d2' }}>
        {content.preferences.contentName}
      </span>
      <span className="content-row__text" style={{ color: '#d2d3d2', width: '35%', marginLeft: '10px' }}>
        {content.owner === 'ME' ? 'My Content' : content.ownerOrganization && content.ownerOrganization.name}
      </span>
      <Radio
        disabled={isAlreadyAssigned}
        name={content._id}
        value={content._id}
        checked={isAlreadyAssigned || !!selected.find(item => item._id === content._id)}
        onClick={ev => {
          const value = ev.currentTarget.value;
          if (selected.find(item => item._id === content._id)) {
            setSelected(selected.filter(item => item._id !== value));
          } else {
            setSelected([...selected, content]);
          }
        }}
      />
    </div>
  );
};

ContentSelectorModalBody.propTypes = {
  orgId: PropTypes.string.isRequired,
  assignedContentSet: PropTypes.shape({
    has: PropTypes.func.isRequired
  }),
  selected: PropTypes.arrayOf(PropTypes.object),
  setSelected: PropTypes.func
};

ContentSelectorModalBody.defaultProps = {
  adminUser: {},
  assignedContentSet: new Set()
};

ContentRow.propTypes = {
  assignedContentSet: PropTypes.shape({
    has: PropTypes.func.isRequired
  }),
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  content: PropTypes.shape({
    _id: PropTypes.string,
    preferences: PropTypes.shape({ contentName: PropTypes.string }),
    owner: PropTypes.string,
    ownerOrganization: PropTypes.shape({ name: PropTypes.string })
  })
};

ContentRow.defaultProps = {
  content: {
    name: '',
    description: ''
  }
};

export default ContentSelectorModal;
