import React from 'react';
import PropTypes from 'prop-types';
import { Table as BootstrapTable, Button } from 'react-bootstrap';
import sn from 'classnames';

import Checkbox from 'atoms/Checkbox';
import IconWrapper from 'atoms/IconWrapper';
import { useFormatMessage } from 'hooks/useFormatMessage';
import './table.scss';

const Heading = ({ item, onSort, activeSort = {}, forceDisableSort }) => {
  const t = useFormatMessage();

  const sortKey = item.overrideSortKey || item.key;
  return (
    <th
      style={item.headerStyles}
      className={`${item.isSortable ? 'sortable' : ''} ${activeSort.sortKey === sortKey ? 'table-heading-green' : 'table-heading-grey'}`}
      onClick={() => {
        if (!item.isSortable || forceDisableSort) {
          return;
        }
        onSort(sortKey, activeSort.sortOrder * -1);
      }}
      key={item}
    >
      {item.isSortable && (
        <IconWrapper
          type={activeSort.sortKey === sortKey ? 'ArrowGreen' : 'ArrowGrey'}
          className={`sort-icon ${activeSort.sortKey === sortKey && activeSort.sortOrder === -1 ? 'sort-asc' : 'sort-dsc'}`}
        />
      )}
      {t(`common/table/${item.key}`)}
    </th>
  );
};

const Table = ({
  data,
  style,
  renderIcon,
  handleEdit,
  tableId,
  selectedIds,
  onSelect,
  restrictedIndex = [],
  hideEditIndexes,
  onSort,
  columnConfig,
  activeSort,
  forceDisableSort,
  forceDisableCheckbox
}) => {
  const t = useFormatMessage();

  const setCheckbox = updated => {
    if (onSelect) {
      onSelect(updated);
    }
  };

  const handleCheckAll = event => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const updated = new Set();
      data.forEach((current, index) => {
        if (!restrictedIndex.includes(index)) {
          updated.add(current._id);
        }
      });
      setCheckbox(updated);
    } else {
      setCheckbox(new Set());
    }
  };

  const handleCheck = event => {
    const id = event.target.dataset.value;
    const isChecked = event.target.checked;
    const updated = new Set(selectedIds.values());
    if (isChecked) {
      updated.add(id);
    } else {
      updated.delete(id);
    }
    setCheckbox(updated);
  };

  const isRowInactive = row => {
    const { status } = row;
    if (status && status.toLowerCase() === 'inactive') {
      return true;
    }
    return false;
  };

  return (
    <BootstrapTable id={tableId} className="table" responsive style={style}>
      <thead>
        <tr>
          <th style={{ width: '50px' }}>
            <Checkbox disabled={forceDisableCheckbox} checked={data.length ? selectedIds.size === data.length - restrictedIndex.length : data.length} onChange={handleCheckAll} />
          </th>
          {columnConfig.map(item => (
            <Heading item={item} key={item.key} onSort={onSort} activeSort={activeSort} forceDisableSort={forceDisableSort} />
          ))}
          <th style={{ width: '50px' }} />
        </tr>
      </thead>
      <tbody>
        {data.map((current, index) => {
          return (
            <tr
              key={current._id}
              className={sn({
                'table__row table__row--inactive': isRowInactive(current)
              })}
            >
              <td>
                {restrictedIndex.includes(index) && typeof renderIcon === 'function' ? (
                  renderIcon(current)
                ) : (
                  <Checkbox
                    disabled={forceDisableCheckbox}
                    style={{ display: hideEditIndexes.includes(index) ? 'none' : 'block' }}
                    data-value={current._id}
                    checked={selectedIds.has(current._id)}
                    onChange={handleCheck}
                  />
                )}
              </td>
              {columnConfig.map(item => {
                return (
                  <td key={item.key} style={item.dataStyles}>
                    {typeof item.renderItem === 'function' ? item.renderItem(current[item.key], current) : current[item.key]}
                  </td>
                );
              })}
              <td style={{ verticalAlign: 'middle' }}>
                <Button
                  style={{ lineHeight: '1', color: '#00aaa4', display: hideEditIndexes.includes(index) ? 'none' : 'block' }}
                  className="p-0"
                  id={current._id}
                  onClick={e => {
                    handleEdit(e.target.id);
                  }}
                  size="sm"
                  variant="link"
                >
                  {t('common/edit')}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </BootstrapTable>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeSort: PropTypes.shape({}).isRequired,
  restrictedIndex: PropTypes.arrayOf(PropTypes.number),
  hideEditIndexes: PropTypes.arrayOf(PropTypes.number),
  selectedIds: PropTypes.shape(new Set()),
  handleEdit: PropTypes.func.isRequired,
  renderIcon: PropTypes.func,
  tableId: PropTypes.string,
  onSelect: PropTypes.func,
  onSort: PropTypes.func,
  style: PropTypes.shape({}),
  forceDisableSort: PropTypes.bool,
  forceDisableCheckbox: PropTypes.bool
};

Table.defaultProps = {
  style: {},
  onSelect: null,
  selectedIds: new Set(),
  renderIcon: () => {},
  hideEditIndexes: [],
  tableId: '',
  onSort: () => {}
};

export default Table;

Heading.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string,
    overrideSortKey: PropTypes.string,
    headerStyles: PropTypes.shape({}),
    dataStyles: PropTypes.shape({}),
    isSortable: PropTypes.bool
  }),
  forceDisableSort: PropTypes.bool,
  forceDisableCheckbox: PropTypes.bool,
  onSort: PropTypes.func,
  activeSort: PropTypes.shape({
    sortKey: PropTypes.string,
    sortOrder: PropTypes.Number
  })
};
