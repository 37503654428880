import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import IconWrapper from 'atoms/IconWrapper';

import './module-add-card.scss';

const blockname = 'module-add-container';

const ModuleAddCard = ({ onClick }) => (
  <Col xs={12} sm={6} md={4} lg={3} className={`${blockname} p-0`}>
    <div className={`${blockname}__add-container`}>
      <IconWrapper type="GreenPlus" onClick={onClick} />
    </div>
  </Col>
);

ModuleAddCard.propTypes = {
  onClick: PropTypes.func
};

ModuleAddCard.defaultProps = {};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleAddCard);
