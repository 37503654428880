import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import Checkbox from 'atoms/Checkbox';
import Feedback from 'atoms/Feedback';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { ErrorsPropTypesShape } from 'utils/core-proptypes';
import './modal-elements.scss';

const Reminder = ({ type: { SELECTED, ACTIVE, NEVER_LOGGED_IN } = {}, error, handleReminderCheck, selectedUsersSize }) => {
  const t = useFormatMessage();

  return (
    <Form>
      <Form.Group>
        <Checkbox
          className="reminder-checkbox checkbox-container"
          label={`${t('user-management/selected-users')} (${selectedUsersSize} ${t('user-management/users-selected')})`}
          checked={SELECTED}
          onChange={() => handleReminderCheck({ SELECTED: !SELECTED })}
        />
        <Checkbox className="reminder-checkbox checkbox-container" label={t('user-management/users-with-pending-content')} checked={ACTIVE} onChange={() => handleReminderCheck({ ACTIVE: !ACTIVE })} />
        <Checkbox
          className="reminder-checkbox checkbox-container"
          label={t('user-management/users-who-have-never-logged-in')}
          checked={NEVER_LOGGED_IN}
          onChange={() => handleReminderCheck({ NEVER_LOGGED_IN: !NEVER_LOGGED_IN })}
        />
      </Form.Group>

      <Feedback errorList={error} allowedTypesKey="AddUserEditUser" />
    </Form>
  );
};

Reminder.propTypes = {
  allSelect: PropTypes.bool,
  type: PropTypes.string.isRequired,
  error: PropTypes.arrayOf(ErrorsPropTypesShape),
  handleReminderCheck: PropTypes.func,
  selectedUsersSize: PropTypes.number.isRequired
};

Reminder.defaultProps = {
  allowedRoles: [],
  groupsList: [],
  error: [],
  handleReminderCheck: () => {}
};

export default Reminder;
