import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Button from 'atoms/Button';
import DropDown from 'atoms/DropDown';
import DatePicker from 'atoms/DatePicker';
import { getGroupList } from '../../../actions';
import { BASE_URL, axiosConfig } from 'utils/api';
import axios from 'axios';
import { useFormatMessage } from 'hooks/useFormatMessage';
import UserStatCard, { UserStatCardLoader } from './user-stat-card';
import IconWrapper from 'atoms/IconWrapper';

import './compliance-dashboard.scss';

const mapUserGroupsDropDown = usergroups => {
  return usergroups.map(item => ({ value: item.name, id: item._id }));
};

const mapUserModulesDropDown = (usergroups = [], groupId) => {
  const group = usergroups.find(item => item._id === groupId);
  const modules = group && group.modules;
  if (modules && modules.length > 0 && groupId) {
    return modules.map(module => ({ value: module.moduleName, id: module._id }));
  }
  return [];
};

const mapUserContentDropDown = (usergroups = [], groupId, moduleId) => {
  const group = usergroups.find(item => item._id === groupId);
  const modules = group && group.modules;
  if (modules && modules.length > 0 && moduleId) {
    const module = modules.find(module => module._id === moduleId);
    const contents = module && module.contents;
    if (contents && contents.length > 0) {
      return contents.map(item => ({ value: item.contentName, id: item._id }));
    }
  }
  return [];
};

const defaultStartDate = moment()
  .subtract(7, 'days')
  .format('YYYY-MM-DD');
const defaultEndDate = moment().format('YYYY-MM-DD');

const ComplianceDashboard = ({ allOrganizations, selectedOrg, getGroupList, groupsList, authToken }) => {
  const t = useFormatMessage();

  const [lastFetchData, setLastFetchData] = useState({ groupId: null, moduleId: null, contentId: null, fromDate: defaultStartDate, toDate: defaultEndDate });

  const [loading, setLoading] = useState(true);
  const [renderId, setRenderId] = useState(1);
  const [dashboardData, setDashboardData] = useState({});
  const [previousDashboardData, setpreviousDashboardData] = useState({});
  const [filterByUserGroup, setFilterByUserGroup] = useState(null);
  const [filterByModule, setFilterByModule] = useState(null);
  const [filterByContent, setFilterByContent] = useState(null);
  const [fromDate, setFromDate] = useState(defaultStartDate);
  const [toDate, setToDate] = useState(defaultEndDate);

  const orgDateFormat = useMemo(() => {
    const selectedOrgDetails = allOrganizations.find(org => org._id === selectedOrg);
    if (selectedOrgDetails) {
      return selectedOrgDetails.settings.dateFormat || 'DD/MM/YYYY';
    }
    return 'DD/MM/YYYY';
  }, [allOrganizations, selectedOrg]);

  const groupDropdownOptions = useMemo(() => mapUserGroupsDropDown(groupsList), [groupsList]);
  const moduleDropdownOptions = useMemo(() => mapUserModulesDropDown(groupsList, filterByUserGroup), [groupsList, filterByUserGroup]);
  const contentDropdownOptions = useMemo(() => mapUserContentDropDown(groupsList, filterByUserGroup, filterByModule), [groupsList, filterByUserGroup, filterByModule]);

  async function fetchComplianceDashboardData(resetAll = false) {
    setLoading(true);
    if (resetAll) {
      setLastFetchData({ groupId: null, moduleId: null, contentId: null, fromDate: defaultStartDate, toDate: defaultEndDate });
    } else {
      setLastFetchData({
        groupId: filterByUserGroup,
        moduleId: filterByModule,
        contentId: filterByContent,
        fromDate: fromDate,
        toDate: toDate
      });
    }
    const previousFromDate = moment(fromDate)
      .subtract(7, 'days')
      .format('YYYY-MM-DD');
    let currentDateRangEndpoint = `${BASE_URL}/organizations/${selectedOrg}/stats/user?startDate=${fromDate}&endDate=${toDate}`;
    let previousWeekEndpoint = `${BASE_URL}/organizations/${selectedOrg}/stats/user?startDate=${previousFromDate}&endDate=${fromDate}`;
    if (filterByUserGroup && !resetAll) {
      currentDateRangEndpoint += `&groupId=${filterByUserGroup}`;
      previousWeekEndpoint += `&groupId=${filterByUserGroup}`;
    }
    if (filterByModule && !resetAll) {
      currentDateRangEndpoint += `&moduleId=${filterByModule}`;
      previousWeekEndpoint += `&moduleId=${filterByModule}`;
    }
    if (filterByContent && !resetAll) {
      currentDateRangEndpoint += `&contentId=${filterByContent}`;
      previousWeekEndpoint += `&contentId=${filterByContent}`;
    }
    Promise.all([axios.get(currentDateRangEndpoint, axiosConfig('application/json')), axios.get(previousWeekEndpoint, axiosConfig('application/json'))]).then(responses => {
      if (responses[0]?.data) {
        setDashboardData(responses[0]?.data || []);
      }
      if (responses[1]?.data) {
        setpreviousDashboardData(responses[1]?.data || []);
      }
      setLoading(false);
      setRenderId(renderId => renderId + 1);
    });
  }

  useEffect(() => {
    setFilterByUserGroup(null);
    setFilterByModule(null);
    setFilterByContent(null);
    setFromDate(defaultStartDate);
    setToDate(defaultEndDate);
    // Call API's to update data
    getGroupList(selectedOrg);
    fetchComplianceDashboardData(true);
  }, [selectedOrg]);

  const startDownload = () => {
    let dowloadUrl = `${BASE_URL}/organizations/${selectedOrg}/stats/download/?token=${authToken}&startDate=${fromDate}&endDate=${toDate}`;
    if (filterByUserGroup) {
      dowloadUrl += `&groupId=${filterByUserGroup}`;
    }
    if (filterByModule) {
      dowloadUrl += `&moduleId=${filterByModule}`;
    }
    if (filterByContent) {
      dowloadUrl += `&contentId=${filterByContent}`;
    }
    window.open(dowloadUrl);
  };

  const handleFromDateChange = momentDate => {
    if (momentDate) {
      setFromDate(momentDate ? moment(momentDate).format('YYYY-MM-DD') : null);
    }
  };

  const handleToDateChange = momentDate => {
    if (momentDate) {
      setToDate(momentDate ? moment(momentDate).format('YYYY-MM-DD') : null);
    }
  };

  const { activeUsers = 0, disabledUsers = 0, neverLoggedInUsers = 0, pendingContentUsers = 0, registeredUsers, uptoDateUsers = 0 } = dashboardData;
  const {
    activeUsers: previousActiveUsers,
    disabledUsers: previousDisabledUsers,
    neverLoggedInUsers: previousNeverLoggedInUsers,
    pendingContentUsers: previousPendingContentUsers,
    uptoDateUsers: previousUptoDateUsers
  } = previousDashboardData;
  return (
    <div className="stat-top-container" key={renderId}>
      <div className="stats-filter-container">
        <div className="stats-head-top" style={{ marginBlock: '20px' }}>
          {t('compliance-dashboard/compliance-dashboard')}
        </div>
        <div className="stat-top-filters">
          <DropDown
            className="filter-dropdown"
            onSelect={eventKey => {
              setFilterByUserGroup(eventKey.id);
              setFilterByModule(null);
              setFilterByContent(null);
            }}
            heading={filterByUserGroup || t('user-stats-dropdown/all-groups')}
            placeholder={t('user-stats-dropdown/all-groups')}
            values={[{ value: t('user-stats-dropdown/all-groups'), id: null }, ...groupDropdownOptions]}
          />
          <DropDown
            className="filter-dropdown"
            key={filterByUserGroup}
            disabled={!filterByUserGroup || moduleDropdownOptions.length === 0}
            onSelect={eventKey => {
              setFilterByModule(eventKey.id);
              setFilterByContent(null);
            }}
            heading={filterByModule || t('user-stats-dropdown/all-modules')}
            placeholder={t('user-stats-dropdown/all-modules')}
            values={[{ value: t('user-stats-dropdown/all-modules'), id: null }, ...moduleDropdownOptions]}
          />
          <DropDown
            className="filter-dropdown"
            disabled={!filterByModule || contentDropdownOptions.length === 0}
            key={`${filterByUserGroup}-${filterByModule}`}
            onSelect={eventKey => {
              setFilterByContent(eventKey.id);
            }}
            heading={filterByContent || t('user-stats-dropdown/all-contents')}
            placeholder={t('user-stats-dropdown/all-contents')}
            values={[{ value: t('user-stats-dropdown/all-contents'), id: null }, ...contentDropdownOptions]}
          />
          <div style={{ width: 137 }}>
            <DatePicker id="dashboard-from" label={t('compliance-dashboard/from-date')} onChange={handleFromDateChange} date={fromDate} displayFormat={orgDateFormat} />
          </div>
          <div style={{ width: 137 }}>
            <DatePicker id="dashboard-to" label={t('compliance-dashboard/to-date')} onChange={handleToDateChange} date={toDate} displayFormat={orgDateFormat} />
          </div>
          <Button
            onClick={() => {
              fetchComplianceDashboardData();
            }}
            style={{ minWidth: '150px', height: '40px' }}
            disabled={
              lastFetchData.groupId === filterByUserGroup &&
              lastFetchData.moduleId === filterByModule &&
              lastFetchData.contentId === filterByContent &&
              lastFetchData.fromDate === fromDate &&
              lastFetchData.toDate === toDate
            }
          >
            {t('compliance-dashboard/update')}
          </Button>
        </div>
      </div>

      <div className="stats-top">
        {loading ? (
          <UserStatCardLoader bg="#f3f5f4" fg="#d9d9d9" />
        ) : (
          <div className="stat-box-top bg-mint-cream">
            <div style={{ height: 20 }} />
            <div>
              <IconWrapper type="RegisteredUsers" />
            </div>
            <p style={{ marginTop: '27px' }}>{`${registeredUsers || 0} ${t('compliance-dashboard/registered-users')}`}</p>
          </div>
        )}
        <UserStatCard
          loading={loading}
          bg="#004f5c"
          fg="#00aaa4"
          isArrowDown={previousActiveUsers > activeUsers}
          className="bg-dark-blue"
          label={`${activeUsers} ${t('compliance-dashboard/active-users')}`}
          percentage={Math.round((activeUsers / registeredUsers) * 100)}
        />
        <UserStatCard
          loading={loading}
          bg="#00aaa4"
          fg="#004f5c"
          isArrowDown={previousUptoDateUsers > uptoDateUsers}
          className="bg-teal-blue"
          label={`${uptoDateUsers} ${t('compliance-dashboard/users-are-up-to-date')}`}
          percentage={Math.round((uptoDateUsers / registeredUsers) * 100)}
        />
        <UserStatCard
          loading={loading}
          bg="#15c9c3"
          fg="#004f5c"
          className="bg-topaz"
          isArrowDown={previousPendingContentUsers > pendingContentUsers}
          label={`${pendingContentUsers} ${t('compliance-dashboard/users-have-pending-content')}`}
          percentage={Math.round((pendingContentUsers / registeredUsers) * 100)}
        />
        <UserStatCard
          loading={loading}
          bg="#f05f5a"
          fg="#f23630"
          className="bg-coral"
          isArrowDown={previousNeverLoggedInUsers > neverLoggedInUsers}
          label={`${neverLoggedInUsers} ${t('compliance-dashboard/users-have-never-logged-in')}`}
          percentage={Math.round((neverLoggedInUsers / registeredUsers) * 100)}
        />
        <UserStatCard
          loading={loading}
          bg="#f3f5f4"
          fg="#d9d9d9"
          isArrowDown={previousDisabledUsers > disabledUsers}
          className="bg-mint-cream-grey"
          label={`${disabledUsers} ${t('compliance-dashboard/users-have-been-disabled')}`}
          percentage={Math.round((disabledUsers / registeredUsers) * 100)}
        />
      </div>
      <a target="_blank" rel="noopener noreferrer" onClick={() => startDownload()}>
        {t('compliance-dashboard/download-all-data')}
      </a>
    </div>
  );
};

ComplianceDashboard.propTypes = {
  selectedOrg: PropTypes.string,
  allOrganizations: PropTypes.arrayOf(PropTypes.object),
  groupsList: PropTypes.arrayOf(PropTypes.object),
  getGroupList: PropTypes.func,
  authToken: PropTypes.string.isRequired
};

const mapStateToProps = ({ groupState, userState, adminState }) => {
  const authToken = get(userState, 'user.authToken', null);

  return {
    allOrganizations: adminState.organizations,
    groupsList: groupState.groupsList,
    authToken
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getGroupList: bindActionCreators(getGroupList, dispatch)
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComplianceDashboard));
