import React, { useState } from 'react';
import { Popover, Overlay, Button } from 'react-bootstrap';
import activeDots from 'assets/icons/dots-active.svg';
import greyDots from 'assets/icons/dots-grey.svg';
import sn from 'classnames';
import PropTypes from 'prop-types';
import './three-dots.scss';

const items = ({ options, onSelect, setActive }) => {
  const handleClick = ({ item }) => {
    setActive(false);
    if (onSelect) {
      onSelect(item.id);
    } else if (item.handler) {
      item.handler();
    }
  };
  return options.map(item => (
    <li disabled={item.disabled} key={item.name}>
      <Button variant="liink" disabled={item.disabled} onClick={() => handleClick({ item })}>
        {item.name}
      </Button>
    </li>
  ));
};

const blockName = 'three-dots';
const ThreeDots = ({ options, size, label, placement, width, style, disabled, disallow, onSelect, onNotAllowedClick, children }) => {
  const [active, setActive] = useState(false);
  const [target, setTarget] = useState(null);
  const dots = active ? activeDots : greyDots;

  const handleTriggerClick = e => {
    if (disallow) {
      onNotAllowedClick();
    } else {
      setTarget(e.target);
      setActive(true);
    }
  };

  const renderContent = () => {
    if (children) {
      return children;
    }
    if (label) {
      return label;
    }
    return <img style={{ verticalAlign: 'baseline' }} src={dots} alt="Save options" />;
  };

  const popoverWidth = width;

  return (
    <React.Fragment>
      <Overlay
        className={`${blockName}__container`}
        trigger="click"
        rootClose
        onHide={() => {
          setActive(false);
        }}
        show={active}
        target={target}
        placement={placement}
      >
        <Popover id="three-dots-popover" style={{ maxWidth: popoverWidth }} className={sn({ 'three-dots-popover__label': label })}>
          <ul>{items({ options, onSelect, setActive })}</ul>
        </Popover>
      </Overlay>
      <button
        style={style}
        className={sn(`${blockName}__container__dots`, {
          [`${blockName}__container__dots--${size}`]: !label,
          [`${blockName}__container__dots__label`]: label,
          [`${blockName}__container__dots__label--disallow`]: disallow,
          [`${blockName}__container__dots__label--disable`]: disabled,
          [`${blockName}__container__dots__label--active`]: active && label
        })}
        disabled={disabled}
        onClick={handleTriggerClick}
      >
        {renderContent()}
      </button>
    </React.Fragment>
  );
};

export default ThreeDots;

ThreeDots.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  style: PropTypes.shape({}),
  size: PropTypes.string,
  width: PropTypes.string,
  placement: PropTypes.string,
  disabled: PropTypes.bool,
  disallow: PropTypes.bool,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  onNotAllowedClick: PropTypes.func,
  children: PropTypes.element
};

ThreeDots.defaultProps = {
  size: 'medium',
  style: {},
  disabled: false,
  disallow: false,
  width: '150px',
  placement: 'top',
  label: '',
  onSelect: null,
  onNotAllowedClick: () => {}
};
