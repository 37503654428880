import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Button from 'atoms/Button';
import Modal from 'atoms/Modal';
import ThreeDots from 'atoms/ThreeDots';
import { useFormatMessage } from 'hooks/useFormatMessage';
import './ActionRowContentPreference.scss';
import { fetcher } from 'hooks/useFetcher';
import { dispatchCustomAction } from './actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const ActionRowContentPreference = ({ organizationId, currentContent, handleRevertToSaved, onSave, contentPreferencePristine, dispatchCustomAction }) => {
  const history = useHistory();
  const t = useFormatMessage();
  const [contentDeleteModal, setContentDeleteModal] = useState(false);
  const [showRevertToSavedModal, setShowRevertToSavedModal] = useState(false);
  const [deleteContentId, setDeleteContentId] = useState(null);

  const handleRevert = () => {
    handleRevertToSaved();
    setShowRevertToSavedModal(false);
  };

  const handleContentDelete = contentId => {
    setDeleteContentId(contentId);
    setContentDeleteModal(true);
  };

  const contentDeleteModalSave = async () => {
    if (deleteContentId) {
      try {
        const result = await fetcher(`/organizations/${organizationId}/contents/${deleteContentId}`, 'DELETE', {}, JSON.stringify({}));
        if (result.errors) {
          if (['CANNOT_DELETE_SHARED_OR_ASSIGNED_CONTENT'].includes(result.errors[0].code)) {
            dispatchCustomAction('CANNOT_DELETE_SHARED_OR_ASSIGNED_CONTENT');
          } else {
            dispatchCustomAction('DELETE_CONTENT_FAILURE');
          }
        } else {
          history.push('/modules-content');
          dispatchCustomAction('DELETE_CONTENT_SUCCESS');
        }
      } catch (err) {
        dispatchCustomAction('DELETE_CONTENT_FAILURE');
      }
    }
    setContentDeleteModal(false);
  };

  return (
    <>
      <Row className="content-preference__action">
        <Col md="2" className="pr-0">
          <Button variant="primary" onClick={onSave} notPristine={contentPreferencePristine}>
            {t('common/save')}
          </Button>
        </Col>
        <Col md="10" className="pl-0">
          <ThreeDots
            options={[
              {
                name: t('common/revert-to-saved'),
                handler: () => {
                  setShowRevertToSavedModal(true);
                }
              },
              {
                name: t('common/delete'),
                handler: () => {
                  handleContentDelete(currentContent._id);
                }
              }
            ]}
          />
        </Col>
      </Row>
      <Modal
        heading={t('common/modal/please-confirm-text')}
        modalBody={<p>{t(`content-delete/confirm`)}</p>}
        show={contentDeleteModal}
        isAlert
        onSave={contentDeleteModalSave}
        onHide={() => setContentDeleteModal(false)}
      />
      <Modal
        heading={t('common/modal/please-confirm-text')}
        modalBody={<p>{t('common/modal/delete-warning-text')}</p>}
        show={showRevertToSavedModal}
        isAlert
        onSave={handleRevert}
        onHide={() => setShowRevertToSavedModal(false)}
      />
    </>
  );
};

ActionRowContentPreference.propTypes = {
  organizationId: PropTypes.string.isRequired,
  currentContent: PropTypes.shape({
    _id: PropTypes.string
  }),
  onSave: PropTypes.func.isRequired,
  contentPreferencePristine: PropTypes.bool,
  handleRevertToSaved: PropTypes.func.isRequired,
  dispatchCustomAction: PropTypes.func.isRequired
};

ActionRowContentPreference.defaultProps = {};

const mapDispatchToProps = dispatch => {
  return {
    dispatchCustomAction: bindActionCreators(dispatchCustomAction, dispatch)
  };
};
export default connect(() => {}, mapDispatchToProps)(ActionRowContentPreference);
