import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import {
  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  GET_COLOR,
  GET_COLOR_SUCCESS,
  GET_COLOR_FAILURE,
  CLEAR_GROUP_ERROR
} from 'utils/constants';

export const addGroup = ({ payload, orgId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${orgId}/groups`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [ADD_GROUP, ADD_GROUP_SUCCESS, ADD_GROUP_FAILURE]
  }
});
export const updateGroup = ({ payload, orgId, groupId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${orgId}/groups/${groupId}`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [UPDATE_GROUP, UPDATE_GROUP_SUCCESS, UPDATE_GROUP_FAILURE]
  }
});
export const deleteGroup = ({ payload, orgId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${orgId}/groups`,
    method: 'DELETE',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [DELETE_GROUP, DELETE_GROUP_SUCCESS, DELETE_GROUP_FAILURE]
  }
});
export const getColorList = ({ orgId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${orgId}/group-colors`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [GET_COLOR, GET_COLOR_SUCCESS, GET_COLOR_FAILURE]
  }
});
export const clearGroupError = () => ({
  type: CLEAR_GROUP_ERROR
});
