import { get } from 'lodash';
import {
  CREATE_MODULE,
  CREATE_MODULE_SUCCESS,
  CREATE_MODULE_FAILURE,
  CREATE_MODULE_LANG_DATA,
  CREATE_MODULE_LANG_DATA_SUCCESS,
  CREATE_MODULE_LANG_DATA_FAILURE,
  UPDATE_MODULE_LANG_DATA,
  UPDATE_MODULE_LANG_DATA_SUCCESS,
  UPDATE_MODULE_LANG_DATA_FAILURE,
  INITIALIZE_CURRENT_MODULE,
  INITIALIZE_CURRENT_MODULE_SUCCESS,
  INITIALIZE_CURRENT_MODULE_FAILURE,
  UPDATE_MODULE_SUCCESS
} from 'utils/constants';

const initialState = {
  currentModule: {}
};

const modulesState = (state = initialState, action) => {
  const message = get(action, 'payload.message', {});
  const errors = get(action, 'payload.response.errors', {});
  switch (action.type) {
    case CREATE_MODULE:
      return {
        ...state
      };

    case CREATE_MODULE_SUCCESS:
      return {
        ...state,
        currentModule: action.payload.module,
        message: { ...state.message, CREATE_MODULE_SUCCESS: message },
        errors: { ...state.errors, CREATE_MODULE_FAILURE: false }
      };

    case CREATE_MODULE_FAILURE:
      return {
        ...state,
        errors: { CREATE_MODULE_FAILURE: errors },
        message: { ...state.message, CREATE_MODULE_SUCCESS: {} }
      };

    case CREATE_MODULE_LANG_DATA:
      return {
        ...state,
        message: { ...state.message, CREATE_MODULE_LANG_DATA_SUCCESS: false },
        errors: { ...state.errors, CREATE_MODULE_LANG_DATA_FAILURE: false }
      };

    case CREATE_MODULE_LANG_DATA_SUCCESS:
      return {
        ...state,
        currentModule: {
          ...state.currentModule,
          data: [...state.currentModule.data, action.payload.moduleData]
        },
        message: { ...state.message, CREATE_MODULE_LANG_DATA_SUCCESS: message },
        errors: { ...state.errors, CREATE_MODULE_LANG_DATA_FAILURE: false }
      };

    case CREATE_MODULE_LANG_DATA_FAILURE:
      return {
        ...state,
        errors: { CREATE_MODULE_LANG_DATA_FAILURE: errors },
        message: { ...state.message, CREATE_MODULE_LANG_DATA_SUCCESS: {} }
      };
    case UPDATE_MODULE_SUCCESS:
      return {
        ...state,
        currentModule: {
          data: state.currentModule.data,
          ...action.payload.module
        }
      };
    case UPDATE_MODULE_LANG_DATA:
      return {
        ...state,
        message: { ...state.message, UPDATE_MODULE_LANG_DATA_SUCCESS: false },
        errors: { ...state.errors, UPDATE_MODULE_LANG_DATA_FAILURE: false }
      };

    case UPDATE_MODULE_LANG_DATA_SUCCESS: {
      const { _id: moduleDataId, langCode } = action.payload.moduleData;
      const modifiedModuleData = state.currentModule.data.map(d => (d._id === moduleDataId ? action.payload.moduleData : d));
      return {
        ...state,
        currentModule: {
          ...state.currentModule,
          data: modifiedModuleData,
          defaultLangCode: langCode
        },
        message: { ...state.message, UPDATE_MODULE_LANG_DATA_SUCCESS: message },
        errors: { ...state.errors, UPDATE_MODULE_LANG_DATA_FAILURE: false }
      };
    }

    case UPDATE_MODULE_LANG_DATA_FAILURE:
      return {
        ...state,
        errors: { UPDATE_MODULE_LANG_DATA_FAILURE: errors },
        message: { ...state.message, UPDATE_MODULE_LANG_DATA_SUCCESS: {} }
      };

    case INITIALIZE_CURRENT_MODULE: {
      return {
        ...state,
        currentModule: initialState.currentModule
      };
    }
    case INITIALIZE_CURRENT_MODULE_SUCCESS: {
      return {
        ...state,
        currentModule: get(action, 'payload.0', initialState.currentModule)
      };
    }
    case INITIALIZE_CURRENT_MODULE_FAILURE: {
      return {
        ...state
      };
    }
    default:
      return state;
  }
};
export default modulesState;
