import React from 'react';
import PropTypes from 'prop-types';
import { Table as BootstrapTable } from 'react-bootstrap';

import { useFormatMessage } from 'hooks/useFormatMessage';
import Checkbox from 'atoms/Checkbox';
import './table.scss';
/*eslint-disable react/jsx-key*/
const TablePlaceholder = ({ columnConfig, overlayText, style }) => {
  const t = useFormatMessage();

  return (
    <BootstrapTable className="table" striped bordered responsive style={style}>
      <div className="table__overlay">{overlayText}</div>
      <thead>
        <th style={{ width: '50px' }}>
          <Checkbox disabled />
        </th>
        {columnConfig.map(item => {
          return (
            <th key={item.key} style={item.headerStyles}>
              {t(`common/table/${item.key}`)}
            </th>
          );
        })}
        <th style={{ width: '50px' }}></th>
      </thead>
      <tbody>
        {[{}, {}, {}, {}, {}].map(() => {
          return (
            <tr style={{ height: '39px' }}>
              <td />
              {columnConfig.map(() => {
                return <td />;
              })}
              <td />
            </tr>
          );
        })}
      </tbody>
    </BootstrapTable>
  );
};

TablePlaceholder.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  columnConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  style: PropTypes.shape({}),
  overlayText: PropTypes.string
};

TablePlaceholder.defaultProps = {
  overlayText: '',
  style: {}
};
export default TablePlaceholder;
