import { getSearchStringFromObject } from 'hooks/useFetcher';
import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import { ASSIGNED_MODULES, ASSIGNED_MODULES_SUCCESS, ASSIGNED_MODULES_FAILURE, FETCH_MODULE_START, FETCH_MODULE_START_SUCCESS, FETCH_MODULE_START_FAILURE } from 'utils/constants';

export const fetchAssignedModules = () => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/dashboard`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [ASSIGNED_MODULES, ASSIGNED_MODULES_SUCCESS, ASSIGNED_MODULES_FAILURE]
  }
});

export const fetchModuleStart = ({ id, isPublic = false, trackingId }) => {
  const publicPath = `${BASE_URL}/start/public/module/${id}${getSearchStringFromObject({ trackingId })}`;
  const privatePath = `${BASE_URL}/start/module/${id}`;

  return {
    [RSAA]: {
      endpoint: isPublic ? publicPath : privatePath,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [FETCH_MODULE_START, FETCH_MODULE_START_SUCCESS, FETCH_MODULE_START_FAILURE]
    }
  };
};
