import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Row, Form } from 'react-bootstrap';
import { find, reduce } from 'lodash';

import Modal from 'atoms/Modal';
import Table from 'atoms/Table';
import Button from 'atoms/Button';
import Input from 'atoms/Input';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { ErrorsPropTypesShape } from 'utils/core-proptypes';
import searchIcon from 'assets/icons/search.png';
import { getGroupList } from '../../../actions';
import EditGroupModalBody from '../../ModalElements/EditGroupModalBody';
import { addGroup, updateGroup, getColorList, deleteGroup, clearGroupError } from './actions';
import './group-management-view.scss';
import GroupName from '../TableData/GroupName';
import UserCount from '../TableData/UserCount';

const reducer = (state, action) => {
  const { key, value, payload } = action;
  switch (action.type) {
    case 'setGroup':
    case 'addGroup':
      return {
        ...state,
        currentGroup: { ...action.payload },
        editAddGroupModalPristine: true
      };
    case 'inputChange':
    case 'colorTileChange':
      return {
        ...state,
        currentGroup: { ...state.currentGroup, [key]: value },
        editAddGroupModalPristine: false
      };
    case 'filterGroups':
      return { ...state, ...action.payload };
    // case 'formatGroup':
    //   return { ...state, groups: action.payload };
    case 'clearFilterGroups':
      return { ...state, ...payload };
    default:
      throw new Error();
  }
};

const GroupManagementView = ({ ...props }) => {
  const t = useFormatMessage();
  const [modalShow, setModalShow] = useState(false);
  const [modalGroupDeleteShow, setModalGroupDeleteShow] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState(new Set());
  const [state, dispatcher] = useReducer(reducer, {});

  const handleGroupSearchClear = () => {
    const payload = {
      filterGroupsBy: '',
      groups: props.filteredGroups
    };
    dispatcher({ type: 'clearFilterGroups', payload });
  };

  useEffect(() => {
    if (props.selectedOrg) props.getColorList({ orgId: props.selectedOrg });
    // close modal after adding updating group details
    if (props.addUpdateGroupSuccess) {
      setModalShow(false);
    }
    // close alert modal after deleting is successful
    if (props.deleteGroupSuccess) {
      setModalGroupDeleteShow(false);
      setSelectedGroups(new Set());
    }
  }, [props.addUpdateGroupSuccess, props.selectedOrg, props.deleteGroupSuccess]); // Only re-run on modalShow changes

  useEffect(() => {
    handleGroupSearchClear();
  }, [props.filteredGroups]); // Only re-run on modalShow changes

  const modalOpen = id => {
    let payload = find(props.groupsList, group => {
      return group._id === id;
    });
    if (id) {
      dispatcher({ type: 'setGroup', payload });
    } else {
      payload = {};
      dispatcher({
        type: 'addGroup',
        payload
      });
    }
    setModalShow(true);
  };

  const modalClose = () => {
    if (props.error.length > 0) props.clearGroupError();
    setModalShow(false);
  };

  const handleModalSave = () => {
    const { _id: groupId } = state.currentGroup;
    if (groupId) {
      props.updateGroup({
        payload: state.currentGroup,
        orgId: props.selectedOrg,
        groupId
      });
    } else {
      props.addGroup({ payload: state.currentGroup, orgId: props.selectedOrg });
    }
  };

  const filterOrganizationGroups = ({ group, value }) => {
    const fullString = `${group.name}`.toLowerCase();
    const searchValue = value.toLowerCase();

    const tokenMatch = fullString.includes(searchValue.split(' ').join(''));
    const textMatch = fullString.includes(searchValue);
    return tokenMatch || textMatch;
  };

  const handleGroupSearch = queryEvent => {
    const { value } = queryEvent.target;
    const groups = props.filteredGroups.filter(group => filterOrganizationGroups({ group, value }));

    const payload = {
      filterGroupsBy: value,
      groups
    };
    dispatcher({ type: 'filterGroups', payload });
  };

  const handleDeleteAlert = () => {
    if (!selectedGroups.size) return;
    setModalGroupDeleteShow(true);
  };

  const handleGroupDelete = () => {
    const payload = {
      groupIDs: Array.from(selectedGroups)
    };
    props.deleteGroup({ payload, orgId: props.selectedOrg });
  };

  return (
    <div className="group-management-view">
      <Row>
        <Col>
          <Form className="group-management-view__form">
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div className="search-bar" style={{ position: 'relative' }}>
                <Input
                  icon="icon-user"
                  value={state.filterGroupsBy}
                  onChange={handleGroupSearch}
                  disabled={props.role === 'PARTNER' ? !props.allowPartnersToManageUsers : false}
                  iconImage={searchIcon}
                  isClearable
                  onClear={handleGroupSearchClear}
                />
              </div>
              <div className="mt-0" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Button disabled={props.role === 'PARTNER' ? !props.allowPartnersToManageUsers : false} className="ellipsis" size="md" onClick={() => modalOpen()} variant="primary">
                  {t('common/add-group')}
                </Button>
                <Button disabled={props.role === 'PARTNER' ? !props.allowPartnersToManageUsers : false} className="ml-2 ellipsis" onClick={handleDeleteAlert} size="md" variant="primary">
                  {t('common/delete-selected')}
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      <Table
        tableId="groupTable"
        forceDisableSort={true}
        columnConfig={props.columnConfig}
        handleEdit={modalOpen}
        selectedIds={selectedGroups}
        onSelect={checked => setSelectedGroups(checked)}
        data={(state && state.groups) || props.filteredGroups}
        key={props.selectedOrg}
        hideEditIndexes={state.filterGroupsBy ? [] : [0]}
      />
      <Modal
        heading={state.currentGroup && state.currentGroup._id ? t('group-management/edit-group') : t('group-management/add-group')}
        modalBody={<EditGroupModalBody dispatcher={dispatcher} error={props.error} group={state.currentGroup} colorList={props.colorList} />}
        notPristine={state.editAddGroupModalPristine}
        show={modalShow}
        onSave={handleModalSave}
        onHide={modalClose}
      />

      <Modal
        heading={t('common/modal/please-confirm-text')}
        modalBody={<p>{t('common/modal/group-warning-delete-text')}</p>}
        show={modalGroupDeleteShow}
        isAlert
        onSave={handleGroupDelete}
        onHide={() => setModalGroupDeleteShow(false)}
      />
    </div>
  );
};

GroupManagementView.propTypes = {
  tablekeys: PropTypes.arrayOf(PropTypes.string),
  groupsList: PropTypes.arrayOf(PropTypes.object),
  filteredGroups: PropTypes.arrayOf(PropTypes.object),
  colorList: PropTypes.arrayOf(PropTypes.string),
  selectedOrg: PropTypes.string,
  columnConfig: PropTypes.arrayOf(PropTypes.shape({})),
  addGroup: PropTypes.func,
  getColorList: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  allowPartnersToManageUsers: PropTypes.bool,
  updateGroup: PropTypes.func,
  deleteGroup: PropTypes.func,
  addUpdateGroupSuccess: PropTypes.bool,
  deleteGroupSuccess: PropTypes.bool,
  getGroupList: PropTypes.func,
  error: PropTypes.arrayOf(ErrorsPropTypesShape),
  clearGroupError: PropTypes.func
};

GroupManagementView.defaultProps = {
  tablekeys: ['name', 'description', 'usersCount'],
  columnConfig: [
    {
      key: 'name',
      headerStyles: { width: '310px', paddingLeft: 42 },
      dataStyles: { maxWidth: '310px' },
      isSortable: false,
      // eslint-disable-next-line react/display-name
      renderItem: (item, row) => {
        return <GroupName name={row.name} colorCode={row.colorCode} />;
      }
    },
    { key: 'description', isSortable: false, headerStyles: { width: '400px' }, dataStyles: { maxWidth: '400px' } },
    // eslint-disable-next-line react/display-name
    { key: 'usersCount', isSortable: false, headerStyles: { width: '300px' }, dataStyles: { maxWidth: '300px' }, renderItem: item => <UserCount item={item} /> }
  ],
  groupsList: [],
  filteredGroups: [],
  colorList: [],
  error: [],
  selectedOrg: '',
  addGroup: () => {},
  updateGroup: () => {},
  deleteGroup: () => {},
  getGroupList: () => {},
  addUpdateGroupSuccess: false,
  deleteGroupSuccess: false,
  clearGroupError: () => {}
};

const getFilteredGroups = groups => {
  const allUsersGroup = {
    _id: 'all-users',
    name: 'All users',
    description: 'All users in your organization',
    colorCode: '#d2d3d2',
    status: 'ACTIVE',
    usersCount: reduce(groups, (sum, group) => sum + group.usersCount, 0)
  };
  return [allUsersGroup, ...groups];
  // const gourpWithAllUsersRow = [allUsersGroup, ...groups];
  // return gourpWithAllUsersRow.map(group => ({
  //   ...group,
  //   usersCount: group.usersCount
  //   // name: { value: group.name, colorCode: group.colorCode }
  // }));
};

const mapStateToProps = ({ groupState, userState, adminState }) => {
  return {
    user: userState.user,
    groupsList: groupState.groupsList,
    filteredGroups: getFilteredGroups(groupState.groupsList),
    error: groupState.error,
    addUpdateGroupSuccess: groupState.addUpdateGroupSuccess,
    deleteGroupSuccess: groupState.deleteGroupSuccess,
    colorList: adminState.colorList
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addGroup: bindActionCreators(addGroup, dispatch),
    updateGroup: bindActionCreators(updateGroup, dispatch),
    getGroupList: bindActionCreators(getGroupList, dispatch),
    getColorList: bindActionCreators(getColorList, dispatch),
    deleteGroup: bindActionCreators(deleteGroup, dispatch),
    clearGroupError: bindActionCreators(clearGroupError, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupManagementView));
