import React from 'react';
import PropTypes from 'prop-types';
import { useFormatMessage } from 'hooks/useFormatMessage';
import ProgressLine from 'atoms/ProgressLine';

const MostActiveUsers = ({ topUsers = [] }) => {
  const t = useFormatMessage();

  const maxAttempts = (topUsers[0] && topUsers[0].noOfAttempts) || 1;
  return (
    <div className="stats-box-large bg-teal-blue">
      <div className="box-large-content-1 white-txt">
        <div className="box-content-head span3">{t('user-activity/most-active-users')}</div>
        {!topUsers.length
          ? t('user-activity/no-data-available')
          : topUsers.map(user => (
              <div key={user.userId}>
                <p className="progress-small-text">{`${user.userName}, ${user.groupName}`}</p>
                <ProgressLine classname="stat-box-progress-bg" visualBarClassname="stat-box-progress" percentage={(user.noOfAttempts / maxAttempts) * 100} />
              </div>
            ))}
      </div>
    </div>
  );
};

MostActiveUsers.propTypes = {
  topUsers: PropTypes.arrayOf(PropTypes.object)
};
export default MostActiveUsers;
