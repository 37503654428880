import { get } from 'lodash';
import {
  ADD_PREFERENCE_SUCCESS,
  ADD_PREFERENCE_FAILURE,
  UPDATE_PREFERENCE_FAILURE,
  UPDATE_PREFERENCE,
  UPDATE_CONTENT_DATA,
  UPDATE_CONTENT_DATA_SUCCESS,
  UPDATE_CONTENT_DATA_FAILURE,
  UPDATE_PREFERENCE_SUCCESS,
  ADD_CONTENT_DATA,
  ADD_CONTENT_DATA_SUCCESS,
  ADD_CONTENT_DATA_FAILURE,
  FETCH_ALL_CONTENT,
  FETCH_ALL_CONTENT_SUCCESS,
  FETCH_ALL_CONTENT_FAILURE,
  DELETE_CONTENT,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_FAILURE,
  INITIALIZE_CURRENT_CONTENT,
  INITIALIZE_CURRENT_CONTENT_SUCCESS,
  INITIALIZE_CURRENT_CONTENT_FAILURE,
  UPDATE_SHARED_CONTENT_SUCCESS
} from 'utils/constants';

const separateContents = (contents = []) => {
  const sharedContents = contents.filter(content => content.shared);
  const allContents = contents.filter(content => !content.shared);
  return { allContents, sharedContents };
};

const initialState = {
  preferences: {},
  currentContent: {},
  allContents: [],
  sharedContents: [],
  fetching: false,
  contentDeleteSuccess: false,
  errors: { UPDATE_CONTENT_DATA_FAILURE: [], ADD_CONTENT_DATA_FAILURE: [] }
};

const contentState = (state = initialState, action) => {
  const message = get(action, 'payload.message', {});
  const errors = get(action, 'payload.response.errors', {});

  switch (action.type) {
    case ADD_PREFERENCE_SUCCESS:
      return {
        ...state,
        allContents: [...state.allContents, { ...action.payload.content }],
        currentContent: action.payload.content,
        message: { ...state.message, ADD_PREFERENCE_SUCCESS: message },
        errors: { ...state.errors, ADD_PREFERENCE_FAILURE: false }
      };
    case ADD_PREFERENCE_FAILURE: {
      return {
        ...state,
        errors: { ADD_PREFERENCE_FAILURE: errors },
        message: { ...state.message, ADD_PREFERENCE_SUCCESS: {} }
      };
    }
    case UPDATE_PREFERENCE: {
      return {
        ...state,
        message: { ...state.message, UPDATE_PREFERENCE_SUCCESS: false },
        errors: { ...state.errors, UPDATE_PREFERENCE_FAILURE: false }
      };
    }
    case UPDATE_PREFERENCE_SUCCESS: {
      const content = get(action, 'payload.content', {});
      return {
        ...state,
        currentContent: { ...state.currentContent, ...content },
        message: { ...state.message, UPDATE_PREFERENCE_SUCCESS: message },
        errors: { ...state.errors, UPDATE_PREFERENCE_FAILURE: false }
      };
    }

    case UPDATE_PREFERENCE_FAILURE: {
      return {
        ...state,
        errors: { [action.type]: errors },
        message: { ...state.message, UPDATE_PREFERENCE_SUCCESS: {} }
      };
    }
    case ADD_CONTENT_DATA: {
      return {
        ...state,
        message: {
          ...state.message,
          ADD_CONTENT_DATA_SUCCESS: false,
          UPDATE_CONTENT_DATA_SUCCESS: false
        },
        errors: { ...state.errors, ADD_CONTENT_DATA_FAILURE: [] }
      };
    }
    case ADD_CONTENT_DATA_SUCCESS: {
      const newContentData = get(action, 'payload.contentData', {});
      return {
        ...state,
        currentContent: {
          ...state.currentContent,
          data: [...state.currentContent.data, newContentData]
        },
        message: {
          ...state.message,
          ADD_CONTENT_DATA_SUCCESS: message,
          UPDATE_CONTENT_DATA_SUCCESS: {}
        },
        errors: { ...state.errors, ADD_CONTENT_DATA_FAILURE: [] }
      };
    }
    case UPDATE_CONTENT_DATA: {
      return {
        ...state,
        message: {
          ...state.message,
          UPDATE_CONTENT_DATA_SUCCESS: false,
          ADD_CONTENT_DATA_SUCCESS: false
        },
        errors: {
          ...state.errors,
          ADD_CONTENT_DATA_FAILURE: [],
          UPDATE_CONTENT_DATA_FAILURE: []
        }
      };
    }
    // to do check and do mcqlist reducer logic for object id to remove and set latest data from api response
    case UPDATE_CONTENT_DATA_SUCCESS: {
      const { langCode, _id: contentId, setDefaultLanguage } = action.payload.contentData;
      const modifiedCurrentContentDataList = state.currentContent.data.map(contentData => (contentId === contentData._id ? action.payload.contentData : contentData));
      return {
        ...state,
        currentContent: {
          ...state.currentContent,
          data: modifiedCurrentContentDataList,
          defaultLangCode: setDefaultLanguage ? langCode : state.currentContent.defaultLangCode
        },
        message: {
          ...state.message,
          UPDATE_CONTENT_DATA_SUCCESS: message,
          ADD_CONTENT_DATA_SUCCESS: {}
        },
        errors: {
          ...state.errors,
          ADD_CONTENT_DATA_FAILURE: [],
          UPDATE_CONTENT_DATA_FAILURE: []
        }
      };
    }
    case UPDATE_CONTENT_DATA_FAILURE: {
      return {
        ...state,
        errors: { ...state.errors, UPDATE_CONTENT_DATA_FAILURE: errors },
        message: {
          ...state.message,
          ADD_CONTENT_DATA_SUCCESS: {},
          UPDATE_CONTENT_DATA_SUCCESS: {}
        }
      };
    }

    case ADD_CONTENT_DATA_FAILURE: {
      return {
        ...state,
        errors: { ADD_CONTENT_DATA_FAILURE: errors },
        message: { ...state.message, ADD_CONTENT_DATA_SUCCESS: {} }
      };
    }
    case FETCH_ALL_CONTENT:
      return {
        ...state,
        allContentFetching: true
      };

    case FETCH_ALL_CONTENT_SUCCESS:
      return {
        ...state,
        allContentFetching: false,
        contentDeleteSuccess: false,
        errors: {},
        message: {},
        ...separateContents(action.payload)
      };

    case FETCH_ALL_CONTENT_FAILURE:
      return {
        ...state,
        allContentFetching: false
      };
    case DELETE_CONTENT:
    case DELETE_CONTENT_FAILURE:
      return {
        ...state,
        contentDeleteSuccess: false
      };

    case DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        contentDeleteSuccess: true
      };

    case INITIALIZE_CURRENT_CONTENT: {
      return {
        ...state,
        currentContent: initialState.currentContent
      };
    }

    case INITIALIZE_CURRENT_CONTENT_SUCCESS: {
      return {
        ...state,
        currentContent: get(action, 'payload.0', initialState.currentContent)
      };
    }
    case INITIALIZE_CURRENT_CONTENT_FAILURE: {
      return {
        ...state
      };
    }

    case UPDATE_SHARED_CONTENT_SUCCESS: {
      const mAllContents = state.allContents.map(content => (content._id === action.payload.contentId ? { ...content, sharedOrganizationCount: action.payload.shared.length } : content));
      return {
        ...state,
        allContents: mAllContents
      };
    }

    default:
      return state;
  }
};
export default contentState;
