const reducer = (state, action) => {
  switch (action.type) {
    case 'initializeState': {
      const { thumbnailImage, media } = action;
      return {
        ...state,
        thumbnailImage,
        media,
        uploadingStatus: 'loaded'
      };
    }
    case 'setShow':
      return {
        ...state,
        show: !state.show,
        target: action.payload.target,
        fileName: action.payload.name
      };
    case 'setUploadingStatus':
      return {
        ...state,
        uploadingStatus: action.payload
      };
    case 'setThumbnailImage':
      return {
        ...state,
        thumbnailImage: action.payload
      };

    case 'setMedia':
      return {
        ...state,
        media: action.payload
      };
    case 'clearAll':
      return {
        ...state,
        ...action.payload,
        thumbnailImage: null,
        media: null,
        target: null,
        fileName: null,
        uploadingStatus: 'initial'
      };
    default:
      throw new Error();
  }
};

export default reducer;
