import { combineReducers } from 'redux';
import userState from './signin';
import HomeState from './home-reducer';
import adminState from './admin';
import organizationUsers from './users-reducer';
import groupState from './group-reducer';
import contentState from './content-reducer';
import modulesState from './module-reducer';
import userActivityState from './user-activity-reducer';
import myAccountState from './my-account-reducer';
import accountSettings from './account-settings';
import appSettings from './app-settings';
import sharedContent from './shared-content';
import globalNotificationState from './notification-global';

const appReducer = combineReducers({
  userState,
  HomeState,
  adminState,
  organizationUsers,
  groupState,
  contentState,
  modulesState,
  userActivityState,
  myAccountState,
  accountSettings,
  appSettings,
  sharedContent,
  globalNotificationState
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER_SUCCESS') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
