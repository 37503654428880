import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import sn from 'classnames';

import IconWrapper from 'atoms/IconWrapper';
import { useFormatMessage } from 'hooks/useFormatMessage';
import './global-notification.scss';

const GlobalNotification = ({ type, globalNotificationId, errorMessage, code, baseCode }) => {
  const t = useFormatMessage();
  const [isNotificationActive, setIsNotificationActive] = useState(null);
  const customTime = ['CREATE_CONTENT_ERROR', 'CREATE_MODULE_ERROR'].includes(baseCode) ? 10000 : 5000;
  useEffect(() => {
    if (type) setIsNotificationActive(true);
    let timer = !isNotificationActive && setTimeout(() => setIsNotificationActive(false), customTime);
    // this will clear Timeout when component unmont like in willComponentUnmount
    return () => {
      clearTimeout(timer);
    };
  }, [globalNotificationId]);

  return isNotificationActive ? (
    <div className={sn('global-notification-container', `global-notification-container--${type}`)}>
      {type === 'success' && <Row className="global-notification-container__row">{t(code)}</Row>}
      {type === 'error' && <Row className="global-notification-container__row">{errorMessage ? errorMessage : t(code, {}, t('global-notification-GENERAL_ERROR'))}</Row>}
      <IconWrapper
        type="CloseIcon"
        className={'global-notification-container__close-icon'}
        onClick={() => {
          setIsNotificationActive(false);
        }}
      />
    </div>
  ) : (
    <div />
  );
};

GlobalNotification.propTypes = {
  type: PropTypes.string,
  code: PropTypes.string,
  baseCode: PropTypes.string,
  errorMessage: PropTypes.string,
  customTimer: PropTypes.string,
  globalNotificationId: PropTypes.string
};

GlobalNotification.defaultProps = {
  type: '',
  code: '',
  globalNotificationId: ''
};

const mapStateToProps = ({ globalNotificationState }) => {
  const { code, type, globalNotificationId, errorMessage, baseCode } = globalNotificationState;
  return {
    code,
    baseCode,
    type,
    globalNotificationId,
    errorMessage
  };
};
const mapDispatchToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalNotification));
