import { getSearchStringFromObject } from 'hooks/useFetcher';
import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';

import {
  FETCH_ALL_CONTENT,
  FETCH_ALL_CONTENT_SUCCESS,
  FETCH_ALL_CONTENT_FAILURE,
  GET_SHARED_CONTENT,
  GET_SHARED_CONTENT_SUCCESS,
  GET_SHARED_CONTENT_FAILURE,
  INITIALIZE_CURRENT_CONTENT,
  INITIALIZE_CURRENT_CONTENT_SUCCESS,
  INITIALIZE_CURRENT_CONTENT_FAILURE,
  UPDATE_SHARED_CONTENT,
  UPDATE_SHARED_CONTENT_SUCCESS,
  UPDATE_SHARED_CONTENT_FAILURE,
  DELETE_CONTENT,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_FAILURE,
  SHARE_CONTENT_COPY,
  SHARE_CONTENT_COPY_SUCCESS,
  SHARE_CONTENT_COPY_FAILURE
} from 'utils/constants';

export const fetchAllContent = ({ selectedOrg: organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/contents${getSearchStringFromObject({
      owned: true,
      shared: true,
      sortType: 'alphabetical',
      skip: 0,
      limit: 10000
    })}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [FETCH_ALL_CONTENT, FETCH_ALL_CONTENT_SUCCESS, FETCH_ALL_CONTENT_FAILURE]
  }
});

export const getContentSharedToOrgs = ({ selectedOrg: organizationId, contentId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/contents/${contentId}/shared`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [GET_SHARED_CONTENT, GET_SHARED_CONTENT_SUCCESS, GET_SHARED_CONTENT_FAILURE]
  }
});

export const updateContentSharedToOrgs = ({ organizationId, contentId, payload }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/contents/${contentId}/shared`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [UPDATE_SHARED_CONTENT, UPDATE_SHARED_CONTENT_SUCCESS, UPDATE_SHARED_CONTENT_FAILURE]
  }
});
export const deleteContent = ({ contentId, selectedOrg: organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/contents/${contentId}`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    types: [DELETE_CONTENT, DELETE_CONTENT_SUCCESS, DELETE_CONTENT_FAILURE]
  }
});

// export const initializeCurrentContent = ({ contentId }) => ({
//   type: INITIALIZE_CURRENT_CONTENT,
//   payload: { contentId }
// });

export const shareContentCopy = ({ contentId, selectedOrg: organizationId, payload }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/contents/${contentId}/shareCopy`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [SHARE_CONTENT_COPY, SHARE_CONTENT_COPY_SUCCESS, SHARE_CONTENT_COPY_FAILURE]
  }
});
export const dispatchCustomAction = (actionType, payload) => {
  return { type: actionType, payload };
};

export const initializeCurrentContent = ({ selectedOrg: organizationId, contentId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/contents/${contentId}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [INITIALIZE_CURRENT_CONTENT, INITIALIZE_CURRENT_CONTENT_SUCCESS, INITIALIZE_CURRENT_CONTENT_FAILURE]
  }
});
