import { cloneDeep, omit } from 'lodash';
import uuidv4 from 'uuid/v4';

export const reducer = (state, action) => {
  const { key, value } = action;

  switch (action.type) {
    case 'inputChange':
    case 'dropDown':
    case 'inputRadio':
      return {
        ...state,
        ...{ [key]: value },
        mcqContentCreateUpdatePristine: false
      };
    case 'addBlock':
      return {
        ...state,
        mcqList: [...state.mcqList, action.payload],
        mcqContentCreateUpdatePristine: false
      };
    case 'sortMcqList':
      return {
        ...state,
        mcqList: [...action.payload],
        mcqContentCreateUpdatePristine: false
      };
    case 'updateAnswer': {
      const { questionIndex, answerIndex } = action;
      const question = { ...state.mcqList[questionIndex] };
      question.options[answerIndex] = value;

      return { ...state, mcqContentCreateUpdatePristine: false };
    }
    case 'addAnswer': {
      const { questionIndex } = action;
      const { mcqList } = { ...state };
      const question = mcqList[questionIndex];
      question.options.push('');
      return {
        ...state,
        mcqList: [...mcqList],
        mcqContentCreateUpdatePristine: false
      };
    }
    case 'updateQuestion': {
      const { index, field } = action;
      const fieldValue = action.value;

      const mcqList = [...state.mcqList];
      mcqList[index][field] = fieldValue;

      return { ...state, mcqList, mcqContentCreateUpdatePristine: false };
    }

    case 'deleteQuestion': {
      const { index } = action;
      const mcqList = [...state.mcqList];
      mcqList.splice(index, 1);
      return {
        ...state,
        mcqList: [...mcqList],
        mcqContentCreateUpdatePristine: false
      };
    }

    case 'setItemMedia': {
      const { index, media } = action;
      return {
        ...state,
        mcqList: state.mcqList.map((mcq, i) => (i === index ? { ...mcq, media: { ...media, upload: true } } : mcq)),
        mcqContentCreateUpdatePristine: false
      };
    }

    case 'duplicateQuestion': {
      const { index } = action;
      const newState = { ...state };
      const newQuestion = omit(cloneDeep({ ...state.mcqList[index] }), ['_id']);
      newQuestion._id = uuidv4();
      newState.mcqList = [...state.mcqList];
      newState.mcqList.splice(index + 1, 0, { ...newQuestion });
      return { ...state, ...newState, mcqContentCreateUpdatePristine: false };
    }
    case 'setThumbnail': {
      const { thumbnail } = action;
      return {
        ...state,
        thumbnail,
        mcqContentCreateUpdatePristine: false,
        thumbnailPristine: true
      };
    }
    case 'setMedia': {
      const { media } = action;
      return {
        ...state,
        media,
        mcqContentCreateUpdatePristine: false,
        mediaPristine: true
      };
    }
    case 'initialState': {
      return {
        ...state,
        ...action.payload,
        mcqContentCreateUpdatePristine: true,
        mediaPristine: false,
        thumbnailPristine: false
      };
    }
    default:
      throw new Error();
  }
};
