import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import { isEmpty, get } from 'lodash';

import { formatLanguages } from 'utils/calculationUtilities';
import SettingsDashboard from './app-settings-dashboard';
import { fetchPublications } from '../../actions';

import './app-settings.scss';

const AppSettings = ({ fetchPublications, publications }) => {
  useEffect(() => {
    if (isEmpty(publications)) {
      fetchPublications();
    }
  }, []);

  return (
    <React.Fragment>
      <Container className="p-0">
        <Row className="app-setting__tabs justify-content-center">
          <Col xs={12}>
            <SettingsDashboard />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

AppSettings.propTypes = {
  fetchPublications: PropTypes.func.isRequired,
  publications: PropTypes.arrayOf(PropTypes.string).isRequired
};

AppSettings.defaultProps = {};

const mapStateToProps = ({ userState, adminState }) => {
  const publications = get(adminState, 'publications', []);
  const formattedLanguageList = formatLanguages(publications);
  return {
    user: userState.user,
    publications: formattedLanguageList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPublications: bindActionCreators(fetchPublications, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppSettings));
