import React, { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import uuidv4 from 'uuid/v4';
import { Col, Row } from 'react-bootstrap';
import { get, lowerCase } from 'lodash';

import Input from 'atoms/Input';
import Editor from 'atoms/Editor';
import Feedback from 'atoms/Feedback';
import { Radio, RadioGroup } from 'atoms/Radio';
import ImageThumbnail from 'atoms/ImageThumbnail';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { AllowedMediaTypeSource, getMediaPayload, contentDataSelector } from 'utils/calculationUtilities';
import { PresentationPreferencesPropTypesShape, CurrentContentPropTypesShape } from 'utils/core-proptypes';
import NoPPTImage from 'assets/icons/nopresentation.svg';
import ActionRowContentData from '../../ActionRowContentData';
import { addContentData, updateContentData } from '../../actions';
import './presentation-create.scss';
import reducer from './reducer';

const blockName = 'presentation-content-create';

const PresentationCreate = ({ contentId, currentContent, orgid, ...props }) => {
  const t = useFormatMessage();
  const langCode = lowerCase(get(props, 'langCode', ''));
  const initialState = {
    langCode,
    setDefaultLanguage: true,
    documentation: ''
  };
  const currentContentData = contentDataSelector({
    contentData: currentContent,
    langCode
  });
  const { preferences } = currentContent;
  const [state, dispatcher] = useReducer(reducer, currentContentData || initialState);

  const media = state.media;
  const createFeedbackMessage = get(props, 'message.ADD_CONTENT_DATA_SUCCESS', {});
  const updateFeedbackMessage = get(props, 'message.UPDATE_CONTENT_DATA_SUCCESS', {});
  const createFeedbackError = get(props, 'errors.ADD_CONTENT_DATA_FAILURE', []);
  const updateFeedbackError = get(props, 'errors.UPDATE_CONTENT_DATA_FAILURE', []);
  const contentDataId = get(currentContentData, '_id', '');

  useEffect(() => {
    const defaultLanguage = props.defaultLangCode;
    dispatcher({
      type: 'initialState',
      payload: {
        ...currentContentData,
        ...currentContentData.details,
        setDefaultLanguage: defaultLanguage,
        revertId: uuidv4()
      }
    });
  }, [currentContent]);

  const contentLanguageRevert = () => {
    dispatcher({
      type: 'initialState',
      payload: {
        ...currentContentData,
        ...currentContentData.details,
        revertId: uuidv4()
      }
    });
  };

  const handleVideoModalSave = media => {
    dispatcher({
      type: 'setMedia',
      media
    });
  };

  const onSave = () => {
    const defaultLanguage = state.setDefaultLanguage === langCode;
    const payload = {
      ...state,
      ...getMediaPayload(state.media, state.mediaPristine, 'media'),
      setDefaultLanguage: defaultLanguage
    };
    if (contentId && contentDataId) {
      props.updateContentData({
        payload,
        contentId,
        contentDataId,
        organizationId: orgid
      });
    } else {
      props.addContentData({ payload, contentId, organizationId: orgid });
    }
  };

  const { documentation } = state;

  return (
    <React.Fragment>
      <div className={blockName}>
        <Row>
          <Col>
            <div className={`${blockName}__title`}>{t('common/content-title')}</div>
            <Input
              className={`${blockName}__input-title`}
              value={state && state.contentTitle}
              placeholder={t('common/content-title')}
              onChange={e => {
                dispatcher({
                  type: 'inputChange',
                  key: 'contentTitle',
                  value: e.target.value
                });
              }}
            />
            <div className={`${blockName}__desc`}>{t('common/description')}</div>
            <Input
              as="textarea"
              rows="4"
              placeholder={t('common/description')}
              className={`${blockName}__input-desc`}
              value={state && state.description}
              onChange={e => {
                dispatcher({
                  type: 'inputChange',
                  key: 'description',
                  value: e.target.value
                });
              }}
            />
            <RadioGroup
              name="mcq-create-default-lang"
              onSelect={value => {
                dispatcher({
                  type: 'inputRadio',
                  key: 'setDefaultLanguage',
                  value: value.value
                });
              }}
            >
              <Radio
                labelClass={`${blockName}__default-lang`}
                value={langCode}
                checked={state.setDefaultLanguage === langCode}
                description={t('common/set-as-default-language-description')}
                label={t('common/set-as-default-language-title')}
              />
            </RadioGroup>
          </Col>
        </Row>
        <Row className={`${blockName}__thumbnail-row`}>
          <div>
            {t('common/video-text')}
            <ImageThumbnail
              placeholderIcon={NoPPTImage}
              defaultMedia={media}
              allowedFileType={[AllowedMediaTypeSource.FILE, AllowedMediaTypeSource.VIMEO, AllowedMediaTypeSource.YOUTUBE]}
              handleSave={handleVideoModalSave}
            />
          </div>
        </Row>

        <Row style={{ paddingLeft: '15px', marginBottom: '10px' }}>Page Content</Row>
        <Row style={{ paddingLeft: '15px' }}>
          <Editor dispatcher={dispatcher} editorKey={'documentation'} documentation={documentation} />
        </Row>
        <Row className="mt-4">
          <ActionRowContentData
            onSave={onSave}
            state={state}
            contentId={contentId}
            contentDataId={contentDataId}
            preferences={preferences}
            onRevert={contentLanguageRevert}
            contentType={currentContent.contentType}
            notPristine={state.mcqContentCreateUpdatePristine}
            onDelete={props.onDelete}
            langCode={langCode}
            orgid={orgid}
          />
        </Row>
      </div>
      <Feedback allowedTypesKey="PresentationData" message={{ ...createFeedbackMessage, ...updateFeedbackMessage }} errorList={[...createFeedbackError, ...updateFeedbackError]} />
    </React.Fragment>
  );
};

PresentationCreate.propTypes = {
  preferences: PresentationPreferencesPropTypesShape,
  updateContentData: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
  addContentData: PropTypes.func.isRequired,
  langCode: PropTypes.string.isRequired,
  defaultLangCode: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  orgid: PropTypes.string.isRequired,
  currentContent: PropTypes.shape({
    currentContentData: CurrentContentPropTypesShape,
    preferences: PresentationPreferencesPropTypesShape,
    contentType: PropTypes.string
  })
};

PresentationCreate.defaultProps = {
  onDelete: () => {},
  currentContentData: {}
};

const mapStateToProps = ({ contentState }) => {
  const errors = get(contentState, 'errors', []);
  const message = get(contentState, 'message', {});

  return {
    contentMediaUploading: contentState.contentMediaUploading,
    error: contentState.error,
    errors,
    message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addContentData: bindActionCreators(addContentData, dispatch),
    updateContentData: bindActionCreators(updateContentData, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PresentationCreate));
