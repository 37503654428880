export const contentTypes = {
  MCQ: 'content-add-card/mcq',
  CAROUSEL: 'content-add-card/carousel',
  PRESENTATION: 'content-add-card/presentation',
  TRUE_FALSE: 'content-add-card/true-or-false',
  STORY: 'content-add-card/story',
  MIXED: 'content-add-card/mixed'
};

export const difficultyLevelTypes = {
  LOW: 'content-preference/LOW',
  MIDDLE: 'content-preference/MIDDLE',
  HIGH: 'content-preference/HIGH'
};

export const estimatedTimeTypes = {
  '3-5': 'content-preference/3-5',
  '6-10': 'content-preference/6-10',
  '11-20': 'content-preference/11-20',
  '21-30': 'content-preference/21-30',
  '31-60': 'content-preference/31-60'
};

export const mixedContentAdditionalPrefs = {
  REVEAL_ANSWERS: 'common-content-preference/reveal-answers',
  SHUFFLE_ANSWER_CHOICES: 'common-content-preference/shuffle-answers-choices',
  APPEAR_ON_DIPLOMA: 'common-content-preference/appear-on-diploma',
  ASK_FOR_USER_CONFIRMATION: 'common-content-preference/ask-for-user-confirmation',
  SHOW_BLOCK_ONE_BY_ONE: 'common-content-preference/show-block-one-by-one',
  MANDATORY_CONTENT: 'common-content-preference/mandatory-content'
};

export const mixedPreferenceConstantToKeyMap = {
  REVEAL_ANSWERS: 'showRightAnswer',
  SHUFFLE_ANSWER_CHOICES: 'shuffleAnswerChoices',
  APPEAR_ON_DIPLOMA: 'showContentOnDiploma',
  ASK_FOR_USER_CONFIRMATION: 'askForConfirmation',
  SHOW_BLOCK_ONE_BY_ONE: 'displayQuestionMode',
  MANDATORY_CONTENT: 'mandatoryContent'
};

export const mcqContentAdditionalPrefs = {
  REVEAL_ANSWERS: 'common-content-preference/reveal-answers',
  SHOW_CONTENT_IN_ORDER: 'common-content-preference/show-content-in-order',
  APPEAR_ON_DIPLOMA: 'common-content-preference/appear-on-diploma',
  SHOW_BLOCK_ONE_BY_ONE: 'common-content-preference/show-block-one-by-one',
  SHOW_AFTER_VIDEO: 'common-cotent-preference/only-show-questions-after-video-played'
};

export const mcqPreferenceConstantToKeyMap = {
  REVEAL_ANSWERS: 'showRightAnswer',
  SHOW_CONTENT_IN_ORDER: 'showContentInOrder',
  APPEAR_ON_DIPLOMA: 'showContentOnDiploma',
  SHOW_BLOCK_ONE_BY_ONE: 'displayQuestionMode',
  SHOW_AFTER_VIDEO: 'showQuestionsAfterVideo'
};

export const carouselStoryContentAdditionalPrefs = {
  APPEAR_ON_DIPLOMA: 'common-content-preference/appear-on-diploma',
  ASK_FOR_USER_CONFIRMATION: 'common-content-preference/ask-for-user-confirmation'
};

export const carouselStoryPreferenceConstantToKeyMap = {
  APPEAR_ON_DIPLOMA: 'showContentOnDiploma',
  ASK_FOR_USER_CONFIRMATION: 'askForConfirmation'
};

export const presentationContentAdditionalPrefs = {
  APPEAR_ON_DIPLOMA: 'common-content-preference/appear-on-diploma',
  ASK_FOR_USER_CONFIRMATION: 'common-content-preference/ask-for-user-confirmation',
  DOWNLOAD_CONTENT: 'presentation-content/let-the-user-download-presentaion'
};

export const presentationPreferenceConstantToKeyMap = {
  APPEAR_ON_DIPLOMA: 'showContentOnDiploma',
  ASK_FOR_USER_CONFIRMATION: 'askForConfirmation',
  DOWNLOAD_CONTENT: 'downloadable'
};
