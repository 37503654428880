import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import useFetcher, { getSearchStringFromObject } from 'hooks/useFetcher';
import { useFormatMessage } from 'hooks/useFormatMessage';
import ThumbnailCardInfo from 'atoms/ThumbnailCardInfo';
import IconWrapper from 'atoms/IconWrapper';
import { HistoryPropTypesShape, UserPropTypesShape } from 'utils/core-proptypes';
import { contentMap } from './utils';
import ContentTypePreview from './../ContentPreview';
import ModuleContentActionRow from '../ModuleContentActionRow';
import ContentCardPreview from '../ContentCardPreview';
import { useModuleContentSetupState } from '../ModuleContentProvider';
import { SharedContentCardLoader, SharedContentListLoader } from './SharedContentLoader';
import './shared-content-setup.scss';

const SharedContentSetupView = ({ selectedOrg }) => {
  const t = useFormatMessage();
  const query = { owned: false, shared: true, data: false };

  const { layout, setLayout, sortType, setSortType } = useModuleContentSetupState();
  const [showContentPreview, setShowContentPreview] = useState(false);
  const [activePreviewContentId, setActivePreviewContentId] = useState(null);
  const { loading, data: sharedContents, showLoadMore: hasNextPage, error: itemsError, loadMoreData: loadMore, doFetch } = useFetcher(
    `/organizations/${selectedOrg}/contents${getSearchStringFromObject({ sort: sortType, ...query })}`,
    12,
    true
  );

  useEffect(() => {
    doFetch(`/organizations/${selectedOrg}/contents${getSearchStringFromObject({ sort: sortType, ...query })}`, true);
  }, [selectedOrg, sortType]);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!itemsError,
    rootMargin: '0px 0px 100px 0px'
  });

  const handlePreview = ({ _id }) => {
    setActivePreviewContentId(_id);
    setShowContentPreview(true);
  };

  const modifiedData = sharedContents.map(content => contentMap(content, t));

  const getOptions = ({ _id, warning, contentType }) => [
    ...(warning
      ? []
      : [
          {
            name: t('common/preview'),
            order: { grid: 1, list: 1 },
            showInListView: true,
            handler: () => {
              handlePreview({ _id, contentType });
            }
          }
        ])
  ];

  const handleView = layout => {
    setLayout(layout);
  };

  const handleSort = sortType => {
    setSortType(sortType);
    doFetch(`/organizations/${selectedOrg}/contents${getSearchStringFromObject({ sort: sortType, ...query })}`, true);
  };

  return (
    <React.Fragment>
      <ModuleContentActionRow layout={layout} sortType={sortType} handleChangeLayout={handleView} handleChangeSort={handleSort} />
      <Container className={`content-setup-view-container ${layout}`}>
        {showContentPreview ? (
          <div className="lessonPreview__overlay">
            <div className="lessonPreview__toolbar">
              <div className="preview">{t('common/preview')}</div>
              <div className="closePreview">{t('common/close-preview')}</div>
              <div
                className="closeIcon"
                onClick={() => {
                  setActivePreviewContentId(null);
                  setShowContentPreview(false);
                }}
              >
                <IconWrapper type="CloseIcon" />
              </div>
            </div>
            {activePreviewContentId && <ContentTypePreview organizationId={selectedOrg} contentId={activePreviewContentId} />}
          </div>
        ) : null}
        {modifiedData.map(content => (
          <ThumbnailCardInfo
            key={content._id}
            layout={layout}
            type="shared-content"
            cardInfo={content}
            options={getOptions(content)}
            renderPreview={<ContentCardPreview heading={content.heading} description={content.description} difficultyLevel={content.difficultyLevel} estimatedTime={content.estimatedTime} />}
          />
        ))}
        {(loading || hasNextPage) && <div ref={sentryRef}></div>}
      </Container>
      {loading && layout === 'grid' && (
        <Container className={`content-setup-view-container ${layout}`} style={{ marginTop: 0 }}>
          <SharedContentCardLoader />
          <SharedContentCardLoader />
          <SharedContentCardLoader />
          <SharedContentCardLoader />
        </Container>
      )}
      {loading && layout === 'list' && (
        <Container className={`content-setup-view-container ${layout}`} style={{ marginTop: 0 }}>
          <SharedContentListLoader />
          <SharedContentListLoader />
          <SharedContentListLoader />
          <SharedContentListLoader />
          <SharedContentListLoader />
          <SharedContentListLoader />
          <SharedContentListLoader />
          <SharedContentListLoader />
          <SharedContentListLoader />
          <SharedContentListLoader />
        </Container>
      )}
    </React.Fragment>
  );
};

SharedContentSetupView.propTypes = {
  history: HistoryPropTypesShape,
  selectedOrg: PropTypes.string.isRequired,
  user: UserPropTypesShape
};

SharedContentSetupView.defaultProps = {};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SharedContentSetupView));
