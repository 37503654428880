import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'utils/unique-id';
import moveGreen from 'assets/icons/move_green.png';

import Switch from 'atoms/Switch';
import IconWrapper from 'atoms/IconWrapper';
import DatePicker from 'atoms/DatePicker';
import { mediaSelector } from 'utils/selectors';
import './module-card-row.scss';

const rowBlockName = 'modules-content-row-layout';

const ModuleCardRow = ({ datePickerProps, cardInfo, actions }) => {
  const { _id, heading, thumbnailImage, status } = cardInfo;
  const thumbnailUrl = useMemo(() => (thumbnailImage ? mediaSelector({ media: thumbnailImage }) : null), [thumbnailImage]);

  const renderAction = action => {
    const disabledClass = action.enabled ? '' : 'disabled';
    switch (action.type) {
      case 'icon-button': {
        return (
          <div key={action.name} className={`${rowBlockName}__front__options__actions ${disabledClass}`} onClick={action.enabled ? action.handler : () => {}} style={action.style}>
            <IconWrapper className={`${rowBlockName}__front__options__actions__icon ${disabledClass}`} type={action.icon} />
            <div className={`${rowBlockName}__front__options__actions__icon__label ${disabledClass}`}>{action.name}</div>
          </div>
        );
      }
      case 'switch': {
        return (
          <div key={action.name} className={`${rowBlockName}__front__options__actions ${disabledClass}`} style={action.style}>
            <Switch key={action.name} id={`activate-deactivate-${_id}`} onSelect={action.enabled ? action.handler : () => {}} value={status === 'ACTIVE'} />
            <div>{action.name}</div>
          </div>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <div data-id={_id} className={`${rowBlockName}`}>
      <div className={`${rowBlockName}__front`}>
        <IconWrapper srcUrl={thumbnailUrl} type="NoImage" className={`${rowBlockName}__front__thumbnail`} />
        <div className={`${rowBlockName}__front__heading`}>
          <p className={`${rowBlockName}__front__heading__text`}>{heading}</p>
        </div>
        <div className={`${rowBlockName}__front__options`}>
          <DatePicker id={_id} {...datePickerProps} />
          {actions.map(action => renderAction(action))}
        </div>
        <div className={`${rowBlockName}__front__move`}>
          <img key={uniqueId('accordion-icon')} className="module-content-cards-sort" src={moveGreen} alt="Sort icon" />
        </div>
      </div>
    </div>
  );
};

ModuleCardRow.propTypes = {
  cardInfo: PropTypes.shape({
    _id: PropTypes.string,
    heading: PropTypes.string,
    thumbnailImage: PropTypes.shape({}),
    status: PropTypes.string
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      enabled: PropTypes.bool,
      type: PropTypes.oneOf(['icon-button', 'switch']),
      icon: PropTypes.string,
      name: PropTypes.string,
      handler: PropTypes.func
    })
  ),
  datePickerProps: PropTypes.shape({})
};

ModuleCardRow.defaultProps = {
  cardInfo: {},
  actions: [],
  datePickerProps: {}
};

export default ModuleCardRow;
