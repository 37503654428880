import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import IconWrapper from 'atoms/IconWrapper';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { difficultyLevelTypes, estimatedTimeTypes } from 'utils/types';
import './content-card-preview.scss';

const ContentCardPreview = ({ heading, description, difficultyLevel, estimatedTime }) => {
  const t = useFormatMessage();
  return (
    <div className="content-card-preview">
      <div className="content-card-preview__body">
        <div className="content-card-preview__heading">{heading}</div>
        <div className="content-card-preview__description">{description}</div>
      </div>
      <div className="content-card-preview__footer">
        <div className="content-card-preview__footer-block">
          <IconWrapper type="StrengthLevelHigh" />
          {t(get(difficultyLevelTypes, difficultyLevel, ''))}
        </div>
        <div className="content-card-preview__footer-block">
          <IconWrapper type="Time" />
          {t(get(estimatedTimeTypes, estimatedTime, ''))}
        </div>
      </div>
    </div>
  );
};

ContentCardPreview.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  difficultyLevel: PropTypes.string,
  estimatedTime: PropTypes.string
};

ContentCardPreview.defaultProps = {
  heading: '',
  description: '',
  difficultyLevel: '',
  estimatedTime: ''
};

export default ContentCardPreview;
