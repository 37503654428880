import React from 'react';
import PropTypes from 'prop-types';
import { useFormatMessage } from 'hooks/useFormatMessage';

const MostUsedContent = ({ topContents = [] }) => {
  const t = useFormatMessage();

  return (
    <div className="stats-box-large bg-white">
      <div className="box-large-content-3">
        <div className="box-content-head span3">{t('user-activity/most-used-content')}</div>
        {!topContents.length
          ? t('user-activity/no-data-available')
          : topContents.map((content, index) => (
              <div className="row-data" key={content.id}>
                <div style={{ display: 'flex' }}>
                  <div className="box-l">
                    <span className="list-no">{index + 1}</span>
                  </div>
                  <div className="box-l ">{content.name}</div>
                </div>
                <div className="view-display-txt">{content.noOfAttempts} views</div>
              </div>
            ))}
      </div>
    </div>
  );
};

MostUsedContent.propTypes = {
  topContents: PropTypes.arrayOf(PropTypes.object)
};
export default MostUsedContent;
