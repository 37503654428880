import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';

import { useFormatMessage } from 'hooks/useFormatMessage';
import { LocationPropTypesShape } from 'utils/core-proptypes';
import RoleCheck from '../RoleCheck';
import './SecondaryNavigation.scss';

const SecondaryNavigation = ({ ...props }) => {
  const t = useFormatMessage();

  return props.location.pathname === '/signin' ? null : (
    <Navbar id="secondary-navigation" className="navigation-container d-none d-sm-block" collapseOnSelect bg="white" variant="dark">
      <Nav>
        <RoleCheck
          allowed={['ADMIN', 'MANAGER', 'PARTNER']}
          component={
            <Nav.Link as="div" className="navigation-container__navlink">
              <NavLink id="s-menu-modules-content" to="/dashboard">
                {t('secondary-navigation/dashboard')}
              </NavLink>
            </Nav.Link>
          }
        />

        <RoleCheck
          allowed={['ADMIN', 'PARTNER', 'MANAGER', 'CREATOR']}
          component={
            <Nav.Link as="div" className="navigation-container__navlink">
              <NavLink id="s-menu-modules-content" to="/modules-content">
                {t('secondary-navigation/modules-and-content')}
              </NavLink>
            </Nav.Link>
          }
        />

        <RoleCheck
          allowed={['ADMIN', 'PARTNER', 'MANAGER']}
          component={
            <Nav.Link as="div" className="navigation-container__navlink">
              <NavLink id="s-menu-users-groups" to="/user-management">
                {t('secondary-navigation/users-and-groups')}
              </NavLink>
            </Nav.Link>
          }
        />

        <RoleCheck
          allowed={['ADMIN', 'PARTNER', 'MANAGER']}
          component={
            <Nav.Link as="div" className="navigation-container__navlink">
              <NavLink id="s-menu-account-settings" to="/account-settings">
                {t('secondary-navigation/account-settings')}
              </NavLink>
            </Nav.Link>
          }
        />

        <RoleCheck
          allowed={['ADMIN']}
          component={
            <Nav.Link as="div" className="navigation-container__navlink">
              <NavLink id="s-menu-account-settings" to="/app-settings">
                {t('secondary-navigation/app-settings')}
              </NavLink>
            </Nav.Link>
          }
        />
      </Nav>
    </Navbar>
  );
};

SecondaryNavigation.propTypes = {
  location: LocationPropTypesShape
};

SecondaryNavigation.defaultProps = {
  location: {
    pathname: ''
  }
};
export default withRouter(SecondaryNavigation);
