import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import Sortable from 'react-sortablejs';

import { useFormatMessage } from 'hooks/useFormatMessage';
import Modal from 'atoms/Modal';
import ModuleCardRow from 'atoms/ModuleCardRow';
import './module-content-card.scss';

const ModuleContentsContainer = ({ contents, handleDateChange, orgDateFormat, dispatcher }) => {
  const t = useFormatMessage();
  const [deleteContentModal, setDeleteContentModal] = useState({ show: false, contentId: null });

  const assignedContents = useMemo(
    () =>
      contents.map(item => {
        const {
          contentType,
          unsaved,
          shared,
          preferences: { thumbnail, contentName }
        } = get(item, 'content', {});
        const { _id, startDate, status } = item.settings;

        return {
          _id,
          contentType,
          heading: contentName,
          thumbnailImage: thumbnail,
          date: startDate,
          status,
          unsaved,
          shared
        };
      }),
    [contents]
  );

  const getRowActions = ({ _id: contentId, unsaved, status }) => {
    const statusOption = status === 'ACTIVE' ? { name: 'Deactivate', value: 'INACTIVE' } : { name: 'Activate', value: 'ACTIVE' };
    return [
      {
        enabled: !unsaved,
        type: 'icon-button',
        icon: 'DocType',
        name: t('common/preferences'),
        style: {
          width: 105
        },
        handler: () => {
          dispatcher({ type: 'openContentPreferenceModal', contentId });
        }
      },
      {
        enabled: true,
        type: 'icon-button',
        icon: 'Wrong',
        name: t('common/remove'),
        style: {
          width: 90
        },
        handler: () => {
          setDeleteContentModal({ show: true, contentId });
        }
      },
      {
        enabled: true,
        type: 'switch',
        icon: null,
        name: t(`common/${statusOption.name.toLowerCase()}`),
        style: {
          width: 153
        },
        handler: () => {
          dispatcher({ type: 'updateContentStatus', status: statusOption.value, contentId });
        }
      }
    ];
  };

  const handleChangeContentOrder = order => {
    const sortedContentID = order;
    const sortedContent = [];
    sortedContentID.map(id => {
      const content = contents.find(q => q.content._id === id);
      if (!isEmpty(content)) {
        sortedContent.push(content);
      }
      return id;
    });
    dispatcher({
      type: 'sortContentList',
      payload: sortedContent
    });
  };

  const renderContents = () => {
    const sortableOptions = {
      animation: 150,
      handle: '.module-content-cards-sort',
      chosenClass: 'sortable-chosen'
    };
    const items = assignedContents.map(assignedContent => {
      return (
        <ModuleCardRow
          key={assignedContent._id}
          data-id={assignedContent._id}
          cardInfo={assignedContent}
          actions={getRowActions(assignedContent)}
          datePickerProps={{
            onChange: handleDateChange,
            date: assignedContent.date,
            displayFormat: orgDateFormat,
            iconStyles: { width: 30, height: 30 },
            placeholder: t('module-preference/set-start-date')
          }}
        />
      );
    });
    return (
      <Sortable style={{ width: '100%' }} options={sortableOptions} onChange={handleChangeContentOrder}>
        {items}
      </Sortable>
    );
  };

  return (
    <React.Fragment>
      <Container className="assigned-content-container">{renderContents()}</Container>
      <Modal
        heading={t('common/modal/please-confirm')}
        modalBody={<p>{t('module-content-delete/confirm')}</p>}
        show={deleteContentModal.show}
        isAlert
        onSave={() => {
          dispatcher({ type: 'removeContent', contentId: deleteContentModal.contentId });
          setDeleteContentModal({ show: false, contentId: null });
        }}
        onHide={() => setDeleteContentModal({ show: false, contentId: null })}
      />
    </React.Fragment>
  );
};

ModuleContentsContainer.propTypes = {
  orgId: PropTypes.string.isRequired,
  orgDateFormat: PropTypes.string.isRequired,
  contents: PropTypes.arrayOf(PropTypes.shape({})),
  handleAddContents: PropTypes.func.isRequired,
  showContentSelector: PropTypes.bool.isRequired,
  setShowContentSelector: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  dispatcher: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

ModuleContentsContainer.defaultProps = {
  contents: []
};

export default ModuleContentsContainer;
