import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Col, Row, Card } from 'react-bootstrap';
import Button from 'atoms/Button';

import VideoViewer from '../../VideoView';
import Response from 'atoms/Response';
import Explanation from 'atoms/Explanation';
import Options from './Options';
import { useFormatMessage } from 'hooks/useFormatMessage';

const OverlayMCQ = ({ handleRetry }) => {
  const t = useFormatMessage();

  return (
    <div className="question-overlay">
      <p style={{ color: '#FFF' }}>{t('retry-overlay/sorry-but-an-error-occured.Please-submit-your-answer-again')}</p>
      <Button width="50px" variant="secondary" onClick={() => handleRetry()}>
        {t('retry-overlay/retry')}
      </Button>
    </div>
  );
};

const Question = ({ index, showQuestionNumber, totalItems, mcq, explanation, selectedKey, correctKey, showResponse, isOptionTouched, isOptionsDisabled, onChangeOption, handleRetry, showError }) => {
  return (
    <Fragment>
      <Row style={{ justifyContent: 'center' }}>
        <Col lg={10} sm={12}>
          {showQuestionNumber && (
            <div className="mixed-numbering-container">
              {index + 1}/{totalItems}
            </div>
          )}
          <div style={showError ? { background: '#F05F5A', marginBottom: '20px', borderRadius: '15px' } : { marginBottom: '20px' }}>
            {showError && <OverlayMCQ handleRetry={handleRetry} />}

            <Card className="mixed-mcq-item-container" style={showError ? { opacity: '.09', background: 'transparent' } : {}}>
              <Card.Body
                className={cn('mixed-mcq-item-container__body', {
                  'mixed-mcq-item-container__body--wrong': isOptionTouched && !showResponse
                })}
              >
                {mcq.media && (
                  <div className="mixed-mcq-item-container__media">
                    <VideoViewer media={mcq.media} showHideButton={false} fullWidth={true} />
                  </div>
                )}
                <Card.Title>{mcq.question}</Card.Title>
                <Options mcq={mcq} disabled={isOptionsDisabled} correctKey={correctKey} onChangeOption={onChangeOption} selectedKey={selectedKey} />
                {isOptionTouched && (
                  <div className="mixed-mcq-item-container__info">
                    <Response isCorrect={showResponse} />
                    <Explanation explanation={explanation} />
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

Question.propTypes = {
  index: PropTypes.number,
  showQuestionNumber: PropTypes.bool,
  totalItems: PropTypes.number,
  mcq: PropTypes.shape({
    question: PropTypes.string,
    media: PropTypes.shape({})
  }),
  explanation: PropTypes.string,
  selectedKey: PropTypes.string,
  correctKey: PropTypes.string,
  showResponse: PropTypes.bool,
  isOptionTouched: PropTypes.bool,
  isOptionsDisabled: PropTypes.bool,
  onChangeOption: PropTypes.func,
  showError: PropTypes.bool,
  handleRetry: PropTypes.func
};

OverlayMCQ.propTypes = {
  handleRetry: PropTypes.func
};
export default Question;
