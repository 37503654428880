import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import Input from 'atoms/Input';
import DropDown from 'atoms/DropDown';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { contentTypes, difficultyLevelTypes, estimatedTimeTypes } from 'utils/types';
import './CommonContentPreferences.scss';

const CommonContentPreferences = ({ contentType, contentName, difficultyLevel, estimatedTime, description, dispatcher }) => {
  const t = useFormatMessage();
  return (
    <>
      <Row>
        <Col>
          <div className="content-preference__title">{contentType && t(contentTypes[contentType.toUpperCase()])}</div>
        </Col>
      </Row>
      <Row className="content-preference__config">
        <Col md={{ span: 5 }}>
          <div className="content-preference__config__name">{t('content-preference/content-name-not-public')}</div>
          <Input
            id="content-preference__config__name"
            onChange={e =>
              dispatcher({
                type: 'inputChange',
                key: 'contentName',
                value: e.target.value
              })
            }
            placeholder={t('placeholder/content-name')}
            value={contentName}
          />
        </Col>
        <Col>
          <div className="content-preference__config__difficulty">{t('content-preference/difficulty-level')}</div>
          <DropDown
            id="content-preference__config__difficulty"
            heading={difficultyLevel}
            placeholder={t('common/select')}
            onSelect={eventKey => {
              dispatcher({
                key: 'difficultyLevel',
                type: 'dropDown',
                value: eventKey.id
              });
            }}
            values={[
              { id: 'LOW', value: t(difficultyLevelTypes.LOW) },
              { id: 'MIDDLE', value: t(difficultyLevelTypes.MIDDLE) },
              { id: 'HIGH', value: t(difficultyLevelTypes.HIGH) }
            ]}
          />
        </Col>
        <Col>
          <div className="content-preference__config__est"> {t('content-preference/estimated-time-usage')}</div>
          <DropDown
            id="carousel-content__config__est"
            heading={estimatedTime}
            placeholder={t('common/select')}
            onSelect={eventKey => {
              dispatcher({
                key: 'estimatedTime',
                type: 'dropDown',
                value: eventKey.id
              });
            }}
            values={[
              { id: '3-5', value: t(estimatedTimeTypes['3-5']) },
              { id: '6-10', value: t(estimatedTimeTypes['6-10']) },
              { id: '11-20', value: t(estimatedTimeTypes['11-20']) },
              { id: '21-30', value: t(estimatedTimeTypes['21-30']) },
              { id: '31-60', value: t(estimatedTimeTypes['31-60']) }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col className="pt-3">
          <div className="content-preference__config__name">{t('common/description')}</div>
          <Input
            as="textarea"
            rows="4"
            placeholder={t('common/description')}
            className={'content-preference__input-desc'}
            value={description}
            onChange={e => {
              dispatcher({
                type: 'inputChange',
                key: 'description',
                value: e.target.value
              });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

CommonContentPreferences.propTypes = {
  contentName: PropTypes.string,
  estimatedTime: PropTypes.string,
  description: PropTypes.string,
  contentType: PropTypes.string,
  dispatcher: PropTypes.func,
  difficultyLevel: PropTypes.string
};
CommonContentPreferences.defaultProps = {};

export default CommonContentPreferences;
