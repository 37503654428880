import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card } from 'react-bootstrap';
import VideoViewer from '../../VideoView';
import './blog-card.scss';

const BlogItemView = ({ showAllMode, componentData, preferences, showMixedItemIndex, totalItems, index }) => {
  const { heading, documentation, media, textColor, backgroundColor } = componentData;
  const { displayQuestionMode } = preferences;
  const showQuestionNumber = displayQuestionMode === 'ONE_BY_ONE' && !showAllMode;

  if (
    (displayQuestionMode === 'ONE_BY_ONE' && showMixedItemIndex === index + 1) ||
    (displayQuestionMode === 'ONE_BY_ONE' && showAllMode && index + 1 !== totalItems) ||
    (displayQuestionMode === 'ALL' && index !== totalItems)
  ) {
    return (
      <Container>
        {showQuestionNumber && (
          <div className="mixed-numbering-container">
            {index + 1}/{totalItems}
          </div>
        )}
        {/* <div className="blog-card fade-in-2s"> */}
        <Row className="blog-card" style={{ justifyContent: 'center' }}>
          <Col lg={10} sm={12}>
            <Card className="mixed-mcq-item-container" style={{ color: textColor, backgroundColor }}>
              <Card.Body className="mixed-mcq-item-container__body">
                {media && <VideoViewer media={media} showHideButton={false} fullWidth={true} />}

                <Card.Title style={{ paddingTop: media ? '40px' : 0 }}>{heading}</Card.Title>
                <div
                  className="blog-card__rich-text"
                  dangerouslySetInnerHTML={{
                    __html: documentation
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
  return null;
};

BlogItemView.propTypes = {
  showAllMode: PropTypes.bool,
  preferences: PropTypes.shape({
    displayQuestionMode: PropTypes.string
  }),
  index: PropTypes.number,
  totalItems: PropTypes.number,
  showMixedItemIndex: PropTypes.number,
  componentData: PropTypes.shape({
    _id: PropTypes.string,
    media: PropTypes.shape({}),
    heading: PropTypes.string,
    documentation: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string
  }),
  isPreview: PropTypes.bool
};

BlogItemView.defaultProps = {
  carouselData: {},
  isPreview: false
};

export default BlogItemView;
