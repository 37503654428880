import React from 'react';
import PropTypes from 'prop-types';
import { useFormatMessage } from 'hooks/useFormatMessage';

const UserCount = ({ item }) => {
  const t = useFormatMessage();
  return <span>{`${item} ${item === 0 || item === 1 ? 'users' : t('common/table/members')}`}</span>;
};

UserCount.propTypes = {
  item: PropTypes.node
};

export default UserCount;
