import React, { useEffect, useContext } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import AppContext from '../../app-context';

import DropDown from 'atoms/DropDown';
import { useFormatMessage } from 'hooks/useFormatMessage';
import SettingsDashboard from './account-settings-dashboard';
import RoleCheck from '../RoleCheck';
import { getOrganisationList } from '../../actions';
import './account-settings.scss';

const AccountSettings = ({ organizationList, user, getOrganisationList, orgDeleted, accoutUpdateId }) => {
  const t = useFormatMessage();
  const context = useContext(AppContext);
  const { role = '', organizationId } = user;

  useEffect(() => {
    if (!context.selectedOrg) {
      context.setContextState({ selectedOrg: organizationId });
    }
  }, []);

  useEffect(() => {
    if (role === 'ADMIN' || role === 'PARTNER') {
      isEmpty(organizationList) && getOrganisationList();
    }
  }, [organizationList.length]);

  useEffect(() => {
    if (orgDeleted) {
      context.setContextState({ selectedOrg: organizationList[0]._id });
    }
  }, [orgDeleted]);

  const selectedOrg = context.selectedOrg || organizationId;
  const handleSelect = eventKey => {
    context.setContextState({ selectedOrg: eventKey.id });
  };

  return (
    <React.Fragment>
      <Container>
        <RoleCheck
          allowed={['ADMIN', 'PARTNER']}
          component={
            <Row className="user-setting__dropdown">
              <Col xs={12} className="user-setting__dropdown__col">
                <DropDown
                  key={accoutUpdateId}
                  isSearchable={true}
                  onSelect={handleSelect}
                  heading={selectedOrg}
                  placeholder={t('common/select-organization')}
                  values={organizationList.map(org => {
                    return { value: org.name, id: org._id };
                  })}
                />
              </Col>
            </Row>
          }
        />
        <Row className="user-setting__tabs justify-content-center">
          <Col xs={12}>
            <SettingsDashboard selectedOrg={selectedOrg} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

AccountSettings.propTypes = {
  organizationList: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.shape({
    role: PropTypes.string,
    organizationId: PropTypes.string
  }),
  hasSelectedOrgUpdated: PropTypes.bool.isRequired,
  getOrganisationList: PropTypes.func.isRequired,
  accoutUpdateId: PropTypes.string,
  orgDeleted: PropTypes.bool.isRequired
};

AccountSettings.defaultProps = {
  organizationList: [],
  hasSelectedOrgUpdated: false
};

const mapStateToProps = ({ userState, adminState, accountSettings }) => {
  const { hasSelectedOrgUpdated, accoutUpdateId } = accountSettings;
  return {
    user: get(userState, 'user.user', {}),
    organizationList: adminState.organizations,
    hasSelectedOrgUpdated,
    accoutUpdateId,
    orgDeleted: adminState.orgDeleted
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrganisationList: bindActionCreators(getOrganisationList, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSettings));
