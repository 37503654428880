import React from 'react';
import PropTypes from 'prop-types';
import { useFormatMessage } from 'hooks/useFormatMessage';

const MostUsedModule = ({ topModules = [] }) => {
  const t = useFormatMessage();

  return (
    <div className="stats-box-large bg-white">
      <div className="box-large-content-3">
        <div className="box-content-head span3">{t('user-activity/most-used-module')}</div>
        {!topModules.length
          ? t('user-activity/no-data-available')
          : topModules.map((module, index) => (
              <div className="row-data" key={module.id}>
                <div style={{ display: 'flex' }}>
                  <div className="box-l">
                    <span className="list-no">{index + 1}</span>
                  </div>
                  <div className="box-l ">{module.name}</div>
                </div>
                <div className="view-display-txt">{module.noOfAttempts} views</div>
              </div>
            ))}
      </div>
    </div>
  );
};

MostUsedModule.propTypes = {
  topModules: PropTypes.arrayOf(PropTypes.object)
};
export default MostUsedModule;
