import React, { useReducer, useEffect, useState } from 'react';
import { PDFReader } from 'reactjs-pdf-reader';
import { Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { getMediaUrl } from 'utils/calculationUtilities';
import IconWrapper from 'atoms/IconWrapper';
import Button from 'atoms/Button';
import VideoPlayer from '../../VideoPlayer';
import FileUploader from '../../FileUploader';
import MindzeedPlaceholder from '../../FileUploader/mindzeed_placeholder.png';
import reducer from './reducer';
import './image-thumbnail.scss';

const baseBlockName = 'image-thumbnail-container';

const isUploading = uploadingStatus => uploadingStatus === 'loading';
const isUploaded = uploadingStatus => uploadingStatus === 'loaded';

const FilePreview = ({ variant, state: { fileName, thumbnailImage, media, uploadingStatus }, placeholderIcon, showMindzeedPlaceholderImage, dispatcher }) => {
  const blockName = `${baseBlockName}-${variant}`;
  const thumbnailImageSrc = isUploaded(uploadingStatus) ? (showMindzeedPlaceholderImage ? MindzeedPlaceholder : thumbnailImage) : placeholderIcon;

  const clearAll = () => {
    dispatcher({
      type: 'clearAll',
      payload: { thumbnailImage: null, media: null, uploadingStatus: 'initial' }
    });
  };

  const setShow = e => {
    dispatcher({ type: 'setShow', payload: { target: e.target } });
  };

  const getFileExtension = file => {
    var re = /(?:\.([^.]+))?$/;
    if (file.metaData) {
      return re.exec(file.metaData.key)[1];
    }
    return re.exec(file.key)[1];
  };

  const getFileThumbnailPreview = () => {
    const ext = getFileExtension(media);
    switch (ext) {
      case 'jpg':
      case 'gif':
      case 'jpeg':
      case 'png': {
        const resolvedImageUrl = getMediaUrl(media);
        return <img className={`${blockName}__img`} src={resolvedImageUrl || thumbnailImageSrc} />;
      }
      case 'pdf': {
        return (
          <div style={{ 'overflow-x': 'scroll', height: 150, width: 250 }}>
            <PDFReader url={getMediaUrl(media)} showAllPage width={250} />
          </div>
        );
      }
      case 'mp4':
      case 'webm': {
        return <VideoPlayer embed={false} media={media} />;
      }
      default: {
        return null;
      }
    }
  };

  const getBlobPreview = () => {
    const ext = getFileExtension({ key: fileName });
    switch (ext) {
      case 'jpg':
      case 'gif':
      case 'jpeg':
      case 'png': {
        return <img className={`${blockName}__img`} src={thumbnailImageSrc} />;
      }
      case 'pdf': {
        return (
          <div style={{ overflow: 'scroll', height: 150, width: 250 }}>
            <PDFReader url={thumbnailImageSrc} showAllPage width={250} />
          </div>
        );
      }
      case 'mp4':
      case 'webm': {
        return <VideoPlayer embed={false} defaultUrl={thumbnailImageSrc} />;
      }
      default:
        return null;
    }
  };

  const thumbnailPreview = () => {
    if (!isEmpty(media)) {
      switch (media.type.toUpperCase()) {
        case 'FILE': {
          return getFileThumbnailPreview();
        }
        case 'VIMEO':
        case 'YOUTUBE': {
          return (
            <div style={{ width: '250px' }}>
              <VideoPlayer embed={false} media={media} />
            </div>
          );
        }
        default:
          return <img className={`${blockName}__img`} src={thumbnailImageSrc} />;
      }
    } else if (fileName) {
      return getBlobPreview();
    } else {
      return <img className={`${blockName}__img`} src={thumbnailImageSrc} />;
    }
  };

  if (variant === 'horizontal') {
    return (
      <div className={`${blockName}__container`}>
        <Row style={{ alignItems: 'center' }}>
          {isUploaded(uploadingStatus) && thumbnailPreview()}
          {isUploaded(uploadingStatus) && fileName && <div className={`${blockName}__file-name`}>{fileName}</div>}
        </Row>
        <Button className={`${blockName}__upload-btn`} variant="secondary" onClick={setShow}>
          Upload
        </Button>
      </div>
    );
  }

  if (isUploading(uploadingStatus)) {
    return (
      <div className={`${blockName}__container`}>
        <IconWrapper className={`${blockName}__img ${blockName}__img--loading`} alt="Loader" type="LoaderImage" />;
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={`${blockName}__container`}>
        {thumbnailPreview()}
        {isUploaded(uploadingStatus) || !isEmpty(media) ? (
          <Button variant="clean" onClick={clearAll}>
            <IconWrapper className={`${blockName}__imgicon`} type="CloseIcon" alt="Close" />
          </Button>
        ) : (
          <Button variant="clean" onClick={setShow}>
            <IconWrapper className={`${blockName}__imgicon`} type="AddMedia" alt="Upload icon" />
          </Button>
        )}
      </div>
      {(fileName || media?.url) && isUploaded(uploadingStatus) && <div className={`${blockName}__file-name`}>{fileName || media?.url}</div>}
    </React.Fragment>
  );
};

const ImageThumbnail = ({
  variant = 'card',
  placeholderIcon,
  defaultActiveTab,
  defaultThumbnail,
  defaultMedia,
  allowedFileType,
  allowedExtension,
  shouldUploadMindzeedPlaceholderImage,
  handleSave
}) => {
  const blockName = `${baseBlockName}-${variant}`;
  const [state, dispatcher] = useReducer(reducer, {
    show: false,
    uploadingStatus: shouldUploadMindzeedPlaceholderImage ? 'loaded' : 'initial',
    thumbnailImage: null,
    media: null,
    target: null,
    fileName: null
  });
  const [showMindzeedPlaceholderImage, setShowMindzeedPlaceholderImage] = useState(shouldUploadMindzeedPlaceholderImage);

  useEffect(() => {
    const hasDefaultThumbnailOrMedia = defaultThumbnail || defaultMedia;
    if (hasDefaultThumbnailOrMedia) {
      dispatcher({
        type: 'initializeState',
        thumbnailImage: defaultThumbnail,
        media: defaultMedia
      });
    }
  }, [defaultThumbnail, defaultMedia]);

  return (
    <React.Fragment>
      <div className={blockName}>
        <FilePreview variant={variant} state={state} placeholderIcon={placeholderIcon} showMindzeedPlaceholderImage={showMindzeedPlaceholderImage} dispatcher={dispatcher} />
        <FileUploader
          show={state.show}
          allowedFileType={allowedFileType}
          allowedExtension={allowedExtension}
          defaultActiveTab={defaultActiveTab}
          shouldUploadMindzeedPlaceholderImage={shouldUploadMindzeedPlaceholderImage}
          thumbnailImage={state.thumbnailImage}
          dispatcher={dispatcher}
          onClickSave={(payload, isMindzeedPlaceholderImage) => {
            if (!isMindzeedPlaceholderImage) {
              setShowMindzeedPlaceholderImage(false);
            }
            handleSave(payload);
          }}
          target={state.target}
        />
      </div>
    </React.Fragment>
  );
};

FilePreview.propTypes = {
  variant: PropTypes.oneOf('card', 'horizontal'),
  state: PropTypes.shape({
    show: PropTypes.bool,
    uploadingStatus: PropTypes.string,
    thumbnailImage: PropTypes.string,
    media: PropTypes.shape({ type: PropTypes.string, url: PropTypes.string }),
    target: PropTypes.object,
    fileName: PropTypes.string
  }),
  placeholderIcon: PropTypes.string.isRequired,
  showMindzeedPlaceholderImage: PropTypes.bool.isRequired,
  dispatcher: PropTypes.func
};

ImageThumbnail.propTypes = {
  variant: PropTypes.oneOf('card', 'horizontal'),
  placeholderIcon: PropTypes.string.isRequired,
  defaultActiveTab: PropTypes.string,
  defaultThumbnail: PropTypes.string,
  defaultMedia: PropTypes.shape({ type: PropTypes.string, url: PropTypes.string, metaData: PropTypes.shape({}) }).isRequired,
  allowedFileType: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowedExtension: PropTypes.string,
  shouldUploadMindzeedPlaceholderImage: PropTypes.bool,
  handleSave: PropTypes.func
};

export default ImageThumbnail;
