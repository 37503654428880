import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { isEmpty, get } from 'lodash';

import { HistoryPropTypesShape, UserPropTypesShape } from 'utils/core-proptypes';
import { publicPaths } from 'utils/calculationUtilities';
import AppContext from '../../app-context';
import { reauthentication } from './actions';

const AuthRoute = props => {
  const context = useContext(AppContext);
  const { userProfile, loginDetailsFetched } = props;

  useEffect(() => {
    if (isEmpty(userProfile)) {
      props.reauthentication();
    }
  }, [loginDetailsFetched]);

  useEffect(() => {
    const userLanguage = get(userProfile, 'user.settings.defaultLangCode', context.language);
    // logic to check user
    const isUser = !isEmpty(get(userProfile, 'user', {}));
    if (context.language !== userLanguage) {
      // set default langueage of user
      context.setContextState({ language: userLanguage });
    }
    if (context.mode !== isUser ? 'private' : 'public') {
      context.setContextState({ mode: isUser ? 'private' : 'public' });
    }
  }, [userProfile]);

  const role = get(userProfile, 'user.role');
  if (loginDetailsFetched) {
    if (!props.isAuthenticated && loginDetailsFetched && !publicPaths.includes(props.path)) {
      return <Redirect to={{ pathname: '/signin' }} />;
    }
    if (props.notallowed.includes(role)) {
      return <Redirect to={{ pathname: '/home' }} />;
    }
    const Comp = props.component;
    return <Comp {...props} />;
  }
  return null;
};

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  history: HistoryPropTypesShape,
  reauthentication: PropTypes.func.isRequired,
  userProfile: UserPropTypesShape,
  fetching: PropTypes.bool,
  loginDetailsFetched: PropTypes.bool,
  notallowed: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired
};

AuthRoute.defaultProps = {
  userProfile: {},
  history: null,
  fetching: false,
  loginDetailsFetched: false,
  notallowed: ''
};

const mapStateToProps = ({ userState }) => {
  const isAuthenticated = !!get(userState, 'user.user._id', false);
  return {
    isAuthenticated,
    userProfile: userState.user,
    fetching: userState.fetching,
    loginDetailsFetched: userState.loginDetailsFetched
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reauthentication: bindActionCreators(reauthentication, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthRoute));
