import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import DropDown from 'atoms/DropDown';
import Checkbox from 'atoms/Checkbox';
import Input from 'atoms/Input';
import Feedback from 'atoms/Feedback';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { ErrorsPropTypesShape, UserPropTypesShape } from 'utils/core-proptypes';
import './modal-elements.scss';

const EditAccountModalBody = ({ adminUser, dispatcher, error }) => {
  const t = useFormatMessage();
  const { name, firstName, lastName, email, _id, accountType, allowedAccountTypes = [], code = '' } = adminUser;
  return (
    <Form>
      <Form.Group>
        <Form.Label>{t('common/modal-body/account-name')}</Form.Label>
        <Input
          type="aname"
          value={name}
          onChange={e =>
            dispatcher({
              type: 'inputChange',
              key: 'name',
              value: e.target.value
            })
          }
          placeholder={t('common/modal-body/account-name')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('login/organization-code')}</Form.Label>
        <Input
          value={code}
          disabled={_id}
          onChange={e =>
            dispatcher({
              type: 'inputChange',
              key: 'code',
              value: e.target.value
            })
          }
          placeholder={t('login/organization-code')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('common/modal-body/account-type')}</Form.Label>
        <DropDown
          placeholder={t('common/modal-body/select-account-type')}
          heading={accountType}
          onSelect={eventKey =>
            dispatcher({
              type: 'dropDownChange',
              key: 'accountType',
              eventKey
            })
          }
          values={[
            { id: 'MINDZEED', value: 'Mindzeed' },
            { id: 'PARTNER', value: 'Partner' },
            { id: 'BUSINESS', value: 'Business' },
            { id: 'ORG_UNIT', value: 'Organization Unit' }
          ].filter(value => allowedAccountTypes.includes(value.id))}
        />
      </Form.Group>
      {_id === undefined ? (
        <React.Fragment>
          <Form.Label className="account-modal__administrator-label">{t('common/modal-body/administrator')}:</Form.Label>
          <Form.Group>
            <Form.Label>{t('common/modal-body/first-name')}</Form.Label>
            <Input
              type="fname"
              value={firstName}
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'firstName',
                  value: e.target.value
                })
              }
              placeholder={t('common/modal-body/first-name')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('common/modal-body/last-name')}</Form.Label>
            <Input
              type="lastName"
              value={lastName}
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'lastName',
                  value: e.target.value
                })
              }
              placeholder={t('common/modal-body/last-name')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('common/modal-body/email')}</Form.Label>
            <Input
              type="email"
              value={email}
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'email',
                  value: e.target.value
                })
              }
              placeholder={t('common/modal-body/email')}
            />
          </Form.Group>
          <Form.Label className="account-modal__notify-label">{t('common/modal-body/new-admins-will-be-notified-by-email')}</Form.Label>
          <Checkbox
            label={t('common/modal-body/make-account-inactive')}
            type="checkbox"
            onChange={() =>
              dispatcher({
                type: 'checkboxChange',
                key: 'status'
              })
            }
            className="inline reminder-checkbox checkbox-container "
          />
        </React.Fragment>
      ) : null}
      <Feedback errorList={error} allowedTypesKey="AddAccountEditaccount" />
    </Form>
  );
};

EditAccountModalBody.propTypes = {
  adminUser: UserPropTypesShape,
  dispatcher: PropTypes.func.isRequired,
  error: PropTypes.arrayOf(ErrorsPropTypesShape)
};

EditAccountModalBody.defaultProps = {
  adminUser: {},
  error: []
};

export default EditAccountModalBody;
