import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import { LOGOUT_USER, LOGOUT_USER_SUCCESS, LOGOUT_USER_FAILURE, SHOW_MANAGER_BROWSER_MESSAGE } from 'utils/constants';

export const logout = () => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/auth/logout`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [LOGOUT_USER, LOGOUT_USER_SUCCESS, LOGOUT_USER_FAILURE]
  }
});

export const showBrowserError = () => ({
  type: SHOW_MANAGER_BROWSER_MESSAGE
});

export const updatePublicUserSettings = langCode => ({
  type: 'DEFAULT_PUBLIC_LANG',
  payload: langCode
});
