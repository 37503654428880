import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';

import {
  ADD_PREFERENCE,
  ADD_PREFERENCE_SUCCESS,
  ADD_PREFERENCE_FAILURE,
  UPDATE_PREFERENCE,
  UPDATE_PREFERENCE_SUCCESS,
  UPDATE_PREFERENCE_FAILURE,
  ADD_CONTENT_DATA,
  ADD_CONTENT_DATA_SUCCESS,
  ADD_CONTENT_DATA_FAILURE,
  UPDATE_CONTENT_DATA,
  UPDATE_CONTENT_DATA_SUCCESS,
  UPDATE_CONTENT_DATA_FAILURE
} from 'utils/constants';

export const addPreference = ({ payload, organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/contents`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [ADD_PREFERENCE, ADD_PREFERENCE_SUCCESS, ADD_PREFERENCE_FAILURE]
  }
});

export const updatePreference = ({ payload, id, organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/contents/${id}`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [UPDATE_PREFERENCE, UPDATE_PREFERENCE_SUCCESS, UPDATE_PREFERENCE_FAILURE]
  }
});

export const addContentData = ({ payload, contentId, organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/contents/${contentId}/contentData`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [ADD_CONTENT_DATA, ADD_CONTENT_DATA_SUCCESS, ADD_CONTENT_DATA_FAILURE]
  }
});

export const updateContentData = ({ payload, contentId, contentDataId, organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/contents/${contentId}/contentData/${contentDataId}`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [UPDATE_CONTENT_DATA, UPDATE_CONTENT_DATA_SUCCESS, UPDATE_CONTENT_DATA_FAILURE]
  }
});

export const dispatchCustomAction = actionType => {
  return { type: actionType };
};
