import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import { CLONE_MODULE, CLONE_MODULE_SUCCESS, CLONE_MODULE_FAILURE, SHARE_MODULE_COPY, SHARE_MODULE_COPY_SUCCESS, SHARE_MODULE_COPY_FAILURE } from 'utils/constants';

export const shareModule = ({ moduleId, orgid: organizationId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/modules/${moduleId}/clone`,
    method: 'POST',
    body: JSON.stringify({}),
    headers: { 'Content-Type': 'application/json' },
    types: [CLONE_MODULE, CLONE_MODULE_SUCCESS, CLONE_MODULE_FAILURE]
  }
});

export const dispatchCustomAction = (actionType, payload) => {
  return { type: actionType, payload };
};

export const shareModuleCopy = ({ moduleId, selectedOrg: organizationId, payload }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations/${organizationId}/modules/${moduleId}/shareCopy`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [SHARE_MODULE_COPY, SHARE_MODULE_COPY_SUCCESS, SHARE_MODULE_COPY_FAILURE]
  }
});
