import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import { TRACK_CONTENT_CAROUSEL, TRACK_CONTENT_CAROUSEL_SUCCESS, TRACK_CONTENT_CAROUSEL_FAILURE } from 'utils/constants';

export const trackContentCarousel = ({ moduleId, contentId, carouselId, payload, isPublic = false }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/submit${isPublic ? '/public' : ''}/module/${moduleId}/content/${contentId}/carousel/${carouselId}`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [TRACK_CONTENT_CAROUSEL, TRACK_CONTENT_CAROUSEL_SUCCESS, TRACK_CONTENT_CAROUSEL_FAILURE]
  }
});
