import React from 'react';
import { AccordionItem, AccordionItemTitle, AccordionItemBody } from 'atoms/Accordion';
import Input from 'atoms/Input';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ThreeDots from 'atoms/ThreeDots';
import Editor from 'atoms/Editor';
import ImageThumbnail from 'atoms/ImageThumbnail';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { AllowedMediaTypeSource } from 'utils/calculationUtilities';
import NoPPTImage from 'assets/icons/nopresentation.svg';

const blockName = 'story-content-create';

const StoryItemBlock = ({ onDispatch, item = {}, index, ...props }) => {
  const t = useFormatMessage();
  const handleVideoModalSave = media => {
    onDispatch({
      type: 'setItemMedia',
      media,
      index
    });
  };

  const deleteQuestionHandler = () => {
    onDispatch({
      type: 'deleteQuestion',
      index
    });
  };

  return (
    <AccordionItem {...props}>
      <AccordionItemTitle accordinTitleplaceholder="Item">{item.heading}</AccordionItemTitle>
      <AccordionItemBody>
        <div>
          <Row className={`${blockName}__thumbnail-row`}>
            <div>
              {t('common/video-text')}
              <ImageThumbnail
                placeholderIcon={NoPPTImage}
                defaultMedia={item.media}
                allowedFileType={[AllowedMediaTypeSource.FILE, AllowedMediaTypeSource.VIMEO, AllowedMediaTypeSource.YOUTUBE]}
                handleSave={handleVideoModalSave}
              />
            </div>
            <Row className={`${blockName}__color-inputs`}>
              <Col>
                {t('story-create/text-color')}
                <Input
                  placeholder={t('story-item-block/text-color')}
                  onChange={e =>
                    onDispatch({
                      type: 'addItem',
                      key: 'textColor',
                      value: e.target.value,
                      index
                    })
                  }
                  value={item.textColor}
                  className={`${blockName}__color-inputs__input`}
                />
              </Col>
              <Col>
                {t('story-create/background-color')}
                <Input
                  placeholder={t('story-item-block/background-color')}
                  onChange={e =>
                    onDispatch({
                      type: 'addItem',
                      key: 'backgroundColor',
                      value: e.target.value,
                      index
                    })
                  }
                  value={item.backgroundColor}
                  className={`${blockName}__color-inputs__input`}
                />
              </Col>
            </Row>
          </Row>
          <div className={`${blockName}__title`}>{t('carousel-content/head')}</div>
          <Input
            placeholder={t('placeholder/title')}
            onChange={e =>
              onDispatch({
                type: 'addItem',
                key: 'heading',
                value: e.target.value,
                index
              })
            }
            value={item.heading}
            className={`${blockName}__input-title`}
          />
        </div>
        <Row style={{ paddingLeft: '15px', paddingTop: '20px' }}>
          <Editor
            dispatcher={e =>
              onDispatch({
                type: 'addItem',
                key: 'documentation',
                value: e.value,
                index
              })
            }
            editorKey={'documentation'}
            documentation={item.documentation}
          />
        </Row>

        <Row className="mt-4" style={{ justifyContent: 'flex-end' }}>
          <ThreeDots
            active
            size="small"
            options={[
              {
                name: t('common/delete'),
                handler: deleteQuestionHandler
              }
            ]}
          />
        </Row>
      </AccordionItemBody>
    </AccordionItem>
  );
};

export default StoryItemBlock;

StoryItemBlock.propTypes = {
  onDispatch: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  documentation: PropTypes.string,
  item: PropTypes.shape({
    heading: PropTypes.string,
    documentation: PropTypes.string
  }).isRequired
};
