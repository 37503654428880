import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { MCQPropTypesShape } from 'utils/core-proptypes';
import Question from './Question';
import './mcq-attempt.scss';

const McqAttempt = ({
  moduleId,
  contentId,
  attemptId,
  mcq = {},
  index,
  totalQuestions,
  displayQuestionMode,
  showAllMode,
  showRightAnswer,
  showExplanation,
  isPreview,
  showMCQIndex,
  isAuthenticated,
  onSubmit,
  mcqSubmit
}) => {
  const [showResponse, setShowResponse] = useState(false);
  const [showError, setShowError] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [selectedKey, setSelectedKey] = useState(null);
  const [correctKey, setCorrectKey] = useState(null);
  const [isOptionTouched, setIsOptionTouched] = useState(false);
  const [isOptionsDisabled, setIsOptionsDisabled] = useState(false);

  useEffect(() => {
    setIsOptionsDisabled(false);
    setShowResponse(false);
    setExplanation('');
    setIsOptionTouched(false);
    setCorrectKey(null);
    setSelectedKey(null);
  }, [attemptId]);

  const handleShowDescription = (description, success) => {
    switch (showExplanation) {
      case 'SHOW_EXP': {
        setExplanation(description);
        break;
      }
      case 'SHOW_EXP_RIGHT':
      case 'SHOW_EXP_WRONG': {
        if (success) {
          setExplanation(description);
        }
        break;
      }

      case 'DO_NOT_SHOW': {
        break;
      }
      default:
        break;
    }
  };

  const handleChangeOption = e => {
    const { _id } = mcq;
    const { value } = e.target;
    setSelectedKey(value);

    if (isPreview) {
      const { correct, explanation: description } = mcq;
      const success = correct === value;
      setIsOptionTouched(true);
      if (success) {
        setShowResponse(true);
      } else {
        setShowResponse(false);
      }
      if (showRightAnswer === 'true') setCorrectKey(correct);
      handleShowDescription(description, success);
      setIsOptionsDisabled(true);
      onSubmit({ key: _id, success, index });
    } else {
      const body = { answer: value, attemptId };
      mcqSubmit({
        body,
        moduleId,
        contentId,
        mcqId: _id,
        attemptId,
        isPublic: !isAuthenticated
      }).then(res => {
        const { success, explanation: description, correct = null } = res.payload;
        if (res.error) {
          setShowError(true);
          return;
        }
        setIsOptionTouched(true);
        if (success) {
          setCorrectKey(correct);
          setShowResponse(true);
        } else {
          setCorrectKey(correct);
          setShowResponse(false);
        }
        setExplanation(description);
        setIsOptionsDisabled(true);
        onSubmit({ key: _id, success, index });
      });
    }
  };

  const handleRetry = () => {
    const { _id } = mcq;

    const body = { answer: selectedKey, attemptId };
    mcqSubmit({
      body,
      moduleId,
      contentId,
      mcqId: _id,
      attemptId,
      isPublic: !isAuthenticated
    }).then(res => {
      const { success, explanation: description, correct = null } = res.payload;
      if (res.error) {
        setShowError(true);
        return;
      }
      setShowError(false);
      setIsOptionTouched(true);
      if (success) {
        setCorrectKey(correct);
        setShowResponse(true);
      } else {
        setCorrectKey(correct);
        setShowResponse(false);
      }
      setExplanation(description);
      setIsOptionsDisabled(true);
      onSubmit({ key: _id, success, index });
    });
  };

  if ((displayQuestionMode === 'ONE_BY_ONE' && showMCQIndex === index + 1) || (displayQuestionMode === 'ONE_BY_ONE' && showAllMode) || (displayQuestionMode === 'ALL' && index !== totalQuestions)) {
    return (
      <Container>
        <Question
          index={index}
          showQuestionNumber={displayQuestionMode === 'ONE_BY_ONE' && !showAllMode}
          totalQuestions={totalQuestions}
          mcq={mcq}
          explanation={explanation}
          selectedKey={selectedKey}
          correctKey={correctKey}
          showResponse={showResponse}
          isOptionTouched={isOptionTouched}
          isOptionsDisabled={isOptionsDisabled}
          onChangeOption={handleChangeOption}
          handleRetry={handleRetry}
          showError={showError}
        />
      </Container>
    );
  }
  return null;
};

McqAttempt.propTypes = {
  mcq: MCQPropTypesShape,
  onSubmit: PropTypes.func,
  isPreview: PropTypes.bool,
  attemptedMCQLength: PropTypes.number,
  showAllMode: PropTypes.bool,
  showRightAnswer: PropTypes.bool,
  showExplanation: PropTypes.string,
  moduleId: PropTypes.string,
  contentId: PropTypes.string,
  attemptId: PropTypes.string,
  displayQuestionMode: PropTypes.string,
  mcqSubmit: PropTypes.func,
  totalQuestions: PropTypes.number,
  index: PropTypes.number,
  showMCQIndex: PropTypes.number,
  isAuthenticated: PropTypes.bool.isRequired
};

McqAttempt.defaultProps = {
  mcq: {},
  onSubmit: () => {},
  isPreview: false,
  showExplanation: 'DO_NOT_SHOW',
  showRightAnswer: false,
  moduleId: '',
  contentId: '',
  attemptId: '',
  mcqSubmit: () => {}
};

export default McqAttempt;
