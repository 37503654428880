import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Sortable from 'react-sortablejs';
import uuidv4 from 'uuid/v4';
import { Col, Row } from 'react-bootstrap';
import { pick, get, isEmpty, map, lowerCase } from 'lodash';

import Input from 'atoms/Input';
import { Radio, RadioGroup } from 'atoms/Radio';
import Button from 'atoms/Button';
import Feedback from 'atoms/Feedback';
import { Accordion } from 'atoms/Accordion';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { contentDataSelector } from 'utils/calculationUtilities';
import { CurrentContentPropTypesShape } from 'utils/core-proptypes';
import ActionRowContentData from '../../ActionRowContentData';
import CarouselItemBlock from './carousel-item-block';
import { addContentData, updateContentData } from '../../actions';
import { reducer } from './reducer';
import './carousel-create.scss';

const blockName = 'carousel-content-create';

const CarouselCreate = ({ contentId, currentContent, orgid, ...props }) => {
  const t = useFormatMessage();
  const langCode = lowerCase(get(props, 'langCode', ''));
  const initialState = {
    langCode,
    setDefaultLanguage: true,
    carouselPages: [
      {
        [uuidv4()]: {
          heading: '',
          documentation: ''
        }
      }
    ]
  };
  const currentContentData = contentDataSelector({ contentData: currentContent, langCode }) || initialState;
  const [state, dispatcher] = useReducer(reducer, currentContentData);
  const createFeedbackMessage = get(props, 'message.ADD_CONTENT_DATA_SUCCESS', {});
  const updateFeedbackMessage = get(props, 'message.UPDATE_CONTENT_DATA_SUCCESS', {});
  const createFeedbackError = get(props, 'errors.ADD_CONTENT_DATA_FAILURE', []);
  const updateFeedbackError = get(props, 'errors.UPDATE_CONTENT_DATA_FAILURE', []);
  const contentDataId = get(currentContentData, '_id', '');
  useEffect(() => {
    const defaultLanguage = props.defaultLangCode;
    dispatcher({
      type: 'initialState',
      payload: {
        ...currentContentData,
        ...currentContentData.details,
        setDefaultLanguage: defaultLanguage
      }
    });
  }, [currentContent]);

  const contentLanguageRevert = () => {
    dispatcher({
      type: 'initialState',
      payload: {
        ...currentContentData,
        ...currentContentData.details,
        revertId: uuidv4()
      }
    });
  };

  const addBlock = () => {
    dispatcher({
      type: 'addBlock',
      index: uuidv4()
    });
  };

  const carouselSortHandler = order => {
    const sortedCarouselID = order;
    const sortedCarouselItems = {};
    sortedCarouselID.map(id => {
      const carouselItem = Object.keys(state.carouselPages).find(q => q === id);
      if (!isEmpty(carouselItem)) {
        sortedCarouselItems[carouselItem] = state.carouselPages[carouselItem];
      }
      return id;
    });
    dispatcher({
      type: 'sortCarouselList',
      payload: sortedCarouselItems
    });
  };
  const onSave = () => {
    const defaultLanguage = state.setDefaultLanguage === langCode;
    const validKeys = ['langCode', 'contentTitle', 'description', 'setDefaultLanguage', 'carouselPages'];

    const payload = pick(
      {
        ...state,
        defaultLanguage,
        carouselPages: Object.values(state.carouselPages),
        setDefaultLanguage: defaultLanguage
      },
      validKeys
    );
    if (contentId && contentDataId) {
      props.updateContentData({
        payload,
        contentId,
        contentDataId,
        organizationId: orgid
      });
    } else {
      props.addContentData({ payload, contentId, organizationId: orgid });
    }
  };

  const renderInfoBlocks = () => {
    const sortableOptions = {
      animation: 150,
      handle: '.accordion-sort',
      chosenClass: 'sortable-chosen'
    };
    const items = map(state.carouselPages, (item, index) => {
      return (
        <>
          <CarouselItemBlock data-id={item._id || index} item={item} index={index} onDispatch={dispatcher} isCollapsed={item.isCollapsed} />
        </>
      );
    });
    return (
      <Sortable options={sortableOptions} onChange={carouselSortHandler}>
        {items}
      </Sortable>
    );
  };
  const { preferences } = currentContent;

  return (
    <React.Fragment>
      <div className={blockName}>
        <Row>
          <Col>
            <div className={`${blockName}__title`}>{t('common/content-title')}</div>
            <Input
              className={`${blockName}__input-title`}
              value={state && state.contentTitle}
              placeholder={t('common/content-title')}
              onChange={e => {
                dispatcher({
                  type: 'inputChange',
                  key: 'contentTitle',
                  value: e.target.value
                });
              }}
            />
            <div className={`${blockName}__desc`}>{t('common/description')}</div>
            <Input
              as="textarea"
              rows="4"
              placeholder={t('common/description')}
              className={`${blockName}__input-desc`}
              value={state && state.description}
              onChange={e => {
                dispatcher({
                  type: 'inputChange',
                  key: 'description',
                  value: e.target.value
                });
              }}
            />
            <RadioGroup
              name="carousel-create-default-lang"
              onSelect={value => {
                dispatcher({
                  type: 'inputRadio',
                  key: 'setDefaultLanguage',
                  value: value.value
                });
              }}
            >
              <Radio
                labelClass={`${blockName}__default-lang`}
                value={langCode}
                checked={state.setDefaultLanguage === langCode}
                description={t('common/set-as-default-language-description')}
                label={t('common/set-as-default-language-title')}
              />
            </RadioGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={`${blockName}__questions`}>
              <Accordion>{renderInfoBlocks()}</Accordion>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <ActionRowContentData
            onSave={onSave}
            state={state}
            contentId={contentId}
            contentDataId={contentDataId}
            preferences={preferences}
            onRevert={contentLanguageRevert}
            contentType={currentContent.contentType}
            notPristine={state.carouselContentCreateUpdatePristine}
            onDelete={props.onDelete}
            langCode={langCode}
            orgid={orgid}
          />
          <Col md="10" className="justify-content-end d-flex">
            <Button variant="primary" onClick={addBlock}>
              {t('common/add-block')}
            </Button>
          </Col>
        </Row>
      </div>

      <Feedback message={{ ...createFeedbackMessage, ...updateFeedbackMessage }} errorList={[...createFeedbackError, ...updateFeedbackError]} allowedTypesKey="MCQData" />
    </React.Fragment>
  );
};

CarouselCreate.propTypes = {
  updateContentData: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
  addContentData: PropTypes.func.isRequired,
  langCode: PropTypes.string.isRequired,
  defaultLangCode: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  orgid: PropTypes.string.isRequired,
  currentContent: PropTypes.shape({
    currentContentData: CurrentContentPropTypesShape,
    preferences: PropTypes.shape({}),
    contentType: PropTypes.string
  })
};

CarouselCreate.defaultProps = {
  onDelete: () => {},
  currentContentData: {}
};

const mapStateToProps = ({ contentState }) => {
  const errors = get(contentState, 'errors', []);
  const message = get(contentState, 'message', {});

  return {
    errors,
    message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addContentData: bindActionCreators(addContentData, dispatch),
    updateContentData: bindActionCreators(updateContentData, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarouselCreate));
