import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

import { UserPropTypesShape } from 'utils/core-proptypes';
import SealImage from 'assets/icons/seal.png';
import MindzeedLogo from 'assets/icons/logo.png';

Font.register({
  family: 'Heebo',
  fonts: [
    {
      src: `/fonts/Heebo/Heebo-Black.ttf`
    },
    {
      src: `/fonts/Heebo/Heebo-Bold.ttf`,
      fontWeight: 'bold'
    },
    {
      src: `/fonts/Heebo/Heebo-ExtraBold.ttf`,
      fontWeight: 'extrabold'
    },
    {
      src: `/fonts/Heebo/Heebo-Light.ttf`,
      fontWeight: 'light'
    },
    {
      src: `/fonts/Heebo/Heebo-Medium.ttf`,
      fontWeight: 'medium'
    },
    {
      src: `/fonts/Heebo/Heebo-Regular.ttf`,
      fontWeight: 'normal'
    },
    {
      src: `/fonts/Heebo/Heebo-Thin.ttf`,
      fontWeight: 'thin'
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#f3f5f4',
    flexDirection: 'column'
  },

  pdfContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    border: '10 solid #fff'
  },
  image: {
    marginTop: 80,
    height: 100,
    width: 80
  },
  completionYear: {
    top: -72,
    width: 80,
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Heebo',
    fontWeight: 'medium'
  },
  diplomaText: {
    fontFamily: 'Heebo',
    fontWeight: 'bold',
    fontSize: 70,
    marginTop: 10
  },
  dateCompletionText: {
    fontSize: 8,
    marginTop: 60,
    fontFamily: 'Heebo',
    fontWeight: 'light'
  },
  nameText: {
    fontSize: 28,
    marginTop: 14,
    marginBottom: 14,
    fontFamily: 'Heebo',
    fontWeight: 'medium'
  },
  contentText: {
    fontSize: 11,
    lineHeight: 1,
    marginTop: 5,
    fontFamily: 'Heebo',
    fontWeight: 'light'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 30,
    color: '#000'
  },
  companyNameText: {
    fontSize: 20,
    fontFamily: 'Heebo',
    fontWeight: 'medium',
    color: '#000',
    marginBottom: 50
  },
  companyLogo: {
    height: 20
  }
});

const DiplomaTemplate = ({ diploma, organization = {}, userProfile = {} }) => {
  const diplomaTitle = get(diploma, 'moduleData[0].moduleTitle', '');
  const contentBlocks = get(diploma, 'diplomaData.contents', []);
  const userDateFormat = get(userProfile, 'settings.dateFormat', 'DD/MM/YYYY');
  const { firstName, lastName = '' } = userProfile;
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.pdfContainer}>
          <Image style={styles.image} source={SealImage} />
          <Text style={styles.completionYear}>{new Date().getFullYear()}</Text>
          <Text style={styles.diplomaText}>{'Diploma'}</Text>
          <Text style={styles.dateCompletionText}>{moment().format(userDateFormat)}</Text>
          <Text style={styles.nameText}>{`${firstName} ${lastName}`}</Text>
          {contentBlocks.map(content => (
            <Text key={content.contentName} style={styles.contentText}>
              {moment(content.endTime).format(userDateFormat)}
              {`: ${diplomaTitle} / ${content.contentName}`}
            </Text>
          ))}
          <View style={styles.footer}>
            <Text style={styles.companyNameText}>{organization.name}</Text>
            <Image style={styles.companyLogo} source={MindzeedLogo} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

DiplomaTemplate.propTypes = {
  contentBlocks: PropTypes.shape([]).isRequired,
  diploma: PropTypes.shape([]).isRequired,
  organization: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  userProfile: UserPropTypesShape
};
export default DiplomaTemplate;
