import React from 'react';
import sn from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty, includes } from 'lodash';

import { useFormatMessage } from 'hooks/useFormatMessage';
import AllowedErrorTypesToBeShown from 'utils/error-types';
import { resolveDynamicString } from 'utils/resolve';
import './errors.scss';

const filterErrors = (errorList, allowedErrors) => {
  return errorList.filter(error => includes(allowedErrors, error.code));
};

const blockName = 'feedback';
const Feedback = ({ errorList, message: successMessage, allowedTypesKey }) => {
  const t = useFormatMessage();

  const getMessage = message => {
    return resolveDynamicString(t(`feedback/${message.code}`, {}, message.userMessage), message.dynamicData);
  };

  let list = [];
  const isSuccess = !isEmpty(successMessage);
  if (isSuccess) {
    list = [successMessage];
  } else if (errorList.length) {
    list = filterErrors(errorList, AllowedErrorTypesToBeShown[allowedTypesKey]);
  }

  return !isEmpty(list) ? (
    <div
      className={sn(blockName, {
        [`${blockName}--success`]: isSuccess,
        [`${blockName}--error`]: !isSuccess
      })}
    >
      <ul>
        {list.map(message => (
          <li key={message.code}>{getMessage(message)}</li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default Feedback;

Feedback.propTypes = {
  errorList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        userMessage: PropTypes.string.isRequired
      })
    ),
    PropTypes.bool
  ]),
  message: PropTypes.oneOfType([
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      userMessage: PropTypes.string.isRequired
    }),
    PropTypes.bool
  ]),
  allowedTypesKey: PropTypes.string.isRequired
};

Feedback.defaultProps = {
  errorList: [],
  message: {},
  allowedTypesKey: ''
};
