import React from 'react';

import { useFormatMessage } from 'hooks/useFormatMessage';
import IconWrapper from 'atoms/IconWrapper';
import './error-comp.scss';
import { Row, Col } from 'react-bootstrap';

const ErrorComp = () => {
  const t = useFormatMessage();

  return (
    <div className="error-comp-container">
      <Row className="error-comp-container__row">
        <IconWrapper type="ErrorMan" className="error-comp-container__error-image" />
      </Row>
      <Row className="error-comp-container__row">
        <h2 className="error-comp-container__error-title">{t('error-page/sorry-error-occured')}</h2>
      </Row>
      <Col className="error-comp-container__msg">
        <div className="error-comp-container__msg__reloading">{t('error-page/try-reloadig')}</div>
        <div className="error-comp-container__msg__reloading">{t('error-page/try-reloadig-contact')}</div>
      </Col>

      <IconWrapper type="MindzeedLogo" className="error-comp-container__logo" />
    </div>
  );
};
ErrorComp.propTypes = {};
export default ErrorComp;
