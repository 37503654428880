import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';

export const trackContentStory = ({ moduleId, contentId, storyId, payload, isPublic = false }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/submit${isPublic ? '/public' : ''}/module/${moduleId}/content/${contentId}/story/${storyId}`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: ['GET', 'GET_SUCCESS', 'GET_FAILURE']
  }
});
