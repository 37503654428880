import React from 'react';
import FormCheck from 'react-bootstrap/FormCheck';
import PropTypes from 'prop-types';
import sn from 'classnames';
import './checkbox.scss';
import { uniqueId } from 'utils/unique-id';

const blockName = 'checkbox-container';
const Checkbox = ({ label, disabled, id, ...props }) => {
  const customId = uniqueId(`${blockName}__`);
  const style = {};

  return (
    <React.Fragment>
      <FormCheck id={id || customId} style={style} className={sn(blockName)} disabled={disabled} label={label} {...props} />
    </React.Fragment>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string
};

Checkbox.defaultProps = {
  label: '',
  disabled: false,
  id: ''
};
export default Checkbox;
