import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import DropDown from 'atoms/DropDown';
import Input from 'atoms/Input';
import Feedback from 'atoms/Feedback';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { ErrorsPropTypesShape, UserPropTypesShape } from 'utils/core-proptypes';
import './modal-elements.scss';

const EditUserModalBody = ({ user, dispatcher, groupsList, error, allowedRoles, useSSO }) => {
  const t = useFormatMessage();
  const { email, _id: userId, firstName, lastName, status, role, groupId, password = '' } = user;
  const allowedRoleOptions = useMemo(
    () =>
      allowedRoles.map(allowedRole => ({
        id: allowedRole,
        value: t(`common/allowed-roles/${allowedRole}`)
      })),
    [allowedRoles]
  );

  return (
    <Form>
      <Form.Group>
        <Form.Label>{t('common/modal-body/first-name')}</Form.Label>
        <Input
          onChange={e =>
            dispatcher({
              type: 'inputChange',
              key: 'firstName',
              value: e.target.value
            })
          }
          type="fname"
          value={firstName}
          placeholder={t('common/modal-body/first-name')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('common/modal-body/last-name')}</Form.Label>
        <Input
          onChange={e =>
            dispatcher({
              type: 'inputChange',
              key: 'lastName',
              value: e.target.value
            })
          }
          type="lname"
          value={lastName}
          placeholder={t('common/modal-body/last-name')}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>{t('common/modal-body/email')}</Form.Label>
        <Input
          type="email"
          onChange={e =>
            dispatcher({
              type: 'inputChange',
              key: 'email',
              value: e.target.value
            })
          }
          disabled={userId && true}
          value={email}
          placeholder={t('common/modal-body/email')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('common/modal-body/role')}</Form.Label>
        <DropDown
          onSelect={eventKey =>
            dispatcher({
              type: 'dropDownChange',
              key: 'role',
              eventKey
            })
          }
          placeholder={t('common/modal-body/select-role')}
          heading={role}
          values={allowedRoleOptions}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('common/modal-body/status')}</Form.Label>
        <DropDown
          heading={status}
          placeholder={t('common/modal-body/select-status')}
          onSelect={eventKey =>
            dispatcher({
              type: 'dropDownChange',
              key: 'status',
              eventKey
            })
          }
          values={[
            { id: 'ACTIVE', value: 'Active' },
            { id: 'INACTIVE', value: 'Disabled' }
          ]}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('common/modal-body/group')}</Form.Label>
        <DropDown
          isColored
          placeholder={t('common/modal-body/select-group')}
          onSelect={eventKey =>
            dispatcher({
              type: 'dropDownChange',
              key: 'groupId',
              eventKey
            })
          }
          heading={groupId}
          values={groupsList.map(grp => ({
            id: grp._id,
            value: grp.name,
            color: grp.colorCode
          }))}
        />
      </Form.Group>
      {userId && !useSSO && (
        <Form.Group>
          <Form.Label>{t('common/modal-body/password')}</Form.Label>
          <Input
            onChange={e =>
              dispatcher({
                type: 'inputChange',
                key: 'password',
                value: e.target.value
              })
            }
            type="password"
            autocomplete="new-password"
            value={password}
            placeholder={t('common/modal-body/password')}
          />
        </Form.Group>
      )}
      <Feedback errorList={error} allowedTypesKey="AddUserEditUser" />
    </Form>
  );
};

EditUserModalBody.propTypes = {
  user: UserPropTypesShape,
  groupsList: PropTypes.arrayOf(PropTypes.object),
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  useSSO: PropTypes.bool,
  error: PropTypes.arrayOf(ErrorsPropTypesShape),
  dispatcher: PropTypes.func.isRequired
};

EditUserModalBody.defaultProps = {
  user: {
    state: {
      _id: '',
      email: '',
      firstName: '',
      lastName: '',
      status: ''
    }
  },
  allowedRoles: [],
  groupsList: [],
  error: []
};

export default EditUserModalBody;
