import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Col, Row, TabContainer, TabContent, TabPane, Nav, NavItem } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import ThreeDots from 'atoms/ThreeDots';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { formatLanguages } from 'utils/calculationUtilities';
import { UserPropTypesShape } from 'utils/core-proptypes';
import EmailTemplate from './email-template';
import { getAllEmailTemplates } from './actions';
import './email-template.scss';

const EmailTemplateIds = ({ publications, getAllEmailTemplates, user, allEmailTemplates }) => {
  const t = useFormatMessage();
  const [currentActiveKey, setCurrentActiveKey] = useState();
  const [listOfTabs, setlistOfTabs] = useState([]);
  const { user: { role = '' } = {} } = user;
  const publicationsModified = formatLanguages(publications);
  const emailTemplateTabs = listOfTabs || [];

  useEffect(() => {
    if (role === 'ADMIN') {
      isEmpty(allEmailTemplates) && getAllEmailTemplates();
    }
    if (!isEmpty(allEmailTemplates)) {
      const modifiedEmailTemplates = allEmailTemplates.map(template => {
        return {
          ...template,
          title: template.title.toUpperCase()
        };
      });
      setlistOfTabs(modifiedEmailTemplates);
    }
  }, [allEmailTemplates]);

  const handleAddLanguageDropDown = idInLowerCase => {
    const id = idInLowerCase.toUpperCase();
    const isAlreadyThere = listOfTabs && listOfTabs.find(tab => tab.title === id); // only add content who are not already selected
    if (!isAlreadyThere) {
      setlistOfTabs([...listOfTabs, { eventKey: `email-template-${idInLowerCase}`, title: `${id}` }]);
    }
    setCurrentActiveKey(`email-template-${idInLowerCase}`);
  };

  return (
    <React.Fragment>
      <Container className="email-template-container p-0">
        <Row className="email-template__tabs">
          <Col>
            <TabContainer defaultActiveKey="email-template-en" onSelect={e => setCurrentActiveKey(e)} activeKey={currentActiveKey} sid="email-template-tab">
              <Nav className="flex-row email-template__tabs">
                {emailTemplateTabs.map(tab => (
                  <NavItem key={tab.title} id={`tab-${tab.title}`}>
                    <Nav.Link as="div" eventKey={tab.eventKey}>
                      {tab.title}
                    </Nav.Link>
                  </NavItem>
                ))}
                <NavItem>
                  <ThreeDots label={t('common/add-language')} width="300px" onSelect={handleAddLanguageDropDown} placement="bottom" options={publicationsModified} />
                </NavItem>
              </Nav>
              <TabContent>
                {emailTemplateTabs.map(tab => (
                  <TabPane key={tab.eventKey} eventKey={tab.eventKey} id={tab.eventKey}>
                    <Row>
                      <Col>
                        <EmailTemplate langCode={tab.title} moduleData={tab.moduleData} emailTemplateData={tab} updateUUID={tab.updateId} />
                      </Col>
                    </Row>
                  </TabPane>
                ))}
              </TabContent>
            </TabContainer>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

EmailTemplateIds.propTypes = {
  publications: PropTypes.arrayOf(PropTypes.shape({})),
  allEmailTemplates: PropTypes.arrayOf(PropTypes.shape({})),
  getAllEmailTemplates: PropTypes.func.isRequired,
  user: UserPropTypesShape
};

EmailTemplateIds.defaultProps = {
  publications: []
};

const mapStateToProps = ({ adminState, userState, appSettings }) => {
  return {
    publications: adminState.publications,
    user: userState.user,
    allEmailTemplates: appSettings.allEmailTemplates
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllEmailTemplates: bindActionCreators(getAllEmailTemplates, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailTemplateIds));
