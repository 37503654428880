import React from 'react';
import PropTypes from 'prop-types';
import IconWrapper from 'atoms/IconWrapper';
import { Button as BootStrapButton } from 'react-bootstrap';
import './Button.scss';

const Button = ({ children, variant, onClick, size, notPristine, ...restProps }) => {
  const typeOfButton = notPristine ? 'unSaved' : variant;
  switch (typeOfButton) {
    case 'clean':
      return (
        <button className="btn-clean" onClick={onClick} {...restProps}>
          {children}
        </button>
      );
    case 'unSaved':
      return <IconWrapper height="35px" {...restProps} type="SavedIcon" />;
    default:
      return (
        <BootStrapButton size={size} variant={variant} onClick={onClick} {...restProps}>
          {children}
        </BootStrapButton>
      );
  }
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  variant: PropTypes.string,
  notPristine: PropTypes.bool,
  size: PropTypes.string
};

Button.defaultProps = {
  onClick: () => {},
  children: {},
  variant: 'primary',
  notPristine: false,
  size: 'md'
};
export default Button;
