import PropTypes from 'prop-types';

export const HistoryPropTypesShape = PropTypes.shape({
  push: PropTypes.func
});

export const LocationPropTypesShape = PropTypes.shape({
  search: PropTypes.string,
  pathname: PropTypes.string,
  language: PropTypes.string
});

export const UserPropTypesShape = PropTypes.shape({
  message: PropTypes.shape({
    title: PropTypes.string
  }),
  user: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
    accountType: PropTypes.string,
    userRole: PropTypes.string,
    role: PropTypes.string,
    groupId: PropTypes.string,
    group: PropTypes.shape({})
  })
});

export const ErrorsPropTypesShape = PropTypes.shape({
  SIGNIN_USER_FAILURE: PropTypes.string,
  RESET_PASSWORD_FAILURE: PropTypes.string,
  UPDATE_PASSWORD_FAILURE: PropTypes.bool
}).isRequired;

export const SuccessMessagePropTypesShape = PropTypes.shape({
  SIGNIN_USER_SUCCESS: PropTypes.string,
  RESET_PASSWORD_SUCCESS: PropTypes.string,
  UPDATE_PASSWORD_SUCCESS: PropTypes.string
}).isRequired;

export const LanguagesPropTypesShape = PropTypes.arrayOf(PropTypes.shape({})).isRequired;

export const MCQPropTypesShape = PropTypes.shape({
  _id: PropTypes.string,
  correct: PropTypes.string,
  explanation: PropTypes.string,
  question: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.array
});

export const MCQPreferencesPropTypesShape = PropTypes.shape({
  passPercent: PropTypes.number,
  showContentInOrder: PropTypes.bool
});

export const MixedPreferencesPropTypesShape = PropTypes.shape({
  passPercent: PropTypes.number,
  showContentInOrder: PropTypes.bool,
  displayQuestionMode: PropTypes.string,
  showRightAnswer: PropTypes.string,
  noOfTestAttempts: PropTypes.string.isRequired,
  showExplanation: PropTypes.oneOf(['SHOW_EXP', 'SHOW_EXP_RIGHT', 'SHOW_EXP_WRONG', 'DO_NOT_SHOW'])
});

export const PresentationPreferencesPropTypesShape = PropTypes.shape({
  contentName: PropTypes.string,
  difficultyLevel: PropTypes.string,
  estimatedTime: PropTypes.string,
  noOfTimeCanBeAccessed: PropTypes.string,
  askForConfirmation: PropTypes.string,
  downloadable: PropTypes.string
});

export const MediaPropTypesShape = PropTypes.shape({
  url: PropTypes.string,
  type: PropTypes.string
});

export const ComputedMatchPropTypesShape = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    orgid: PropTypes.s
  })
});

export const ContentsPropTypesShape = PropTypes.arrayOf(PropTypes.shape({}));
export const ModulesPropTypesShape = PropTypes.arrayOf(PropTypes.shape({}));
export const CurrentModulepropTypesShape = PropTypes.shape({
  defaultLangCode: PropTypes.string,
  data: PropTypes.array
}).isRequired;
export const CurrentContentPropTypesShape = PropTypes.shape({
  attemptId: PropTypes.string,
  details: PropTypes.shape({}),
  preferences: PropTypes.shape({}),
  defaultLangCode: PropTypes.string
}).isRequired;
