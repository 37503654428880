import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Container, Row } from 'react-bootstrap';
import axios from 'axios';

import IconWrapper from 'atoms/IconWrapper';
import Button from 'atoms/Button';
import UserImportFeedback from './UserImportFeedback';
import { BASE_URL, axiosConfig } from 'utils/api';
import { useFormatMessage } from 'hooks/useFormatMessage';
import './user-import.scss';

const UserImport = props => {
  const [file, setFile] = useState('');
  const [excelPloadResp, setExcelUploadResp] = useState(undefined);

  const t = useFormatMessage();
  // const userOrgId = get(props, 'user.user.organizationId', null);
  // const [state, dispatcher] = useReducer(reducer, { users: [] });
  const handleDownloadableClick = () => {
    window.open(`${BASE_URL}/static/sample-users-import.xlsx`, '_blank');
  };

  const uploadUserExcel = async () => {
    setExcelUploadResp(undefined);

    const endpoint = `${BASE_URL}/upload`;
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(endpoint, formData, axiosConfig('multipart/form-data'));
    const uploadUsers = await axios.post(`${BASE_URL}/organizations/${props.selectedOrg}/import/users`, { file: response.data.file }, axiosConfig('application/json'));
    //AFter that save data to users api
    setExcelUploadResp(uploadUsers.data);
    setFile('');
  };
  return (
    <>
      <Container className="user-import-container">
        <Row className="user-import-container__first-row">{t('user-import/for-correct-import-the-xlsx-file-must-be-properly-formatted-and-cant-exceed-10MB')}</Row>
        <Row className="user-import-container__row user-import-container__row--download" onClick={handleDownloadableClick}>
          {t('user-import/download-a-sample-template')}
        </Row>
        <Row className="user-import-container__row user-import-container__row--upload">
          <Row className="align-items-center">
            <Button variant="secondary" className="user-import-container__choose-button">
              <label htmlFor="user-import-file">
                {t('user-import/choose-file')}
                <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  id="user-import-file"
                  type="file"
                  key={file.name}
                  className="user-import-container__file-input"
                  onChange={e => {
                    setFile(e.target.files[0]);
                  }}
                />
              </label>
            </Button>
            {file && (
              <>
                <p>{file.name} </p>
                <IconWrapper onClick={() => setFile('')} className={'user-import-container__row--upload__closeicon'} type="CloseIcon" alt="Close" />
              </>
            )}
          </Row>
          <Row>
            <Button disabled={!file} variant="primary" onClick={uploadUserExcel} className="user-import-container__choose-button">
              {t('user-import/upload-file')}
            </Button>
          </Row>
        </Row>
      </Container>
      {!isEmpty(excelPloadResp) && <UserImportFeedback feedback={excelPloadResp} />}
    </>
  );
};

UserImport.propTypes = {
  selectedOrg: PropTypes.string
};

UserImport.defaultProps = {};

export default UserImport;
