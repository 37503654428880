import React, { Fragment, useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import { isEmpty, get } from 'lodash';

import Button from 'atoms/Button';
// import IconWrapper from 'atoms/IconWrapper';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { HistoryPropTypesShape, MCQPreferencesPropTypesShape } from 'utils/core-proptypes';
import PageHeader from '../../PageHeader';
import MCQStatus from './McqStatus';
import McqAttempt from './McqAttempt';

const MCQViewBottom = ({ showVideo, contentData, currentContent, preferences, isPreview, moduleId, contentId, attemptId, trackingId, langCode, mcqSubmit, history, videoPlayed, ...props }) => {
  const t = useFormatMessage();
  const scrollToTopRef = useRef(null);

  const [attemptedMCQ, setAttemptedMCQ] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [showStatusForOneByOne, setShowStatusForOneByOne] = useState(false);
  const [showMCQIndex, setShowMCQIndex] = useState(1);
  const [currentAnswered, setcurrentAnswered] = useState(0);

  const content = isEmpty(contentData.details) ? currentContent : contentData;
  const media = get(content, 'details.media', {});

  const { passPercent, showRightAnswer, showExplanation, showQuestionsAfterVideo, showContentOnDiploma, displayQuestionMode = 'ALL' } = preferences;
  const passCount = passPercent ? (passPercent / 100) * get(content, 'mcqList.length', 0) : get(content, 'mcqList.length', 0);
  const mcqList = content?.mcqList ? [...content.mcqList] : [];

  const totalQuestions = useMemo(() => get(content, 'mcqList.length', 0), content);

  useEffect(() => {
    if (!isPreview && !contentId && !moduleId) {
      history.replace('/home');
    }
  }, [isPreview, contentId, moduleId]);

  useEffect(() => {
    // default cases in case of handle retry
    setAttemptedMCQ({});
    setShowResult(false);
    setShowMCQIndex(1);
    setcurrentAnswered(0);
  }, [attemptId]);

  const startContentAgain = () => {
    !isPreview && props.fetchContentStart({ moduleId, contentId, langCode, isPublic: !props.isAuthenticated, trackingId });
  };

  const redirectToContentList = () => {
    if (!props.isAuthenticated) {
      history.replace(`/content-list/${moduleId}?tid=${trackingId}`);
    } else {
      history.replace(`/content-list/${moduleId}`);
    }
  };

  const handleSubmit = ({ key, success, index }) => {
    setAttemptedMCQ({ ...attemptedMCQ, [key]: { success } });
    setcurrentAnswered(index + 1);
  };
  console.log('hey', showVideo, showQuestionsAfterVideo, currentContent, videoPlayed, contentData);
  return (
    !showVideo && (
      <Fragment>
        <Container>
          <PageHeader variant="small text-center" title={get(content, 'details.contentTitle', '')} desc={get(content, 'details.description', '')} isDangerousHTML={true} />
          {showQuestionsAfterVideo === 'true' && !videoPlayed && (
            <Row className="justify-content-center">
              <Button style={{ padding: '10px 20px', color: ' #00aaa4', borderRadius: ' 0.5rem', marginBottom: '20px', cursor: 'initial' }} variant="secondary">
                {t('feedback/AFTER_VIDEO_MESSAGE')}
              </Button>
            </Row>
          )}
        </Container>
        {((showQuestionsAfterVideo === 'true' && videoPlayed) || showQuestionsAfterVideo !== 'true' || isEmpty(media)) && (
          <>
            <Container ref={scrollToTopRef}>
              {mcqList.map((q, index) => (
                <McqAttempt
                  {...{
                    moduleId,
                    contentId,
                    attemptId,
                    mcqSubmit
                  }}
                  isAuthenticated={props.isAuthenticated}
                  showRightAnswer={showRightAnswer}
                  showMCQIndex={showMCQIndex}
                  showExplanation={showExplanation}
                  key={q._id}
                  mcq={q}
                  index={index}
                  onSubmit={handleSubmit}
                  isPreview={isPreview}
                  attemptedMCQLength={Object.keys(attemptedMCQ).length}
                  showAllMode={showResult}
                  totalQuestions={totalQuestions}
                  displayQuestionMode={displayQuestionMode}
                />
              ))}
              {displayQuestionMode === 'ONE_BY_ONE' &&
                showMCQIndex <= totalQuestions &&
                (currentAnswered + 1 === showMCQIndex ? (
                  <Button className="mcq-view__next-question" disabled variant="primary">
                    {t('common/next')}
                  </Button>
                ) : (
                  <Button
                    className="mcq-view__next-question"
                    alt="Next Question"
                    onClick={() => {
                      scrollToTopRef.current.scrollIntoView();
                      if (currentAnswered === totalQuestions) {
                        setShowStatusForOneByOne(true);
                      } else {
                        setShowStatusForOneByOne(false);
                      }
                      setShowMCQIndex(showMCQIndex + 1);
                    }}
                    variant="primary"
                  >
                    {t('common/next')}
                  </Button>
                ))}
            </Container>

            {/* Pass Count should be calculated using pass percent */}

            {(displayQuestionMode === 'ALL' || (displayQuestionMode === 'ONE_BY_ONE' && showStatusForOneByOne)) && (
              <Container>
                <MCQStatus
                  isPreview={isPreview}
                  redirectToContentList={redirectToContentList}
                  startContentAgain={startContentAgain}
                  setShowResult={setShowResult}
                  showResult={showResult}
                  attempted={attemptedMCQ}
                  isAuthenticated={props.isAuthenticated}
                  total={totalQuestions}
                  passCount={passCount}
                  showContentOnDiploma={showContentOnDiploma}
                  displayQuestionMode={displayQuestionMode}
                />
              </Container>
            )}
            {/* {!isPreview && (
              // ? (
              //   <div className="mcq-view__bottom-buttons">
              //     <IconWrapper onClick={redirectToContentList} type="GoToModules" alt="Go to Modules" className="action-icons" style={{ marginLeft: '22px' }} />
              //     {noOfTestAttempts !== 'ONE' && <IconWrapper onClick={startContentAgain} type="Retry" alt="Retry" className="action-icons" />}
              //   </div>
              // ) :
              // (
              <div className="mcq-view__fake-space" />
            )} */}
          </>
        )}
      </Fragment>
    )
  );
};

MCQViewBottom.propTypes = {
  videoPlayed: PropTypes.bool,
  contentData: PropTypes.shape({
    _id: PropTypes.string,
    details: PropTypes.shape({}),
    mcqList: PropTypes.array
  }),
  currentContent: PropTypes.shape({}),
  preferences: MCQPreferencesPropTypesShape,
  isPreview: PropTypes.bool,
  showVideo: PropTypes.bool,
  moduleId: PropTypes.string,
  contentId: PropTypes.string,
  langCode: PropTypes.string,
  attemptId: PropTypes.string,
  trackingId: PropTypes.string,
  mcqSubmit: PropTypes.func,
  history: HistoryPropTypesShape,
  fetchContentStart: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

MCQViewBottom.defaultProps = {
  contentData: {
    details: {},
    mcqList: []
  },
  currentContent: null,
  preferences: {},
  isPreview: false,
  moduleId: '',
  contentId: '',
  history: null,
  langCode: '',
  attemptId: '',
  mcqSubmit: () => {}
};

export default MCQViewBottom;
