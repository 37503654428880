import React from 'react';
import { Card } from 'react-bootstrap';
import { map as mapObject } from 'lodash';
import cn from 'classnames';

const Options = ({ mcq, disabled, onChangeOption, selectedKey, correctKey }) => {
  return mapObject(mcq.options, (option, key) => {
    return (
      <Card.Text key={key}>
        <input
          type="radio"
          id={`radio-group-${key}`}
          value={key}
          disabled={disabled}
          name={`radio-group-${mcq._id}`}
          onChange={onChangeOption}
          checked={selectedKey === key}
          className={cn('mcq-container__button', {
            'mcq-container__button--disabled': disabled,
            'mcq-container__button--correct': correctKey === key
          })}
        />
        <label
          htmlFor={`radio-group-${key}`}
          className={cn('mcq-container__label', {
            'mcq-container__label--disabled': disabled,
            'mcq-container__label--correct': correctKey === key
          })}
        >
          {option}
        </label>
      </Card.Text>
    );
  });
};
export default Options;
