import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import { ADD_ORGANIZATION, ADD_ORGANIZATION_SUCCESS, ADD_ORGANIZATION_FAILURE, CLEAR_ACCOUNT_ERROR } from 'utils/constants';

export const addOrganizationAndRegisterManager = ({ payload }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/organizations`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [ADD_ORGANIZATION, ADD_ORGANIZATION_SUCCESS, ADD_ORGANIZATION_FAILURE]
  }
});

export const clearAccountError = () => ({
  type: CLEAR_ACCOUNT_ERROR
});
