const AllowedErrorTypesToBeShown = {
  AddAccountEditaccount: ['INVALID_ORGANIZATION_NAME', 'INVALID_ACCOUNT_TYPE', 'INVALID_EMAIL', 'INVALID_FIRST_NAME', 'INVALID_LAST_NAME', 'INVALID_STATUS'],
  AddUserEditUser: [
    'EMAIL_ALREADY_EXISTS',
    'CANNOT_CREATE_WITHOUT_ROLE',
    'INVALID_FIRST_NAME',
    'INVALID_LAST_NAME',
    'INVALID_EMAIL',
    'INVALID_STATUS',
    'INVALID_GROUP',
    'INVALID_ROLE',
    'INVALID_PASSWORD'
  ],
  AddGroupEditGroup: ['INVALID_GROUP_NAME', 'INVALID_GROUP_COLOR', 'CANNOT_CREATE_DUPLICATE_GROUP_NAME'],
  MyAccountChangePassword: ['OLD_PASSWORD_OR_NEW_PASSWORD_BLANK', 'OLD_PASSWORD_MISMATCH'],
  MCQPreferences: [
    'INVALID_CONTENT_NAME',
    'INVALID_CONTENT_DIFFICULTY',
    'INVALID_ESTIMATED_TIME',
    'INVALID_PASS_PERCENT',
    'INVALID_SHOW_EXPLANATION',
    'INVALID_SHOW_ANSWER',
    'INVALID_CONTENT_ORDERING',
    'INVALID_NO_OF_TESTS',
    'INVALID_THUMBNAIL_TYPE',
    'INVALID_THUMBNAIL_URL',
    'INVALID_SHOW_QUESTIONS_AFTER_VIDEO',
    'INVALID_NO_OF_QUESTIONS_TO_DISPLAY'
  ],
  MCQData: ['INVALID_CONTENT_TITLE', 'INVALID_THUMBNAIL_TYPE', 'INVALID_THUMBNAIL_URL', 'INVALID_MEDIA_TYPE', 'INVALID_MEDIA_URL', 'ATLEAST_ONE_MCQ_REQUIRED', 'INVALID_QUESTION'],
  PresentationPreferences: ['INVALID_CONTENT_NAME', 'INVALID_CONTENT_DIFFICULTY', 'INVALID_ESTIMATED_TIME', 'INVALID_NO_OF_TIMES_ACCESSIBLE', 'INVALID_DOWNLOADABLE', 'INVALID_ASK_FOR_CONFIRMATION'],
  PresentationData: ['INVALID_CONTENT_TITLE', 'INVALID_THUMBNAIL_TYPE', 'INVALID_THUMBNAIL_URL', 'INVALID_MEDIA_TYPE', 'INVALID_MEDIA_URL', 'INVALID_PRESENTATION_DOCUMENTATION'],
  ModulePreferences: ['INVALID_MODULE_NAME', 'INVALID_MODULE_START_DATE', 'INVALID_MODULE_END_DATE', 'INVALID_THUMBNAIL_TYPE', 'INVALID_THUMBNAIL_URL', 'CANNOT_ASSIGN_GROUP_WITHOUT_CONTENT'],
  ModuleData: ['INVALID_MODULE_TITLE', 'INVALID_MEDIA_TYPE', 'INVALID_MEDIA_URL'],
  LanguageSelector: [
    'CANNOT_START_FUTURE_OR_INACTIVE_CONTENT',
    'ERROR_STARTING_CONTENT',
    'CAN_ATTEMPT_CONTENT_ONLY_ONCE',
    'CAN_ATTEMPT_CONTENT_ONLY_TWICE',
    'CAN_ATTEMPT_CONTENT_ONLY_ONCE_IN_24_HOURS',
    'MODULE_NOT_STARTED_YET',
    'CONTENT_DOES_NOT_EXISTS',
    'TECHNICAL_ERROR_OCCURED'
  ],
  ContentSelector: ['NO_CONTENT_MATCH_FOUND']
};
//not shown right now
// ACSettingsGeneral: ['INVALID_ORGANIZATION_NAME', 'INVALID_ORGANIZATION_OWNER_EMAIL', 'INVALID_ORGANIZATION_SUPPORT_EMAIL', 'INVALID_TIMEZONE', 'INVALID_DATE_FORMAT', 'INVALID_LANGUAGE_CODE'];
// //not shown right nnow
// MyAccountMySettings: ['INVALID_TIMEZONE', 'INVALID_DATE_FORMAT', 'INVALID_LANGUAGE_CODE'];

// //not used for now
// ModuleContentMCQPreferences: ['INVALID_PASS_PERCENT', 'INVALID_SHOW_EXPLANATION', 'INVALID_SHOW_ANSWER', 'INVALID_CONTENT_ORDERING', 'INVALID_NO_OF_TESTS'];
// //not used for now
// ModuleContentPresentationPreferences: ['INVALID_NO_OF_TIMES_ACCESSIBLE', 'INVALID_DOWNLOADABLE', 'INVALID_ASK_FOR_CONFIRMATION'];

export default AllowedErrorTypesToBeShown;
