import React, { useReducer } from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import IconWrapper from 'atoms/IconWrapper';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { dispatchCustomAction } from './actions';
import './content-add-card.scss';

const blockname = 'content-add-container';
const contentOptions = ['mcq', 'presentation', 'carousel', 'story', 'mixed'];

const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'setContentOptions':
      return { ...state, currentView: 'contentOptions' };
    default:
      throw new Error();
  }
};

const ContentAddCard = ({ selectedOrg, ...props }) => {
  const t = useFormatMessage();
  const [state, dispatcher] = useReducer(reducer, {
    currentView: 'addContent'
  });

  const CurrentView = () => {
    switch (state.currentView) {
      case 'addContent': {
        return (
          <div className={`${blockname}__add-container`}>
            <IconWrapper
              id={blockname}
              type="GreenPlus"
              onClick={() => {
                dispatcher({ type: 'setContentOptions' });
              }}
            />
          </div>
        );
      }
      case 'contentOptions': {
        return (
          <div className={`${blockname}__options-container`}>
            <span>{t('content-add-card/select-content-type')}</span>
            {contentOptions.map(contentType => (
              <Link
                key={contentType}
                id={`content-ops-${contentType}`}
                to={`/org/${selectedOrg}/content/${contentType}`}
                onClick={() => {
                  props.dispatchCustomAction('INITIALIZE_CURRENT_CONTENT');
                }}
              >
                {t(`content-add-card/${contentType}`)}
              </Link>
            ))}
          </div>
        );
      }
      default:
        return null;
    }
  };
  return (
    <Col xs={12} sm={6} md={4} lg={3} className={`${blockname} p-0`}>
      <CurrentView />
    </Col>
  );
};

ContentAddCard.propTypes = {
  dispatchCustomAction: PropTypes.func.isRequired,
  selectedOrg: PropTypes.string.isRequired
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchCustomAction: bindActionCreators(dispatchCustomAction, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentAddCard));
