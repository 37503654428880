import React, { useState, useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { get, isEmpty, pick } from 'lodash';

import DropDown from 'atoms/DropDown';
import Button from 'atoms/Button';
import Modal from 'atoms/Modal';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { validatePassword } from 'utils/password-validation';
import { formatLanguages, getFilteredTimezone } from 'utils/calculationUtilities';
import ChangePassword from '../../ModalElements/ChangePasswordModalBody';
import { getAllTimezones, fetchPublications, updateUserSettings } from '../../../actions';
import AppContext from '../../../app-context';
import { updatePassword } from './actions';
import './my-settings.scss';

const reducer = (state, action) => {
  const { key, value, eventKey: { id: dropDownKey } = {} } = action;
  switch (action.type) {
    case 'inputChange':
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          [key]: value,
          errors: []
        }
      };
    case 'clearPasswordModal':
      return {
        ...state,
        changePassword: {}
      };
    case 'invalidConfirmPassword':
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          errors: [{ userMessage: action.payload, title: action.payload }]
        }
      };
    case 'dropDownChange':
      return {
        ...state,
        notPristine: false,
        [key]: dropDownKey
      };
    case 'saveState':
      return {
        ...state,
        notPristine: true,
        userAccounDetails: action.payload
      };
    case 'getTimezones':
      return {
        ...state,
        filteredTimezone: action.payload
        // userAccounDetails: {
        //   ...action.payload,
        //   settings: {
        //     ...action.payload.settings,
        //     timezone: { ...action.payload.settings.timezone, timezone: null }
        //   }
        // }
      };
    case 'initializeState':
      return {
        ...state,
        userAccounDetails: action.payload
      };
    case 'initializeFilteredTimezones':
      return {
        ...state,
        filteredTimezone: action.filteredTimezone
      };

    default:
      throw new Error();
  }
};

const MySettings = ({
  getAllTimezones,
  publications,
  fetchPublications,
  userAccounDetails,
  updatePassword,
  updateUserSettings,
  changePasswordSuccess,
  changePasswordErrors,
  dateValues,
  updateSettingsSuccess,
  timezonesCountry,
  timezones
}) => {
  const t = useFormatMessage();
  const context = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [state, dispatcher] = useReducer(reducer, {
    notPristine: true,
    userAccounDetails: {}
  });
  const { changePassword } = state;
  const countryToBefiltered = get(userAccounDetails, 'settings.timezone.country');

  useEffect(() => {
    dispatcher({
      type: 'initializeState',
      payload: userAccounDetails
    });
    getAllTimezones();
    if (isEmpty(publications)) {
      fetchPublications();
    }
  }, []);

  useEffect(() => {
    dispatcher({
      type: 'initializeFilteredTimezones',
      filteredTimezone: getFilteredTimezone(timezones, countryToBefiltered)
    });
  }, [timezones]);

  useEffect(() => {
    if (!isEmpty(changePasswordSuccess)) {
      setModalShow(false);
    }
  }, [changePasswordSuccess]);

  useEffect(() => {
    if (updateSettingsSuccess) {
      dispatcher({
        type: 'saveState',
        payload: userAccounDetails
      });
    }
  }, [updateSettingsSuccess]);

  useEffect(() => {
    const userLanguage = get(userAccounDetails, 'defaultLangCode', context.language);
    if (context.language !== userLanguage) {
      context.setContextState({ language: userLanguage });
    }
  }, [userAccounDetails]);

  const handlePasswordChange = () => {
    const { newPassword, oldPassword } = changePassword;
    !validatePassword(newPassword)
      ? dispatcher({
          type: 'invalidConfirmPassword',
          payload: t('reset/password-validation-message')
        })
      : updatePassword({ newPassword, oldPassword });
  };

  const handleAccountSettingUpdate = () => {
    const payload = pick(state, ['defaultLangCode', 'dateFormat', 'timezone']);
    updateUserSettings(payload);
  };

  const { settings: { dateFormat, defaultLangCode, timezone: { timezone, country } = {} } = {}, firstName, lastName, email } = state.userAccounDetails;
  const { notPristine, filteredTimezone, timezoneCountry } = state;

  return (
    <React.Fragment>
      <Container className="my-settings-container">
        <Row className="my-settings-container__row">
          <Col className="pl-0">{t('account-settings/name')}</Col>
          <Col className="pr-0 my-settings-container__row__flex-end">{`${firstName} ${lastName}`}</Col>
        </Row>
        <Row className="my-settings-container__row">
          <Col className="pl-0">{t('common/modal-body/email')}</Col>
          <Col className="pr-0 my-settings-container__row__flex-end">{email}</Col>
        </Row>
        <Row className="my-settings-container__row">
          <Col className="pl-0">{t('account-settings/language')}</Col>
          <Col className="pr-0 my-settings-container__row__flex-end">
            <DropDown
              key={defaultLangCode}
              onSelect={eventKey =>
                dispatcher({
                  type: 'dropDownChange',
                  key: 'defaultLangCode',
                  eventKey
                })
              }
              heading={defaultLangCode}
              placeholder={t('common/select')}
              values={publications}
            />
          </Col>
        </Row>
        <Row className="my-settings-container__row">
          <Col className="pl-0">{t('account-settings/date-format')}</Col>
          <Col className="pr-0 my-settings-container__row__flex-end">
            <DropDown
              onSelect={eventKey =>
                dispatcher({
                  type: 'dropDownChange',
                  key: 'dateFormat',
                  eventKey
                })
              }
              heading={dateFormat}
              placeholder={t('common/select')}
              values={dateValues}
            />
          </Col>
        </Row>
        <Row className="my-settings-container__row">
          <Col className="pl-0">{t('account-settings/timezone-format')}</Col>
          <Col className="pr-0 my-settings-container__row__flex-end timezone">
            <DropDown
              onSelect={eventKey => {
                dispatcher({
                  type: 'dropDownChange',
                  key: 'timezoneCountry',
                  eventKey
                }),
                  dispatcher({
                    type: 'getTimezones',
                    payload: getFilteredTimezone(timezones, eventKey.id)
                  });
              }}
              heading={country}
              placeholder={t('common/select')}
              values={timezonesCountry}
            />
            <DropDown
              onSelect={eventKey =>
                dispatcher({
                  type: 'dropDownChange',
                  key: 'timezone',
                  eventKey
                })
              }
              heading={timezone}
              key={timezoneCountry}
              placeholder={t('common/select')}
              values={filteredTimezone}
            />
          </Col>
        </Row>
        {!userAccounDetails.useSSO && (
          <Row className="my-settings-container__row">
            <Col className="pl-0">{t('account-settings/password')}</Col>
            <Col className="pr-0 my-settings-container__row__flex-end">
              <Button
                onClick={() => {
                  dispatcher({
                    type: 'clearPasswordModal'
                  });
                  setModalShow(true);
                }}
                variant="secondary"
              >
                {t('navigation/change-password')}
              </Button>
            </Col>
          </Row>
        )}
        <Row className="my-settings-container__row" style={{ border: 'none' }}>
          <Button notPristine={notPristine} onClick={handleAccountSettingUpdate}>
            {t('common/save')}
          </Button>
        </Row>
        <Modal
          heading={t('navigation/change-password')}
          modalBody={<ChangePassword changePassword={changePassword} dispatcher={dispatcher} errors={changePasswordErrors} successMessage={changePasswordSuccess} />}
          show={modalShow}
          onSave={handlePasswordChange}
          onHide={() => setModalShow(false)}
          className="language-selector"
        />
      </Container>
    </React.Fragment>
  );
};

MySettings.propTypes = {
  getAllTimezones: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updateUserSettings: PropTypes.func.isRequired,
  fetchPublications: PropTypes.func.isRequired,
  timezones: PropTypes.arrayOf(PropTypes.shape({})),
  timezonesCountry: PropTypes.arrayOf(PropTypes.shape({})),
  dateValues: PropTypes.arrayOf(PropTypes.shape({})),
  changePasswordErrors: PropTypes.arrayOf(PropTypes.shape({})),
  changePasswordSuccess: PropTypes.arrayOf(PropTypes.shape({})),
  publications: PropTypes.arrayOf(PropTypes.shape({})),
  updateSettingsSuccess: PropTypes.bool,
  userAccounDetails: PropTypes.shape({
    settings: PropTypes.shape({
      dateFormat: PropTypes.string,
      defaultLangCode: PropTypes.string,
      timezone: PropTypes.string
    }),
    firstName: PropTypes.trign,
    lastName: PropTypes.trign,
    email: PropTypes.trign,
    useSSO: PropTypes.bool
  })
};

MySettings.defaultProps = {};

const mapStateToProps = ({ myAccountState, adminState, userState }) => {
  const publications = get(adminState, 'publications', []);
  const formattedLanguageList = formatLanguages(publications);
  const userAccounDetails = get(userState, 'user.user', {});

  return {
    timezones: myAccountState.timezones,
    timezonesCountry: myAccountState.timezonesCountry,
    publications: formattedLanguageList,
    userAccounDetails,
    changePasswordErrors: myAccountState.errors.CHANGE_PASSWORD_FAILURE,
    updateSettingsSuccess: userState.updateSettingsSuccess,
    changePasswordSuccess: myAccountState.message.CHANGE_PASSWORD_SUCCESS
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllTimezones: bindActionCreators(getAllTimezones, dispatch),
    updatePassword: bindActionCreators(updatePassword, dispatch),
    updateUserSettings: bindActionCreators(updateUserSettings, dispatch),
    fetchPublications: bindActionCreators(fetchPublications, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MySettings));
