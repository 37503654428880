import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Modal from 'atoms/Modal';
import { fetcher } from 'hooks/useFetcher';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { ContentsPropTypesShape, LanguagesPropTypesShape } from 'utils/core-proptypes';
import ContentUserStatusUpdate from '../../../../ModalElements/ContentUserStatusUpdate';

const MarkContentComplete = ({ orgId, moduleId, contents, locales }) => {
  const t = useFormatMessage();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState({ show: false, data: {} });
  const [state, setState] = useState({ users: '', language: null, contentId: null });

  const handleClose = () => {
    setShow(false);
    setMessage({ show: false, data: {} });
    setState({});
  };

  const handleSave = async () => {
    const { users, language, contentId } = state;
    const usersEmail = users ? users.split(/\r?\n/).map(x => x.trim()) : [];
    try {
      setLoader(true);
      const payload = { language, usersEmail };
      const result = await fetcher(`/organizations/${orgId}/modules/${moduleId}/contents/${contentId}/complete`, 'POST', {}, JSON.stringify(payload));
      if (!result.errors) {
        setMessage({
          show: true,
          data: {
            successCount: get(result, 'success.length', 0),
            failedCount: get(result, 'failed.length', 0)
          }
        });
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <>
      <span onClick={() => setShow(true)} className="module-contents__status-completed">
        {t(`module-preference/change-user-status-to-completed`)}
      </span>
      <Modal
        modalBody={<ContentUserStatusUpdate state={state} setState={setState} locales={locales} message={message} contents={contents} onClose={handleClose} />}
        show={show}
        showFooter={false}
        showHeader={false}
        showSaveFooterDisabled={loader}
        showSaveFooter={!message.show}
        className={'module-contents__modal'}
        onSave={handleSave}
        onHide={handleClose}
      />
    </>
  );
};

MarkContentComplete.propTypes = {
  orgId: PropTypes.string.isRequired,
  moduleId: PropTypes.string,
  contents: ContentsPropTypesShape,
  locales: LanguagesPropTypesShape
};

export default MarkContentComplete;
