import React from 'react';
import PropTypes from 'prop-types';
import './radio.scss';

const RadioGroup = ({ children, onSelect, name, label, helpText, helpTextClassName }) => {
  const handleOnSelect = event => {
    const { value } = event.target;
    if (onSelect) onSelect({ value });
  };
  const childrenWithExtraProp = React.Children.map(children, child => {
    return React.cloneElement(child, {
      onChange: handleOnSelect,
      name
    });
  });
  return (
    <React.Fragment>
      <div>{label}</div>
      {helpText && <p className={helpTextClassName}>{helpText}</p>}
      {childrenWithExtraProp}
    </React.Fragment>
  );
};

RadioGroup.propTypes = {
  children: PropTypes.shape({}).isRequired,
  onSelect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  helpTextClassName: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string
};

RadioGroup.defaultProps = {
  label: '',
  helpText: null,
  helpTextClassName: ''
};
export default RadioGroup;
