import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';

import IconWrapper from 'atoms/IconWrapper';
import Modal from 'atoms/Modal';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { getMediaUrl, getFalsyValue } from 'utils/calculationUtilities';
import { HistoryPropTypesShape } from 'utils/core-proptypes';
import PageHeader from '../../PageHeader';
import VideoViewer from '../VideoView';
import { trackContentPresentation } from './actions';

import './presentation-view.scss';

const PresentationView = ({ history, moduleId, contentId, contentData, currentContent, contentSpecificPreferences, preferences, trackProgress, attemptId, trackingId, isPreview, ...props }) => {
  const t = useFormatMessage();
  const [showWarning, setShowWarning] = useState(false);
  const presentationPreferences = useMemo(() => contentSpecificPreferences || preferences, [contentSpecificPreferences, preferences]);
  const content = useMemo(() => (isEmpty(contentData.details) ? currentContent : contentData), [contentData, currentContent]);
  const media = useMemo(() => get(content, 'details.media', {}), [content]);

  useEffect(() => {
    if (!isPreview && !contentId && !moduleId) {
      history.replace('/home');
    }
  }, [isPreview, contentId, moduleId]);

  useEffect(() => {
    presentationStart();
  }, []);

  const redirectToContentList = () => {
    if (!props.isAuthenticated) {
      history.replace(`/content-list/${moduleId}?tid=${trackingId}`);
    } else {
      history.replace(`/content-list/${moduleId}`);
    }
  };

  const handleGoToModules = () => {
    if (getFalsyValue(presentationPreferences.askForConfirmation)) {
      setShowWarning(true);
    } else {
      const payload = { attemptId, event: 'finish' };
      !isPreview &&
        trackProgress({ moduleId, contentId, payload, isPublic: !props.isAuthenticated }).then(() => {
          redirectToContentList();
        });
    }
  };

  const handelPresentationModal = () => {
    const payload = { attemptId, event: 'finish' };
    !isPreview &&
      trackProgress({ moduleId, contentId, payload }).then(() => {
        redirectToContentList();
      });
    setShowWarning(false);
  };

  const presentationStart = () => {
    const payload = { attemptId, event: 'start' };
    !isPreview && trackProgress({ moduleId, contentId, payload });
  };

  const handleDownloadableClick = () => {
    window.open(getMediaUrl(media), '_blank');
  };

  return (
    <>
      <div className="presentation-view">
        <VideoViewer media={media} />
        {getFalsyValue(presentationPreferences.downloadable) && media && media.type === 'FILE' && (
          <Container className="presentation-view__downloadable-container">
            <IconWrapper type="Download" onClick={handleDownloadableClick} />
          </Container>
        )}
        <>
          <Container>
            <Row style={{ justifyContent: 'center' }}>
              <PageHeader variant="small" title={get(content, 'details.contentTitle', '')} desc={get(content, 'details.description', '')} />
              <Col lg={10} sm={12}>
                <Container className="p-0">
                  <div
                    className="presentation-view__rich-text"
                    dangerouslySetInnerHTML={{
                      __html: get(content, 'details.documentation', '')
                    }}
                  />
                </Container>
              </Col>
            </Row>
          </Container>

          {!isPreview ? (
            <div className="presentation-view__bottom-buttons">
              <IconWrapper type="GoToModules" alt="Go to Modules" onClick={() => handleGoToModules(moduleId)} className="action-icons" style={{ marginLeft: '22px' }} />
            </div>
          ) : (
            <div className="presentation-view__fake-space" />
          )}
          <Modal
            heading={t('common/modal/please-confirm-text')}
            modalBody={<p>{t('presentation-warning-text')}</p>}
            show={showWarning}
            onSave={handelPresentationModal}
            onHide={() => setShowWarning(false)}
            isAlert
            className="language-selector"
          />
        </>
      </div>
    </>
  );
};

PresentationView.propTypes = {
  isPreview: PropTypes.bool,
  moduleId: PropTypes.string,
  contentId: PropTypes.string,
  langCode: PropTypes.string,
  attemptId: PropTypes.string,
  trackingId: PropTypes.string,
  currentContent: PropTypes.shape({}),
  contentSpecificPreferences: PropTypes.shape({}),
  preferences: PropTypes.shape({ showContentInOrder: true }),
  contentData: PropTypes.shape({
    _id: PropTypes.string,
    details: PropTypes.shape({})
  }),
  trackProgress: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  history: HistoryPropTypesShape
};

PresentationView.defaultProps = {
  contentData: {
    details: {}
  },
  currentContent: null,
  isPreview: false,
  preferences: {}
};

const mapStateToProps = ({ userState, userActivityState }) => {
  const isAuthenticated = get(userState, 'user.user._id', false);
  const currentContent = userActivityState.currentContent && get(userActivityState, 'currentContent.content.data', {});
  const moduleId = get(userActivityState, 'currentModule._id', '');
  const contentId = get(userActivityState, 'currentContent.content.contentId', '');
  const contentSpecificPreferences = get(userActivityState, 'currentContent.content.data.preferences', {});
  const langCode = get(userActivityState, 'currentContent.content.langCode', '');
  const attemptId = get(userActivityState, 'currentContent.attemptId', '');
  const trackingId = get(userActivityState, 'currentContent.trackingId', '');
  return {
    isAuthenticated,
    currentContent,
    contentId,
    moduleId,
    attemptId,
    trackingId,
    langCode,
    contentSpecificPreferences
  };
};

const mapDispatchToProps = dispatch => {
  return {
    trackProgress: bindActionCreators(trackContentPresentation, dispatch)
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PresentationView));
