import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isEmpty } from 'lodash';

import IconWrapper from 'atoms/IconWrapper';
import Input from 'atoms/Input';
import Button from 'atoms/Button';
import Switch from 'atoms/Switch';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { updateAccountSsoConfig } from '../actions';
import './sso.scss';

const reducer = (state, action) => {
  const { key, value } = action;
  switch (action.type) {
    case 'inputChange':
      return {
        ...state,
        [key]: value,
        touched: true
      };
    case 'setStateAsUnTouched':
      return {
        ...state,
        touched: false
      };
    case 'initializeState': {
      const { enableAzureSync, ssoAppObjectId, tenantId, clientId, clientSecret } = action.payload;
      return {
        ...state,
        enableAzureSync,
        ssoAppObjectId,
        tenantId,
        clientId,
        clientSecret,
        touched: false
      };
    }
    default:
      throw new Error();
  }
};

const SsoSettings = ({ selectedOrg, selectedOrgDetails, hasSelectedOrgUpdated, updateAccountSsoConfig }) => {
  const t = useFormatMessage();
  const [state, dispatcher] = useReducer(reducer, { touched: false });
  const { enableAzureSync, ssoAppObjectId, tenantId, clientId, clientSecret } = state;

  useEffect(() => {
    if (hasSelectedOrgUpdated) {
      dispatcher({
        type: 'setStateAsUnTouched'
      });
    }
  }, [hasSelectedOrgUpdated]);

  useEffect(() => {
    if (!isEmpty(selectedOrgDetails)) {
      const { ssoConfig } = selectedOrgDetails;
      dispatcher({
        type: 'initializeState',
        payload: {
          enableAzureSync: ssoConfig.enableAzureSync,
          ssoAppObjectId: ssoConfig.azureSyncConfig?.ssoAppObjectId,
          tenantId: ssoConfig.azureSyncConfig?.tenantId,
          clientId: ssoConfig.azureSyncConfig?.clientId,
          clientSecret: ssoConfig.azureSyncConfig?.clientSecret
        }
      });
    }
  }, [selectedOrgDetails]);

  const handleUpdateSsoConfig = () => {
    updateAccountSsoConfig({
      organizationId: selectedOrg,
      payload: {
        enableAzureSync,
        azureSyncConfig: {
          ssoAppObjectId,
          tenantId,
          clientId,
          clientSecret
        }
      }
    });
  };

  if (!selectedOrgDetails.useSSO) {
    return (
      <Container className="sso-config-container" style={{ paddingTop: 20 }}>
        <Row>
          <div dangerouslySetInnerHTML={{ __html: t('account-settings/sso-not-enabled-message') }} />
        </Row>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <Container className="sso-config-container">
        <Row className="sso-config-container__row">
          <Col className="pl-0">{t('account-settings/id-provider')}</Col>
          <Col className="pr-0 sso-config-container__row__flex-end">{selectedOrgDetails.ssoConfig?.idp}</Col>
        </Row>

        {selectedOrgDetails.ssoConfig?.loginUrl && (
          <Row className="sso-config-container__row">
            <Col className="pl-0">{t('account-settings/login-url')}</Col>
            <Col className="pr-0 sso-config-container__row__flex-end sso-config-container__copy-to-clipboard" style={{ wordBreak: 'break-all' }}>
              <div className="login-url">{selectedOrgDetails.ssoConfig?.loginUrl}</div>
              <CopyToClipboard text={selectedOrgDetails.ssoConfig?.loginUrl}>
                <IconWrapper className="sso-config-container__row__flex-end__image" type="Clipboard" />
              </CopyToClipboard>
            </Col>
          </Row>
        )}

        {selectedOrgDetails.ssoConfig?.logoutUrl && (
          <Row className="sso-config-container__row">
            <Col className="pl-0">{t('account-settings/logout-url')}</Col>
            <Col className="pr-0 sso-config-container__row__flex-end sso-config-container__copy-to-clipboard" style={{ wordBreak: 'break-all' }}>
              <div className="logout-url">{selectedOrgDetails.ssoConfig?.logoutUrl}</div>
              <CopyToClipboard text={selectedOrgDetails.ssoConfig?.logoutUrl}>
                <IconWrapper className="sso-config-container__row__flex-end__image" type="Clipboard" />
              </CopyToClipboard>
            </Col>
          </Row>
        )}

        {selectedOrgDetails.ssoConfig?.certificate && (
          <Row className="sso-config-container__row">
            <Col className="pl-0">{t('account-settings/signing-certificate')}</Col>
            <Col className="pr-0 sso-config-container__row__flex-end sso-config-container__copy-to-clipboard">
              &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
              <CopyToClipboard text={selectedOrgDetails.ssoConfig?.certificate}>
                <IconWrapper className="sso-config-container__row__flex-end__image" type="Clipboard" />
              </CopyToClipboard>
            </Col>
          </Row>
        )}

        {selectedOrgDetails.ssoConfig?.idp === 'Azure' && (
          <>
            <Row className="sso-config-container__row">
              <Col className="pl-0">{t('account-settings/enable-azure-sync')}</Col>
              <Col className="pr-0 sso-config-container__row__flex-end">
                <Switch
                  id="enableAzureSync"
                  onSelect={() => {
                    dispatcher({
                      type: 'inputChange',
                      key: 'enableAzureSync',
                      value: enableAzureSync === 'YES' ? 'NO' : 'YES'
                    });
                  }}
                  value={enableAzureSync === 'YES'}
                />
              </Col>
            </Row>

            {enableAzureSync === 'YES' && (
              <>
                <Row className="sso-config-container__row">
                  <Col className="pl-0">{t('account-settings/sso-app-object-id')}</Col>
                  <Col className="pr-0 sso-config-container__row__flex-end">
                    <Input
                      onChange={e =>
                        dispatcher({
                          type: 'inputChange',
                          key: 'ssoAppObjectId',
                          value: e.target.value
                        })
                      }
                      value={ssoAppObjectId}
                    />
                  </Col>
                </Row>

                <Row className="sso-config-container__row">
                  <Col className="pl-0">{t('account-settings/tenant-id')}</Col>
                  <Col className="pr-0 sso-config-container__row__flex-end">
                    <Input
                      onChange={e =>
                        dispatcher({
                          type: 'inputChange',
                          key: 'tenantId',
                          value: e.target.value
                        })
                      }
                      value={tenantId}
                    />
                  </Col>
                </Row>

                <Row className="sso-config-container__row">
                  <Col className="pl-0">{t('account-settings/client-id')}</Col>
                  <Col className="pr-0 sso-config-container__row__flex-end">
                    <Input
                      onChange={e =>
                        dispatcher({
                          type: 'inputChange',
                          key: 'clientId',
                          value: e.target.value
                        })
                      }
                      value={clientId}
                    />
                  </Col>
                </Row>

                <Row className="sso-config-container__row">
                  <Col className="pl-0">{t('account-settings/client-secret')}</Col>
                  <Col className="pr-0 sso-config-container__row__flex-end">
                    <Input
                      onChange={e =>
                        dispatcher({
                          type: 'inputChange',
                          key: 'clientSecret',
                          value: e.target.value
                        })
                      }
                      value={clientSecret}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="sso-config-container__row__action">
              <div>
                <Button notPristine={!state.touched} onClick={handleUpdateSsoConfig} style={{ minWidth: '100px' }}>
                  {t('common/save')}
                </Button>
                <div className="sso-config-container__row__action--touched">{state.touched ? t('common/you-have-unsaved-changes') : ''}</div>
              </div>
            </Row>
          </>
        )}
      </Container>
    </React.Fragment>
  );
};

SsoSettings.propTypes = {
  selectedOrg: PropTypes.string.isRequired,
  updateAccountSsoConfig: PropTypes.func.isRequired,
  hasSelectedOrgUpdated: PropTypes.bool.isRequired,
  selectedOrgDetails: PropTypes.shape({
    code: PropTypes.string,
    settings: PropTypes.shape({}),
    useSSO: PropTypes.bool,
    ssoConfig: PropTypes.shape({
      idp: PropTypes.string,
      loginUrl: PropTypes.string,
      logoutUrl: PropTypes.string,
      certificate: PropTypes.string,
      enableAzureSync: PropTypes.string,
      azureSyncConfig: PropTypes.shape({
        ssoAppObjectId: PropTypes.string,
        tenantId: PropTypes.string,
        clientId: PropTypes.string,
        clientSecret: PropTypes.string
      })
    })
  })
};

SsoSettings.defaultProps = {
  hasSelectedOrgUpdated: false
};

const mapStateToProps = ({ accountSettings }) => {
  const { selectedOrgDetails, hasSelectedOrgUpdated } = accountSettings;
  return {
    selectedOrgDetails,
    hasSelectedOrgUpdated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAccountSsoConfig: bindActionCreators(updateAccountSsoConfig, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SsoSettings));
