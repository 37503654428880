import { getSearchStringFromObject } from 'hooks/useFetcher';
import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';

import { FETCH_CONTENT_START, FETCH_CONTENT_START_SUCCESS, FETCH_CONTENT_START_FAILURE, CLEAR_LANGUAGE_SELECTOR_ERROR } from 'utils/constants';

export const fetchContentStart = ({ moduleId, contentId, langCode, isPublic = false, trackingId }) => {
  const publicPath = `${BASE_URL}/start/public/module/${moduleId}/content/${contentId}${getSearchStringFromObject({ langCode, trackingId })}`;
  const privatePath = `${BASE_URL}/start/module/${moduleId}/content/${contentId}?langCode=${langCode}`;

  return {
    [RSAA]: {
      endpoint: isPublic ? publicPath : privatePath,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      types: [FETCH_CONTENT_START, FETCH_CONTENT_START_SUCCESS, FETCH_CONTENT_START_FAILURE]
    }
  };
};

export const clearLanguageSelectorErrors = () => ({
  type: CLEAR_LANGUAGE_SELECTOR_ERROR
});

export const dispatchCustomActionGlobalNotification = payload => {
  return { type: 'SET_CUSTOM_MESSAGE_FOR_ERROR', payload };
};
