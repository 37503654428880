import React from 'react';
import PropTypes from 'prop-types';
import { Container, Tabs, Tab } from 'react-bootstrap';

import { useFormatMessage } from 'hooks/useFormatMessage';
// import CompanyInfo from './CompanyInfo';
// import Activity from './Activity';
// import Languages from './Languages';
// import APIs from './APIs';
import EmailTemplateIds from './EmailTemplateId';

const SettingsDashboard = ({ ...props }) => {
  const t = useFormatMessage();
  return (
    <React.Fragment>
      <Container style={{ display: 'flex' }}>
        <Tabs id="app-settings-tab">
          {/* <Tab eventKey="app-company-info" title={t('app-settings/company-info')}>
            <CompanyInfo {...props} />
          </Tab>
          <Tab eventKey="app-activity" title={t('app-settings/activity')}>
            <Activity {...props} />
          </Tab>
          <Tab eventKey="app-languages" title={t('app-settings/languages')}>
            <Languages {...props} />
          </Tab> */}
          <Tab eventKey="app-template-ids" title={t('app-settings/e-mail-templates-ids')}>
            <EmailTemplateIds {...props} />
          </Tab>
          {/* <Tab eventKey="app-api" title={t('app-settings/apis')}>
            <APIs {...props} />
          </Tab> */}
        </Tabs>
      </Container>
    </React.Fragment>
  );
};

SettingsDashboard.propTypes = {
  role: PropTypes.string.isRequired
};

SettingsDashboard.defaultProps = {};

export default SettingsDashboard;
