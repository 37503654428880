import React, { useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Button, Row } from 'react-bootstrap';
import Input from 'atoms/Input';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import axios from 'axios';
import { BASE_URL, axiosConfig } from 'utils/api';
import Cookies from 'js-cookie';
import { useMediaQuery } from 'react-responsive';

import { reauthentication } from '../AuthRoute/actions';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { validatePassword } from 'utils/password-validation';
import { LocationPropTypesShape, HistoryPropTypesShape, ErrorsPropTypesShape, SuccessMessagePropTypesShape } from 'utils/core-proptypes';
import { parseQueryString } from 'utils/calculationUtilities';
import { signin, resetPassword, verifyResetToken } from './actions';
import { fetchPublications } from '../../actions';
import AppContext from '../../app-context';
import { COOKIE } from 'utils/constants';
import './sign-page.scss';

const reducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'handleChange':
      return { ...state, ...action.payload };
    case 'formStateChange':
      return { ...state, formState: payload.formStateType };
    case 'invalidConfirmPassword':
      return { ...state, invalidConfirmPassword: payload };
    case 'signInFormState':
      return { ...(payload === 'orgState' ? { formState: 'login' } : state), signInFormState: payload };
    default:
      throw new Error();
  }
};

const init = (path, token) => {
  let formState = 'login';
  if (path === '/reset-password') {
    formState = 'confirmReset';
  } else if (path === '/set-password') {
    formState = 'setPassword';
  }
  return {
    username: '',
    password: '',
    formState,
    signInFormState: token ? 'signInInitiated' : 'orgState'
  };
};

export const SignInComponent = ({ publications, fetchPublications, ...props }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 989px)' });
  const t = useFormatMessage();
  const context = useContext(AppContext);
  const { errors = [], message = {} } = props;
  const path = get(props, 'match.path', '');
  const { token } = parseQueryString(props.location);
  const [state, dispatcher] = useReducer(reducer, init(path, token));

  useEffect(() => {
    if (publications) {
      fetchPublications();
    }
  }, []);

  useEffect(() => {
    if (path === '/reset-password' || path === '/set-password') {
      const { token } = parseQueryString(props.location);
      props.verifyResetToken({
        resetToken: token
      });
    }
  }, []);

  useEffect(() => {
    if (path === '/') {
      const { token } = parseQueryString(props.location);
      if (token) {
        Cookies.set(COOKIE.TOKEN_NAME, token, { expires: 1 });
        props.reauthentication();
      }
    }
  }, []);

  useEffect(() => {
    if (path === '/signin') {
      const { org_code } = parseQueryString(props.location);
      if (org_code) {
        dispatcher({
          type: 'handleChange',
          payload: { code: org_code }
        });
        login(org_code);
      }
    }
  }, []);

  useEffect(() => {
    if (props.isTokenInvalid) {
      props.history.push('/signin');
      formStateChange('login');
    }
  }, [props.isTokenInvalid]);

  useEffect(() => {
    if (props.isAuthenticated) {
      props.history.push('/home');
    }
  }, [props.isAuthenticated]);

  const renderSuccess = ({ message, successKey }) => {
    const successMessage = get(message, successKey, {});
    return !isEmpty(successMessage) && <p className="login-wrapper__success">{t(`login/${successMessage.code}`, {}, successMessage.userMessage)}</p>;
  };

  const renderErrors = ({ errors, errorKey, errorsArr = [] }) => {
    const list = get(errors, errorKey, errorsArr);
    return (
      list &&
      list.map(error => (
        <p key={error.userMessage} className="login-wrapper__error">
          {t(`login/${error.code}`, {}, error.userMessage)}
        </p>
      ))
    );
  };

  const handleChange = event => {
    dispatcher({
      type: 'handleChange',
      payload: { [event.target.id]: event.target.value }
    });
  };

  const handleResetPassword = e => {
    e.preventDefault();
    const email = state.usernameReset;
    props.resetPassword({ email, organizationId: state.organizationId });
  };

  const handleUpdatePassword = async e => {
    e.preventDefault();
    const { passwordReset, passwordResetConfirm } = state;
    if (passwordResetConfirm !== passwordReset || !passwordResetConfirm) {
      dispatcher({
        type: 'invalidConfirmPassword',
        payload: 'reset/password-mismatch-message'
      });
    } else if (!validatePassword(passwordReset)) {
      dispatcher({
        type: 'invalidConfirmPassword',
        payload: 'reset/password-validation-message'
      });
    } else {
      dispatcher({ type: 'invalidConfirmPassword', payload: '' });

      const query = queryString.parse(get(props, 'location.search', ''));
      const endpoint = `${BASE_URL}/auth/updatePassword`;
      const response = await axios.post(endpoint, { password: passwordResetConfirm, resetToken: query.token }, axiosConfig('application/json'));
      if (response?.data?.user) {
        const { email, organizationId } = response.data.user;
        props.signin({
          email,
          password: passwordResetConfirm,
          organizationId
        });
      } else if (response?.data?.errors) {
        dispatcher({
          type: 'handleChange',
          payload: { errors: response.data.errors }
        });
      }
    }
  };

  const login = async org_code => {
    if (state.signInFormState === 'orgState') {
      dispatcher({
        type: 'signInFormState',
        payload: 'siginin'
      });
      const endpoint = `${BASE_URL}/auth/init`;
      const response = await axios.post(endpoint, { code: org_code || state.code }, axiosConfig('application/json'));
      if (response?.data?.useSSO) {
        dispatcher({
          type: 'signInFormState',
          payload: 'signInInitiated'
        });
        window.location = response.data.ssoURL;
      } else if (response?.data.organizationId) {
        dispatcher({
          type: 'signInFormState',
          payload: 'signin'
        });
        dispatcher({
          type: 'handleChange',
          payload: { ...response.data, errors: [] }
        });
      } else {
        dispatcher({
          type: 'handleChange',
          payload: { ...response.data, signInFormState: 'orgState' }
        });
      }
    }
    if (state.signInFormState === 'signin') {
      const { email = '', password, organizationId } = state;
      props.signin({ email: email.trim(), password, organizationId });
    }
  };

  const formStateChange = formStateType => {
    dispatcher({ type: 'formStateChange', payload: { formStateType } });
  };

  const renderResetForm = () => {
    return (
      <form className="login-wrapper__form" onSubmit={e => handleResetPassword(e)}>
        <h2 className="login-wrapper__header">{t('login/reset-password')}</h2>
        <p className="login-wrapper__desc">{t('login/to-reset-your-password-please-type-your-username')}</p>
        {renderErrors({ errors, errorKey: 'RESET_PASSWORD_FAILURE' })}
        {renderSuccess({ message, successKey: 'RESET_PASSWORD_SUCCESS' })}
        <input type="text" name="username" id="usernameReset" placeholder={t('placeholder/username')} value={state.usernameReset} onChange={handleChange} className="login-wrapper__login-form" />
        <br />
        <Button variant="link" onClick={() => formStateChange('login')} type="button" className="login-wrapper__return-to-sign-in-btn pl-0 pr-0 pt-3">
          {t('login/return-to-sign-in')}
        </Button>
        <button className="login-wrapper__login-btn">{t('common/submit')}</button>
      </form>
    );
  };

  const renderSetOrConfirmResetForm = formType => {
    const isSettingPassword = formType === 'set';
    return (
      props.hasVerifiedToken && (
        <form className="login-wrapper__form" onSubmit={e => handleUpdatePassword(e)}>
          <h2 className="login-wrapper__header">{isSettingPassword ? t('login/pick-a-password') : t('login/reset-password')}</h2>
          <p className="">{t('login/password-validation-message')}</p>
          {renderErrors({ errors, errorsArr: state.errors, errorKey: 'UPDATE_PASSWORD_FAILURE' })}
          <input
            type="password"
            name="passwordReset"
            id="passwordReset"
            value={state.passwordReset}
            placeholder={t('placeholder/new-password')}
            onChange={handleChange}
            className="login-wrapper__login-form"
          />
          <br />
          <input
            type="password"
            name="passwordResetConfirm"
            placeholder={t('placeholder/confirm-password')}
            id="passwordResetConfirm"
            onChange={handleChange}
            value={state.passwordResetConfirm}
            className="login-wrapper__login-form login-wrapper__login-form-confirm-password "
          />
          <br />
          {state.invalidConfirmPassword && <p className="login-wrapper__error">{t(state.invalidConfirmPassword)}</p>}
          <br />
          <button className="login-wrapper__login-btn">{isSettingPassword ? t('login/set-password-and-sign-in') : t('login/reset-password-and-sign-in')}</button>
        </form>
      )
    );
  };
  const renderLoginForm = () => {
    const list = get(errors, 'SIGNIN_USER_FAILURE');
    const isInvalidToken = list && list[0]?.code === 'INVALID_TOKEN';
    return (
      <div className="login-wrapper__form">
        {renderErrors({ errors: errors, errorsArr: state.errors, errorKey: 'SIGNIN_USER_FAILURE' })}
        {state.signInFormState === 'orgState' && (
          <Input
            onKeyPress={e => {
              if (e.key === 'Enter') {
                login();
              }
            }}
            style={{ height: '50px' }}
            placeholder={t('login/organization-code')}
            onChange={event =>
              dispatcher({
                type: 'handleChange',
                payload: { code: event.target.value }
              })
            }
            className={'login-wrapper__login-form'}
            value={state.code}
          />
        )}
        {state.signInFormState === 'signInInitiated' && isInvalidToken && (
          <Button style={{ paddingLeft: 0 }} className="login-wrapper__form__button-help" variant="link" onClick={() => formStateChange('reset')} type="button">
            {t('login/forgot-your-password')}
          </Button>
        )}
        {state.signInFormState === 'signInInitiated' && !isInvalidToken && <p>Please wait while we redirect you to your organization login page</p>}
        {state.signInFormState === 'signin' && (
          <>
            <Input
              placeholder={t('placeholder/username')}
              onChange={event =>
                dispatcher({
                  type: 'handleChange',
                  payload: { email: event.target.value }
                })
              }
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  login();
                }
              }}
              className={'login-wrapper__login-form'}
              style={{ height: '50px' }}
              value={state.email}
            />
            <Input
              type="password"
              style={{ marginTop: isMobile ? '10px' : '20px', height: '50px' }}
              placeholder={t('placeholder/password')}
              onChange={event =>
                dispatcher({
                  type: 'handleChange',
                  payload: { password: event.target.value }
                })
              }
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  login();
                }
              }}
              className={'login-wrapper__login-form'}
              value={state.password}
            />
            <br />
            <Row style={{ justifyContent: 'space-between', height: '20px' }}>
              <Button
                variant="link"
                className="login-wrapper__form__button-help"
                style={{ marginLeft: '5px', paddingRight: 0 }}
                onClick={() => {
                  dispatcher({
                    type: 'signInFormState',
                    payload: 'orgState'
                  });
                }}
                type="button"
              >
                {t('login/back')}
              </Button>
              <Button style={{ paddingLeft: 0 }} className="login-wrapper__form__button-help" variant="link" onClick={() => formStateChange('reset')} type="button">
                {t('login/forgot-your-password')}
              </Button>
            </Row>
          </>
        )}
        <br />

        {state.signInFormState === 'orgState' && (
          <button
            className="login-wrapper__next-btn"
            onClick={() => {
              login();
            }}
          >
            {t('common/next')}
          </button>
        )}
        {state.signInFormState === 'signin' && (
          <button
            className="login-wrapper__next-btn"
            onClick={() => {
              login();
            }}
          >
            {t('login/sign-in')}
          </button>
        )}
      </div>
    );
  };

  const renderForm = () => {
    const { formState } = state;
    if (formState === 'login') {
      return renderLoginForm();
    }
    if (formState === 'reset') {
      return renderResetForm();
    } else if (formState === 'confirmReset') {
      return renderSetOrConfirmResetForm('reset');
    } else if (formState === 'setPassword') {
      return renderSetOrConfirmResetForm('set');
    }
    return null;
  };

  const faqHref = `https://mindzeed.com/${context.language}/faq`;
  return (
    <Container className="container-front" fluid>
      <Container className="container-front__row h-100 m-0 p-0">
        <Container className="col-sm-12 align-self-center p-0">
          <div className="mx-auto  login-wrapper">
            <div className={state?.organizationName ? 'login-wrapper__logo' : ''}>{state?.organizationName}</div>
            {renderForm()}
          </div>
          <div className="mx-auto language-wrapper">
            {publications
              .filter(lang => lang.langCode !== context.language)
              .map(lang => {
                return (
                  <label
                    key={lang._id}
                    id={lang.langCode}
                    onClick={e => {
                      context.setContextState({ language: e.target.id });
                    }}
                  >
                    {lang.language}
                  </label>
                );
              })}
          </div>
          <div className="mx-auto faq-help">
            <a href={faqHref}>{t('login/click-here-if-you-need-help')}</a>
          </div>
        </Container>
      </Container>
    </Container>
  );
};

SignInComponent.propTypes = {
  isTokenInvalid: PropTypes.bool.isRequired,
  hasVerifiedToken: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  signin: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  verifyResetToken: PropTypes.func.isRequired,
  history: HistoryPropTypesShape,
  location: LocationPropTypesShape,
  fetchPublications: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(ErrorsPropTypesShape),
  message: PropTypes.shape(SuccessMessagePropTypesShape),
  successKey: PropTypes.shape(SuccessMessagePropTypesShape),
  publications: PropTypes.arrayOf(PropTypes.shape({})),
  reauthentication: PropTypes.func.isRequired
};

SignInComponent.defaultProps = {
  history: null,
  isAuthenticated: false,
  isTokenInvalid: false,
  hasVerifiedToken: false
};

const mapStateToProps = ({ userState, adminState }) => {
  const isAuthenticated = get(userState, 'user.user._id', false);
  const errors = get(userState, 'errors', []);
  const message = get(userState, 'message', {});
  const publications = get(adminState, 'publications', []);

  return {
    isAuthenticated,
    errors,
    message,
    hasVerifiedToken: userState.hasVerifiedToken,
    isTokenInvalid: userState.isTokenInvalid,
    publications
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signin: bindActionCreators(signin, dispatch),
    resetPassword: bindActionCreators(resetPassword, dispatch),
    verifyResetToken: bindActionCreators(verifyResetToken, dispatch),
    fetchPublications: bindActionCreators(fetchPublications, dispatch),
    reauthentication: bindActionCreators(reauthentication, dispatch)
  };
};

const SignInPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInComponent));

export default SignInPage;
