import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup } from 'atoms/Radio';
import { Row, Col } from 'react-bootstrap';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { mcqContentAdditionalPrefs, mcqPreferenceConstantToKeyMap } from 'utils/types';
import Switch from 'atoms/Switch';

import { useFormatMessage } from 'hooks/useFormatMessage';
import { MCQPreferencesPropTypesShape } from 'utils/core-proptypes';
import './modal-elements.scss';

const ModuleSpecificMCQPreference = ({ dispatcher, modalPreferenceData }) => {
  const t = useFormatMessage();
  return (
    <div>
      <Row>
        <Col className="content-preference__row-left-border" style={{ paddingBottom: 0, paddingTop: 0 }}>
          <div className="content-preference__pass-slider__title">{t('common-content-preference/passing-score')}</div>
          <div className="content-preference__pass-slider">
            <Row>
              <Col>
                <Slider
                  className="content-preference__pass-slider__selector"
                  min={0}
                  max={100}
                  value={modalPreferenceData.passPercent}
                  onChange={value => {
                    dispatcher({
                      type: 'modalPreferenceSlider',
                      key: 'passPercent',
                      value
                    });
                  }}
                />
              </Col>
              <Col>
                <div className="content-preference__pass-slider__progress">{modalPreferenceData.passPercent}%</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="content-preference__row-left-border" style={{ paddingBottom: 0 }}>
          <div className="content-preference__pass-slider__title">{t('common-content-preference/no-of-questions-to-display')}</div>
          <div className="content-preference__pass-slider">
            <Row>
              <Col>
                <Slider
                  className="content-preference__pass-slider__selector"
                  min={1}
                  max={50}
                  value={modalPreferenceData.noOfQuestionsToDisplay}
                  onChange={value => {
                    dispatcher({
                      type: 'modalPreferenceSlider',
                      key: 'noOfQuestionsToDisplay',
                      value
                    });
                  }}
                />
              </Col>
              <Col>
                <div className="content-preference__pass-slider__progress">{modalPreferenceData.noOfQuestionsToDisplay}</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="content-preference__row-left-border">
          <RadioGroup
            label={t('common-content-preference/feedback')}
            name="common-content-feedback"
            onSelect={({ value }) =>
              dispatcher({
                type: 'modalPreferenceInputRadio',
                key: 'showExplanation',
                value
              })
            }
          >
            <Radio value="SHOW_EXP" checked={modalPreferenceData.showExplanation === 'SHOW_EXP'} label={t('common-content-preference/show-feedback')} />
            <Radio value="SHOW_EXP_WRONG" checked={modalPreferenceData.showExplanation === 'SHOW_EXP_WRONG'} label={t('common-content-preference/show-feedback-if-answer-is-wrong')} />
            <Radio value="SHOW_EXP_RIGHT" checked={modalPreferenceData.showExplanation === 'SHOW_EXP_RIGHT'} label={t('common-content-preference/show-feedback-if-answer-is-correct')} />
            <Radio value="DO_NOT_SHOW" checked={modalPreferenceData.showExplanation === 'DO_NOT_SHOW'} label={t('common-content-preference/do-not-show-feedback')} />
          </RadioGroup>
        </Col>
      </Row>

      <Row>
        <Col className="content-preference__row-left-border">
          <RadioGroup
            label={t('common-content-preference/retries')}
            name="common-content-attempts"
            onSelect={({ value }) =>
              dispatcher({
                type: 'modalPreferenceInputRadio',
                key: 'noOfTestAttempts',
                value
              })
            }
          >
            <Radio value="ONE" defaultChecked="true" checked={modalPreferenceData.noOfTestAttempts === 'ONE'} label={t('common/one')} />
            <Radio value="ONE_IN_24" checked={modalPreferenceData.noOfTestAttempts === 'ONE_IN_24'} label={t('common/one-every-24-hours')} />
            <Radio value="UNLIMITED" checked={modalPreferenceData.noOfTestAttempts === 'UNLIMITED'} label={t('common/unlimited')} />
          </RadioGroup>
        </Col>
      </Row>
      <Col className="content-preference__body__thumbnail-container" style={{ padding: 0 }}>
        {Object.keys(mcqContentAdditionalPrefs).map(pref => (
          <Row key={pref} className="content-preference__row" style={{ height: '50px', padding: '5px 0' }}>
            <div style={{ width: '80%' }} className="content-preference__row__switch-label">
              {t(mcqContentAdditionalPrefs[pref])}
            </div>
            <div className="content-preference__row__switch" style={{ marginTop: '7px', width: '20%' }}>
              <Switch
                id={pref}
                onSelect={() => {
                  let value;
                  if (pref === 'SHOW_BLOCK_ONE_BY_ONE') {
                    value = modalPreferenceData[mcqPreferenceConstantToKeyMap[pref]] === 'ONE_BY_ONE' ? 'ALL' : 'ONE_BY_ONE';
                  } else {
                    value = modalPreferenceData[mcqPreferenceConstantToKeyMap[pref]] === 'false' ? 'true' : 'false';
                  }
                  dispatcher({
                    type: 'modalPreferenceInputRadio',
                    key: mcqPreferenceConstantToKeyMap[pref],
                    value
                  });
                }}
                value={
                  pref === 'SHOW_BLOCK_ONE_BY_ONE' ? modalPreferenceData[mcqPreferenceConstantToKeyMap[pref]] === 'ONE_BY_ONE' : !(modalPreferenceData[mcqPreferenceConstantToKeyMap[pref]] === 'false')
                }
              />
            </div>
          </Row>
        ))}
      </Col>
    </div>
  );
};

ModuleSpecificMCQPreference.propTypes = {
  modalPreferenceData: MCQPreferencesPropTypesShape,
  dispatcher: PropTypes.func.isRequired
};
ModuleSpecificMCQPreference.defaultProps = {
  modalPreferenceData: {}
};
export default ModuleSpecificMCQPreference;
