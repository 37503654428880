import React from 'react';
import PropTypes from 'prop-types';

import VideoViewer from '../VideoView';
import './story-card.scss';

const StoryCard = ({ storyData, index }) => {
  const { heading, documentation, media, textColor, backgroundColor } = storyData;
  return (
    <div className="story-card fade-in-2s" style={{ color: textColor, backgroundColor, marginTop: index === 0 ? '70px' : '30px' }}>
      <span className="story-card__index-circle">{index + 1}</span>
      <VideoViewer media={media} showHideButton={false} fullWidth={true} />
      <h4 style={{ paddingTop: media ? '50px' : 0 }}>{heading}</h4>
      <div
        className="story-card__rich-text"
        dangerouslySetInnerHTML={{
          __html: documentation
        }}
      />
    </div>
  );
};

StoryCard.propTypes = {
  storyData: PropTypes.shape({
    _id: PropTypes.string,
    media: PropTypes.shape({}),
    heading: PropTypes.string,
    documentation: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string
  }),
  isPreview: PropTypes.bool,
  storyCardRefs: PropTypes.shape([]),
  index: PropTypes.number
};

StoryCard.defaultProps = {
  storyData: {},
  isPreview: false
};

export default StoryCard;
