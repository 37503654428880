import React from 'react';
import PropTypes from 'prop-types';
import { useFormatMessage } from 'hooks/useFormatMessage';
import ProgressLine from 'atoms/ProgressLine';

const MostActiveUserGroups = ({ topGroups = [] }) => {
  const t = useFormatMessage();

  const maxAttempts = (topGroups[0] && topGroups[0].noOfAttempts) || 1;
  return (
    <div className="stats-box-large bg-dark-blue">
      <div className="box-large-content-1 white-txt">
        <div className="box-content-head span3">{t('user-activity/most-active-usergroups')}</div>
        {!topGroups.length
          ? t('user-activity/no-data-available')
          : topGroups.map(group => (
              <div key={group.groupId}>
                <p className="progress-small-text">{`${group.groupName}`}</p>
                <ProgressLine classname="stat-box-progress-bg" visualBarClassname="stat-box-progress" percentage={(group.noOfAttempts / maxAttempts) * 100} />
              </div>
            ))}
      </div>
    </div>
  );
};

MostActiveUserGroups.propTypes = {
  topGroups: PropTypes.arrayOf(PropTypes.object)
};
export default MostActiveUserGroups;
