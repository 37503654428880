import React from 'react';
import PropTypes from 'prop-types';
import IconWrapper from 'atoms/IconWrapper';
import { useFormatMessage } from 'hooks/useFormatMessage';
import './response.scss';

const Response = ({ isCorrect }) => {
  const t = useFormatMessage();

  return (
    <div className="response">
      {isCorrect ? <IconWrapper type="Right" alt="The Answer is Correct" /> : <IconWrapper type="Wrong" alt="Unfortunately, The Answer is Wrong" />}
      <p>{isCorrect ? t('common/correct') : t('common/incorrect')}</p>
    </div>
  );
};

Response.propTypes = {
  isCorrect: PropTypes.bool.isRequired
};

Response.defaultProps = {};

export default Response;
