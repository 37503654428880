import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import ColorTile from 'atoms/ColorTile';
import Input from 'atoms/Input';
import Feedback from 'atoms/Feedback';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { ErrorsPropTypesShape } from 'utils/core-proptypes';

const EditGroupModalBody = ({ group, dispatcher, colorList, error }) => {
  const t = useFormatMessage();

  const { name, description, colorCode } = group;
  return (
    <Form>
      <Form.Group>
        <Form.Label>{t('common/modal-body/group-name')}</Form.Label>
        <Input
          onChange={e =>
            dispatcher({
              type: 'inputChange',
              key: 'name',
              value: e.target.value
            })
          }
          value={name}
          type="gname"
          placeholder={t('common/modal-body/group-name')}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('common/description')}</Form.Label>
        <Input
          as="textarea"
          value={description}
          onChange={e =>
            dispatcher({
              type: 'inputChange',
              key: 'description',
              value: e.target.value
            })
          }
          placeholder={t('common/description')}
          rows="3"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('common/modal-body/color-tag')}</Form.Label>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {colorList.map(color => {
            return (
              <ColorTile
                key={color}
                onClick={e =>
                  dispatcher({
                    type: 'colorTileChange',
                    key: 'colorCode',
                    value: e.target.id
                  })
                }
                isSelect={colorCode === color}
                color={color}
              />
            );
          })}
        </div>
      </Form.Group>
      <Feedback errorList={error} allowedTypesKey="AddGroupEditGroup" />
    </Form>
  );
};

EditGroupModalBody.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    colorCode: PropTypes.string.isRequired
  }),
  colorList: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.arrayOf(ErrorsPropTypesShape),
  dispatcher: PropTypes.func.isRequired
};

EditGroupModalBody.defaultProps = {
  group: {},
  colorList: [],
  error: []
};

export default EditGroupModalBody;
