import da from './da/da.json';
import de from './de/de.json';
import en from './en/en.json';
import nl from './nl/nl.json';
import no from './no/no.json';
import sv from './sv/sv.json';
import kl from './kl/kl.json';
import fi from './fi/fi.json';

export default {
  da,
  de,
  en,
  nl,
  no,
  sv,
  kl,
  fi
};
