import React, { useState, useEffect, useMemo, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Container, Row, Col } from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import { Link, Element } from 'react-scroll';
import { InView } from 'react-intersection-observer';

import CustomCarousel from 'atoms/Carousel';
import CarouselCard from '../CarouselView/CarouselCard';
import IconWrapper from 'atoms/IconWrapper';
import Modal from 'atoms/Modal';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { getFalsyValue } from 'utils/calculationUtilities';
import { HistoryPropTypesShape } from 'utils/core-proptypes';
import StoryCard from './StoryCard';
import { trackContentStory } from './actions';

import './story-view.scss';

const StoryView = ({ contentId, moduleId, contentData, currentContent, contentSpecificPreferences, history, trackContentStory, attemptId, trackingId, isPreview, ...props }) => {
  const t = useFormatMessage();
  const [visitedPages, setVisitedPages] = useState(new Set([0]));
  const storySideBar = useRef(null);
  const pageNames = useRef([]);
  const isMobile = useMediaQuery({ query: '(max-width: 989px)' });
  const [storyWarningShow, setStoryWarningShow] = useState(false);
  const { askForConfirmation } = contentSpecificPreferences || props.preferences;

  const storyPages = useMemo(() => {
    const content = isEmpty(contentData.details) ? currentContent : contentData;
    return get(content, 'storyPages', []);
  }, [contentData, currentContent]);

  const contentTitle = isEmpty(contentData.details) ? get(currentContent, 'details.contentTitle', '') : get(contentData, 'details.contentTitle', '');

  const redirectToContentList = () => {
    if (!props.isAuthenticated) {
      history.replace(`/content-list/${moduleId}?tid=${trackingId}`);
    } else {
      history.replace(`/content-list/${moduleId}`);
    }
  };

  const handleGoToModules = () => {
    if (getFalsyValue(askForConfirmation)) {
      setStoryWarningShow(true);
    } else {
      redirectToContentList();
    }
  };

  const handleStoryModal = () => {
    setStoryWarningShow(false);
    redirectToContentList();
  };

  const handleSlideChange = slideIndex => {
    setVisitedPages(new Set(visitedPages).add(slideIndex));
    if (!isPreview) {
      const storyId = storyPages[slideIndex]._id;
      trackContentStory({ moduleId, contentId, storyId, payload: { attemptId, page: slideIndex }, isPublic: !props.isAuthenticated });
    }
  };

  useEffect(() => {
    if (!isPreview && !contentId && !moduleId) {
      history.replace('/home');
    }
  }, [isPreview, contentId, moduleId]);

  useEffect(() => {
    if (!isPreview) {
      const storyId = storyPages[0] && storyPages[0]._id;
      if (storyId) trackContentStory({ moduleId, contentId, storyId, payload: { attemptId, page: 0 }, isPublic: !props.isAuthenticated });
    }
  }, []);

  useLayoutEffect(() => {
    if (storySideBar.current) {
      const { top } = storySideBar.current.getBoundingClientRect();
      storySideBar.current.style.height = `calc(100vh - ${top}px)`;
    }
  }, []);

  useEffect(() => {
    pageNames.current = [...storyPages].fill(null, 0, storyPages.length);
  }, [storyPages.length]);

  return (
    <>
      <div className="story-view">
        <Container className="p-0">
          {!isMobile ? (
            <Row>
              <div className="story-view__sidepane" style={{ position: 'fixed', width: '200px' }}>
                <div className="m-0">
                  <IconWrapper type="LeftArrow" alt="Go back" onClick={() => handleGoToModules(moduleId)} className="story-view__sidepane__go-back" />
                </div>
                <p>{contentTitle}</p>
                <div ref={storySideBar} className="story-view__page-name-scroll" style={{ overflowY: 'scroll', paddingBottom: '50px' }}>
                  {storyPages.map((item, index) => (
                    <Row className="m-0" key={item.heading}>
                      <Link
                        className="linkItem"
                        activeClass="active"
                        onSetActive={() => {
                          if (storySideBar.current) {
                            const { top: pageNameTop } = pageNames.current[index].getBoundingClientRect();
                            const { top: sidebarTop, bottom: sidebarBottom } = storySideBar.current.getBoundingClientRect();
                            if (pageNames.current[index] && (pageNameTop < sidebarTop || pageNameTop + 50 > sidebarBottom)) {
                              pageNames.current[index].scrollIntoView();
                            }
                          }
                        }}
                        to={item._id}
                        spy={true}
                        smooth={true}
                        offset={-170}
                        duration={600}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                      >
                        <span
                          ref={el => {
                            pageNames.current[index] = el;
                          }}
                          style={{ display: 'inline-block', width: '20px' }}
                        >
                          {index + 1}
                        </span>
                        {item.heading}
                      </Link>
                    </Row>
                  ))}
                </div>
              </div>
              <Col style={{ marginLeft: '200px' }}>
                {storyPages.map((item, index) => (
                  <Element key={item._id} name={item._id} className={item._id}>
                    <InView
                      as="div"
                      triggerOnce
                      delay={100}
                      onChange={inView => {
                        if (inView && index !== 0) {
                          handleSlideChange(index);
                        }
                      }}
                    >
                      <StoryCard index={index} storyData={item} />
                    </InView>
                  </Element>
                ))}
                {!isPreview && (
                  <div className="justify-content-center">
                    <IconWrapper type="GoToModules" alt="Go to Modules" onClick={() => handleGoToModules(moduleId)} className="action-icons mt-4 mb-4" style={{ marginLeft: '22px' }} />
                  </div>
                )}
              </Col>
            </Row>
          ) : (
            <>
              <CustomCarousel onChangeSlide={handleSlideChange} enableSlideNextPrev={true} enableNextPrevIcons={false}>
                {storyPages.map(item => (
                  <CarouselCard key={item._id} carouselData={item} />
                ))}
              </CustomCarousel>
              {!isPreview && (
                <div className="justify-content-center">
                  <IconWrapper type="GoToModules" alt="Go to Modules" onClick={handleGoToModules} className="action-icons mt-4 mb-4" style={{ marginLeft: '22px' }} />
                </div>
              )}
            </>
          )}
        </Container>
        <Modal
          heading={t('common/modal/please-confirm-text')}
          modalBody={<p>{t('story-warning-text')}</p>}
          show={storyWarningShow}
          onSave={handleStoryModal}
          onHide={() => setStoryWarningShow(false)}
          isAlert
          className="language-selector"
        />
      </div>
    </>
  );
};

StoryView.propTypes = {
  contentData: PropTypes.shape({
    _id: PropTypes.string,
    details: PropTypes.shape({})
  }),
  history: HistoryPropTypesShape,
  contentSpecificPreferences: PropTypes.shape({}),
  currentContent: PropTypes.shape({}),
  isPreview: PropTypes.bool,
  contentId: PropTypes.string,
  moduleId: PropTypes.string,
  preferences: PropTypes.shape({
    showContentInOrder: true
  }),
  trackContentStory: PropTypes.func.isRequired,
  langCode: PropTypes.string,
  attemptId: PropTypes.string,
  trackingId: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired
};

StoryView.defaultProps = {
  contentData: {
    details: {}
  },
  currentContent: null,
  isPreview: false,
  preferences: {}
};

const mapStateToProps = ({ userState, userActivityState }) => {
  const isAuthenticated = get(userState, 'user.user._id', false);
  const currentContent = userActivityState.currentContent && get(userActivityState, 'currentContent.content.data', {});
  const moduleId = get(userActivityState, 'currentModule._id', '');
  const contentId = get(userActivityState, 'currentContent.content.contentId', '');
  const contentSpecificPreferences = get(userActivityState, 'currentContent.content.data.preferences', {});
  const langCode = get(userActivityState, 'currentContent.content.langCode', '');
  const attemptId = get(userActivityState, 'currentContent.attemptId', '');
  const trackingId = get(userActivityState, 'currentContent.trackingId', '');

  return {
    isAuthenticated,
    currentContent,
    contentId,
    moduleId,
    attemptId,
    trackingId,
    langCode,
    contentSpecificPreferences
  };
};

const mapDispatchToProps = dispatch => {
  return {
    trackContentStory: bindActionCreators(trackContentStory, dispatch)
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StoryView));
