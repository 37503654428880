import React from 'react';
import uuidv4 from 'uuid/v4';
import { isEmpty } from 'lodash';

import MCQView from '../components/AttemptsAndPreview/McqView';
import PresentationView from '../components/AttemptsAndPreview/PresentationView';
import CarouselView from '../components/AttemptsAndPreview/CarouselView';
import StoryView from '../components/AttemptsAndPreview/StoryView';
import MixedView from '../components/AttemptsAndPreview/MixedView';

export const renderContentTypePreviewContentPage = ({ state, contentType, preferences }) => {
  switch (contentType) {
    case 'mcq':
    case 'MCQ': {
      return <MCQView contentData={createPreviewMcqContentData({ ...state })} preferences={preferences} isPreview />;
    }
    case 'presentation':
    case 'PRESENTATION': {
      return <PresentationView contentData={createPreviewPresentationContentData({ ...state })} preferences={preferences} isPreview />;
    }
    case 'carousel':
    case 'CAROUSEL': {
      return <CarouselView contentData={createPreviewCarouselContentData({ ...state })} preferences={preferences} isPreview />;
    }
    case 'story':
    case 'STORY': {
      return <StoryView contentData={createPreviewStoryContentData({ ...state })} preferences={preferences} isPreview />;
    }
    case 'mixed':
    case 'MIXED': {
      return <MixedView contentData={createPreviewMixedContentData({ ...state })} preferences={preferences} isPreview />;
    }
    default:
      break;
  }
};

const createPreviewMcqContentData = state => {
  return {
    _id: uuidv4(),
    details: {
      contentTitle: state.contentTitle,
      description: state.description,
      media: state.media
    },
    mcqList: createPreviewMcqList(state.mcqList)
  };
};

const createPreviewMcqList = mcqList => {
  return mcqList.map(mcq => {
    const { question, explanation, status = 'ACTIVE', media } = mcq;
    const { options, correct } = createOptions(mcq.options, mcq.correct);
    return {
      _id: uuidv4(),
      question,
      options,
      correct,
      explanation,
      status,
      media
    };
  });
};

const createOptions = (optionsReq = [], correctReq) => {
  let correct = null;
  const options = {};
  for (let index = 0; index < optionsReq.length; index += 1) {
    const option = optionsReq[index];
    const uuid = uuidv4();
    if (index === correctReq) {
      correct = uuid;
    }
    if (index === correctReq || !isEmpty(option)) {
      options[uuid] = option;
    }
  }
  return {
    options,
    correct
  };
};

const createPreviewPresentationContentData = state => {
  return {
    _id: uuidv4(),
    details: {
      contentTitle: state.contentTitle,
      description: state.description,
      documentation: state.documentation,
      media: state.media
    }
  };
};

const createPreviewCarouselContentData = state => {
  return {
    _id: uuidv4(),
    details: {
      contentTitle: state.contentTitle,
      description: state.description
    },
    carouselPages: Object.values(state.carouselPages)
  };
};

const createPreviewStoryContentData = state => {
  return {
    _id: uuidv4(),
    details: {
      contentTitle: state.contentTitle,
      description: state.description
    },
    storyPages: Object.values(state.storyPages)
  };
};

const createPreviewMixedContentData = state => {
  const componentList = state.componentList.map(item => {
    const { componentType, ...rest } = item;
    const componentId = uuidv4();
    return {
      _id: componentId,
      componentType,
      componentData: { ...rest, ...createMixedOptions(componentType, rest.options, rest.correct) }
    };
  });

  return {
    _id: uuidv4(),
    details: {
      contentTitle: state.contentTitle,
      description: state.description
    },
    requiredComponents: componentList.filter(i => i.componentType !== 'BLOG').map(item => item._id),
    componentList: componentList
  };
};

const createMixedOptions = (componentType, optionsReq = [], correctReq) => {
  if (!['MRQ', 'MCQ'].includes(componentType)) {
    return {};
  }
  let correct = null;
  const options = [];
  if (componentType === 'MCQ') {
    for (let index = 0; index < optionsReq.length; index += 1) {
      const option = optionsReq[index];
      const uuid = uuidv4();
      if (index === correctReq) {
        correct = uuid;
      }
      if (index === correctReq || !isEmpty(option)) {
        options.push({
          id: uuid,
          answer: option
        });
      }
    }
  }
  if (componentType === 'MRQ') {
    correct = [];
    for (let index = 0; index < optionsReq.length; index += 1) {
      const option = optionsReq[index];
      const uuid = uuidv4();
      if (correctReq.includes(index)) {
        correct.push(uuid);
      }
      if (index === correctReq || !isEmpty(option)) {
        options.push({
          id: uuid,
          answer: option
        });
      }
    }
  }

  return {
    options,
    correct
  };
};
