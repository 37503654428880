import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import { useFormatMessage } from 'hooks/useFormatMessage';
import General from './General';
// import Plan from './Plan';
// import Billing from './Billing';
import Notifications from './Notifications';
import SSO from './SSO';
import Webhook from './Webhook';

import { getOrganisationDetails } from '../../actions';

const AccountSettingsDashboard = ({ selectedOrg, getOrganisationDetails, ...props }) => {
  const t = useFormatMessage();

  useEffect(() => {
    !isEmpty(selectedOrg) && getOrganisationDetails(selectedOrg);
  }, [selectedOrg]);

  return (
    <React.Fragment>
      <Tabs id="account-settings-tab">
        <Tab eventKey="app-general" title={t('account-settings/general')}>
          <General {...props} selectedOrg={selectedOrg} />
        </Tab>
        {/* <Tab eventKey="app-plan" title={t('account-settings/plan')}>
            <Plan {...props} />
          </Tab>
          <Tab
            eventKey="app-billing"
            title={t('account-settings/billing')}
          >
            <Billing {...props} />
          </Tab> */}
        <Tab eventKey="app-notifications" title={t('account-settings/notifications')}>
          <Notifications {...props} selectedOrg={selectedOrg} />
        </Tab>
        <Tab eventKey="app-webhook" title={t('account-settings/webhook')}>
          <Webhook {...props} selectedOrg={selectedOrg} />
        </Tab>
        <Tab eventKey="app-sso" title={t('account-settings/SSO')}>
          <SSO {...props} selectedOrg={selectedOrg} />
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};

AccountSettingsDashboard.propTypes = {
  selectedOrg: PropTypes.string.isRequired,
  getOrganisationDetails: PropTypes.func.isRequired
};

AccountSettingsDashboard.defaultProps = {};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    getOrganisationDetails: bindActionCreators(getOrganisationDetails, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsDashboard);
