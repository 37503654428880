import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Container } from 'react-bootstrap';
import IconWrapper from 'atoms/IconWrapper';
import './module-content-action-row.scss';

const blockName = 'module-content-action-row-container';

const ModuleContentActionRow = props => {
  return (
    <Container className={`${blockName}`}>
      <Row className={`${blockName}__module-content-action-row`}>
        <Col>{props.left}</Col>
        <Col className={`${blockName}__module-content-action-row__right`}>
          <IconWrapper type={props.layout === 'grid' ? 'ListOff' : 'ListOn'} onClick={() => props.handleChangeLayout('list')} />
          <IconWrapper type={props.layout === 'grid' ? 'CardOn' : 'CardOff'} onClick={() => props.handleChangeLayout('grid')} />
          <div className="divider" />
          <IconWrapper
            type={props.sortType === 'createdAt' ? 'SortAlphabeticalOff' : 'SortAlphabeticalOn'}
            onClick={() => {
              props.handleChangeSort('alphabetical');
            }}
          />
          <IconWrapper type={props.sortType === 'alphabetical' ? 'SortDateOff' : 'SortDateOn'} onClick={() => props.handleChangeSort('createdAt')} />
        </Col>
      </Row>
    </Container>
  );
};

ModuleContentActionRow.propTypes = {
  left: PropTypes.node,
  layout: PropTypes.string.isRequired,
  sortType: PropTypes.string.isRequired,
  handleChangeLayout: PropTypes.func,
  handleChangeSort: PropTypes.func
};

ModuleContentActionRow.defaultProps = {
  left: <></>,
  handleChangeLayout: () => {},
  handleChangeSort: () => {}
};

export default ModuleContentActionRow;
