import React from 'react';
import PropTypes from 'prop-types';
import UserEngagementRecentDays from './UserEngagementRecentDays';
import UserActivityRecentDays from './UserActivityRecentDays';
import ComplianceDashboard from './ComplianceDashboard';
import Shortcuts from './Shortcuts';
import { Container } from 'react-bootstrap';

const Statistics = ({ selectedOrg }) => {
  return (
    <div>
      <Container style={{ marginBottom: '40px' }}>
        <ComplianceDashboard selectedOrg={selectedOrg} />
      </Container>
      <div style={{ backgroundColor: '#eff1ef' }}>
        <Container>
          <UserEngagementRecentDays selectedOrg={selectedOrg} />
          <UserActivityRecentDays selectedOrg={selectedOrg} />
        </Container>
      </div>
      <Container>
        <Shortcuts />
      </Container>
    </div>
  );
};

Statistics.propTypes = {
  selectedOrg: PropTypes.string.isRequired
};

Statistics.defaultProps = {};

export default Statistics;
