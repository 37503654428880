import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import useFetcher from 'hooks/useFetcher';
import Loader from 'atoms/Loader';
import MCQView from '../AttemptsAndPreview/McqView';
import PresentationView from '../AttemptsAndPreview/PresentationView';
import CarouselView from '../AttemptsAndPreview/CarouselView';
import StoryView from '../AttemptsAndPreview/StoryView';
import MixedView from '../AttemptsAndPreview/MixedView';

export const getPreviewData = ({ contents, contentId }) => {
  let previewContentData = [];
  const contentToBePreviewed = contents.find(content => content._id === contentId) || {};
  const { data, defaultLangCode } = contentToBePreviewed;
  if (data) {
    previewContentData = data.find(content => content.langCode === defaultLangCode);
  }
  return {
    contentType: contentToBePreviewed.contentType,
    preferences: contentToBePreviewed.preferences,
    contentData: previewContentData
  };
};

const ContentTypePreview = ({ organizationId, contentId }) => {
  const { loading, data: contents } = useFetcher(`/organizations/${organizationId}/contents/${contentId}`, 1);
  if (loading) {
    return (
      <div style={{ width: '100%', height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </div>
    );
  }
  if (!contents[0]) {
    return null;
  }

  const previewData = getPreviewData({ contents, contentId });
  switch (previewData.contentType) {
    case 'mcq':
    case 'MCQ': {
      return <MCQView contentData={previewData.contentData} preferences={previewData.preferences} isPreview />;
    }
    case 'presentation':
    case 'PRESENTATION': {
      return <PresentationView contentData={previewData.contentData} preferences={previewData.preferences} isPreview />;
    }
    case 'carousel':
    case 'CAROUSEL': {
      return <CarouselView contentData={previewData.contentData} preferences={previewData.preferences} isPreview />;
    }
    case 'story':
    case 'STORY': {
      return <StoryView contentData={previewData.contentData} preferences={previewData.preferences} isPreview />;
    }
    case 'mixed':
    case 'MIXED': {
      const componentsList = get(previewData, 'contentData.componentList', []);
      const requiredComponents = componentsList.filter(i => i.componentType !== 'BLOG').map(item => item._id);
      const modifiedContentData = { ...previewData.contentData, requiredComponents };
      return <MixedView contentData={modifiedContentData} preferences={previewData.preferences} isPreview />;
    }
    default:
      return null;
  }
};

ContentTypePreview.propTypes = {
  organizationId: PropTypes.string,
  contentId: PropTypes.string
};

ContentTypePreview.defaultProps = {};

export default ContentTypePreview;
