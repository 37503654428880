import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import { AccordionItemContext } from './AccordionItem';

const AccordionItemBody = ({ children }) => {
  const { isCollapsed } = useContext(AccordionItemContext);
  return (
    <Collapse in={isCollapsed}>
      <div>
        <div className="card-body">{children}</div>
      </div>
    </Collapse>
  );
};
export default AccordionItemBody;

AccordionItemBody.propTypes = {
  children: PropTypes.node.isRequired
};
