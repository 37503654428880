import { GET_SHARED_CONTENT, GET_SHARED_CONTENT_SUCCESS, GET_SHARED_CONTENT_FAILURE, UPDATE_SHARED_CONTENT, UPDATE_SHARED_CONTENT_SUCCESS, UPDATE_SHARED_CONTENT_FAILURE } from 'utils/constants';

const initialState = {
  contents: {}
};

const sharedContent = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHARED_CONTENT: {
      return {
        ...state,
        contents: {}
      };
    }
    case GET_SHARED_CONTENT_SUCCESS:
    case UPDATE_SHARED_CONTENT_SUCCESS: {
      return {
        ...state,
        contents: action.payload
      };
    }
    case UPDATE_SHARED_CONTENT:
    case UPDATE_SHARED_CONTENT_FAILURE:
    case GET_SHARED_CONTENT_FAILURE: {
      return {
        ...state
      };
    }
    default:
      return state;
  }
};
export default sharedContent;
