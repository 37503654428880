import { get } from 'lodash';
import { getAllCounriesTimezones } from 'utils/calculationUtilities';
import {
  SIGNIN_USER_SUCCESS,
  REAUTH_SUCCESS,
  GET_ALL_DIPLOMAS,
  GET_ALL_DIPLOMAS_SUCCESS,
  GET_ALL_DIPLOMAS_FAILURE,
  GET_ALL_TIMEZONE,
  GET_ALL_TIMEZONE_SUCCESS,
  GET_ALL_TIMEZONE_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE
} from 'utils/constants';

const initialState = {
  userDefaultLangCode: null,
  diplomas: [],
  organization: {},
  timezones: [],
  errors: {},
  message: {}
};

const myAccountState = (state = initialState, action) => {
  const errors = get(action, 'payload.response.errors', {});
  const message = get(action, 'payload.message', {});
  switch (action.type) {
    case SIGNIN_USER_SUCCESS:
      return {
        ...state,
        userDefaultLangCode: action.payload.user.defaultLangCode
      };
    case REAUTH_SUCCESS:
      return {
        ...state,
        userDefaultLangCode: action.payload.user.defaultLangCode
      };
    case GET_ALL_DIPLOMAS:
      return {
        ...state,
        diplomas: []
      };
    case GET_ALL_DIPLOMAS_SUCCESS:
      return {
        ...state,
        diplomas: action.payload[0].diplomas,
        organization: action.payload[0].organization[0]
      };
    case GET_ALL_DIPLOMAS_FAILURE:
      return {
        ...state
      };
    case GET_ALL_TIMEZONE:
      return {
        ...state,
        timezones: []
      };
    case GET_ALL_TIMEZONE_SUCCESS:
      return {
        ...state,
        timezones: action.payload,
        timezonesCountry: getAllCounriesTimezones(action.payload)
      };
    case GET_ALL_TIMEZONE_FAILURE:
      return {
        ...state
      };
    case CHANGE_PASSWORD:
      return {
        ...state
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        message: { ...state.message, CHANGE_PASSWORD_SUCCESS: message },
        errors: { ...state.errors, CHANGE_PASSWORD_FAILURE: false }
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        errors: {
          CHANGE_PASSWORD_FAILURE: errors
        },
        message: { ...state.message, CHANGE_PASSWORD_SUCCESS: false }
      };

    default:
      return state;
  }
};
export default myAccountState;
