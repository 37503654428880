import React, { useState, Fragment, useEffect, useContext, useRef } from 'react';
import queryString from 'query-string';

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { get, isEmpty, sortBy } from 'lodash';
import moment from 'moment';

import Card from 'atoms/Card';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { getPriortizedDataAccordingToLanguage, formatLanguages, getLowerCaseContentType } from 'utils/calculationUtilities';
import { mediaSelector } from 'utils/selectors';
import AppContext from '../../app-context';
import PageHeader from '../PageHeader';
import VideoView from '../AttemptsAndPreview/VideoView';
import LanguageSelector from './content-list-language-selector';
import {
  HistoryPropTypesShape,
  ContentsPropTypesShape,
  CurrentContentPropTypesShape,
  CurrentModulepropTypesShape,
  MediaPropTypesShape,
  LanguagesPropTypesShape,
  ComputedMatchPropTypesShape
} from 'utils/core-proptypes';
import { fetchModuleStart } from '../Home/actions';
import { fetchLocaleLanguages } from '../../actions';
import { fetchContentStart, clearLanguageSelectorErrors, dispatchCustomActionGlobalNotification } from './actions';
import './content-list.scss';

const ContentList = ({
  currentModule,
  currentContent,
  contents,
  fetchModuleStart,
  fetchContentStart,
  clearLanguageSelectorErrors,
  dispatchCustomActionGlobalNotification,
  history,
  computedMatch,
  languages,
  userErrorActivityErrors,
  userDefaultLangCode,
  userDateFormat,
  ...props
}) => {
  const context = useContext(AppContext);
  const t = useFormatMessage();
  const { search } = useLocation();
  const isDefaultLangCodeSet = useRef(false);
  const { moduleId } = computedMatch.params;
  const [selectedContent, setSelectedContent] = useState(false);
  const [isAttemptable, setIsAttemptable] = useState(false);
  const { tid: trackingId } = queryString.parse(search);

  useEffect(() => {
    if (!props.isAuthenticated && !isDefaultLangCodeSet.current && currentModule.defaultLangCode) {
      isDefaultLangCodeSet.current = true;
      context.setContextState({ language: currentModule.defaultLangCode });
    }
  }, [currentModule.defaultLangCode]);

  useEffect(() => {
    if (isEmpty(languages)) {
      props.fetchLocaleLanguages();
    }
    fetchModuleStart({ id: moduleId, isPublic: !props.isAuthenticated, trackingId }); //pass extra key isPublic to determine content access mode
  }, []);

  useEffect(() => {
    if (isAttemptable && currentContent?.attemptId && currentContent?.trackingId && currentContent?.content?.contentType) {
      setIsAttemptable(false);
      history.push(`/${getLowerCaseContentType(currentContent.content.contentType)}/${currentContent.trackingId}`);
    }
  }, [currentContent?.attemptId]);

  useEffect(() => {
    if (!isEmpty(userErrorActivityErrors)) {
      dispatchCustomActionGlobalNotification(userErrorActivityErrors);
    }
  }, [userErrorActivityErrors]);

  const { moduleTitle = '', description = '', media } = !isEmpty(currentModule) ? getPriortizedDataAccordingToLanguage([userDefaultLangCode, currentModule.defaultLangCode], currentModule.data) : {};

  const getContentProps = ({ content: contentInfo }) => {
    const { show, statusDescription, statusIcon, status } = getContentStatus(contentInfo);
    const contentData = getPriortizedDataAccordingToLanguage([userDefaultLangCode, currentModule.defaultLangCode, contentInfo.content.defaultLangCode], contentInfo.content.data);
    return {
      show,
      cover: mediaSelector({
        media: get(contentData, 'details.thumbnail', {})
      }),
      heading: get(contentData, 'details.contentTitle', ''),
      description: get(contentData, 'details.description', ''),
      statusDescription,
      statusIcon,
      onClick: () => handleContentClick({ content: contentInfo, status }),
      status
    };
  };

  const handleStartContent = ({ langCode, contentId }) => {
    if (!langCode || !contentId) return;
    setIsAttemptable(true);
    fetchContentStart({ moduleId, contentId, langCode, isPublic: !props.isAuthenticated, trackingId });
  };

  const handleContentClick = ({ content, status }) => {
    if (status === 'active') {
      setSelectedContent(content);
      const contentDataList = get(content, 'content.data', []);
      const languageMapFromContent = sortBy(
        contentDataList.map(contentData => {
          const { langCode } = contentData;
          const langObject = languages.find(lang => lang.id === contentData.langCode) || {};
          return { id: langCode, value: langObject.value };
        }),
        'value'
      );
      if (languageMapFromContent.length === 1) {
        handleStartContent({ langCode: languageMapFromContent[0].id, contentId: get(content, 'content._id', '') });
      }
    }
    clearLanguageSelectorErrors();
  };

  const getContentStatus = content => {
    if (get(content, 'progress.isContentCompleted', false)) {
      return {
        show: true,
        statusDescription: t('content-list/completed'),
        statusIcon: 'Complete',
        status: 'active'
      };
    }

    if (get(content, 'settings.isStartDateInFuture', false)) {
      const startDate = get(content, 'settings.startDate', '');
      return {
        show: true,
        statusDescription: `${t('content-list/available-on')} ${moment(startDate).format(userDateFormat)}`,
        statusIcon: 'Waiting',
        status: 'inactive'
      };
    }

    return {
      show: true,
      statusDescription: t('content-list/waiting-for-you'),
      statusIcon: 'Available',
      status: 'active'
    };
  };

  return (
    <Fragment>
      <Container fluid className="course-top p-0">
        {media && <VideoView media={media} />}
      </Container>
      <Container className="content-list">
        <PageHeader title={moduleTitle} desc={description} />
        <Row className={contents.length < 4 ? 'justify-content-center' : ''}>
          {contents.map(content => {
            const contentProps = getContentProps({ content });
            return contentProps.show ? (
              <Col key={content?.content?._id} style={{ padding: '0' }} lg={3} md={6} sm={12}>
                <Card {...contentProps} showProgress={props.isAuthenticated || !!trackingId} />
              </Col>
            ) : null;
          })}
          {selectedContent?.content?.data?.length > 1 && (
            <LanguageSelector
              defaultLangCode={currentModule.defaultLangCode}
              onHide={() => setSelectedContent(null)}
              content={selectedContent}
              userErrorActivityErrors={userErrorActivityErrors}
              onSelect={langCode => {
                handleStartContent({ langCode, contentId: get(selectedContent, 'content._id', '') });
              }}
              languages={languages}
            />
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

ContentList.propTypes = {
  contents: ContentsPropTypesShape,
  currentModule: CurrentModulepropTypesShape,
  currentContent: CurrentContentPropTypesShape,
  fetchModuleStart: PropTypes.func.isRequired,
  fetchContentStart: PropTypes.func.isRequired,
  clearLanguageSelectorErrors: PropTypes.func.isRequired,
  dispatchCustomActionGlobalNotification: PropTypes.func.isRequired,
  userDateFormat: PropTypes.string.isRequired,
  fetchLocaleLanguages: PropTypes.func.isRequired,
  languages: LanguagesPropTypesShape,
  userErrorActivityErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userDefaultLangCode: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  media: MediaPropTypesShape,
  history: HistoryPropTypesShape,
  computedMatch: ComputedMatchPropTypesShape,
  isAuthenticated: PropTypes.bool.isRequired
};

ContentList.defaultProps = {
  thumbnail: '',
  computedMatch: {},
  media: null,
  history: null,
  contents: []
};

const mapStateToProps = ({ userState, userActivityState, adminState }) => {
  const { errorMessage: userErrorActivityErrors, currentModule, currentContent = {} } = userActivityState;
  const isAuthenticated = !!get(userState, 'user.user._id', false);
  const { contents = [] } = currentModule;
  const { media } = get(currentModule, 'data.0', '');
  const languages = get(adminState, 'localLanguages', []);
  const formattedLanguageList = formatLanguages(languages);
  const userDateFormat = get(userState, 'user.user.settings.dateFormat', 'DD/MM/YYYY');
  const defaultLangCodeUser = get(userState, 'user.user.settings.defaultLangCode');

  return {
    currentModule,
    isAuthenticated,
    media,
    currentContent,
    userErrorActivityErrors,
    contents: contents,
    languages: formattedLanguageList,
    userDefaultLangCode: defaultLangCodeUser,
    userDateFormat
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchLocaleLanguages: bindActionCreators(fetchLocaleLanguages, dispatch),
    fetchModuleStart: bindActionCreators(fetchModuleStart, dispatch),
    fetchContentStart: bindActionCreators(fetchContentStart, dispatch),
    dispatchCustomActionGlobalNotification: bindActionCreators(dispatchCustomActionGlobalNotification, dispatch),
    clearLanguageSelectorErrors: bindActionCreators(clearLanguageSelectorErrors, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentList));
