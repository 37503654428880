import ReactQuill from 'react-quill';
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css'; // ES6
import './editor.scss';

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video'];

const reducer = (state, action) => {
  const { key, value } = action;

  switch (action.type) {
    case 'inputChange':
      return {
        ...state,
        ...{ [key]: value }
      };
    case 'initialState': {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      throw new Error();
  }
};

const Editor = ({ dispatcher, editorKey, documentation = '' }) => {
  const initialState = {
    editorHtml: documentation,
    theme: 'snow'
  };
  const [state, dispatcherEditor] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatcherEditor({
      type: 'inputChange',
      key: 'editorHtml',
      value: documentation
    });
  }, [documentation]);

  const handleChange = html => {
    dispatcherEditor({
      type: 'inputChange',
      key: 'editorHtml',
      value: html
    });
    dispatcher({
      type: 'inputChange',
      key: editorKey,
      value: html
    });
  };

  const { theme, editorHtml } = state;

  return (
    <div className="quill-editor">
      <ReactQuill theme={theme} onChange={handleChange} value={editorHtml} modules={modules} formats={formats} />
    </div>
  );
};

Editor.propTypes = {
  editorKey: PropTypes.string.isRequired,
  documentation: PropTypes.string.isRequired,
  dispatcher: PropTypes.func
};

export default Editor;
