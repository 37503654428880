import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useFormatMessage } from 'hooks/useFormatMessage';
import IconWrapper from 'atoms/IconWrapper';

import './shortcuts.scss';

const Shortcuts = () => {
  const t = useFormatMessage();

  return (
    <Container className="stat-bottom-container">
      <div className="stats-head-middle span3">Shortcuts</div>
      <Row style={{ justifyContent: 'space-between', margin: 0 }}>
        <div className="short-box-col">
          <a href="/modules-content">
            <div className="short-box">
              <div>
                <IconWrapper type="ShortContent" className="shortcut-box-icon" />
              </div>
              <div className="short-box-txt">{t('shortcuts/content-and-modules')}</div>
            </div>
          </a>
        </div>
        <div className="short-box-col">
          <a href="/user-management">
            <div className="short-box">
              <div>
                <IconWrapper type="RegisteredUsers" className="shortcut-box-icon" />
              </div>
              <div className="short-box-txt">{t('shortcuts/users-and-usergroups')}</div>
            </div>
          </a>
        </div>
        <div className="short-box-col">
          <a href="/account-settings">
            <div className="short-box">
              <div>
                <IconWrapper type="ShortSettings" className="shortcut-box-icon" />
              </div>
              <div className="short-box-txt">{t('shortcuts/account-settings')}</div>
            </div>
          </a>
        </div>
      </Row>
    </Container>
  );
};

Shortcuts.propTypes = {};
export default Shortcuts;
