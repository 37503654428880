import uuidv4 from 'uuid/v4';
import {
  CREATE_MODULE_SUCCESS,
  UPDATE_MODULE_SUCCESS,
  CREATE_MODULE_LANG_DATA_SUCCESS,
  UPDATE_MODULE_LANG_DATA_SUCCESS,
  ADD_PREFERENCE_SUCCESS,
  UPDATE_PREFERENCE_SUCCESS,
  ADD_CONTENT_DATA_SUCCESS,
  UPDATE_CONTENT_DATA_SUCCESS,
  ADD_GROUP_SUCCESS,
  UPDATE_GROUP_SUCCESS,
  ASSIGN_GROUP_SUCCESS,
  ADD_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_SETTINGS_SUCCESS,
  CREATE_MODULE_FAILURE,
  UPDATE_MODULE_FAILURE,
  CLONE_MODULE_FAILURE,
  CLONE_MODULE_SUCCESS,
  CREATE_MODULE_LANG_DATA_FAILURE,
  UPDATE_MODULE_LANG_DATA_FAILURE,
  ADD_PREFERENCE_FAILURE,
  UPDATE_CONTENT_DATA_FAILURE,
  UPDATE_PREFERENCE_FAILURE,
  ADD_CONTENT_DATA_FAILURE,
  UPDATE_GROUP_FAILURE,
  ADD_GROUP_FAILURE,
  ASSIGN_GROUP_FAILURE,
  UPDATE_ORGANIZATION_FAILURE,
  ADD_ORGANIZATION_FAILURE,
  UPDATE_ACCOUNT_SETTINGS_FAILURE,
  ADD_EMAIL_TEMPLATE_FAILURE,
  UPDATE_EMAIL_TEMPLATE_FAILURE,
  UPDATE_SETTINGS_FAILURE,
  DELETE_MINDZEED_ACCOUNT_SUCCESS,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_FAILURE,
  SHOW_MANAGER_BROWSER_MESSAGE,
  SHARE_CONTENT_COPY_SUCCESS,
  SHARE_CONTENT_COPY_FAILURE,
  SHARE_MODULE_COPY_SUCCESS,
  SHARE_MODULE_COPY_FAILURE,
  SHOW_REMINDER_SUCCESS,
  UPDATE_ACCOUNT_SSO_CONFIG_FAILURE,
  UPDATE_ACCOUNT_SSO_CONFIG_SUCCESS,
  UPDATE_ACCOUNT_WEBHOOK_SETTINGS_FAILURE,
  UPDATE_ACCOUNT_WEBHOOK_SETTINGS_SUCCESS
} from 'utils/constants';

const successTypes = [
  CREATE_MODULE_SUCCESS,
  UPDATE_MODULE_SUCCESS,
  'DELETE_MODULE_SUCCESS',
  CREATE_MODULE_LANG_DATA_SUCCESS,
  UPDATE_MODULE_LANG_DATA_SUCCESS,
  'CONTENT_SPECIFIC_PREFERENCE_SUCCESS',
  ADD_PREFERENCE_SUCCESS,
  UPDATE_CONTENT_DATA_SUCCESS,
  UPDATE_PREFERENCE_SUCCESS,
  ADD_CONTENT_DATA_SUCCESS,
  'UPDATE_USER_SUCCESS',
  UPDATE_GROUP_SUCCESS,
  'ADD_USER_SUCCESS',
  ADD_GROUP_SUCCESS,
  ASSIGN_GROUP_SUCCESS,
  UPDATE_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_SUCCESS,
  UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_SETTINGS_SUCCESS,
  DELETE_MINDZEED_ACCOUNT_SUCCESS,
  DELETE_CONTENT_SUCCESS,
  SHARE_CONTENT_COPY_SUCCESS,
  SHARE_MODULE_COPY_SUCCESS,
  SHOW_REMINDER_SUCCESS,
  CLONE_MODULE_SUCCESS,
  UPDATE_ACCOUNT_SSO_CONFIG_SUCCESS,
  UPDATE_ACCOUNT_WEBHOOK_SETTINGS_SUCCESS,
  'CREATE_CONTENT_ERROR',
  'CREATE_MODULE_ERROR'
];

const errorTypes = [
  CREATE_MODULE_FAILURE,
  UPDATE_MODULE_FAILURE,
  'DELETE_MODULE_FAILURE',
  CREATE_MODULE_LANG_DATA_FAILURE,
  UPDATE_MODULE_LANG_DATA_FAILURE,
  'CONTENT_SPECIFIC_PREFERENCE_FAILURE',
  ADD_PREFERENCE_FAILURE,
  UPDATE_CONTENT_DATA_FAILURE,
  UPDATE_PREFERENCE_FAILURE,
  ADD_CONTENT_DATA_FAILURE,
  'UPDATE_USER_FAILURE',
  UPDATE_GROUP_FAILURE,
  'ADD_USER_FAILURE',
  ADD_GROUP_FAILURE,
  ASSIGN_GROUP_FAILURE,
  UPDATE_ORGANIZATION_FAILURE,
  ADD_ORGANIZATION_FAILURE,
  UPDATE_ACCOUNT_SETTINGS_FAILURE,
  ADD_EMAIL_TEMPLATE_FAILURE,
  UPDATE_EMAIL_TEMPLATE_FAILURE,
  UPDATE_SETTINGS_FAILURE,
  'DELETE_CONTENT_DATA_FAILURE',
  SHOW_MANAGER_BROWSER_MESSAGE,
  DELETE_CONTENT_FAILURE,
  SHARE_CONTENT_COPY_FAILURE,
  SHARE_MODULE_COPY_FAILURE,
  CLONE_MODULE_FAILURE,
  UPDATE_ACCOUNT_SSO_CONFIG_FAILURE,
  UPDATE_ACCOUNT_WEBHOOK_SETTINGS_FAILURE,
  'CANNOT_DELETE_SHARED_OR_ASSIGNED_CONTENT'
];
const initialState = {
  message: {},
  type: null
};

const isSuccessType = type => successTypes.includes(type);
const isErrorType = type => errorTypes.includes(type);
const globalNotificationState = (state = initialState, action) => {
  if (isSuccessType(action.type)) {
    return {
      ...state,
      type: 'success',
      globalNotificationId: uuidv4(),
      errorMessage: null,
      baseCode: action.type,
      code: `global-notification-${action.type}`
    };
  }
  if (isErrorType(action.type)) {
    return {
      ...state,
      type: 'error',
      globalNotificationId: uuidv4(),
      errorMessage: null,
      code: `global-notification-${action.type}`
    };
  }
  if (action.type === 'SET_CUSTOM_MESSAGE_FOR_ERROR') {
    const {
      payload: [error]
    } = action;
    return {
      ...state,
      type: 'error',
      globalNotificationId: uuidv4(),
      errorMessage: error.userMessage
    };
  }

  return state;
};
export default globalNotificationState;
