import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { fetcher } from 'hooks/useFetcher';
import Button from 'atoms/Button';
import Modal from 'atoms/Modal';
import ThreeDots from 'atoms/ThreeDots';
import IconWrapper from 'atoms/IconWrapper';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { renderContentTypePreviewContentPage } from 'utils/renderContentPreview';
import { dispatchCustomAction } from './actions';

const ActionRowContentData = ({ orgid, contentId, contentDataId, contentType, langCode, preferences, onSave, onDelete, onRevert, notPristine, state }) => {
  const t = useFormatMessage();
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const handleDelete = async () => {
    try {
      if (orgid && contentId && contentDataId) {
        const result = await fetcher(`/organizations/${orgid}/contents/${contentId}/contentData/${contentDataId}`, 'DELETE', {}, JSON.stringify({}));
        if (result.errors) {
          dispatchCustomAction('DELETE_CONTENT_DATA_FAILURE');
          return;
        }
        dispatchCustomAction('DELETE_CONTENT_DATA_SUCCESS');
        setShowDeleteModal(false);
      }
      onDelete(langCode);
    } catch (err) {
      dispatchCustomAction('DELETE_CONTENT_DATA_FAILURE');
    }
  };

  const handleRevert = () => {
    onRevert();
    setShowRevertModal(false);
  };

  return (
    <>
      <Col md="1">
        <Button variant="primary" onClick={onSave} notPristine={notPristine} style={{ width: '95px' }}>
          {t('common/save')}
        </Button>
      </Col>

      <Col md="1">
        <ThreeDots
          options={[
            {
              name: t('common/revert-to-saved'),
              handler: () => setShowRevertModal(true)
            },
            {
              name: t('common/delete'),
              handler: () => setShowDeleteModal(true)
            },
            {
              name: t('common/preview'),
              handler: () => setShowPreview(true)
            }
          ]}
        />
      </Col>

      <Modal
        heading={t('common/modal/please-confirm-text')}
        modalBody={<p>{t('common/modal/delete-warning-text')}</p>}
        show={showRevertModal}
        isAlert
        onSave={handleRevert}
        onHide={() => setShowRevertModal(false)}
      />

      <Modal
        heading={t('common/modal/please-confirm-text')}
        modalBody={<p>{t('common/modal/delete-content-warning-text')}</p>}
        show={showDeleteModal}
        isAlert
        onSave={handleDelete}
        onHide={() => setShowDeleteModal(false)}
      />

      {showPreview && (
        <div className="lessonPreview__overlay">
          <div className="lessonPreview__toolbar">
            <div className="preview">{t('common/preview')}</div>
            <div className="closePreview">{t('common/close-preview')}</div>
            <div className="closeIcon" onClick={() => setShowPreview(false)}>
              <IconWrapper type="CloseIcon" alt="crossIcon" />
            </div>
          </div>
          {renderContentTypePreviewContentPage({ preferences, state, contentType })}
        </div>
      )}
    </>
  );
};

ActionRowContentData.propTypes = {
  orgid: PropTypes.string,
  contentId: PropTypes.string,
  contentDataId: PropTypes.string,
  contentType: PropTypes.string,
  langCode: PropTypes.string,
  preferences: PropTypes.shape({}),
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  notPristine: PropTypes.bool,
  dispatchCustomAction: PropTypes.func.isRequired,
  state: PropTypes.shape({})
};
ActionRowContentData.defaultProps = {};

const mapDispatchToProps = dispatch => {
  return {
    dispatchCustomAction: bindActionCreators(dispatchCustomAction, dispatch)
  };
};
export default connect(() => {}, mapDispatchToProps)(ActionRowContentData);
