import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import DropDown from 'atoms/DropDown';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { UserPropTypesShape } from 'utils/core-proptypes';
import ModuleContentSetupView from './ModuleContentSetupView';
import RoleCheck from '../RoleCheck';
import AppContext from '../../app-context';

import { getOrganisationList } from '../../actions';
import './modules-contents.scss';

const ModulesContentDashboard = ({ user, organizationList, getOrganisationList }) => {
  const t = useFormatMessage();
  const context = useContext(AppContext);
  const { user: { role = '', organizationId } = {} } = user;

  useEffect(() => {
    if (!context.selectedOrg) {
      context.setContextState({ selectedOrg: organizationId });
    }
  }, []);

  useEffect(() => {
    if (role === 'ADMIN' || role === 'PARTNER') {
      isEmpty(organizationList) && getOrganisationList();
    }
  }, [organizationList.length]);

  const selectedOrg = context.selectedOrg || organizationId;
  const handleSelect = eventKey => {
    context.setContextState({ selectedOrg: eventKey.id });
  };

  return (
    <div>
      <Container>
        <RoleCheck
          allowed={['ADMIN', 'PARTNER']}
          component={
            <Row className="module-content-dashboard__top-row justify-content-center">
              <Col xs={12} className="module-content-dashboard__top-row__col">
                <DropDown
                  onSelect={handleSelect}
                  isSearchable={true}
                  heading={selectedOrg}
                  placeholder={t('common/select-organization')}
                  values={organizationList.map(org => {
                    return { value: org.name, id: org._id };
                  })}
                />
              </Col>
            </Row>
          } // default organisationId in case of no selectedOrg and selected org valid only for admin
        />
      </Container>
      <ModuleContentSetupView selectedOrg={selectedOrg} />
    </div>
  );
};

ModulesContentDashboard.propTypes = {
  user: UserPropTypesShape,
  organizationList: PropTypes.arrayOf(PropTypes.object),
  getOrganisationList: PropTypes.func.isRequired
};

ModulesContentDashboard.defaultProps = {
  organizationList: []
};

const mapStateToProps = ({ userState, adminState, accountSettings }) => {
  const { orgName } = accountSettings;

  return {
    user: userState.user,
    organizationList: adminState.organizations,
    orgName
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getOrganisationList: bindActionCreators(getOrganisationList, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModulesContentDashboard);
