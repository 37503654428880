import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import { CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE } from 'utils/constants';

export const updatePassword = payload => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/updateUserPassword`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE]
  }
});
