import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';

import { MIXED_SUBMIT, MIXED_SUBMIT_SUCCESS, MIXED_SUBMIT_FAILURE } from 'utils/constants';

export const mixedComponentSubmitAction = ({ moduleId, contentId, mixedComponentId, body, isPublic = false }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/submit${isPublic ? '/public' : ''}/module/${moduleId}/content/${contentId}/mixed/${mixedComponentId}`,
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    types: [MIXED_SUBMIT, MIXED_SUBMIT_SUCCESS, MIXED_SUBMIT_FAILURE]
  }
});
