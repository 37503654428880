import {
  ADD_EMAIL_TEMPLATE,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  ADD_EMAIL_TEMPLATE_FAILURE,
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAILURE,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_TEMPLATE_FAILURE
} from 'utils/constants';
import uuidv4 from 'uuid/v4';

const initialState = {
  allEmailTemplates: []
};

const AppSettings = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMAIL_TEMPLATE:
      return {
        ...state
      };

    case ADD_EMAIL_TEMPLATE_SUCCESS: {
      const template = action.payload.emailSettings;
      const addedTemplate = {
        eventKey: `email-template-${template.langCode}`,
        title: `${template.langCode}`,
        ...template,
        notPristine: true
      };
      return {
        ...state,
        allEmailTemplates: [...state.allEmailTemplates, addedTemplate]
      };
    }

    case ADD_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state
      };
    case UPDATE_EMAIL_TEMPLATE:
      return {
        ...state
      };

    case UPDATE_EMAIL_TEMPLATE_SUCCESS: {
      const template = action.payload.emailSettings;
      const mList = state.allEmailTemplates.map(tmplate => (tmplate.langCode === template.langCode ? { ...tmplate, templates: template.templates, updateId: uuidv4() } : tmplate));
      return {
        ...state,
        allEmailTemplates: mList
      };
    }

    case UPDATE_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state
      };
    case GET_EMAIL_TEMPLATE:
      return {
        ...state
      };

    case GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        allEmailTemplates: action.payload.map(template => {
          return {
            eventKey: `email-template-${template.langCode}`,
            title: `${template.langCode}`,
            ...template
          };
        })
      };

    case GET_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state
      };

    default:
      return state;
  }
};
export default AppSettings;
