import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import uuidv4 from 'uuid/v4';
import Sortable from 'react-sortablejs';
import { Col, Row } from 'react-bootstrap';
import { get, isEmpty, lowerCase } from 'lodash';

import Input from 'atoms/Input';
import Button from 'atoms/Button';
import Feedback from 'atoms/Feedback';
import { Accordion } from 'atoms/Accordion';
import { Radio, RadioGroup } from 'atoms/Radio';
import ImageThumbnail from 'atoms/ImageThumbnail';
import Editor from 'atoms/Editor';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { contentDataSelector, AllowedMediaTypeSource, getMediaPayload } from 'utils/calculationUtilities';
import { MCQPreferencesPropTypesShape, CurrentContentPropTypesShape } from 'utils/core-proptypes';
import QuestionBlock from 'atoms/QuestionBlock';
import { addContentData, updateContentData } from '../../actions';
import NoPPTImage from 'assets/icons/nopresentation.svg';
import ActionRowContentData from '../../ActionRowContentData';
import { reducer } from './reducer';
import './mcq-create.scss';

const blockName = 'mcq-content-create';

/**
 *
 * @param {array} optionsReq
 * @param {string} correctReq
 * @returns an object with options mapped with uuid
 */

const MCQCreate = ({ orgid, contentId, currentContent, ...props }) => {
  const t = useFormatMessage();
  const langCode = lowerCase(get(props, 'langCode', ''));
  const initialState = {
    langCode,
    setDefaultLanguage: true,
    mcqList: [
      {
        question: '',
        options: ['', ''],
        correct: 0,
        explanation: ''
      }
    ]
  };
  const currentContentData = contentDataSelector({
    contentData: currentContent,
    langCode
  });
  const { preferences } = currentContent;
  const [state, dispatcher] = useReducer(reducer, currentContentData || initialState);
  const media = get(currentContentData, 'media') || state.media;
  const createFeedbackMessage = get(props, 'message.ADD_CONTENT_DATA_SUCCESS', {});
  const updateFeedbackMessage = get(props, 'message.UPDATE_CONTENT_DATA_SUCCESS', {});
  const createFeedbackError = get(props, 'errors.ADD_CONTENT_DATA_FAILURE', []);
  const updateFeedbackError = get(props, 'errors.UPDATE_CONTENT_DATA_FAILURE', []);
  const contentDataId = get(currentContentData, '_id', '');

  useEffect(() => {
    const defaultLanguage = props.defaultLangCode;
    dispatcher({
      type: 'initialState',
      payload: {
        ...currentContentData,
        ...currentContentData.details,
        setDefaultLanguage: defaultLanguage,
        revertId: uuidv4()
      }
    });
  }, [currentContent]);

  const contentLanguageRevert = () => {
    dispatcher({
      type: 'initialState',
      payload: {
        ...currentContentData,
        ...currentContentData.details,
        revertId: uuidv4()
      }
    });
  };

  const handleVideoModalSave = media => {
    dispatcher({
      type: 'setMedia',
      media
    });
  };

  const addBlock = () => {
    const payload = {
      _id: uuidv4(),
      question: '',
      options: ['', ''],
      correct: 0,
      explanation: '',
      isCollapsed: true
    };
    dispatcher({
      type: 'addBlock',
      payload
    });
  };

  const mcqSortHandler = order => {
    const sortedMCQID = order;
    const sortedMCQ = [];
    sortedMCQID.map(id => {
      const mcq = state.mcqList.find(q => q._id === id);
      if (!isEmpty(mcq)) {
        sortedMCQ.push(mcq);
      }
      return id;
    });
    dispatcher({
      type: 'sortMcqList',
      payload: sortedMCQ
    });
  };

  const onSave = () => {
    const defaultLanguage = state.setDefaultLanguage === langCode;
    const payload = {
      ...state,
      ...getMediaPayload(state.media, state.mediaPristine, 'media'),
      setDefaultLanguage: defaultLanguage
    };
    if (contentId && contentDataId) {
      props.updateContentData({
        payload,
        contentId,
        contentDataId,
        organizationId: orgid
      });
    } else {
      props.addContentData({ payload, contentId, organizationId: orgid });
    }
  };

  const renderQuestions = () => {
    const sortableOptions = {
      animation: 150,
      handle: '.accordion-sort',
      chosenClass: 'sortable-chosen'
    };
    const items = state.mcqList.map((q, index) => {
      return <QuestionBlock key={q._id} data-id={q._id} question={q} index={index} onDispatch={dispatcher} isCollapsed={q.isCollapsed} />;
    });
    return (
      <Sortable options={sortableOptions} onChange={mcqSortHandler}>
        {items}
      </Sortable>
    );
  };

  return (
    <React.Fragment>
      <div className={blockName}>
        <Row>
          <Col>
            <div className={`${blockName}__title`}>{t('common/content-title')}</div>
            <Input
              className={`${blockName}__input-title`}
              value={state && state.contentTitle}
              placeholder={t('common/content-title')}
              onChange={e => {
                dispatcher({
                  type: 'inputChange',
                  key: 'contentTitle',
                  value: e.target.value
                });
              }}
            />
            <div className={`${blockName}__desc`}>{t('common/description')}</div>
            <Editor
              dispatcher={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'description',
                  value: e.value
                })
              }
              editorKey={'documentation'}
              documentation={state.description}
            />
            <RadioGroup
              name="mcq-create-default-lang"
              onSelect={value => {
                dispatcher({
                  type: 'inputRadio',
                  key: 'setDefaultLanguage',
                  value: value.value
                });
              }}
            >
              <Radio
                labelClass={`${blockName}__default-lang`}
                value={langCode}
                checked={state.setDefaultLanguage === langCode}
                description={t('common/set-as-default-language-description')}
                label={t('common/set-as-default-language-title')}
              />
            </RadioGroup>
          </Col>
        </Row>
        <Row className={`${blockName}__thumbnail-row`}>
          <div>
            {t('common/video-text')}
            <ImageThumbnail
              placeholderIcon={NoPPTImage}
              defaultMedia={media}
              allowedFileType={[AllowedMediaTypeSource.FILE, AllowedMediaTypeSource.VIMEO, AllowedMediaTypeSource.YOUTUBE]}
              handleSave={handleVideoModalSave}
            />
          </div>
        </Row>

        <Row>
          <Col>
            <div className={`${blockName}__questions`}>
              <Accordion>{renderQuestions()}</Accordion>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <ActionRowContentData
            orgid={orgid}
            contentId={contentId}
            contentType={currentContent.contentType}
            contentDataId={contentDataId}
            langCode={langCode}
            preferences={preferences}
            onSave={onSave}
            onDelete={props.onDelete}
            onRevert={contentLanguageRevert}
            state={state}
            notPristine={state.mcqContentCreateUpdatePristine}
          />
          <Col md="10" className="justify-content-end d-flex">
            <Button variant="primary" onClick={addBlock}>
              {t('common/add-block')}
            </Button>
          </Col>
        </Row>
      </div>
      <Feedback message={{ ...createFeedbackMessage, ...updateFeedbackMessage }} errorList={[...createFeedbackError, ...updateFeedbackError]} allowedTypesKey="MCQData" />
    </React.Fragment>
  );
};

MCQCreate.propTypes = {
  updateContentData: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
  addContentData: PropTypes.func.isRequired,
  langCode: PropTypes.string.isRequired,
  defaultLangCode: PropTypes.string.isRequired,
  currentContent: PropTypes.shape({
    currentContentData: CurrentContentPropTypesShape,
    preferences: MCQPreferencesPropTypesShape,
    contentType: PropTypes.string
  }),
  onDelete: PropTypes.func,
  type: PropTypes.string,
  orgid: PropTypes.string.isRequired
};

MCQCreate.defaultProps = {
  onDelete: () => {},
  currentContent: {}
};

const mapStateToProps = ({ contentState }) => {
  const errors = get(contentState, 'errors', []);
  const message = get(contentState, 'message', {});

  return {
    contentMediaUploading: contentState.contentMediaUploading,
    error: contentState.error,
    errors,
    message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addContentData: bindActionCreators(addContentData, dispatch),
    updateContentData: bindActionCreators(updateContentData, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MCQCreate));
