import moment from 'moment';
import { get, differenceBy, omit, orderBy } from 'lodash';
import { groupListMap } from 'utils/calculationUtilities';

export const reducer = (state, action) => {
  const { key, value } = action;

  switch (action.type) {
    case 'initialState': {
      const { groups = [], contents = [], moduleType } = action.payload.currentModule; // tao initialize assigned groups from current module
      const assignedGroups = groupListMap(groups.filter(grp => grp.isModuleAssigned));
      const notAssignedGroups = groupListMap(differenceBy(groups, assignedGroups, '_id'));
      return {
        ...state,
        currentModule: { ...state.currentModule, ...action.payload.currentModule, isOpen: moduleType === 'PUBLIC' ? true : false },
        contents,
        notAssignedGroups,
        assignedGroups,
        modulePreferencePristine: true,
        thumbnailPristine: false
      };
    }
    case 'inputChange': {
      return {
        ...state,
        currentModule: { ...state.currentModule, ...{ [key]: value } },
        modulePreferencePristine: false
      };
    }
    case 'setThumbnail': {
      const { thumbnail } = action;
      return {
        ...state,
        currentModule: { ...state.currentModule, thumbnail },
        modulePreferencePristine: false,
        thumbnailPristine: true
      };
    }
    case 'dropDownChange': {
      const { _id: groupId } = action.group;
      const modifiedGroups = state.notAssignedGroups.filter(grp => grp._id !== groupId);
      return {
        ...state,
        assignedGroups: [...state.assignedGroups, action.group],
        notAssignedGroups: modifiedGroups,
        modulePreferencePristine: false
      };
    }
    case 'addAllGroup': {
      return {
        ...state,
        assignedGroups: [...state.assignedGroups, ...action.notAssignedGroups],
        notAssignedGroups: [],
        modulePreferencePristine: false
      };
    }
    case 'statusChange': {
      const { status } = action;
      return {
        ...state,
        currentModule: { ...state.currentModule, status },
        modulePreferencePristine: false
      };
    }
    case 'checkboxChange': {
      const { isOpen } = action;
      return {
        ...state,
        currentModule: { ...state.currentModule, isOpen },
        modulePreferencePristine: false
      };
    }
    case 'dateChange': {
      const { date: startDate = null, contentId } = action;
      const modifiedContents = state.contents.map(item => {
        return item.settings._id === contentId
          ? {
              content: item.content,
              settings: { ...item.settings, startDate }
            }
          : item;
      });
      return {
        ...state,
        contents: modifiedContents,
        modulePreferencePristine: false
      };
    }
    case 'removeAssignedGroup': {
      const { id: groupId } = action;
      const groupToRemove = state.assignedGroups.find(grp => grp._id === groupId);
      const assignedGroups = state.assignedGroups.filter(grp => grp._id !== groupId);
      const notAssignedGroups = orderBy([...state.notAssignedGroups, groupToRemove], 'value');
      return {
        ...state,
        assignedGroups,
        notAssignedGroups,
        modulePreferencePristine: false
      };
    }
    case 'saveSuccess': {
      return {
        ...state,
        contents: state.contents.map(item => ({
          ...item,
          content: omit(item.content, ['unsaved'])
        })),
        modulePreferencePristine: true
      };
    }
    case 'addContent': {
      const { selectedContents = [] } = action;

      const newContents = selectedContents.map(content => {
        const { shared, preferences, contentType, _id } = content;
        return {
          content: { _id, unsaved: true, shared, preferences, contentType },
          settings: { _id, status: 'ACTIVE', startDate: moment().format('YYYY-MM-DD'), preferences: omit(preferences, ['contentName', 'description', 'difficultyLevel', 'estimatedTime', 'thumbnail']) }
        };
      });

      return {
        ...state,
        contents: [...state.contents, ...newContents],
        modulePreferencePristine: false
      };
    }
    case 'removeContent': {
      const { contentId } = action;
      const modifiedContents = state.contents.filter(content => content.settings._id !== contentId);
      return {
        ...state,
        contents: modifiedContents,
        modulePreferencePristine: false
      };
    }
    case 'sortContentList': {
      return {
        ...state,
        contents: action.payload,
        modulePreferencePristine: false
      };
    }
    case 'updateContentStatus': {
      const { status, contentId } = action;
      const mContent = state.contents.map(content =>
        content.settings._id === contentId
          ? {
              content: { ...content.content },
              settings: { ...content.settings, status: status }
            }
          : content
      );
      return {
        ...state,
        contents: mContent,
        modulePreferencePristine: false
      };
    }
    case 'openContentPreferenceModal': {
      const { contentId } = action;
      const moduleContent = state.contents.find(content => content.settings._id === contentId);

      const {
        contentType,
        preferences: { contentName }
      } = get(moduleContent, 'content', {});

      return {
        ...state,
        contentPreferenceModalPristine: true,
        modalPreferenceTitle: contentName,
        modalPreferenceData: {
          ...moduleContent.settings.preferences,
          _id: moduleContent.settings._id,
          contentType
        },
        openContentPreferenceModal: true
      };
    }
    case 'closeContentPreferenceModal': {
      return {
        ...state,
        openContentPreferenceModal: false
      };
    }
    case 'updateContentPreference': {
      const { _id: contentId, preferences } = action.payload;
      return {
        ...state,
        contents: state.contents.map(content => (content.settings._id === contentId ? { ...content, settings: { ...content.settings, preferences } } : content)),
        openContentPreferenceModal: false
      };
    }
    case 'modalPreferenceSlider':
    case 'modalPreferenceInputRadio': {
      const { key, value } = action;

      return {
        ...state,
        contentPreferenceModalPristine: false,
        modalPreferenceData: {
          ...state.modalPreferenceData,
          [key]: value
        }
      };
    }
    default:
      throw new Error();
  }
};
