import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Row, Form } from 'react-bootstrap';
import { noop, find, get } from 'lodash';
import axios from 'axios';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import useFetcher, { getSearchStringFromObject, fetcher, addQueryToUrl } from 'hooks/useFetcher';
import useDebounce from 'hooks/useDebounce';
import Table from 'atoms/Table';
import Button from 'atoms/Button';
import Input from 'atoms/Input';
import Modal from 'atoms/Modal';
import DropDown from 'atoms/DropDown';
import TablePlaceholder from 'atoms/Table/TablePlaceholder';
import { useFormatMessage } from 'hooks/useFormatMessage';
import searchIcon from 'assets/icons/search.png';
import activeKeyIcon from 'assets/icons/admin-icon.svg';
import RoleCheck from '../../RoleCheck';
import { dispatchUserMessage, assignGroup, showReminderSuccess } from './actions';
import { BASE_URL, axiosConfig } from 'utils/api';
import { getGroupList } from '../../../actions';
import EditUserModalBody from '../../ModalElements/EditUserModalBody';
import Reminder from '../../ModalElements/Reminder';
import UserStatus from '../TableData/UserStatus';
import GroupName from '../TableData/GroupName';
import IconWrapper from 'atoms/IconWrapper';
import { UserListLoader } from './UserListLoader';
import './user-management.scss';

const reducer = (state = {}, action) => {
  const { key, value, payload } = action;
  const { eventKey: { id: dropDownKey } = {} } = action;
  switch (action.type) {
    case 'setUser':
      return {
        ...state,
        currentUser: {
          ...payload,
          groupId: payload.group[0] && payload.group[0]._id
        },
        editAddUserModalPristine: true
      };
    case 'addUser':
      return {
        ...state,
        currentUser: { ...payload },
        editAddUserModalPristine: true
      };
    case 'inputChange':
      return {
        ...state,
        currentUser: { ...state.currentUser, [key]: value },
        editAddUserModalPristine: false
      };
    case 'dropDownChange':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          [key]: dropDownKey
        },
        editAddUserModalPristine: false
      };
    case 'filterChange':
      return { ...state, ...payload };
    case 'clearFilterUsers':
      return { ...state, ...payload };
    case 'handleReminderCheckboxes':
      return { ...state, type: { ...state.type, ...payload } };
    default:
      throw new Error();
  }
};

const UserManagementView = props => {
  const t = useFormatMessage();
  const [modalShow, setModalShow] = useState(false);
  const [addEditUserErrors, setAddEditUserErrors] = useState([false]);
  const [modalUserDeleteShow, setModalUserDeleteShow] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [state, dispatcher] = useReducer(reducer, { users: [], type: {}, sortOrder: 1, sortBy: 'firstName', filterUserBy: '' });
  const { loading, data: items, showLoadMore: hasNextPage, error: itemsError, loadMoreData: loadMore, doFetch, updateData } = useFetcher(`/organizations/${props.selectedOrg}/users`, 100, true);
  const searchText = useDebounce(state.filterUserBy, 1000);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore, //trigger point
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!itemsError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 100px 0px'
  });

  const resetFilters = () => {
    dispatcher({
      type: 'clearFilterUsers',
      payload: {
        filterUserBy: ''
      }
    });
  };

  useEffect(() => {
    doFetch(
      `/organizations/${props.selectedOrg}/users${getSearchStringFromObject({ searchText: searchText, sortBy: state.sortBy, sortOrder: state.sortOrder, progressStatus: state.progressStatus })}`,
      true
    );
    if (props.assignGroupSuccess) {
      setSelectedUsers(new Set());
      props.getGroupList(props.selectedOrg);
    }
  }, [props.assignGroupSuccess, props.selectedOrg, searchText, state.sortBy, state.sortOrder, state.progressStatus]);

  useEffect(() => {
    resetFilters();
  }, [props.selectedOrg]);

  const modalClose = () => {
    if (addEditUserErrors.length > 0) setAddEditUserErrors([]);
    setModalShow(false);
  };

  const handleModalSave = async () => {
    const { _id: userId } = state.currentUser;
    if (userId) {
      const result = await fetcher(`/organizations/${props.selectedOrg}/users/${userId}`, 'PUT', {}, JSON.stringify(state.currentUser));
      if (result.errors) {
        setAddEditUserErrors(result.errors);
        props.dispatchUserMessage('UPDATE_USER_FAILURE');
      } else {
        updateData(items.map(item => (item._id === result.user._id ? { ...item, ...result.user } : item)));
        props.dispatchUserMessage('UPDATE_USER_SUCCESS');
        props.getGroupList(props.selectedOrg);
        setModalShow(false);
      }
    } else {
      const result = await fetcher(`/organizations/${props.selectedOrg}/users`, 'POST', {}, JSON.stringify(state.currentUser));
      if (result.errors) {
        setAddEditUserErrors(result.errors);
        props.dispatchUserMessage('ADD_USER_FAILURE');
      } else {
        props.dispatchUserMessage('ADD_USER_SUCCESS');
        updateData([...items, { ...result.user, userStatus: 'NEVER_LOGGED_IN' }]);
        props.getGroupList(props.selectedOrg);
        setModalShow(false);
      }
    }
  };

  const modalOpen = id => {
    let payload = find(items, orgUser => {
      return orgUser._id === id;
    });
    if (id) {
      dispatcher({ type: 'setUser', payload });
    } else {
      payload = {};
      dispatcher({
        type: 'addUser',
        payload
      });
    }
    setModalShow(true);
  };

  const handleUserSearch = queryEvent => {
    dispatcher({ type: 'filterChange', payload: { filterUserBy: queryEvent.target.value } });
  };

  const handleUserDelete = async () => {
    const payload = {
      userIDs: selectedUsers
    };
    const endpoint = `/organizations/${props.selectedOrg}/users`;
    const response = await fetcher(endpoint, 'DELETE', {}, JSON.stringify(payload));
    if (response?.modifiedCount) {
      setModalUserDeleteShow(false);
      setSelectedUsers(new Set());
      props.getGroupList(props.selectedOrg);
      const deletedUserSet = new Set(response.deletedUsersId.map(x => x._id));
      updateData(items.filter(x => !deletedUserSet.has(x._id)));
    }
  };

  const handleAssignGroup = eventKey => {
    if (!selectedUsers.size) {
      return;
    }
    const group = props.groupsList.find(g => g._id === eventKey.id);
    const payload = {
      userIDs: Array.from(selectedUsers),
      groupId: group._id,
      group
    };
    props.assignGroup({ payload, orgId: props.selectedOrg });
  };

  const handleUserDeleteAlert = () => {
    if (!selectedUsers.size) return;
    setModalUserDeleteShow(true);
  };

  const sendReminder = async () => {
    // handle api logic
    const endpoint = `${BASE_URL}/organizations/${props.selectedOrg}/sendPendingModules`;
    await axios.post(
      endpoint,
      {
        selectedUserIds: Array.from(selectedUsers),
        sendTo: Object.keys(state.type).filter(u => state.type[u])
      },
      axiosConfig('application/json')
    );
    dispatcher({ type: 'handleReminderCheckboxes', payload: { SELECTED: false, ACTIVE: false, NEVER_LOGGED_IN: false } });
    props.showReminderSuccess();
    setShowReminderModal(false);
  };
  const handleReminderCheck = payload => {
    dispatcher({ type: 'handleReminderCheckboxes', payload });
  };

  const groupListMap = groups => {
    return groups.map(group => ({
      id: group._id,
      value: group.name,
      color: group.colorCode
    }));
  };

  const formatUsers = (users = []) => {
    return users.map(user => {
      return {
        ...user,
        role: t(`common/allowed-roles/${user.role}`),
        meta: {
          role: user.role
        }
      };
    });
  };

  const getRestrictedIndex = (users = []) => {
    const restricted = [];
    users.forEach((u, i) => {
      if (u.role?.toLowerCase() === 'admin') {
        restricted.push(i);
      }
    });
    return restricted;
  };

  const handleSort = (sortBy, sortOrder) => {
    dispatcher({ type: 'filterChange', payload: { sortBy, sortOrder } });
  };

  const handleProgressFilter = eventKey => {
    if (['COMPLETED', 'UNCOMPLETED'].includes(eventKey.id)) {
      setSelectedUsers(new Set());
    }
    dispatcher({ type: 'filterChange', payload: { progressStatus: eventKey.id } });
  };

  const handleDownloadStatistics = () => {
    let sendTo = state.progressStatus;
    if (!state.progressStatus) {
      sendTo = selectedUsers.size > 0 ? 'SELECTED' : null;
    }
    window.open(addQueryToUrl(`${BASE_URL}/organizations/${props.selectedOrg}/stats/download`, { token: props.authToken, sendTo, userIds: Array.from(selectedUsers.values()).join(',') }), '_self');
  };

  const { useSSO } = props.organizations.find(organization => organization._id === props.selectedOrg) || {};
  return (
    <div className="user-management">
      <Row>
        <Col>
          <Form className="user-management__form" onSubmit={e => e.preventDefault()}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div className="mt-0" style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="search-bar mr-2" style={{ position: 'relative', height: 35 }}>
                  <Input
                    value={state.filterUserBy}
                    onChange={handleUserSearch}
                    disabled={props.role === 'PARTNER' ? !props.allowPartnersToManageUsers : false}
                    iconImage={searchIcon}
                    isClearable
                    onClear={resetFilters}
                  />
                </div>
                <div className="filter">
                  <DropDown
                    className="mr-2"
                    onSelect={handleProgressFilter}
                    placeholder={t('user-management/filter')}
                    style={{ width: 140, height: 35 }}
                    heading={state.progressStatus}
                    values={[
                      { id: null, value: t('user-management/filter-ALL') },
                      { id: 'COMPLETED', value: t('user-management/filter-COMPLETED') },
                      { id: 'UNCOMPLETED', value: t('user-management/filter-UNCOMPLETED') }
                    ]}
                  />
                </div>
                <Button size="md" onClick={handleDownloadStatistics} className="mr-2 ellipsis" variant="primary" style={{ width: 100, height: 35 }}>
                  {t('user-management/download-stats')}
                  <IconWrapper type="DownloadWhite" style={{ marginLeft: 12, height: 14 }} />
                </Button>
              </div>
              <div className="mt-0" style={{ display: 'flex', flexDirection: 'row' }}>
                <DropDown
                  disabled={(props.role === 'PARTNER' ? !props.allowPartnersToManageUsers : false) || props.groupsList.length < 1}
                  onSelect={handleAssignGroup}
                  isColored
                  isStaticHeading
                  style={{ marginRight: '5px' }}
                  placeholder={t('user-management/assign-group-to-the-selected')}
                  values={groupListMap(props.groupsList)}
                />
                <Button
                  disabled={props.role === 'PARTNER' ? !props.allowPartnersToManageUsers : false}
                  size="md"
                  onClick={() => modalOpen()}
                  className="mr-2 ellipsis"
                  variant="primary"
                  style={{ width: 110, height: 35 }}
                >
                  {t('user-management/add-user')}
                </Button>

                <Button
                  disabled={props.role === 'PARTNER' ? !props.allowPartnersToManageUsers : false}
                  size="md"
                  onClick={() => handleUserDeleteAlert()}
                  className="mr-2 ellipsis"
                  variant="primary"
                  style={{ width: 110, height: 35 }}
                >
                  {t('user-management/delete-users')}
                </Button>
                <Button
                  disabled={props.role === 'PARTNER' ? !props.allowPartnersToManageUsers : false}
                  size="md"
                  onClick={() => setShowReminderModal(true)}
                  className="mr-2 ellipsis"
                  style={{ width: 110, height: 35, backgroundColor: '#f05f5a' }}
                >
                  {t('user-management/send-reminder')}
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <RoleCheck
            allowed={['MANAGER', 'ADMIN', ...(props.allowPartnersToManageUsers ? ['PARTNER'] : [])]}
            {...props}
            component={
              <>
                <Table
                  style={{ minWidth: 1110 }}
                  columnConfig={props.columnConfig}
                  activeSort={{ sortKey: state.sortBy, sortOrder: state.sortOrder }}
                  forceDisableSort={!!searchText}
                  forceDisableCheckbox={['COMPLETED', 'UNCOMPLETED'].includes(state.progressStatus)}
                  handleEdit={modalOpen}
                  selectedIds={selectedUsers}
                  onSelect={checked => setSelectedUsers(checked)}
                  data={formatUsers(items)}
                  restrictedIndex={getRestrictedIndex(items)}
                  onSort={handleSort}
                  renderIcon={row => {
                    if (row?.meta?.role === 'ADMIN') {
                      return <img src={activeKeyIcon} alt="admin icon" />;
                    }
                    return null;
                  }}
                />
                {loading && (
                  <>
                    <UserListLoader />
                    <UserListLoader />
                    <UserListLoader />
                    <UserListLoader />
                    <UserListLoader />
                    <UserListLoader />
                    <UserListLoader />
                    <UserListLoader />
                    <UserListLoader />
                    <UserListLoader />
                  </>
                )}
                {(loading || hasNextPage) && <div ref={sentryRef}></div>}
                {!loading && !hasNextPage && items.length === 0 && <div className="no-users-message">{t('user-management/no-user-with-criteria')}</div>}
              </>
            }
            fallbackComp={<TablePlaceholder columnConfig={props.columnConfig} overlayText={t('user-management/permission-denied-text')} />}
          />
        </Col>
        <Modal
          heading={state.currentUser && state.currentUser._id ? t('user-management/edit-user') : t('user-management/add-user')}
          modalBody={
            <EditUserModalBody useSSO={useSSO} dispatcher={dispatcher} allowedRoles={props.allowedRoles.updateUser} error={addEditUserErrors} groupsList={props.groupsList} user={state.currentUser} />
          }
          show={modalShow}
          notPristine={state.editAddUserModalPristine}
          onSave={handleModalSave}
          onHide={modalClose}
        />
        <Modal
          heading={t('common/modal/please-confirm-text')}
          modalBody={<p>{t('common/modal/user-inactive-warning-text')}</p>}
          show={modalUserDeleteShow}
          isAlert
          onSave={handleUserDelete}
          onHide={() => setModalUserDeleteShow(false)}
        />
        <Modal
          className="reminder-modal"
          heading={t('user-management/send-reminder-to')}
          modalBody={<Reminder user={state.currentUser} selectedUsersSize={selectedUsers.size || 0} handleReminderCheck={handleReminderCheck} type={state.type} />}
          show={showReminderModal}
          saveText={t('common/send-reminder')}
          onSave={() => sendReminder()}
          onHide={() => setShowReminderModal(false)}
        />
      </Row>
    </div>
  );
};
const mapStateToProps = ({ organizationUsers, userState, groupState, adminState }) => {
  return {
    authToken: get(userState, 'user.authToken', null),
    assignGroupSuccess: organizationUsers.assignGroupSuccess,
    allowedRoles: adminState.allowedRoles,
    organizations: adminState.organizations,
    groupsList: groupState.groupsList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchUserMessage: bindActionCreators(dispatchUserMessage, dispatch),
    getGroupList: bindActionCreators(getGroupList, dispatch),
    assignGroup: bindActionCreators(assignGroup, dispatch),
    showReminderSuccess: bindActionCreators(showReminderSuccess, dispatch)
  };
};

UserManagementView.propTypes = {
  tablePlaceholderKyes: PropTypes.arrayOf(PropTypes.string),
  organizations: PropTypes.arrayOf(PropTypes.shape({})),
  showReminderSuccess: PropTypes.func,
  columnConfig: PropTypes.arrayOf(PropTypes.shape({})),
  allowedRoles: PropTypes.shape({
    updateUser: PropTypes.arrayOf(PropTypes.string)
  }),
  role: PropTypes.string.isRequired,
  allowPartnersToManageUsers: PropTypes.bool.isRequired,
  authToken: PropTypes.string,
  selectedOrg: PropTypes.string,
  dispatchUserMessage: PropTypes.func,
  getGroupList: PropTypes.func,
  assignGroupSuccess: PropTypes.bool,
  useSSO: PropTypes.bool,
  groupsList: PropTypes.arrayOf(PropTypes.shape({})),
  assignGroup: PropTypes.func
};

UserManagementView.defaultProps = {
  columnConfig: [
    {
      key: 'userStatus',
      isSortable: true,
      headerStyles: { width: '140px' },
      dataStyles: { maxWidth: '140px' },
      // eslint-disable-next-line react/display-name
      renderItem: item => <UserStatus item={item} />
    },
    { key: 'firstName', isSortable: true, headerStyles: { width: '120px' }, dataStyles: { maxWidth: '120px' } },
    { key: 'lastName', isSortable: true, headerStyles: { width: '120px' }, dataStyles: { maxWidth: '120px' } },
    { key: 'email', isSortable: true, headerStyles: { width: '260px' }, dataStyles: { maxWidth: '260px' } },
    {
      key: 'group',
      isSortable: true,
      overrideSortKey: 'groupName',
      headerStyles: { width: '220px', paddingLeft: 42 },
      dataStyles: { maxWidth: '220px' },
      // eslint-disable-next-line react/display-name
      renderItem: item => <GroupName name={get(item, '0.name', '')} colorCode={get(item, '0.colorCode', '')} />
    },
    { key: 'role', isSortable: true, headerStyles: { width: '150px' }, dataStyles: { maxWidth: '150px' } }
  ],
  allowedRoles: {},
  getGroupList: () => {},
  selectedOrg: '',
  dispatchUserMessage: noop,
  assignGroupSuccess: false,
  showReminderSuccess: noop,
  groupsList: [],
  assignGroup: noop
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementView);
