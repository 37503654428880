import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, InputGroup } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import IconWrapper from 'atoms/IconWrapper';
import Input from 'atoms/Input';
import Button from 'atoms/Button';
import Switch from 'atoms/Switch';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { updateAccountWebhookSettings } from '../actions';
import './webhook.scss';

const initialState = { url: '', secret: '', alertEmail: '', events: [], status: 'INACTIVE' };

const reducer = (state, action) => {
  const { key, value } = action;
  switch (action.type) {
    case 'inputChange':
      return {
        ...state,
        [key]: value,
        touched: true
      };
    case 'checkboxChange':
      return {
        ...state,
        [key]: value,
        touched: true
      };
    case 'setStateAsUnTouched':
      return {
        ...state,
        touched: false
      };
    case 'initializeState': {
      return {
        ...state,
        ...action.payload,
        touched: false
      };
    }
    default:
      throw new Error();
  }
};

const WebhookSettings = ({ selectedOrg, selectedOrgDetails, hasSelectedOrgUpdated, updateAccountWebhookSettings }) => {
  const t = useFormatMessage();
  const [showSecret, setShowSecret] = useState(false);
  const [state, dispatcher] = useReducer(reducer, { touched: false });
  const { url, secret, alertEmail, events = [], status } = state;

  useEffect(() => {
    if (hasSelectedOrgUpdated) {
      dispatcher({
        type: 'setStateAsUnTouched'
      });
    }
  }, [hasSelectedOrgUpdated]);

  useEffect(() => {
    if (!isEmpty(selectedOrgDetails)) {
      const { webhook = {} } = selectedOrgDetails;
      dispatcher({
        type: 'initializeState',
        payload: { ...initialState, ...webhook }
      });
    }
  }, [selectedOrgDetails]);

  const handleUpdateWebhookSettings = () => {
    updateAccountWebhookSettings({
      organizationId: selectedOrg,
      payload: {
        url,
        secret,
        alertEmail,
        events,
        status
      }
    });
  };

  return (
    <React.Fragment>
      <Container className="webhook-settings-container">
        <Row className="webhook-settings-container__row">
          <Col className="pl-0">{t('account-settings/enabled')}</Col>
          <Col className="pr-0 webhook-settings-container__row__flex-end">
            <Switch
              id="status"
              onSelect={() => {
                dispatcher({
                  type: 'inputChange',
                  key: 'status',
                  value: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
                });
              }}
              value={status === 'ACTIVE'}
            />
          </Col>
        </Row>
        <Row className="webhook-settings-container__row">
          <Col className="pl-0">{t('account-settings/webhook-url')}</Col>
          <Col className="pr-0 webhook-settings-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'url',
                  value: e.target.value
                })
              }
              placeholder={t('account-settings/webhook-url-placeholder')}
              value={url}
            />
          </Col>
        </Row>

        <Row className="webhook-settings-container__row">
          <Col className="pl-0">{t('account-settings/webhook-secret')}</Col>
          <Col className="pr-0 webhook-settings-container__row__flex-end">
            <InputGroup>
              <Input
                autoComplete="new-password"
                type={showSecret ? 'text' : 'password'}
                onChange={e =>
                  dispatcher({
                    type: 'inputChange',
                    key: 'secret',
                    value: e.target.value
                  })
                }
                placeholder={t('account-settings/webhook-secret-placeholder')}
                value={secret}
              />
              <InputGroup.Append>
                <Button
                  onClick={() => {
                    setShowSecret(!showSecret);
                  }}
                  variant="secondary"
                  style={{ color: '#00aaa4' }}
                  size="sm"
                >
                  {showSecret ? <IconWrapper type="PreviewHide" alt="Hide secret" /> : <IconWrapper type="Preview" alt="Show secret" />}
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>

        <Row className="webhook-settings-container__row">
          <Col className="pl-0">{t('account-settings/webhook-alert-email')}</Col>
          <Col className="pr-0 webhook-settings-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'alertEmail',
                  value: e.target.value
                })
              }
              placeholder={t('account-settings/webhook-alert-email-placeholder')}
              value={alertEmail}
            />
          </Col>
        </Row>

        <Row className="webhook-settings-container__row">
          <Col className="pl-0">{t('account-settings/webhook-events')}</Col>
          <Col className="pr-0">
            {['module.content.started', 'module.content.completed'].map(item => (
              <div key={item} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                <span className="mb-1">{item}</span>
                <Switch
                  id={item}
                  onSelect={() => {
                    dispatcher({
                      type: 'checkboxChange',
                      key: 'events',
                      value: events.includes(item) ? events.filter(event => event !== item) : [...events, item]
                    });
                  }}
                  value={events.includes(item)}
                />
              </div>
            ))}
          </Col>
        </Row>

        <Row className="webhook-settings-container__row__action">
          <div>
            <Button notPristine={!state.touched} onClick={handleUpdateWebhookSettings} style={{ minWidth: '100px' }}>
              {t('common/save')}
            </Button>
            <div className="webhook-settings-container__row__action--touched">{state.touched ? t('common/you-have-unsaved-changes') : ''}</div>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
};

WebhookSettings.propTypes = {
  selectedOrg: PropTypes.string.isRequired,
  updateAccountWebhookSettings: PropTypes.func.isRequired,
  hasSelectedOrgUpdated: PropTypes.bool.isRequired,
  selectedOrgDetails: PropTypes.shape({
    webhook: PropTypes.shape({
      url: PropTypes.string,
      secret: PropTypes.string,
      alertEmail: PropTypes.string,
      events: PropTypes.arrayOf(PropTypes.string),
      status: PropTypes.string
    })
  })
};

WebhookSettings.defaultProps = {
  hasSelectedOrgUpdated: false
};

const mapStateToProps = ({ accountSettings }) => {
  const { selectedOrgDetails, hasSelectedOrgUpdated, webhookErrors } = accountSettings;
  return {
    selectedOrgDetails,
    hasSelectedOrgUpdated,
    webhookErrors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAccountWebhookSettings: bindActionCreators(updateAccountWebhookSettings, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebhookSettings));
