import React from 'react';
import { MediaPropTypesShape } from 'utils/core-proptypes';
import { getMediaUrl } from 'utils/calculationUtilities';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ embed, media, defaultUrl, onVideoPlayerStart, onVideoPlayerEnd, ...rest }) => {
  const resolvedMediaUrl = defaultUrl || getMediaUrl(media);
  const getPlayer = () => {
    return (
      <div className={embed ? 'embed-responsive embed-responsive-16by9' : ''}>
        <ReactPlayer
          config={{
            youtube: {
              playerVars: { showinfo: 1, controls: 1, rel: 0 }
            },
            file: {
              attributes: { controls: true, controlslist: 'nodownload', disablePictureInPicture: true }
            },
            vimeo: {
              playerOptions: {
                autopause: false,
                byline: false,
                portrait: false,
                title: false
              },
              preload: true
            }
          }}
          width="100%"
          height="100%"
          controls={true}
          url={resolvedMediaUrl}
          onStart={onVideoPlayerStart}
          onEnded={onVideoPlayerEnd}
          playsinline={true}
          {...rest}
        />
      </div>
    );
  };

  return getPlayer();
};

VideoPlayer.defaultProps = {
  embed: true
};

VideoPlayer.propTypes = {
  embed: PropTypes.bool,
  media: MediaPropTypesShape,
  onVideoPlayerStart: PropTypes.func,
  onVideoPlayerEnd: PropTypes.func
};

export default VideoPlayer;
