import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useFormatMessage } from 'hooks/useFormatMessage';
import IconWrapper from 'atoms/IconWrapper';
import DiplomaDownloadLink from '../../DiplomaDownloadLink';

import './mixed-status.scss';

const MixedStatus = ({ isPreview, isAuthenticated, showStatus, totalCount, passCount, attempted, showContentOnDiploma }) => {
  const t = useFormatMessage();
  const totalAttempted = Object.keys(attempted);
  const totalQuestions = totalCount;

  const correct = totalAttempted.filter(key => attempted[key].success);
  const passed = correct.length >= passCount;
  const message = passed ? t('mcq-view-status/you-passed') : t('mcq-view-status/sorry-you-did-not-pass');
  return (
    <Container className="mixed-status">
      <Col className="mixed-status__row">
        {totalAttempted.length === totalQuestions && showStatus ? (
          <>
            <Row className="mixed-status__col" lg={8} sm={12}>
              <span className="mixed-status__questions--correct">{correct.length}</span>
              <IconWrapper type="Right" alt="Correct" className="mixed-status__questions__icon--correct" />
              <IconWrapper type={passed ? 'ThumbsUp' : 'ThumbsDown'} alt={message} />
              <IconWrapper type="Wrong" alt="Wrong" className="mixed-status__questions__icon--wrong" />
              <span className="mixed-status__questions--wrong">{totalQuestions - correct.length}</span>
            </Row>
            {passed && !isPreview && showContentOnDiploma === 'true' && <DiplomaDownloadLink isAuthenticated={isAuthenticated} />}
          </>
        ) : null}
      </Col>
    </Container>
  );
};

MixedStatus.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  passCount: PropTypes.number.isRequired,
  attempted: PropTypes.shape({}).isRequired,
  showStatus: PropTypes.bool.isRequired,
  showContentOnDiploma: PropTypes.bool.isRequired
};

MixedStatus.defaultProps = {};

export default MixedStatus;
