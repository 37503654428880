import { orderBy, upperFirst, isEmpty, uniqBy, lowerCase, sortBy, find, omit, get } from 'lodash';
import { mediaSelector, mcqListSelector, mixedListSelector } from './selectors';

export const checkNaNPercent = percent => (isNaN(percent) ? 0 : percent.toFixed(0));

export const randomize = () => Math.random() - 0.5;

export const randomizeArray = (array = []) => array.sort(randomize);

export const getPriortizedDataAccordingToLanguage = (priority = [], data = [], key = 'langCode') => {
  let match = null;
  let index = 0;
  // 1st Fallback. Set the english to priority if nothing is found
  const mPriority = !priority.includes('en') ? [...priority, 'en'] : priority;

  while (!match && index < mPriority.length) {
    const found = data.find(d => d[key] === mPriority[index]);
    found ? (match = found) : index++;
  }
  // 2nd Fallback Pick the zeroth index
  if (!match && data.length > 0) {
    match = data[0];
  }

  return match;
};
export const formatLanguages = (languages = []) => {
  return orderBy(
    languages.map(lang => ({
      name: `${lang.language} (${lang.langCode.toUpperCase()})`,
      id: lang.langCode,
      value: `${lang.language} (${lang.langCode.toUpperCase()})`
    })),
    ['value'],
    ['asc']
  );
};

export const headingFormatter = heading => {
  return heading ? upperFirst(lowerCase(heading)) : null;
};

export const formatTimezone = (timezones = []) => {
  return timezones.map(timezone => ({
    id: timezone.timezone,
    value: `${timezone.city}`
  }));
};

export const getAllCounriesTimezones = (timezones = []) => {
  const countries = uniqBy(timezones, 'country');
  return orderBy(
    countries.map(item => ({
      id: item.country,
      value: `${item.country}`
    })),
    ['value'],
    ['asc']
  );
};

export const getFilteredTimezone = (timezones, country) => {
  const filteredCountries = timezones.filter(timezone => timezone.country === country);
  return orderBy(
    filteredCountries.map(item => ({
      id: item.timezone,
      value: `${item.city}`
    })),
    ['value'],
    ['asc']
  );
};

export const dateValues = [
  { id: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
  { id: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
  { id: 'YYYY-MM-DD', value: 'YYYY-MM-DD' }
];

export const isYoutubeUrl = url => {
  const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return youtubeRegex.test(url);
};

export const isVimeoUrl = url => {
  const vimeoRegex = /https:\/\/vimeo.com\/\d{8,10}(?=\b|\/)/;
  return vimeoRegex.test(url);
};

export const groupListMap = (groups = []) => {
  const a = orderBy(
    groups.map(group => ({
      _id: group._id,
      value: group.name,
      color: group.colorCode,
      usersCount: group.usersCount
    })),
    'value'
  );
  return a;
};

export const AllowedMediaTypeSource = {
  FILE: 'File Repository',
  VIMEO: 'Vimeo',
  YOUTUBE: 'Youtube'
};

export const availableLanguages = data => {
  const langages = sortBy(data.map(content => content.langCode.toUpperCase()));
  return langages.join(', ');
};

export const getPreviewData = ({ contents, contentId }) => {
  let previewContentData = [];
  const contentToBePreviewed = contents.find(content => content._id === contentId) || {};
  const { data, defaultLangCode } = contentToBePreviewed;
  if (data) {
    previewContentData = data.find(content => content.langCode === defaultLangCode);
  }
  return {
    preferences: contentToBePreviewed.preferences,
    contentData: previewContentData
  };
};

export const getLowerCaseContentType = (contentType = '') => {
  switch (contentType.toUpperCase()) {
    case 'MCQ': {
      return 'mcq';
    }
    case 'PRESENTATION': {
      return 'presentation';
    }
    case 'CAROUSEL': {
      return 'carousel';
    }
    case 'STORY': {
      return 'story';
    }
    case 'MIXED': {
      return 'mixed';
    }
    default:
      return '';
  }
};

export const getMediaUrl = media => {
  if (media.type === 'FILE') {
    return mediaSelector({ media });
  }
  return media.url;
};

export const getMediaPayload = (media, mediaPristine, key) => {
  if (!mediaPristine || isEmpty(media)) {
    return {};
  }
  return { [`${key}Url`]: media.url, [`${key}Type`]: media.type };
};

export const getFileExtension = file => {
  var re = /(?:\.([^.]+))?$/;
  if (file.metaData) {
    return re.exec(file.metaData.key)[1];
  }
  return re.exec(file.key)[1];
};

export const getFalsyValue = value => {
  return value === 'false' ? false : true;
};

export const appendEmailAddWithName = items => {
  return items.map(item => ({
    id: item._id,
    name: `${item.name} (${item.email})`
  }));
};

export const contentDataSelector = ({ contentData, langCode }) => {
  const selectedContent = find(get(contentData, 'data', {}), d => d.langCode.toUpperCase() === langCode.toUpperCase());
  const { contentType } = contentData;
  if (!selectedContent) return false;
  switch (contentType.toUpperCase()) {
    case 'MCQ': {
      const mcqList = selectedContent && mcqListSelector({ mcqList: selectedContent.mcqList });
      return {
        ...omit(selectedContent, ['mcqList']),
        ...selectedContent.details,
        mcqList
      };
    }
    case 'MIXED': {
      const componentList = selectedContent && mixedListSelector({ componentList: selectedContent.componentList });
      return {
        ...omit(selectedContent, ['componentList']),
        ...selectedContent.details,
        componentList
      };
    }
    case 'STORY':
    case 'CAROUSEL':
    case 'PRESENTATION': {
      return {
        ...omit(selectedContent, ['details']),
        ...selectedContent.details
      };
    }
    default:
      return false;
  }
};

export const publicPaths = ['/content-list/:moduleId', '/mcq/:trackingId', '/presentation/:trackingId', '/carousel/:trackingId', '/story/:trackingId', '/mixed/:trackingId'];

export const parseQueryString = location => {
  const data = location.search ? location.search.slice(location.search.indexOf('?') + 1).split('&') : [];
  const urlData = {};
  data.forEach(data => {
    try {
      data = data.split('=');
      const dataVal = decodeURIComponent(data[1]);
      urlData[data[0]] = dataVal;
    } catch (e) {
      console.log(e);
    }
  });
  return urlData;
};
