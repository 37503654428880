import {
  ORGANIZATION,
  ORGANIZATION_SUCCESS,
  ORGANIZATION_FAILURE,
  ADD_ORGANIZATION,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_FAILURE,
  ALLOWED_ROLES_SUCCESS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  GET_COLOR,
  GET_COLOR_SUCCESS,
  GET_COLOR_FAILURE,
  FETCH_LOCALE_LANGUAGE,
  FETCH_LOCALE_LANGUAGE_SUCCESS,
  FETCH_LOCALE_LANGUAGE_FAILURE,
  FETCH_PUBLICATION,
  FETCH_PUBLICATION_SUCCESS,
  FETCH_PUBLICATION_FAILURE,
  CLEAR_ACCOUNT_ERROR,
  UPDATE_ACCOUNT_SETTINGS_SUCCESS,
  DELETE_MINDZEED_ACCOUNT,
  DELETE_MINDZEED_ACCOUNT_SUCCESS,
  DELETE_MINDZEED_ACCOUNT_FAILURE
} from 'utils/constants';

const initialState = {
  organizations: [],
  colorList: [],
  allowedRoles: {},
  fetching: false,
  localLanguages: [],
  addUpdateOrganizationAndRegisterManagerSuccess: false,
  localeLangugagesFetching: false,
  localLanguagesSuccess: false,
  orgDeleted: false
};

const adminState = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION:
      return {
        ...state,
        fetching: true
      };

    case ORGANIZATION_SUCCESS: {
      const allOrgs = action.payload || [];
      const mindzeedOrgs = allOrgs.filter(org => org.accountType === 'MINDZEED');
      const partnerOrgs = allOrgs.filter(org => org.accountType === 'PARTNER');
      const businessOrgs = allOrgs.filter(org => org.accountType === 'BUSINESS');
      const unitOrgs = allOrgs.filter(org => org.accountType === 'ORG_UNIT');

      return { ...state, organizations: [...mindzeedOrgs, ...partnerOrgs, ...businessOrgs, ...unitOrgs], fetching: false };
    }
    case ORGANIZATION_FAILURE:
    case ADD_ORGANIZATION_FAILURE:
    case UPDATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        error: action.payload.response.errors,
        fetching: false
      };

    case ADD_ORGANIZATION:
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        fetching: true,
        addUpdateOrganizationAndRegisterManagerSuccess: false
      };

    case ADD_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: [...state.organizations, action.payload],
        fetching: false,
        addUpdateOrganizationAndRegisterManagerSuccess: true
      };

    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: state.organizations.map(org => (org._id === action.payload.organization._id ? { ...org, ...action.payload.organization } : org)),
        fetching: false,
        addUpdateOrganizationAndRegisterManagerSuccess: true
      };
    case GET_COLOR:
      return {
        ...state,
        colorFetching: true
      };

    case GET_COLOR_SUCCESS:
      return {
        ...state,
        colorFetching: false,
        colorList: action.payload
          .filter((color, i) => {
            return i % 10 === 0;
          })
          .slice(0, 12)
      };

    case GET_COLOR_FAILURE:
      return {
        ...state,
        colorFetching: false
      };
    case ALLOWED_ROLES_SUCCESS:
      return {
        ...state,
        allowedRoles: action.payload
      };

    case CLEAR_ACCOUNT_ERROR:
      return {
        ...state,
        error: []
      };

    case FETCH_LOCALE_LANGUAGE:
      return {
        ...state,
        localeLangugagesFetching: true,
        localLanguagesSuccess: false
      };

    case FETCH_LOCALE_LANGUAGE_SUCCESS:
      return {
        ...state,
        localeLangugagesFetching: false,
        localLanguages: action.payload,
        localLanguagesSuccess: true
      };

    case FETCH_LOCALE_LANGUAGE_FAILURE:
      return {
        ...state,
        localeLangugagesFetching: false,
        localLanguagesSuccess: false
      };
    case FETCH_PUBLICATION:
      return {
        ...state,
        publicationsFetching: true,
        publicationsSuccess: false
      };

    case FETCH_PUBLICATION_SUCCESS:
      return {
        ...state,
        publicationsFetching: false,
        publications: action.payload,
        publicationsSuccess: true
      };

    case FETCH_PUBLICATION_FAILURE:
      return {
        ...state,
        publicationsFetching: false,
        publicationsSuccess: false
      };
    case UPDATE_ACCOUNT_SETTINGS_SUCCESS: {
      const mlist = state.organizations.map(org => (org._id === action.payload.organization._id ? { ...org, ...action.payload.organization } : org));
      return {
        ...state,
        organizations: mlist
      };
    }

    case DELETE_MINDZEED_ACCOUNT:
    case DELETE_MINDZEED_ACCOUNT_FAILURE:
      return {
        ...state,
        orgDeleted: false
      };

    case DELETE_MINDZEED_ACCOUNT_SUCCESS:
      return {
        ...state,
        orgDeleted: true,
        organizations: state.organizations.filter(org => org._id !== action.payload.deletedOrganizationId)
      };

    default:
      return state;
  }
};
export default adminState;
