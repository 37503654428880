export default (state, action) => {
  const { key, value } = action;

  switch (action.type) {
    case 'inputChange':
    case 'dropDown':
    case 'inputRadio':
      return {
        ...state,
        ...{ [key]: value },
        mcqContentCreateUpdatePristine: false
      };
    case 'setThumbnail': {
      const { thumbnail } = action;
      return {
        ...state,
        thumbnail,
        mcqContentCreateUpdatePristine: false,
        thumbnailPristine: true
      };
    }
    case 'setMedia': {
      const { media } = action;
      return {
        ...state,
        media,
        mediaPristine: true,
        mcqContentCreateUpdatePristine: false
      };
    }
    case 'initialState': {
      return {
        ...state,
        ...action.payload,
        mcqContentCreateUpdatePristine: true,
        mediaPristine: false,
        thumbnailPristine: false
      };
    }
    default:
      throw new Error();
  }
};
