import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';

import {
  ADD_EMAIL_TEMPLATE,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  ADD_EMAIL_TEMPLATE_FAILURE,
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAILURE,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_TEMPLATE_FAILURE
} from 'utils/constants';

export const addEmailTemplate = payload => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/emailSettings`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [ADD_EMAIL_TEMPLATE, ADD_EMAIL_TEMPLATE_SUCCESS, ADD_EMAIL_TEMPLATE_FAILURE]
  }
});

export const updateEmailTemplate = ({ payload, emailTemplateId }) => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/emailSettings/${emailTemplateId}`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
    types: [UPDATE_EMAIL_TEMPLATE, UPDATE_EMAIL_TEMPLATE_SUCCESS, UPDATE_EMAIL_TEMPLATE_FAILURE]
  }
});

export const getAllEmailTemplates = () => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/emailSettings`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [GET_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATE_SUCCESS, GET_EMAIL_TEMPLATE_FAILURE]
  }
});
