import React from 'react';
import PropTypes from 'prop-types';

import VideoViewer from '../VideoView';
import './carousel-card.scss';

const CarouselCard = ({ carouselData }) => {
  const { heading, documentation, media } = carouselData;
  return (
    <div className="carousel-card fade-in-2s">
      <VideoViewer media={media} showHideButton={false} fullWidth={true} />
      <h4>{heading}</h4>
      <div
        className="carousel-card__rich-text"
        dangerouslySetInnerHTML={{
          __html: documentation
        }}
      />
    </div>
  );
};

CarouselCard.propTypes = {
  carouselData: PropTypes.shape({
    _id: PropTypes.string,
    media: PropTypes.shape({}),
    heading: PropTypes.string,
    documentation: PropTypes.string
  }),
  isPreview: PropTypes.bool
};

CarouselCard.defaultProps = {
  carouselData: {},
  isPreview: false
};

export default CarouselCard;
