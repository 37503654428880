import { cloneDeep, omit } from 'lodash';
import uuidv4 from 'uuid/v4';

export const reducer = (state, action) => {
  const { key, value } = action;

  switch (action.type) {
    case 'inputChange':
    case 'dropDown':
    case 'inputRadio':
      return {
        ...state,
        ...{ [key]: value },
        mixedContentCreateUpdatePristine: false
      };
    case 'addBlock':
      return {
        ...state,
        componentList: [...state.componentList, action.payload],
        mixedContentCreateUpdatePristine: false
      };
    case 'sortComponentList':
      return {
        ...state,
        componentList: [...action.payload],
        mixedContentCreateUpdatePristine: false
      };
    case 'updateAnswer': {
      const { questionIndex, answerIndex } = action;
      const question = { ...state.componentList[questionIndex] };
      question.options[answerIndex] = value;

      return { ...state, mixedContentCreateUpdatePristine: false };
    }
    case 'addAnswer': {
      const { questionIndex } = action;
      const { componentList } = { ...state };
      const question = componentList[questionIndex];
      question.options.push('');
      return {
        ...state,
        componentList: [...componentList],
        mixedContentCreateUpdatePristine: false
      };
    }
    case 'updateQuestion': {
      const { index, field } = action;
      const fieldValue = action.value;

      const componentList = [...state.componentList];
      componentList[index][field] = fieldValue;

      return { ...state, componentList, mixedContentCreateUpdatePristine: false };
    }

    case 'deleteQuestion': {
      const { index } = action;
      const componentList = [...state.componentList];
      componentList.splice(index, 1);
      return {
        ...state,
        componentList: [...componentList],
        mixedContentCreateUpdatePristine: false
      };
    }

    case 'addItem': {
      const { index, key, value } = action;
      const componentList = [...state.componentList];
      componentList[index][key] = value;
      return { ...state, componentList, mixedContentCreateUpdatePristine: false };
    }
    case 'setItemMedia': {
      const { index, media } = action;
      return {
        ...state,
        componentList: state.componentList.map((item, i) => (i === index ? { ...item, media: { ...media, upload: true } } : item)),
        mixedContentCreateUpdatePristine: false
      };
    }

    case 'duplicateQuestion': {
      const { index } = action;
      const newState = { ...state };
      const newQuestion = omit(cloneDeep({ ...state.componentList[index] }), ['_id']);
      newQuestion._id = uuidv4();
      newState.componentList = [...state.componentList];
      newState.componentList.splice(index + 1, 0, { ...newQuestion });
      return { ...state, ...newState, mixedContentCreateUpdatePristine: false };
    }
    case 'initialState': {
      return {
        ...state,
        ...action.payload,
        mixedContentCreateUpdatePristine: true,
        mediaPristine: false,
        thumbnailPristine: false
      };
    }
    default:
      throw new Error();
  }
};
