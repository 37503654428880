import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { formatLanguages } from 'utils/calculationUtilities';
import DropDown from 'atoms/DropDown';
import Input from 'atoms/Input';
import { useFormatMessage } from 'hooks/useFormatMessage';
import './modal-elements.scss';

const findContentLangs = (contents, selectedContentId, locales) => {
  if (!selectedContentId) {
    return [];
  }
  const content = contents.find(item => item.content._id === selectedContentId);
  const langCodes = new Set(content.content.data.map(x => x.langCode));
  const languages = locales.filter(x => langCodes.has(x.langCode));
  return formatLanguages(languages);
};

const ContentUserStatusUpdate = ({ state, setState, locales, contents = [], message, onClose }) => {
  const t = useFormatMessage();
  const {
    show,
    data: { successCount }
  } = message;

  if (show) {
    return (
      <div>
        <h2>{t('content-update-modal/its-done')}</h2>
        <p>{t('content-update-modal/changed-status').replace('{successCount}', successCount)}</p>
        <span onClick={onClose} className="module-preference__status-completed">
          {t(`common/close`)}
        </span>
      </div>
    );
  }

  return (
    <Form>
      <Form.Group>
        <Form.Label>{t('content-update-modal/select-content')}</Form.Label>
        <DropDown
          heading={state.contentId}
          placeholder={t('common/select')}
          values={contents.map(item => ({ value: item.content.preferences.contentName, id: item.content._id }))}
          onSelect={eventKey => {
            setState({ ...state, contentId: eventKey.id, langCode: null });
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('content-update-modal/select-language')}</Form.Label>
        <DropDown
          heading={state.language}
          key={state.contentId}
          placeholder={t('common/select')}
          onSelect={eventKey => setState({ ...state, language: eventKey.id })}
          values={findContentLangs(contents, state.contentId, locales)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('content-update-modal/insert-users')}</Form.Label>
        <Input as="textarea" value={state.users} onChange={e => setState({ ...state, users: e.target.value })} rows="3" />
      </Form.Group>
      <Form.Label>{t('content-update-modal/completion-date-info')}</Form.Label>
    </Form>
  );
};

ContentUserStatusUpdate.propTypes = {
  state: PropTypes.shape({
    contentId: PropTypes.string,
    language: PropTypes.string,
    users: PropTypes.string
  }).isRequired,
  setState: PropTypes.func.isRequired,
  locales: PropTypes.arrayOf(PropTypes.object).isRequired,
  contents: PropTypes.arrayOf(PropTypes.object).isRequired,
  message: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.object
  }),
  onClose: PropTypes.func.isRequired
};

export default ContentUserStatusUpdate;
