import { get } from 'lodash';
import Cookies from 'js-cookie';
import {
  COOKIE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  REAUTH,
  REAUTH_SUCCESS,
  REAUTH_FAILURE,
  LOGOUT_USER_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  VERIFY_RESET_TOKEN,
  VERIFY_RESET_TOKEN_SUCCESS,
  VERIFY_RESET_TOKEN_FAILURE
} from 'utils/constants';

const initialState = {
  user: null,
  fetching: false,
  loginDetailsFetched: false
};

const userState = (state = initialState, action) => {
  const message = get(action, 'payload.message', {});
  const errors = get(action, 'payload.response.errors', []);
  const previousError = get(state, 'error', []);
  const previousMessage = get(state, 'message', []);
  switch (action.type) {
    case SIGNIN_USER:
      return {
        ...state,
        message: {},
        errors: {},
        loginDetailsFetched: false,
        fetching: true
      };
    case SIGNIN_USER_SUCCESS:
      Cookies.set(COOKIE.TOKEN_NAME, action.payload.authToken, { expires: 1 });
      //Cookies.set(COOKIE.LOGGED_IN, '1', { expires: 1 });
      return {
        ...state,
        user: action.payload,
        fetching: false,
        loginDetailsFetched: true
      };

    case SIGNIN_USER_FAILURE:
      return {
        ...state,
        errors: { SIGNIN_USER_FAILURE: errors },
        message: { ...state.message, SIGNIN_USER_SUCCESS: {} },
        fetching: false
      };
    case REAUTH:
      return {
        ...state,
        loginDetailsFetched: false,
        fetching: true
      };
    case REAUTH_SUCCESS:
      Cookies.set(COOKIE.TOKEN_NAME, action.payload.authToken, { expires: 1 });
      //Cookies.set(COOKIE.LOGGED_IN, '1', { expires: 1 });
      return {
        ...state,
        user: action.payload,
        fetching: false,
        loginDetailsFetched: true
      };

    case REAUTH_FAILURE:
      return {
        ...state,
        user: {
          user: action.error
        },
        fetching: false,
        loginDetailsFetched: true
      };
    case LOGOUT_USER_SUCCESS:
      Cookies.remove(COOKIE.TOKEN_NAME);
      // Cookies.remove(COOKIE.LOGGED_IN);
      return {
        ...state,
        user: null,
        fetching: false
      };
    case RESET_PASSWORD_SUCCESS: {
      return {
        message: { RESET_PASSWORD_SUCCESS: message },
        errors: { ...previousError, ...{ RESET_PASSWORD_FAILURE: false } }
      };
    }
    case RESET_PASSWORD_FAILURE: {
      return {
        errors: { ...previousError, ...{ RESET_PASSWORD_FAILURE: errors } },
        message: { ...previousMessage, RESET_PASSWORD_SUCCESS: false }
      };
    }
    case UPDATE_SETTINGS:
      return {
        ...state,
        updateSettingsSuccess: false
      };
    case UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          user: { ...state.user.user, ...action.payload.user }
        },
        updateSettingsSuccess: true
      };
    case 'DEFAULT_PUBLIC_LANG':
      return {
        ...state,
        user: {
          user: { settings: { defaultLangCode: action.payload } }
        }
      };
    case VERIFY_RESET_TOKEN:
      return {
        ...state,
        hasVerifiedToken: false,
        isTokenInvalid: false
      };
    case VERIFY_RESET_TOKEN_SUCCESS:
      return {
        ...state,
        hasVerifiedToken: true,
        isTokenInvalid: false
      };
    case VERIFY_RESET_TOKEN_FAILURE:
      return {
        ...state,
        errors: { SIGNIN_USER_FAILURE: errors },
        hasVerifiedToken: true,
        isTokenInvalid: true
      };
    default:
      return state;
  }
};
export default userState;
