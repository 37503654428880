import { RSAA } from 'redux-api-middleware';
import Cookies from 'js-cookie';
import { COOKIE } from 'utils/constants';

const token = () => Cookies.get(COOKIE.TOKEN_NAME) || '';
export default function() {
  return function(next) {
    return function(action) {
      const authToken = token();
      const rsaaApi = action[RSAA];
      if (rsaaApi) {
        rsaaApi.headers = {
          ...rsaaApi.headers,
          ...(authToken ? { Authorization: `Bearer ${authToken}` } : {})
        };
        rsaaApi.fetch =
          rsaaApi.fetch ||
          (async (...args) => {
            const res = await fetch(...args);
            const json = await res.json();
            return new Response(JSON.stringify(json), {
              status: res.status === 200 && json.errors ? 500 : res.status,
              headers: {
                'Content-Type': 'application/json'
              }
            });
          });
      }

      return next(action);
    };
  };
}
