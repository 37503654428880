import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useCountUp } from 'use-count-up';
import ReactContentLoader from 'react-content-loader';
import IconWrapper from 'atoms/IconWrapper';

import './compliance-dashboard.scss';

const UserStatCard = ({ loading, percentage, label = '', className = '', bg = '', fg = '', isArrowDown }) => {
  const { value } = useCountUp({ isCounting: true, start: 0, end: percentage, duration: 2 });
  if (loading) {
    return <UserStatCardLoader bg={bg} fg={fg} />;
  }
  return (
    <div className={`stat-box-top ${className}`} style={{ backgroundColor: bg }}>
      <div style={{ height: 20 }} />
      {percentage > 0 && (
        <div className="progress-arrow up-down" style={{ opacity: (isNaN(value) ? 0 : value) / percentage }}>
          {<IconWrapper type={isArrowDown ? 'ArrowDown' : 'ArrowUp'} />}
        </div>
      )}
      <h3 className="card-number">
        <span className="slowNumber">{isNaN(value) ? 0 : value}%</span>
      </h3>
      <div className="stat-box-top-progress-bg">
        <div style={{ width: `${value}%` }} className="stat-box-top-progress" />
      </div>
      <p className="user-stat-label">{label}</p>
    </div>
  );
};

UserStatCard.propTypes = {
  loading: PropTypes.bool,
  percentage: PropTypes.number,
  isArrowDown: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  bg: PropTypes.string,
  fg: PropTypes.string
};

export const UserStatCardLoader = ({ bg = '', fg = '' }) => {
  return (
    <div className={`stat-box-top`}>
      <ReactContentLoader
        style={{
          width: '100%',
          height: '100%',
          borderRadius: 8,
          marginRight: 0,
          marginBottom: 0
        }}
        speed={2}
        viewBox="0 0 356 200"
        backgroundColor={bg}
        foregroundColor={fg}
      >
        <rect x="0" y="0" rx="8" ry="8" width="356" height="200" />
      </ReactContentLoader>
    </div>
  );
};

export default memo(UserStatCard);
