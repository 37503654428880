import React from 'react';
import PropTypes from 'prop-types';
import { useFormatMessage } from 'hooks/useFormatMessage';

const statusColorMap = {
  UPTO_DATE: '#45d4d3',
  NEVER_LOGGED_IN: '#fa837f',
  ACTIVE: '#54a8af',
  DISABLED: '#bebfbf'
};

const UserStatus = ({ item }) => {
  const t = useFormatMessage();
  return <div style={{ color: statusColorMap[item] }}>{t(`user-status/${item}`)}</div>;
};

UserStatus.propTypes = {
  item: PropTypes.node
};

export default UserStatus;
