import React from 'react';
import PropTypes from 'prop-types';
import { useCountUp } from 'use-count-up';

import './progress-line.scss';

const ProgressLine = ({ visualBarClassname, classname, percentage }) => {
  const { value } = useCountUp({ isCounting: true, start: 0, end: percentage, duration: 2 });
  return (
    <>
      <div className={`progress-visual-full ${classname}`}>
        <div style={{ width: `${value}%` }} className={`progress-visual-part ${visualBarClassname}`} />
      </div>
    </>
  );
};
ProgressLine.propTypes = {
  backgroundColor: PropTypes.string,
  visualBarClassname: PropTypes.string,
  classname: PropTypes.string,
  percentage: PropTypes.number
};
export default ProgressLine;
