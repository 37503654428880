import { RSAA } from 'redux-api-middleware';
import { BASE_URL } from 'utils/api';
import { REAUTH, REAUTH_SUCCESS, REAUTH_FAILURE } from 'utils/constants';

export const reauthentication = () => ({
  [RSAA]: {
    endpoint: `${BASE_URL}/re-auth`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [REAUTH, REAUTH_SUCCESS, REAUTH_FAILURE]
  }
});
