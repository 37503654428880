import React from 'react';
import { Card } from 'react-bootstrap';
import cn from 'classnames';

const Options = ({ mcq, disabled, onChangeOption, selectedKeys, correctKeys }) => {
  //key is used for preview schenario
  return mcq.options.map((option, key) => {
    return (
      <Card.Text key={option.id || key}>
        <input
          type="checkbox"
          id={`checkbox-group-${option.id || key}`}
          value={option.id || key}
          disabled={disabled}
          name={`checkbox-group-${mcq._id}`}
          onChange={onChangeOption}
          checked={selectedKeys.includes(option.id || `${key}`)}
          className={cn('mixed-mrq-item-container__button', {
            'mixed-mrq-item-container__button--disabled': disabled,
            'mixed-mrq-item-container__button--correct': correctKeys.includes(option.id || `${key}`)
          })}
        />
        <label
          htmlFor={`checkbox-group-${option.id || key}`}
          className={cn('mixed-mrq-item-container__label', {
            'mixed-mrq-item-container__label--disabled': disabled,
            'mixed-mrq-item-container__label--correct': correctKeys.includes(option.id || `${key}`)
          })}
        >
          {option.answer || option}
        </label>
      </Card.Text>
    );
  });
};
export default Options;
