import React from 'react';
import { Col } from 'react-bootstrap';
import ReactContentLoader from 'react-content-loader';

export const UserListLoader = props => (
  <Col xs={12} className="p-0">
    <ReactContentLoader
      style={{
        width: '100%'
      }}
      speed={1}
      viewBox="0 0 1110 46"
      backgroundColor="#f1f4f1"
      foregroundColor="#e7ece7"
      {...props}
    >
      <rect x="13" y="13" rx="2" ry="2" width="20" height="20" />
      <rect x="50" y="13" rx="0" ry="0" width="1060" height="20" />
    </ReactContentLoader>
  </Col>
);
