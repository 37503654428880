import React from 'react';
import { withRouter } from 'react-router';
import { LocationPropTypesShape } from 'utils/core-proptypes';

import './Footer.scss';

const Footer = props => {
  const { location } = props;
  if (location.pathname === '/signin') return null;
  return <div className="Footer-container">Copyright © Mindzeed ApS</div>;
};

Footer.propTypes = {
  location: LocationPropTypesShape
};

export default withRouter(Footer);
