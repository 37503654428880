import React from 'react';
import PropTypes from 'prop-types';
import sn from 'classnames';
import { Card } from 'react-bootstrap';
import './color-tile.scss';

const ColorTile = ({ color, onClick, width, height, margin, isSelect, ...props }) => {
  return (
    <Card
      className={sn(isSelect ? 'color-tile--isSelect' : 'color-tile')}
      onClick={onClick}
      id={color}
      style={{
        backgroundColor: color,
        width: width,
        margin: margin,
        height: height
      }}
      {...props}
    />
  );
};

ColorTile.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  isSelect: PropTypes.bool
};

ColorTile.defaultProps = {
  onClick: () => {},
  isSelect: false,
  color: '',
  width: '40px',
  height: '40px',
  margin: '5px'
};
export default ColorTile;
