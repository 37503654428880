import React, { useReducer, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Input from 'atoms/Input';
import Button from 'atoms/Button';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { addEmailTemplate, updateEmailTemplate } from './actions';
import './email-template.scss';

const reducer = (state, action) => {
  const { key, value, payload } = action;
  switch (action.type) {
    case 'inputChange':
      return {
        ...state,
        [key]: value,
        notPristine: false
      };
    case 'initializeState': {
      return {
        ...state,
        ...payload,
        notPristine: true
      };
    }

    default:
      throw new Error();
  }
};

const EmailTemplate = ({ addEmailTemplate, updateEmailTemplate, langCode, emailTemplateData, updateUUID }) => {
  const t = useFormatMessage();
  const [state, dispatcher] = useReducer(reducer, {
    notPristine: true,
    ...emailTemplateData.templates,
    _id: emailTemplateData._id
  });

  useEffect(() => {
    dispatcher({
      type: 'initializeState',
      payload: emailTemplateData.templates
    });
  }, [updateUUID]);

  const handleEmailTemplateSave = () => {
    const { createPassword, resetPassword, moduleAssign, userWeeklyReminder, managerWeeklySummary, passwordReminder, _id } = state;
    _id
      ? updateEmailTemplate({
          payload: {
            templates: {
              createPassword,
              resetPassword,
              moduleAssign,
              userWeeklyReminder,
              managerWeeklySummary,
              passwordReminder
            }
          },
          emailTemplateId: _id
        })
      : addEmailTemplate({
          langCode,
          templates: {
            createPassword,
            resetPassword,
            moduleAssign,
            userWeeklyReminder,
            managerWeeklySummary,
            passwordReminder
          }
        });
  };

  const { createPassword, resetPassword, moduleAssign, userWeeklyReminder, managerWeeklySummary, passwordReminder, notPristine } = state;

  return (
    <React.Fragment>
      <Container className="email-template-container">
        <Row className="email-template-container__row">
          <Col className="pl-0">{t('app-settings/create-password:id')}</Col>
          <Col className="pr-0 email-template-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'createPassword',
                  value: e.target.value
                })
              }
              value={createPassword}
            />
          </Col>
        </Row>
        <Row className="email-template-container__row">
          <Col className="pl-0">{t('app-settings/reset-password:id')}</Col>
          <Col className="pr-0 email-template-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'resetPassword',
                  value: e.target.value
                })
              }
              value={resetPassword}
            />
          </Col>
        </Row>
        <Row className="email-template-container__row">
          <Col className="pl-0">{t('app-settings/module-assign:id')}</Col>
          <Col className="pr-0 email-template-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'moduleAssign',
                  value: e.target.value
                })
              }
              value={moduleAssign}
            />
          </Col>
        </Row>
        <Row className="email-template-container__row">
          <Col className="pl-0">{t('app-settings/user-reminder:id')}</Col>
          <Col className="pr-0 email-template-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'userWeeklyReminder',
                  value: e.target.value
                })
              }
              value={userWeeklyReminder}
            />
          </Col>
        </Row>
        <Row className="email-template-container__row">
          <Col className="pl-0">{t('app-settings/manager-weekly-summary:id')}</Col>
          <Col className="pr-0 email-template-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'managerWeeklySummary',
                  value: e.target.value
                })
              }
              value={managerWeeklySummary}
            />
          </Col>
        </Row>
        <Row className="email-template-container__row">
          <Col className="pl-0">{t('app-settings/password-reminder:id')}</Col>
          <Col className="pr-0 email-template-container__row__flex-end">
            <Input
              onChange={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'passwordReminder',
                  value: e.target.value
                })
              }
              value={passwordReminder}
            />
          </Col>
        </Row>
        <Row className="email-template-container__row">
          <Button notPristine={notPristine} onClick={handleEmailTemplateSave}>
            {t('common/save')}
          </Button>
        </Row>
      </Container>
    </React.Fragment>
  );
};

EmailTemplate.propTypes = {
  addEmailTemplate: PropTypes.func.isRequired,
  updateEmailTemplate: PropTypes.func.isRequired,
  langCode: PropTypes.string.isRequired,
  emailTemplateData: PropTypes.shape({
    _id: PropTypes.string,
    templates: PropTypes.shape({}),
    notPristine: PropTypes.bool
  }).isRequired,
  updateUUID: PropTypes.strinng
};

EmailTemplate.defaultProps = {};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    addEmailTemplate: bindActionCreators(addEmailTemplate, dispatch),
    updateEmailTemplate: bindActionCreators(updateEmailTemplate, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailTemplate));
