import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PDFDownloadLink } from '@react-pdf/renderer';
import uuidv4 from 'uuid/v4';
import { useFormatMessage } from 'hooks/useFormatMessage';
import Button from 'atoms/Button';
import { Form, Row } from 'react-bootstrap';

import DiplomaTemplate from '../UserSettings/DiplomaCard/template';
import Input from 'atoms/Input';

import './diploma-download.scss';

const DiplomaDownload = ({ setDiplomaModal = true, currentContentTitle, currentModuleTitle }) => {
  const [userName, setUserName] = useState('');
  const t = useFormatMessage();

  return (
    <Form className="public-diploma-container">
      <Form.Group>
        <Input value={userName} onChange={e => setUserName(e.target.value)} placeholder={t('public-text/placeholder-name')} />
      </Form.Group>
      <Row className="public-diploma-container__button-row" style={{ margin: '25px 0 0' }}>
        <PDFDownloadLink
          key={uuidv4()}
          document={
            <DiplomaTemplate
              diploma={{
                moduleId: '5dcaa6f2eef17873b28de869',
                moduleData: [
                  {
                    moduleTitle: currentModuleTitle
                  }
                ],
                diplomaData: {
                  diplomaDate: new Date(),
                  contents: [
                    {
                      contentName: currentContentTitle
                    }
                  ]
                }
              }}
              userProfile={{ firstName: userName }}
            />
          }
          fileName={`${userName}.pdf`}
          style={{
            textDecoration: 'none',
            color: '#4a4a4a'
          }}
        >
          <Button>{t('public-diploma/download')}</Button>
        </PDFDownloadLink>
        <Button
          variant="secondary"
          onClick={() => {
            setDiplomaModal(false);
          }}
        >
          {t('common/cancel')}
        </Button>
      </Row>
    </Form>
  );
};

DiplomaDownload.propTypes = {
  setDiplomaModal: PropTypes.func.isRequired,
  currentContentTitle: PropTypes.string,
  currentModuleTitle: PropTypes.string
};

DiplomaDownload.defaultProps = {};

export default DiplomaDownload;
