/*eslint-disable*/

import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup } from 'atoms/Radio';
import { Row, Col } from 'react-bootstrap';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

import Switch from 'atoms/Switch';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { MixedPreferencesPropTypesShape } from 'utils/core-proptypes';
import { mixedContentAdditionalPrefs, mixedPreferenceConstantToKeyMap } from 'utils/types';

import './modal-elements.scss';

const ModuleSpecificMixedContentPreference = ({ dispatcher, modalPreferenceData }) => {
  const t = useFormatMessage();
  const { noOfTestAttempts, passPercent } = modalPreferenceData;
  return (
    <div>
      <Row>
        <Col className="content-preference__row-left-border" style={{ paddingBottom: 0,paddingTop:0 }}>
          <div className="content-preference__pass-slider__title">{t('common-content-preference/passing-score')}</div>
          <div className="content-preference__pass-slider">
            <Row>
              <Col>
                <Slider
                  className="content-preference__pass-slider__selector"
                  min={0}
                  max={100}
                  value={passPercent}
                  onChange={value => {
                    dispatcher({
                      type: 'modalPreferenceSlider',
                      key: 'passPercent',
                      value
                    });
                  }}
                />
              </Col>
              <Col>
                <div className="content-preference__pass-slider__progress">{passPercent}%</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="content-preference__row-left-border">
          <RadioGroup
            label={t('common-content-preference/feedback')}
            name="common-content-feedback"
            onSelect={({ value }) =>
              dispatcher({
                type: 'modalPreferenceInputRadio',
                key: 'showExplanation',
                value
              })
            }
          >
            <Radio value="SHOW_EXP" checked={modalPreferenceData.showExplanation === 'SHOW_EXP'} label={t('common-content-preference/show-feedback')} />
            <Radio value="SHOW_EXP_WRONG" checked={modalPreferenceData.showExplanation === 'SHOW_EXP_WRONG'} label={t('common-content-preference/show-feedback-if-answer-is-wrong')} />
            <Radio value="SHOW_EXP_RIGHT" checked={modalPreferenceData.showExplanation === 'SHOW_EXP_RIGHT'} label={t('common-content-preference/show-feedback-if-answer-is-correct')} />
            <Radio value="DO_NOT_SHOW" checked={modalPreferenceData.showExplanation === 'DO_NOT_SHOW'} label={t('common-content-preference/do-not-show-feedback')} />
          </RadioGroup>
        </Col>
      </Row>

      <Row>
        <Col className="content-preference__row-left-border" style={{ marginBottom: 0, paddingBottom: '15px' }}>
          <RadioGroup
            label={t('common-content-preference/retries')}
            name="common-content-attempts"
            onSelect={({ value }) =>
              dispatcher({
                type: 'modalPreferenceInputRadio',
                key: 'noOfTestAttempts',
                value
              })
            }
          >
            <Radio value="ONE" defaultChecked="true"  checked={noOfTestAttempts === 'ONE'} label={t('common/one')} />
            <Radio value="ONE_IN_24" checked={noOfTestAttempts === 'ONE_IN_24'} label={t('common/one-every-24-hours')} />
            <Radio value="UNLIMITED" checked={noOfTestAttempts === 'UNLIMITED'} label={t('common/unlimited')} />
          </RadioGroup>
        </Col>
      </Row>
      <Col className="content-preference__body__thumbnail-container" style={{ padding: 0 }}>
        {Object.keys(mixedContentAdditionalPrefs).map(pref => (
          <Row key={pref} className="content-preference__row" style={{ height: '50px', padding: '5px 0' }}>
            <div style={{ width: '80%' }} className="content-preference__row__switch-label">{t(mixedContentAdditionalPrefs[pref])}</div>
            <div className="content-preference__row__switch" style={{ marginTop: '7px' ,width: '20%'}}>
              <Switch
                id={pref}
                onSelect={() => {
                  let value;
                  if (pref === 'SHOW_BLOCK_ONE_BY_ONE') {
                    value = modalPreferenceData[mixedPreferenceConstantToKeyMap[pref]] === 'ONE_BY_ONE' ? 'ALL' : 'ONE_BY_ONE';
                  } else {
                    value = modalPreferenceData[mixedPreferenceConstantToKeyMap[pref]] === 'false' ? 'true' : 'false';
                  }
                  dispatcher({
                    type: 'modalPreferenceInputRadio',
                    key: mixedPreferenceConstantToKeyMap[pref],
                    value
                  });
                }}
                value={
                  pref === 'SHOW_BLOCK_ONE_BY_ONE'
                    ? modalPreferenceData[mixedPreferenceConstantToKeyMap[pref]] === 'ONE_BY_ONE'
                      ? true
                      : false
                    : modalPreferenceData[mixedPreferenceConstantToKeyMap[pref]] === 'false'
                    ? false
                    : true
                }
              />
            </div>
          </Row>
        ))}
      </Col>
    </div>
  );
};

ModuleSpecificMixedContentPreference.propTypes = {
  modalPreferenceData: MixedPreferencesPropTypesShape,
  dispatcher: PropTypes.func.isRequired
};
ModuleSpecificMixedContentPreference.defaultProps = {
  modalPreferenceData: {}
};
export default ModuleSpecificMixedContentPreference;
