import React, { cloneElement, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import sn from 'classnames';

import './accordion.scss';

const blockName = 'accordion-item';
export const AccordionItemContext = createContext();

const AccordionItem = ({ children, isCollapsed, ...props }) => {
  const [collapse, setCollapse] = useState(isCollapsed);
  const handleOnCollapse = () => {
    setCollapse(!collapse);
  };

  const AccordionItemTitle = cloneElement(children[0], {
    onCollapse: handleOnCollapse
  });

  const AccordionItemBody = cloneElement(children[1], {
    collapsed: collapse
  });
  const handleCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <AccordionItemContext.Provider value={{ isCollapsed: collapse, onCollapse: handleCollapse }}>
      <div className={blockName} {...props}>
        <div className={sn('card', { collapsed: collapse })}>
          {AccordionItemTitle}
          {AccordionItemBody}
        </div>
      </div>
    </AccordionItemContext.Provider>
  );
};
export default AccordionItem;

AccordionItem.propTypes = {
  children: PropTypes.node.isRequired,
  isCollapsed: PropTypes.bool
};
AccordionItem.defaultProps = {
  isCollapsed: false
};
