import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { MediaPropTypesShape, MCQPreferencesPropTypesShape } from 'utils/core-proptypes';
import VideoPlayer from '../VideoPlayer';
import { PDFReader } from 'reactjs-pdf-reader';
import { getFileExtension, getMediaUrl } from 'utils/calculationUtilities';

import { isEmpty, get } from 'lodash';
import PageHeader from '../PageHeader';
import Button from 'atoms/Button';
import { useFormatMessage } from 'hooks/useFormatMessage';
import './video-view.scss';

const Video = ({ videoPlayed, contentData = {}, onVideoEnd, preferences = {}, currentContent, media, onVideoStart, showVideo, setShowVideo, fullWidth }) => {
  const t = useFormatMessage();
  const { showQuestionsAfterVideo } = preferences;

  const getFileThumbnailPreview = () => {
    const ext = getFileExtension(media);
    switch (ext) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png': {
        onVideoEnd();
        const resolvedImageUrl = getMediaUrl(media);
        return <img src={resolvedImageUrl} style={{ width: '100%' }} />;
      }
      case 'pdf': {
        onVideoEnd();
        return (
          <div style={{ 'overflow-x': 'scroll', height: 600 }}>
            <PDFReader url={getMediaUrl(media)} showAllPage />
          </div>
        );
      }
      case 'mp4':
      case 'webm': {
        return <VideoPlayer media={media} onVideoPlayerEnd={onVideoEnd} onVideoPlayerStart={onVideoStart} />;
      }
      default: {
        onVideoEnd();
        return null;
      }
    }
  };

  const thumbnailPreview = () => {
    if (!isEmpty(media)) {
      switch (media.type.toUpperCase()) {
        case 'FILE': {
          return getFileThumbnailPreview();
        }
        case 'VIMEO':
        case 'YOUTUBE': {
          return <VideoPlayer media={media} onVideoPlayerEnd={onVideoEnd} onVideoPlayerStart={onVideoStart} />;
        }
      }
    }
    return null;
  };
  const content = isEmpty(contentData.details) ? currentContent : contentData;
  const showAfterVideoButton = showQuestionsAfterVideo === 'true' && !videoPlayed;
  const isVideo = ['VIMEO', 'YOUTUBE'].includes(media?.type?.toUpperCase());
  return media && showVideo ? (
    <Fragment>
      <Container>
        <PageHeader variant="small text-center" title={get(content, 'details.contentTitle', '')} desc={get(content, 'details.description', '')} isDangerousHTML={true} />
        {isVideo && (
          <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: '#00aaa4' }}>
            {t('general/please-watch-the-video-and-then-click-the-button-below-to-go-to-the-test-questions')}
          </p>
        )}
      </Container>
      {showVideo && (
        <Container fluid className="course-top">
          <Row className="justify-content-center  " style={{ paddingBottom: '15px' }}>
            {fullWidth ? (
              <Col className="p-0 ">{thumbnailPreview()}</Col>
            ) : (
              <Col lg={8} md={12} sm={12} className="video-container">
                {thumbnailPreview()}
              </Col>
            )}
          </Row>
          {showAfterVideoButton && (
            <Row className="justify-content-center">
              <Button style={{ padding: '10px 20px', backgroundColor: '#ffffff', color: '#333333', borderRadius: ' 0.5rem', marginTop: '20px', cursor: 'initial' }} variant="primary">
                {t('feedback/AFTER_VIDEO_MESSAGE')}
              </Button>
            </Row>
          )}
          {!showAfterVideoButton && (
            <Row className="justify-content-center">
              <Button
                onClick={() => {
                  setShowVideo(false);
                }}
                style={{ padding: '10px 20px', borderRadius: ' 0.5rem', marginTop: '20px', cursor: 'initial' }}
                variant="primary"
              >
                {t('feedback/GO_TO_TEXT')}
              </Button>
            </Row>
          )}
        </Container>
      )}
    </Fragment>
  ) : null;
};

Video.propTypes = {
  media: MediaPropTypesShape,
  onVideoStart: PropTypes.func,
  setShowVideo: PropTypes.func,
  onVideoEnd: PropTypes.func,
  videoPlayed: PropTypes.bool,
  showHideButton: PropTypes.bool,
  preferences: MCQPreferencesPropTypesShape,
  showVideo: PropTypes.bool,
  fullWidth: PropTypes.string,
  contentData: PropTypes.shape({
    _id: PropTypes.string,
    details: PropTypes.shape({}),
    mcqList: PropTypes.array
  }),
  currentContent: PropTypes.shape({})
};

Video.defaultProps = {
  onVideoStart: () => {},
  onVideoEnd: () => {},
  showHideButton: true
};

export default Video;
