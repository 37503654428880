import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './switch.scss';

const Switch = ({ id, onSelect, value }) => {
  const [switchitem, setSwitchItem] = useState(value);

  useEffect(() => {
    setSwitchItem(value);
  }, [value]);

  const handleToggle = () => {
    setSwitchItem(!switchitem);
    onSelect({
      type: 'switchChange',
      key: id,
      value: !switchitem // next state beinng sent in switch
    });
  };

  return (
    <>
      <input checked={switchitem} onChange={handleToggle} className="react-switch-checkbox" id={`switch-${id}`} type="checkbox" />
      <label style={{ background: switchitem && '#00aaa4' }} className="react-switch-label" htmlFor={`switch-${id}`}>
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onSelect: PropTypes.func.isRequired
};

Switch.defaultProps = {
  value: null
};

export default Switch;
