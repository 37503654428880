import React from 'react';
import PropTypes from 'prop-types';
import sn from 'classnames';

import { iconsList } from './icons-list';

export const IconPropType = PropTypes.oneOf(iconsList);

/**
 * @description Renders icon
 * @param {String} className
 * @param {String} icon
 * @param {String} iconClassName
 * @param props
 * @return {*}
 */
const Icon = ({ className, icon, iconClassName }) => {
  return (
    <bdi className={sn(className, 'pictogram-wrapper')} aria-hidden="true">
      <i className={sn('icon', icon, iconClassName)} />
    </bdi>
  );
};

Icon.propTypes = {
  iconClassName: PropTypes.string,
  className: PropTypes.string,
  icon: IconPropType
};

Icon.defaultProps = {
  icon: '',
  iconClassName: '',
  className: ''
};

export default Icon;
