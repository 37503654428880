import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Col, Row, TabContainer, TabContent, TabPane, Nav, NavItem } from 'react-bootstrap';

import { useFormatMessage } from 'hooks/useFormatMessage';
import AllOrganizations from './AllOrganizations';
import SharedOrganizations from './SharedOrganizations';
import NotSharedOrganizations from './NotSharedOrganizations';
import './modal-elem.scss';

const ShareContent = ({ shared = [], notShared = [], allOrganizations = [], contentId, updateContentSharedToOrgs, selectedOrg }) => {
  const t = useFormatMessage();
  const [currentActiveKey, setCurrentActiveKey] = useState('view-all');

  const handleShareToggle = value => {
    const { key, value: switchValue } = value;
    const payload = {
      organizations: [
        {
          _id: key,
          active: switchValue
        }
      ]
    };
    updateContentSharedToOrgs({ organizationId: selectedOrg, contentId, payload });
  };

  return (
    <Container style={{ padding: '0' }}>
      <Row className="share-content__tabs justify-content-center" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
        <Col>
          <TabContainer defaultActiveKey="view-all" onSelect={e => setCurrentActiveKey(e)} activeKey={currentActiveKey} sid="content-create-tab">
            <Nav className="flex-row share-content__tabs">
              <NavItem id="view-all-tab">
                <Nav.Link eventKey="view-all">
                  {t('share/view-all')} {`(${allOrganizations.length})`}
                </Nav.Link>
              </NavItem>
              <NavItem id="shared-tab">
                <Nav.Link eventKey="shared">
                  {t('share/shared')} {`(${shared.length})`}
                </Nav.Link>
              </NavItem>
              <NavItem id="not-shared-tab">
                <Nav.Link eventKey="not-shared">
                  {t('share/not-shared')} {`(${notShared.length})`}
                </Nav.Link>
              </NavItem>
            </Nav>
            <TabContent>
              <TabPane className="tab1" eventKey="view-all">
                <AllOrganizations allOrganizations={allOrganizations} handleShareToggle={handleShareToggle} />
              </TabPane>
              <TabPane className="tab1" eventKey="shared">
                <SharedOrganizations shared={shared} handleShareToggle={handleShareToggle} />
              </TabPane>
              <TabPane className="tab1" eventKey="not-shared">
                <NotSharedOrganizations notShared={notShared} handleShareToggle={handleShareToggle} />
              </TabPane>
            </TabContent>
          </TabContainer>
        </Col>
      </Row>
    </Container>
  );
};

ShareContent.propTypes = {
  allOrganizations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  notShared: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shared: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateContentSharedToOrgs: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
  selectedOrg: PropTypes.string.isRequired
};

ShareContent.defaultProps = {};

const mapStateToProps = ({ sharedContent }) => {
  const { contents: { allOrganizations, notShared, shared } = {} } = sharedContent;
  return {
    shared,
    notShared,
    allOrganizations
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareContent));
