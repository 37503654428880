import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { MediaPropTypesShape } from 'utils/core-proptypes';
import IconWrapper from 'atoms/IconWrapper';
import VideoPlayer from '../VideoPlayer';
import { PDFReader } from 'reactjs-pdf-reader';
import { getFileExtension, getMediaUrl } from 'utils/calculationUtilities';
import { isEmpty } from 'lodash';

const Video = ({ media, onVideoStart, onVideoEnd, showHideButton, fullWidth }) => {
  const [showVideo, setShowVideo] = useState(true);
  const toggleVideo = () => {
    setShowVideo(!showVideo);
    window.scrollTo(0, 0);
  };

  const getFileThumbnailPreview = () => {
    const ext = getFileExtension(media);
    switch (ext) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png': {
        onVideoEnd();
        const resolvedImageUrl = getMediaUrl(media);
        return <img src={resolvedImageUrl} style={{ width: '100%' }} />;
      }
      case 'pdf': {
        onVideoEnd();
        return (
          <div style={{ 'overflow-x': 'scroll', height: 600 }}>
            <PDFReader url={getMediaUrl(media)} showAllPage />
          </div>
        );
      }
      case 'mp4':
      case 'webm': {
        return <VideoPlayer media={media} onVideoPlayerEnd={onVideoEnd} onVideoPlayerStart={onVideoStart} />;
      }
      default: {
        onVideoEnd();
        return null;
      }
    }
  };

  const thumbnailPreview = () => {
    if (!isEmpty(media)) {
      switch (media.type.toUpperCase()) {
        case 'FILE': {
          return getFileThumbnailPreview();
        }
        case 'VIMEO':
        case 'YOUTUBE': {
          return <VideoPlayer media={media} onVideoPlayerEnd={onVideoEnd} onVideoPlayerStart={onVideoStart} />;
        }
      }
    }
    return null;
  };

  return media ? (
    <Fragment>
      {showVideo && (
        <Container fluid className="course-top">
          {showVideo && (
            <Row className="justify-content-center">
              {fullWidth ? (
                <Col className="p-0">{thumbnailPreview()}</Col>
              ) : (
                <Col lg={8} md={12} sm={12} className="p-0">
                  {thumbnailPreview()}
                </Col>
              )}
            </Row>
          )}
        </Container>
      )}
      {showHideButton && (
        <div>
          <Col
            style={{
              textAlign: 'center'
            }}
          >
            <button className="btn show-hide-button" onClick={toggleVideo}>
              {showVideo ? <IconWrapper type="PreviewHide" alt="Hide media content" /> : <IconWrapper type="Preview" alt="Show media content" />}
            </button>
          </Col>
        </div>
      )}
    </Fragment>
  ) : null;
};

Video.propTypes = {
  media: MediaPropTypesShape,
  onVideoStart: PropTypes.func,
  onVideoEnd: PropTypes.func,
  showHideButton: PropTypes.bool,
  fullWidth: PropTypes.string
};

Video.defaultProps = {
  onVideoStart: () => {},
  onVideoEnd: () => {},
  showHideButton: true
};

export default Video;
