import {
  GROUP_LIST,
  GROUP_LIST_SUCCESS,
  GROUP_LIST_FAILURE,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  CLEAR_GROUP_ERROR
} from 'utils/constants';

const initialState = {
  groupsList: [],
  fetching: false,
  addUpdateGroupSuccess: false
};

const groupState = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_LIST:
      return {
        ...state,
        fetching: true
      };

    case GROUP_LIST_SUCCESS:
      return { ...state, groupsList: action.payload, fetching: false };

    case GROUP_LIST_FAILURE:
      return {
        ...state,
        groupsList: [],
        error: [...action.error],
        fetching: false
      };
    case ADD_GROUP:
    case UPDATE_GROUP:
      return {
        ...state,
        addUpdateGroupSuccess: false
      };
    case ADD_GROUP_SUCCESS:
      return {
        ...state,
        groupsList: [...state.groupsList, action.payload.group],
        addUpdateGroupSuccess: true
      };
    case ADD_GROUP_FAILURE:
    case UPDATE_GROUP_FAILURE:
      return {
        ...state,
        error: action.payload.response.errors
      };
    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        groupsList: state.groupsList.map(group => (group._id === action.payload.group._id ? { ...group, ...action.payload.group } : group)),
        addUpdateGroupSuccess: true
      };
    case DELETE_GROUP:
      return {
        ...state,
        deleteGroupSuccess: false
      };
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        groupsList: state.groupsList.filter(u => !action.payload.deletedGroupsIds.includes(u._id)),
        deleteGroupSuccess: true
      };
    case CLEAR_GROUP_ERROR:
      return {
        ...state,
        error: []
      };
    default:
      return state;
  }
};
export default groupState;
