import { keyBy } from 'lodash';

export const reducer = (state, action) => {
  const { key, value, index } = action;

  switch (action.type) {
    case 'inputChange':
    case 'dropDown':
    case 'inputRadio': {
      return {
        ...state,
        ...{ [key]: value },
        carouselContentCreateUpdatePristine: false
      };
    }
    case 'addBlock':
      return {
        ...state,
        carouselPages: { ...state.carouselPages, [index]: {} },
        carouselContentCreateUpdatePristine: false
      };
    case 'addItem':
      return {
        ...state,
        carouselPages: {
          ...state.carouselPages,
          [index]: {
            ...state.carouselPages[index],
            [key]: value
          }
        },
        carouselContentCreateUpdatePristine: false
      };

    case 'deleteQuestion': {
      const { index } = action;
      const filteredItems = Object.keys(state.carouselPages)
        .filter(x => x !== index)
        .reduce((obj, key) => {
          obj[key] = state.carouselPages[key];
          return obj;
        }, {});
      return {
        ...state,
        carouselPages: filteredItems,
        components: state.carouselPages.components && state.carouselPages.components.filter(x => x !== index),
        carouselContentCreateUpdatePristine: false
      };
    }

    case 'setItemMedia': {
      const { media } = action;
      return {
        ...state,
        carouselPages: {
          ...state.carouselPages,
          [index]: {
            ...state.carouselPages[index],
            media: { ...media, upload: true }
          }
        },
        carouselContentCreateUpdatePristine: false
      };
    }
    case 'sortCarouselList': {
      return {
        ...state,
        carouselPages: action.payload,
        carouselContentCreateUpdatePristine: false
      };
    }
    case 'initialState': {
      const { carouselPages } = action.payload;
      const mCarouselPages = keyBy(carouselPages, item => item._id);
      return {
        ...state,
        ...action.payload,
        carouselPages: keyBy(mCarouselPages, '_id'),
        carouselContentCreateUpdatePristine: true
      };
    }
    default:
      throw new Error();
  }
};
