import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, orderBy } from 'lodash';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useFormatMessage } from 'hooks/useFormatMessage';

import DropDown from 'atoms/DropDown';
import IconWrapper from 'atoms/IconWrapper';
import { ContentsPropTypesShape } from 'utils/core-proptypes';
import useFetcher, { getSearchStringFromObject } from 'hooks/useFetcher';
import './user-status.scss';
import ContentLoader from 'react-content-loader';

const rowBlockName = 'user-status-container';

const UserStatus = ({ orgId, moduleId, contents = [] }) => {
  const t = useFormatMessage();
  const contentOptions = orderBy(
    contents.map(content => {
      return { value: content.content.preferences.contentName, id: content.content._id };
    }),
    'value',
    'asc'
  );
  const [sort, setSort] = useState({ by: 'firstName', order: 1 });
  const [selectedContentId, setSelectedContentId] = useState(get(contentOptions, '0.id'));

  const { loading, data: users, showLoadMore: hasNextPage, error: itemsError, loadMoreData: loadMore, doFetch } = useFetcher(
    `/organizations/${orgId}/modules/${moduleId}/contents/${selectedContentId}/statistics${getSearchStringFromObject({ sortBy: sort.by, sortOrder: sort.order })}`,
    50,
    true
  );
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!itemsError,
    rootMargin: '0px 0px 100px 0px'
  });

  useEffect(() => {
    doFetch(`/organizations/${orgId}/modules/${moduleId}/contents/${selectedContentId}/statistics${getSearchStringFromObject({ sortBy: sort.by, sortOrder: sort.order })}`, true);
  }, [orgId, moduleId, selectedContentId, sort.by, sort.order]);

  const handleSortChange = (sortBy, sortOrder) => {
    setSort({ by: sortBy, order: sortOrder });
  };

  const getSortStyle = sortBy => {
    return sort.by === sortBy ? { color: '#000' } : {};
  };

  return (
    <div className={`${rowBlockName}`}>
      <div className={`${rowBlockName}__filter-row`}>
        <div className={`${rowBlockName}__filter-row__filters`}>
          <span style={{ color: '#000' }}>Sort By:</span>
          <span style={getSortStyle('isContentCompleted')} onClick={() => handleSortChange('isContentCompleted', -1)}>
            {t('user-status/status')}
          </span>
          <span style={getSortStyle('firstName')} onClick={() => handleSortChange('firstName', 1)}>
            {t('user-status/first-name')}
          </span>
          <span style={getSortStyle('lastName')} onClick={() => handleSortChange('lastName', 1)}>
            {t('user-status/last-name')}
          </span>
          <span style={getSortStyle('email')} onClick={() => handleSortChange('email', 1)}>
            {t('user-status/email')}
          </span>
          <span style={getSortStyle('groupName')} onClick={() => handleSortChange('groupName', 1)}>
            {t('user-status/group')}
          </span>
          <span style={getSortStyle('dateOfCompletion')} onClick={() => handleSortChange('dateOfCompletion', -1)}>
            {t('user-status/date-of-completion')}
          </span>
        </div>
        <div>
          <DropDown
            className="filter-dropdown"
            onSelect={eventKey => {
              setSelectedContentId(eventKey.id);
            }}
            heading={selectedContentId}
            values={contentOptions}
          />
        </div>
      </div>
      <div>
        {users.map(user => (
          <UserRow key={user._id} user={user} />
        ))}
        {loading &&
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(item => (
            <ContentLoader key={item} speed={2} width={1140} height={45} viewBox="0 0 1140 45" backgroundColor="#dcdcdc" foregroundColor="#ecebeb">
              <circle cx="10" cy="22" r="10" />
              <rect x="40" y="12" rx="0" ry="0" width="150" height="21" />
              <rect x="210" y="12" rx="0" ry="0" width="150" height="21" />
              <rect x="380" y="12" rx="0" ry="0" width="340" height="21" />
              <rect x="740" y="12" rx="0" ry="0" width="220" height="21" />
              <rect x="1000" y="12" rx="0" ry="0" width="140" height="21" />
            </ContentLoader>
          ))}
        {(loading || hasNextPage) && <div ref={sentryRef}></div>}
      </div>
    </div>
  );
};

const UserRow = ({ user }) => {
  const { firstName, lastName, email, groupName, dateOfCompletion, isContentCompleted } = user;

  const statusIconType = isContentCompleted ? 'GreenDot' : 'RedDot';

  return (
    <div className={`${rowBlockName}__status-row`}>
      <IconWrapper type={statusIconType} className={`mr-4`} />
      <div className={`${rowBlockName}__status-row__details`}>
        <div className={`${rowBlockName}__status-row__details__firstName ellipsis`}>{firstName}</div>
        <div className={`${rowBlockName}__status-row__details__lastName ellipsis`}>{lastName}</div>
        <div className={`${rowBlockName}__status-row__details__email ellipsis`}>{email}</div>
        <div className={`${rowBlockName}__status-row__details__groupName ellipsis`}>{groupName}</div>
        <div className={`${rowBlockName}__status-row__details__dateOfCompletion ellipsis`}>{dateOfCompletion}</div>
      </div>
    </div>
  );
};

UserStatus.propTypes = {
  orgId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  contents: ContentsPropTypesShape
};

UserRow.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    groupName: PropTypes.string,
    dateOfCompletion: PropTypes.string,
    isContentCompleted: PropTypes.bool
  })
};

export default UserStatus;
