import { UPDATE_GROUP, UPDATE_GROUP_SUCCESS, ASSIGN_GROUP, ASSIGN_GROUP_SUCCESS } from 'utils/constants';

const initialState = {
  users: [],
  fetching: false
};

const organizationUsers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GROUP:
      return {
        ...state,

        addUpdateGroupSuccess: false
      };
    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        users: state.users.map(user => {
          return user.group[0] && user.group[0]._id === action.payload.group._id ? { ...user, group: [action.payload.group] } : user;
        }),
        addUpdateGroupSuccess: true
      };
    case ASSIGN_GROUP:
      return {
        ...state,
        assignGroupSuccess: false
      };
    case ASSIGN_GROUP_SUCCESS: {
      const updatedUserMap = action.payload.updatedUsersId.map(u => u._id);
      const oldGroup = [];
      const users = state.users.map(user => {
        const updatedUser = user;
        if (updatedUserMap.includes(user._id)) {
          const userGroup = [];
          if (!user.group.length) {
            userGroup.push('defaultGroup');
          }

          if (user.group.length) {
            userGroup.push(...user.group);
          }
          oldGroup.push(...userGroup);
          updatedUser.group = action.payload.group;
        }
        return updatedUser;
      });
      return {
        ...state,
        users,
        lastAssignedGroup: { newGroup: action.payload.group, oldGroup },
        assignGroupSuccess: true
      };
    }

    default:
      return state;
  }
};
export default organizationUsers;
