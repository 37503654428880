import React from 'react';
import PropTypes from 'prop-types';
import sn from 'classnames';
import AddMedia from 'assets/icons/add-media.svg';
import AdminIcon from 'assets/icons/admin-icon.svg';
import ManagerIcon from 'assets/icons/manager-icon.svg';
import CloseIcon from 'assets/icons/crossout-rev@3x.png';
import LoaderImage from 'assets/icons/loadingdots.svg';
import CrossIcon from 'assets/icons/cross-icon.svg';
import DocType from 'assets/icons/Doctype.svg';
import GoButton from 'assets/icons/go-btn@3x.png';
import GreenDot from 'assets/icons/greendot.svg';
import GreenPlus from 'assets/icons/greenplus.svg';
import GreyDot from 'assets/icons/greydot.svg';
import LanguageTip from 'assets/icons/language-tip.svg';
import StrengthLevelHigh from 'assets/icons/strength-level-high.svg';
import StrengthLevelLow from 'assets/icons/strength-level-low.svg';
import StrengthLevelMedium from 'assets/icons/strength-level-mid.svg';
import Time from 'assets/icons/time.svg';
import Media from 'assets/icons/media.svg';
import Warning from 'assets/icons/warning.svg';
import NoImage from 'assets/icons/noimage.svg';
import Seal from 'assets/icons/seal.svg';
import NoPPT from 'assets/icons/nopresentation.svg';
import NoLanguage from 'assets/icons/no-language.svg';
import RedDot from 'assets/icons/reddot.svg';
import User from 'assets/icons/user.svg';
import UserActive from 'assets/icons/user-active.svg';
import UserInactive from 'assets/icons/user-inactive.svg';
import Author from 'assets/icons/author.svg';
import Author22By16 from 'assets/icons/author_22x16.svg';
import Power from 'assets/icons/power.svg';
import Calendar from 'assets/icons/calendar.svg';
import ContentBlock from 'assets/icons/content-blocks.svg';
import Cuboid from 'assets/icons/Module-cuboid.svg';
import CalendarGrey from 'assets/icons/setdate-grey.svg';
import Star from 'assets/icons/star.svg';
import Preview from 'assets/icons/preview.svg';
import PreviewHide from 'assets/icons/preview-hide.svg';
import Retry from 'assets/icons/course_retry.svg';
import GoToModules from 'assets/icons/course_view2.svg';
import SavedIcon from 'assets/icons/saved_ani.svg';
import Right from 'assets/icons/right.svg';
import Wrong from 'assets/icons/wrong.svg';
import ThumbsUp from 'assets/icons/thumbs-up.svg';
import ThumbsDown from 'assets/icons/thumbs-down.svg';
import Download from 'assets/icons/download.svg';
import DownloadWhite from 'assets/icons/download-white.svg';
import Clipboard from 'assets/icons/clipboard.jpeg';
import HomeIcon from 'assets/icons/home.svg';
import LanguageSelector from 'assets/icons/language-selector.svg';
import LogoutIcon from 'assets/icons/logout.svg';
import MyAccount from 'assets/icons/my-account.svg';
import Share from 'assets/icons/share.svg';
import MoveForward from 'assets/icons/next-questions.svg';
import MoveForwardInactive from 'assets/icons/next-inactive.svg';
import MoveBackward from 'assets/icons/move-backward.svg';
// import LeftArrow from 'assets/icons/left-arrow.svg';
import LeftArrow from 'assets/icons/left-arrow.png';
import Complete from 'assets/icons/complete.svg';
import Available from 'assets/icons/available.svg';
import Waiting from 'assets/icons/later.svg';
import MindzeedLogo from 'assets/icons/mindzeed-logo.svg';
import ErrorMan from 'assets/icons/errorman.svg';
import ArrowGreen from 'assets/icons/arrow-down-green.svg';
import ArrowGrey from 'assets/icons/arrow-down-grey.svg';
import ArrowUp from 'assets/icons/arrow-up.svg';
import ArrowDown from 'assets/icons/arrow-down.svg';
import RegisteredUsers from 'assets/icons/registered-users.svg';
import ShortContent from 'assets/icons/short-content.svg';
import ShortSettings from 'assets/icons/short-settings.svg';
import Plus from 'assets/icons/plus_20@3x.svg';
import CardOff from 'assets/icons/card_off.svg';
import CardOn from 'assets/icons/card_on.svg';
import ListOff from 'assets/icons/list_off.svg';
import ListOn from 'assets/icons/list_on.svg';
import SortDateOff from 'assets/icons/sort_date_off.svg';
import SortDateOn from 'assets/icons/sort_date_on.svg';
import SortAlphabeticalOff from 'assets/icons/sort_alphabetical_off.svg';
import SortAlphabeticalOn from 'assets/icons/sort_alphabetical_on.svg';

const blockname = 'icon-container';
const list = {
  AddMedia,
  AdminIcon,
  ManagerIcon,
  CrossIcon,
  DocType,
  GreenDot,
  GreenPlus,
  GreyDot,
  LanguageTip,
  StrengthLevelHigh,
  StrengthLevelLow,
  StrengthLevelMedium,
  Time,
  Media,
  NoImage,
  RedDot,
  NoPPT,
  User,
  NoLanguage,
  Warning,
  UserActive,
  UserInactive,
  Author,
  Power,
  Calendar,
  LoaderImage,
  CloseIcon,
  CalendarGrey,
  GoButton,
  Star,
  ContentBlock,
  Preview,
  PreviewHide,
  Retry,
  GoToModules,
  Right,
  Wrong,
  ThumbsUp,
  ThumbsDown,
  Author22By16,
  SavedIcon,
  Seal,
  Download,
  DownloadWhite,
  Clipboard,
  Share,
  HomeIcon,
  LanguageSelector,
  MyAccount,
  LogoutIcon,
  Cuboid,
  MoveForward,
  MoveForwardInactive,
  MoveBackward,
  LeftArrow,
  Complete,
  Available,
  Waiting,
  MindzeedLogo,
  ErrorMan,
  ArrowGrey,
  ArrowGreen,
  ArrowUp,
  ArrowDown,
  RegisteredUsers,
  ShortContent,
  ShortSettings,
  Plus,
  CardOff,
  CardOn,
  ListOn,
  ListOff,
  SortDateOff,
  SortDateOn,
  SortAlphabeticalOff,
  SortAlphabeticalOn
};
const IconWrapper = ({ type, srcUrl, className, ...props }) => {
  return <img src={srcUrl || (list && list[type])} {...props} className={sn(`${blockname}`, `${className}`)} />;
};

IconWrapper.propTypes = {
  type: PropTypes.string,
  srcUrl: PropTypes.string,
  className: PropTypes.string
};

IconWrapper.defaultProps = {
  className: '',
  type: NoImage,
  srcUrl: null
};

export default IconWrapper;
