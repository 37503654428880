import React from 'react';
import { Col } from 'react-bootstrap';
import ReactContentLoader from 'react-content-loader';

export const ContentCardLoader = props => (
  <Col xs={12} sm={6} md={4} lg={3} className="p-0">
    <ReactContentLoader
      style={{
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 20px)',
        backgroundColor: '#ffffff',
        borderRadius: 8,
        marginRight: 20,
        marginBottom: 20
      }}
      speed={2}
      viewBox="0 0 262 393"
      backgroundColor="#f1f4f1"
      foregroundColor="#e7ece7"
      {...props}
    >
      <rect x="0" y="0" rx="8" ry="8" width="262" height="150" />
      <rect x="0" y="142" rx="0" ry="0" width="262" height="8" />
      <rect x="25" y="170" rx="0" ry="0" width="212" height="20" />
      <rect x="50" y="210" rx="2" ry="2" width="187" height="16" />
      <rect x="25" y="210" rx="2" ry="2" width="16" height="16" />
      <rect x="50" y="237" rx="2" ry="2" width="187" height="16" />
      <rect x="25" y="237" rx="2" ry="2" width="16" height="16" />
      <rect x="50" y="264" rx="2" ry="2" width="187" height="16" />
      <rect x="25" y="264" rx="2" ry="2" width="16" height="16" />
      <rect x="50" y="291" rx="2" ry="2" width="187" height="16" />
      <rect x="25" y="291" rx="2" ry="2" width="16" height="16" />
      <circle cx="34" cy="366" r="9" />
      <rect x="50" y="357" rx="2" ry="2" width="44" height="16" />
      <circle cx="206" cy="370" r="3" />
      <circle cx="216" cy="370" r="3" />
      <circle cx="226" cy="370" r="3" />
    </ReactContentLoader>
  </Col>
);

export const ContentListLoader = props => (
  <Col xs={12} className="p-0">
    <ReactContentLoader
      style={{
        width: '100%'
      }}
      speed={1}
      viewBox="0 0 1110 41"
      backgroundColor="#f1f4f1"
      foregroundColor="#e7ece7"
      {...props}
    >
      <rect x="50" y="10" rx="0" ry="0" width="1060" height="20" />
      <circle cx="10" cy="20" r="10" />
    </ReactContentLoader>
  </Col>
);
