import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import './loader.scss';

const Loader = () => {
  return <Spinner animation="border" />;
};

Loader.propTypes = {};

Loader.defaultProps = {};
export default Loader;
