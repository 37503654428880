import React from 'react';
import PropTypes from 'prop-types';

const GroupName = ({ name, colorCode }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <span style={{ height: 20, width: 20, marginRight: 10, borderRadius: 3, background: colorCode }} />
      <span style={{ textOverflow: 'ellipsis', width: 'calc(100% - 30px)', whiteSpace: 'nowrap', overflow: 'hidden', wordBreak: 'break-all' }}>{name}</span>
    </div>
  );
};

GroupName.propTypes = {
  name: PropTypes.string,
  colorCode: PropTypes.string
};

export default GroupName;
