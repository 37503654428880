import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import uuidv4 from 'uuid/v4';
import Sortable from 'react-sortablejs';
import { Col, Row } from 'react-bootstrap';
import { get, isEmpty, lowerCase } from 'lodash';

import ThreeDots from 'atoms/ThreeDots';
import Input from 'atoms/Input';
import Button from 'atoms/Button';
import Feedback from 'atoms/Feedback';
import { Accordion } from 'atoms/Accordion';
import { Radio, RadioGroup } from 'atoms/Radio';
import Editor from 'atoms/Editor';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { contentDataSelector } from 'utils/calculationUtilities';
import { MCQPreferencesPropTypesShape, CurrentContentPropTypesShape } from 'utils/core-proptypes';
import QuestionBlock from 'atoms/QuestionBlock';
import MRQBlock from 'atoms/MRQBlock';
import StoryItemBlock from 'atoms/StoryItemBlock';
import { addContentData, updateContentData } from '../../actions';
import ActionRowContentData from '../../ActionRowContentData';
import { reducer } from './reducer';
import './mixed-create.scss';

const blockName = 'mixed-content-create';

/**
 *
 * @param {array} optionsReq
 * @param {string} correctReq
 * @returns an object with options mapped with uuid
 */

const MixedCreate = ({ contentId, currentContent, orgid, ...props }) => {
  const t = useFormatMessage();
  const langCode = lowerCase(get(props, 'langCode', ''));
  const initialState = {
    langCode,
    setDefaultLanguage: true,
    componentList: [
      {
        question: '',
        options: ['', ''],
        correct: 0,
        explanation: '',
        componentType: 'MCQ'
      }
    ]
  };
  const currentContentData = contentDataSelector({
    contentData: currentContent,
    langCode
  });
  const { preferences } = currentContent;
  const [state, dispatcher] = useReducer(reducer, currentContentData || initialState);
  const createFeedbackMessage = get(props, 'message.ADD_CONTENT_DATA_SUCCESS', {});
  const updateFeedbackMessage = get(props, 'message.UPDATE_CONTENT_DATA_SUCCESS', {});
  const createFeedbackError = get(props, 'errors.ADD_CONTENT_DATA_FAILURE', []);
  const updateFeedbackError = get(props, 'errors.UPDATE_CONTENT_DATA_FAILURE', []);
  const contentDataId = get(currentContentData, '_id', '');
  useEffect(() => {
    const defaultLanguage = props.defaultLangCode;
    dispatcher({
      type: 'initialState',
      payload: {
        ...currentContentData,
        ...currentContentData.details,
        setDefaultLanguage: defaultLanguage,
        revertId: uuidv4()
      }
    });
  }, [currentContent]);
  const contentLanguageRevert = () => {
    dispatcher({
      type: 'initialState',
      payload: {
        ...currentContentData,
        ...currentContentData.details,
        revertId: uuidv4()
      }
    });
  };
  const addBlock = componentType => {
    let payload = {
      _id: uuidv4(),
      isCollapsed: true,
      componentType
    };
    if (['MCQ', 'MRQ'].includes(componentType)) {
      payload = { ...payload, question: '', options: ['', ''], correct: 0, explanation: '' };
    } else {
      payload = { ...payload, textColor: '#333333', backgroundColor: '#FFFFFF' };
    }
    if (componentType === 'MRQ') {
      payload = { ...payload, correct: [0] };
    }
    dispatcher({
      type: 'addBlock',
      payload
    });
  };

  const itemSortHandler = order => {
    const sortedItemID = order;
    const sortedItem = [];
    sortedItemID.map(id => {
      const item = state.componentList.find(q => q._id === id);
      if (!isEmpty(item)) {
        sortedItem.push(item);
      }
      return id;
    });
    dispatcher({
      type: 'sortComponentList',
      payload: sortedItem
    });
  };
  const onSave = () => {
    const defaultLanguage = state.setDefaultLanguage === langCode;
    let payload = {
      ...state,
      setDefaultLanguage: defaultLanguage
    };
    const { componentList, ...restPayload } = payload;
    const mComponentList = componentList.map(c => {
      const { componentType, _id, ...componentData } = c;
      return { componentType, _id, componentData: componentData };
    });
    payload = { ...restPayload, componentList: mComponentList };
    if (contentId && contentDataId) {
      props.updateContentData({
        payload,
        contentId,
        contentDataId,
        organizationId: orgid
      });
    } else {
      props.addContentData({ payload, contentId, organizationId: orgid });
    }
  };
  const renderQuestions = () => {
    const sortableOptions = {
      animation: 150,
      handle: '.accordion-sort',
      chosenClass: 'sortable-chosen'
    };
    const items = state.componentList.map((q, index) => {
      switch (q.componentType) {
        case 'MCQ':
          return <QuestionBlock key={q._id} data-id={q._id} question={q} index={index} onDispatch={dispatcher} isCollapsed={q.isCollapsed} />;
        case 'MRQ':
          return <MRQBlock key={q._id} data-id={q._id} question={q} index={index} onDispatch={dispatcher} isCollapsed={q.isCollapsed} />;
        case 'BLOG':
          return <StoryItemBlock key={q._id} data-id={q._id} item={q} index={index} onDispatch={dispatcher} isCollapsed={q.isCollapsed} />;

        default:
          return null;
      }
    });
    return (
      <Sortable options={sortableOptions} onChange={itemSortHandler}>
        {items}
      </Sortable>
    );
  };

  return (
    <React.Fragment>
      <div className={blockName}>
        <Row>
          <Col>
            <div className={`${blockName}__title`}>{t('common/content-title')}</div>
            <Input
              className={`${blockName}__input-title`}
              value={state && state.contentTitle}
              placeholder={t('common/content-title')}
              onChange={e => {
                dispatcher({
                  type: 'inputChange',
                  key: 'contentTitle',
                  value: e.target.value
                });
              }}
            />
            <div className={`${blockName}__desc`}>{t('common/description')}</div>
            <Editor
              dispatcher={e =>
                dispatcher({
                  type: 'inputChange',
                  key: 'description',
                  value: e.value
                })
              }
              editorKey={'documentation'}
              documentation={state.description}
            />
            <RadioGroup
              name="mixed-create-default-lang"
              onSelect={value => {
                dispatcher({
                  type: 'inputRadio',
                  key: 'setDefaultLanguage',
                  value: value.value
                });
              }}
            >
              <Radio
                labelClass={`${blockName}__default-lang`}
                value={langCode}
                checked={state.setDefaultLanguage === langCode}
                description={t('common/set-as-default-language-description')}
                label={t('common/set-as-default-language-title')}
              />
            </RadioGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={`${blockName}__questions`}>
              <Accordion>{renderQuestions()}</Accordion>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <ActionRowContentData
            onSave={onSave}
            state={state}
            contentId={contentId}
            contentDataId={contentDataId}
            preferences={preferences}
            onRevert={contentLanguageRevert}
            contentType={currentContent.contentType}
            notPristine={state.mixedContentCreateUpdatePristine}
            onDelete={props.onDelete}
            langCode={langCode}
            orgid={orgid}
          />
          <Col md="10" className="justify-content-end d-flex">
            <Button variant="primary" style={{ height: '35px' }}>
              <ThreeDots
                style={{ padding: 0, color: '#fff' }}
                label={t('common/add-block')}
                width="300px"
                placement="top"
                options={[
                  {
                    name: 'Multiple Choice',
                    handler: () => addBlock('MCQ')
                  },
                  {
                    name: 'Multiple Response',
                    handler: () => addBlock('MRQ')
                  },
                  {
                    name: 'Blog',
                    handler: () => addBlock('BLOG')
                  }
                ]}
              />
            </Button>
          </Col>
        </Row>
      </div>
      <Feedback message={{ ...createFeedbackMessage, ...updateFeedbackMessage }} errorList={[...createFeedbackError, ...updateFeedbackError]} allowedTypesKey="MCQData" />
    </React.Fragment>
  );
};

MixedCreate.propTypes = {
  updateContentData: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
  addContentData: PropTypes.func.isRequired,
  langCode: PropTypes.string.isRequired,
  defaultLangCode: PropTypes.string.isRequired,
  currentContent: PropTypes.shape({
    currentContentData: CurrentContentPropTypesShape,
    preferences: MCQPreferencesPropTypesShape,
    contentType: PropTypes.string
  }),
  onDelete: PropTypes.func,
  type: PropTypes.string,
  orgid: PropTypes.string.isRequired
};

MixedCreate.defaultProps = {
  onDelete: () => {},
  currentContent: {}
};

const mapStateToProps = ({ contentState }) => {
  const errors = get(contentState, 'errors', []);
  const message = get(contentState, 'message', {});

  return {
    error: contentState.error,
    errors,
    message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addContentData: bindActionCreators(addContentData, dispatch),
    updateContentData: bindActionCreators(updateContentData, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MixedCreate));
