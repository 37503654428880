import React from 'react';
import PropTypes from 'prop-types';
import IconWrapper from 'atoms/IconWrapper';

import { Card } from 'react-bootstrap';
import './group-atom.scss';

const GroupAtoms = ({ group: { _id, color, usersCount, value }, style, onClose }) => {
  return (
    <div onClose={onClose} className="group-assign-container" style={style} id={_id}>
      <div>
        <Card
          style={{
            height: '25px',
            width: '25px',
            backgroundColor: color,
            marginRight: '5px'
          }}
        />
        {`${value} (${usersCount})`}
      </div>
      <IconWrapper type="CrossIcon" onClick={() => onClose(_id)} className="group-assign-container__close" />
    </div>
  );
};

GroupAtoms.propTypes = {
  onClose: PropTypes.func,
  style: PropTypes.shape({}),
  group: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    color: PropTypes.string,
    value: PropTypes.string.isRequired,
    usersCount: PropTypes.number.isRequired
  })
};

GroupAtoms.defaultProps = {
  onClose: () => {},
  style: {},
  color: ''
};

export default GroupAtoms;
