import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { PDFDownloadLink } from '@react-pdf/renderer';

import IconWrapper from 'atoms/IconWrapper';
import { UserPropTypesShape } from 'utils/core-proptypes';
import DiplomaTemplate from './template';
import './diploma-card.scss';

const DiplomaCard = ({ diploma, organization, userProfile }) => {
  const diplomaTitle = get(diploma, 'moduleData[0].moduleTitle', '');
  const diplomaDate = get(diploma, 'diplomaData.diplomaDate', '');
  const userDateFormat = get(userProfile, 'settings.dateFormat', 'DD/MM/YYYY');

  return (
    <div className={'diploma-card-container'}>
      <div className="diploma-card-container__diploma-date">{diplomaDate && moment(diplomaDate).format(userDateFormat)}</div>
      <IconWrapper type="Seal" className="diploma-card-container__diploma-image" />
      <div className="diploma-card-container__diploma-text-wrapper">
        <p className="diploma-card-container__diploma-title">{diplomaTitle}</p>
        <IconWrapper />
        {
          <PDFDownloadLink
            document={<DiplomaTemplate diploma={diploma} organization={organization} userProfile={userProfile} />}
            fileName={`${diploma.moduleId}.pdf`}
            style={{
              textDecoration: 'none',
              padding: '10px',
              color: '#4a4a4a'
            }}
          >
            <IconWrapper type="Download" className="diploma-card-container__download-image" />
          </PDFDownloadLink>
        }
      </div>
    </div>
  );
};

DiplomaCard.propTypes = {
  heading: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  cover: PropTypes.object.isRequired,
  diploma: PropTypes.shape({
    moduleId: PropTypes.string.isRequired
  }).isRequired,
  organization: PropTypes.shape({ name: PropTypes.string }).isRequired,
  userProfile: UserPropTypesShape
};
export default DiplomaCard;
