import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import IconWrapper from 'atoms/IconWrapper';

import { useFormatMessage } from 'hooks/useFormatMessage';
import './user-import-feedback.scss';

const UserImportFeedback = props => {
  const t = useFormatMessage();
  const { feedback } = props;
  return (
    <Container className="user-import-feedback">
      {feedback.errors ? (
        <>
          <Row className="user-import-feedback__type">
            <IconWrapper className="user-import-feedback__type__icon" type="RedDot" />
            {t('user-import-feedback/errors-in-the-file-and-could-not-upload-it')}
          </Row>

          {feedback.errors
            .filter(e => e.dynamicData)
            .map(error => {
              // eslint-disable-next-line react/jsx-key
              return (
                <div key={`${error.code}_${error.dynamicData.row}`} className="user-import-feedback__error-row">
                  <span className="mr-5">{error.dynamicData.row}</span>
                  {t(`import-users-error/${error.code}`, error.dynamicData)}
                </div>
              );
            })}
        </>
      ) : (
        <Row className="user-import-feedback__type" style={{ border: 'none' }}>
          <IconWrapper className="user-import-feedback__type__icon" type="GreenDot" />
          {t('user-import-feedback/perfect-we-are-working-on-importing', feedback)}
        </Row>
      )}
    </Container>
  );
};

UserImportFeedback.propTypes = {
  feedback: PropTypes.shape([])
};

UserImportFeedback.defaultProps = {};

export default UserImportFeedback;
