import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import Input from 'atoms/Input';
// import { Radio } from 'atoms/Radio';
import Button from 'atoms/Button';
import { AccordionItem, AccordionItemTitle, AccordionItemBody } from 'atoms/Accordion';
import { useFormatMessage } from 'hooks/useFormatMessage';
// import { uniqueId } from 'utils/unique-id';
import ThreeDots from 'atoms/ThreeDots';
import ImageThumbnail from 'atoms/ImageThumbnail';
import NoPPTImage from 'assets/icons/nopresentation.svg';
import { AllowedMediaTypeSource } from 'utils/calculationUtilities';
import Checkbox from 'atoms/Checkbox';

const blockName = 'mcq-content-create';

const MRQBlock = ({ onDispatch, question, index, ...props }) => {
  const t = useFormatMessage();
  // const radioName = uniqueId();

  const handleTitle = e => {
    onDispatch({
      type: 'updateQuestion',
      field: 'question',
      index,
      value: e.target.value
    });
  };

  const handleExplanation = e => {
    onDispatch({
      type: 'updateQuestion',
      field: 'explanation',
      index,
      value: e.target.value
    });
  };

  const handleAnswer = (e, answerIndex) => {
    onDispatch({
      type: 'updateAnswer',
      questionIndex: index,
      answerIndex,
      value: e.target.value
    });
  };

  const addAnswer = e => {
    onDispatch({
      type: 'addAnswer',
      questionIndex: index,
      value: e.target.value
    });
  };

  const duplicateQuestionHandler = () => {
    onDispatch({
      type: 'duplicateQuestion',
      index
    });
  };

  const deleteQuestionHandler = () => {
    onDispatch({
      type: 'deleteQuestion',
      index
    });
  };

  const handleVideoModalSave = media => {
    onDispatch({
      type: 'setItemMedia',
      media,
      index
    });
  };
  return (
    <AccordionItem {...props}>
      <AccordionItemTitle accordinTitleplaceholder="Question">{question.question}</AccordionItemTitle>
      <AccordionItemBody>
        <div>
          <Row className={`${blockName}__component-thumbnail-row`}>
            <div>
              {t('common/video-text')}
              <ImageThumbnail
                placeholderIcon={NoPPTImage}
                defaultMedia={question.media}
                allowedFileType={[AllowedMediaTypeSource.FILE, AllowedMediaTypeSource.VIMEO, AllowedMediaTypeSource.YOUTUBE]}
                handleSave={handleVideoModalSave}
              />
            </div>
          </Row>
          <div className={`${blockName}__title`}>{t('question-block/question')}</div>
          <Input as="textarea" rows="2" placeholder={t('placeholder/questoin-title')} onChange={handleTitle} value={question.question} className={`${blockName}__input-title`} />
          <div className={`${blockName}__desc`}>{t('question-block/explanation')}</div>
          <Input onChange={handleExplanation} placeholder={t('placeholder/questoin-explanation')} as="textarea" rows="5" value={question.explanation} className={`${blockName}__input-desc`} />

          {question.options.map((op, opIndex) => {
            return (
              <Row key={opIndex}>
                <Col md="10">
                  <div className={`${blockName}__desc`}>
                    {t('question-block/answer')} {opIndex + 1}
                  </div>
                  <Input as="textarea" rows="3" onChange={e => handleAnswer(e, opIndex)} value={op} className={`${blockName}__input-desc`} />
                </Col>
                <Col md="2" style={{ display: 'flex', alignItems: 'center', paddingTop: '47px' }}>
                  <Checkbox
                    // label={radioName}
                    onChange={e => {
                      let isCorrectOption = !question.correct.includes(opIndex);
                      let newCorrectOptions;
                      if (isCorrectOption) {
                        newCorrectOptions = [...question.correct, +e.target.value];
                      } else {
                        newCorrectOptions = question.correct.filter(x => x !== opIndex);
                      }
                      onDispatch({
                        type: 'updateQuestion',
                        field: 'correct',
                        index,
                        value: newCorrectOptions
                      });
                    }}
                    checked={question.correct.includes(opIndex)}
                    type="checkbox"
                    className=" reminder-checkbox checkbox-container "
                    value={opIndex}
                    label={t('common/correct')}
                  />
                </Col>
              </Row>
            );
          })}

          <Row className="mt-4">
            <Col md="11">
              <Button variant="secondary" onClick={addAnswer}>
                {t('question-block/new-answer')}
              </Button>
            </Col>
            <Col>
              <ThreeDots
                active
                size="small"
                options={[
                  {
                    name: t('common/duplicate'),
                    handler: duplicateQuestionHandler
                  },
                  {
                    name: t('common/delete'),
                    handler: deleteQuestionHandler
                  }
                ]}
              />
            </Col>
          </Row>
        </div>
      </AccordionItemBody>
    </AccordionItem>
  );
};

export default MRQBlock;

MRQBlock.propTypes = {
  onDispatch: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    media: PropTypes.shape({}),
    question: PropTypes.string,
    correct: PropTypes.shape([]),
    explanation: PropTypes.string,
    options: PropTypes.shape([])
  }).isRequired
};
