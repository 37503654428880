import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import Switch from 'atoms/Switch';
import Button from 'atoms/Button';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { updateAccountSettings } from '../actions';
import './notification.scss';

const reducer = (state, action) => {
  const { key, value } = action;
  switch (action.type) {
    case 'switchChange':
      return {
        ...state,
        [key]: value,
        notPristine: false
      };
    case 'initializeStateToPristine':
      return {
        ...state,
        notPristine: true
      };
    case 'initializeState': {
      const {
        notifications: {
          weeklyReminderToUser: { active: weeklyReminderToUserActive } = {},
          notifyUsersOnModuleAssign: notifyUsersOnModuleAssignActive,
          weeklyStatusToManager: { active: weeklyStatusToManagerActive } = {}
        } = {}
      } = action.payload;
      return {
        ...state,
        weeklyReminderToUserActive,
        notifyUsersOnModuleAssignActive,
        weeklyStatusToManagerActive,
        notPristine: true
      };
    }

    default:
      throw new Error();
  }
};

const Notifications = ({ selectedOrgDetails, updateAccountSettings, selectedOrg, hasSelectedOrgUpdated }) => {
  const t = useFormatMessage();
  const [state, dispatcher] = useReducer(reducer, {});
  const { weeklyReminderToUserActive, notifyUsersOnModuleAssignActive, weeklyStatusToManagerActive, notPristine } = state;

  useEffect(() => {
    if (!isEmpty(selectedOrgDetails)) {
      dispatcher({
        type: 'initializeState',
        payload: selectedOrgDetails
      });
    }
  }, [selectedOrgDetails]);

  useEffect(() => {
    if (hasSelectedOrgUpdated) {
      dispatcher({
        type: 'initializeStateToPristine'
      });
    }
  }, [hasSelectedOrgUpdated]);

  const handleAccountSettingUpdate = () => {
    updateAccountSettings({
      organizationId: selectedOrg,
      payload: {
        weeklyReminderToUser: weeklyReminderToUserActive,
        notifyUsersOnModuleAssign: notifyUsersOnModuleAssignActive,
        weeklyStatusToManager: weeklyStatusToManagerActive
      }
    });
  };

  return (
    <React.Fragment>
      <Container className="app-notifications-container">
        <Row className="app-notifications-container__row">
          <Col className="app-notifications-container__row__switch">
            <Switch id="notifyUsersOnModuleAssignActive" onSelect={dispatcher} value={notifyUsersOnModuleAssignActive} />
          </Col>
          <Col className="app-notifications-container__row__switch-label">{t('app-settings/notify-users-when-they-are-assigned-to-modules')}</Col>
        </Row>
        <Row className="app-notifications-container__row">
          <Col className="app-notifications-container__row__switch">
            <Switch id="weeklyReminderToUserActive" onSelect={dispatcher} value={weeklyReminderToUserActive} />
          </Col>
          <Col className="app-notifications-container__row__switch-label">{t('app-settings/weekly-reminder-mail-to-users')}</Col>
        </Row>
        <Row className="app-notifications-container__row">
          <Col className="app-notifications-container__row__switch">
            <Switch id="weeklyStatusToManagerActive" onSelect={dispatcher} value={weeklyStatusToManagerActive} />
          </Col>
          <Col className="app-notifications-container__row__switch-label">{t('app-settings/weekly-status-mail-to-managers')}</Col>
        </Row>
        <Row className="app-notifications-container__row" style={{ border: 'none', marginTop: '30px' }}>
          <Button notPristine={notPristine} onClick={handleAccountSettingUpdate} style={{ minWidth: '100px' }}>
            {t('common/save')}
          </Button>
        </Row>
      </Container>
    </React.Fragment>
  );
};

Notifications.propTypes = {
  selectedOrgDetails: PropTypes.shape({}),
  updateAccountSettings: PropTypes.func.isRequired,
  selectedOrg: PropTypes.string.isRequired,
  hasSelectedOrgUpdated: PropTypes.bool.isRequired
};

Notifications.defaultProps = {
  hasSelectedOrgUpdated: false
};

const mapStateToProps = ({ accountSettings }) => {
  const { selectedOrgDetails, hasSelectedOrgUpdated } = accountSettings;
  return { selectedOrgDetails, hasSelectedOrgUpdated };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAccountSettings: bindActionCreators(updateAccountSettings, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
