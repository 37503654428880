import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup } from 'atoms/Radio';
import { Row, Col } from 'react-bootstrap';
import { carouselStoryContentAdditionalPrefs, carouselStoryPreferenceConstantToKeyMap } from 'utils/types';
import Switch from 'atoms/Switch';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { MCQPreferencesPropTypesShape } from 'utils/core-proptypes';
import './modal-elements.scss';

const ModuleSpecificCarouselPreference = ({ dispatcher, modalPreferenceData }) => {
  const t = useFormatMessage();
  return (
    <div>
      <Row>
        <Col className="content-preference__row-left-border" style={{ paddingTop: 0 }}>
          <RadioGroup
            label={t('carousel-content/how-many-times-can-the-user-access-the-content')}
            name="common-content-attempts"
            onSelect={({ value }) =>
              dispatcher({
                type: 'modalPreferenceInputRadio',
                key: 'noOfTimeCanBeAccessed',
                value
              })
            }
          >
            <Radio value="ONE" checked={modalPreferenceData.noOfTimeCanBeAccessed === 'ONE'} label={t('common/one')} />
            <Radio value="TWO" checked={modalPreferenceData.noOfTimeCanBeAccessed === 'TWO'} label={t('common/two')} />
            <Radio value="UNLIMITED" checked={modalPreferenceData.noOfTimeCanBeAccessed === 'UNLIMITED'} label={t('common/unlimited')} />
          </RadioGroup>
        </Col>
      </Row>
      <Col className="content-preference__body__thumbnail-container" style={{ padding: 0 }}>
        {Object.keys(carouselStoryContentAdditionalPrefs).map(pref => (
          <Row key={pref} className="content-preference__row" style={{ height: '50px', padding: '5px 0' }}>
            <div style={{ width: '80%' }} className="content-preference__row__switch-label">
              {t(carouselStoryContentAdditionalPrefs[pref])}
            </div>
            <div className="content-preference__row__switch" style={{ marginTop: '7px', width: '20%' }}>
              <Switch
                id={pref}
                onSelect={() => {
                  dispatcher({
                    type: 'modalPreferenceInputRadio',
                    key: carouselStoryPreferenceConstantToKeyMap[pref],
                    value: modalPreferenceData[carouselStoryPreferenceConstantToKeyMap[pref]] === 'false' ? 'true' : 'false'
                  });
                }}
                value={modalPreferenceData[carouselStoryPreferenceConstantToKeyMap[pref]] === 'false' ? false : true}
              />
            </div>
          </Row>
        ))}
      </Col>
    </div>
  );
};

ModuleSpecificCarouselPreference.propTypes = {
  modalPreferenceData: MCQPreferencesPropTypesShape,
  dispatcher: PropTypes.func.isRequired
};
ModuleSpecificCarouselPreference.defaultProps = {
  modalPreferenceData: {}
};
export default ModuleSpecificCarouselPreference;
