import { availableLanguages } from 'utils/calculationUtilities';
import { contentTypes } from 'utils/types';

export const contentMap = (content, t) => {
  const {
    _id,
    contentType,
    data = [],
    status,
    owner,
    ownerOrganization,
    preferences: { contentName: heading = '', thumbnail: thumbnailImage, difficultyLevel, estimatedTime, description } = {}
  } = content;
  const availableLanguage = availableLanguages(data);
  const languageOption =
    availableLanguage.length <= 0
      ? {
          type: 'NoLanguage',
          value: t('content-setup/card/no-language-added')
        }
      : {
          type: 'LanguageTip',
          value: availableLanguage
        };
  const warning = availableLanguage.length <= 0;
  return {
    _id,
    contentType,
    heading,
    difficultyLevel,
    estimatedTime,
    thumbnailImage,
    status,
    description,
    warning,
    details: [
      {
        type: 'Author22By16',
        value: owner === 'ME' ? t('content-setup/me') : ownerOrganization.name || t('content-setup/another-user')
      },
      { type: 'DocType', value: t(contentTypes[contentType.toUpperCase()]) },
      languageOption
    ]
  };
};
