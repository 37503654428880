import { useState, useReducer, useEffect } from 'react';
import { token, BASE_URL } from 'utils/api';

function dataFetchReducer(state, action) {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
        error: false
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        data: Array.isArray(action.payload) ? [...state.data, ...action.payload] : state.data,
        loading: false,
        error: false
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: true
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: true
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: action.payload
      };
    default:
      throw new Error();
  }
}

export function getSearchStringFromObject(urlData) {
  const keys = Object.keys(urlData);
  let search = '?';
  keys.forEach(key => {
    if (urlData[key] !== undefined && urlData[key] !== null && urlData[key] !== '') {
      search += `${key}=${encodeURIComponent(urlData[key])}&`;
    }
  });
  return search.substring(0, search.length - 1);
}

export function addQueryToUrl(url, query = {}) {
  const index = url.indexOf('?');
  const domain = index > 0 ? url.substring(0, index) : url;
  const search = index > 0 ? url.slice(index + 1).split('&') : [];

  const urlData = {};
  search.forEach(attribute => {
    try {
      const parts = attribute.split('=');
      urlData[parts[0]] = decodeURIComponent(parts[1]);
    } catch (e) {
      console.log(e);
    }
  });
  return `${domain}${getSearchStringFromObject({ ...urlData, ...query })}`;
}

export async function fetcher(url, method = 'GET', headers = {}, body = {}) {
  let options = {
    method,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${token()}`,
      ...headers
    }
  };
  if (['POST', 'PUT', 'DELETE'].includes(method)) options = { ...options, body };

  return fetch(`${BASE_URL}${url}`, options)
    .then(res => {
      return Promise.resolve(res.json());
    })
    .catch(error => Promise.reject(error));
}

const useFetcher = (endpoint, limit = 50, lazy = false, staticDataLength = 0, defaultData = []) => {
  const [options, setOptions] = useState({
    url: endpoint,
    cursor: { offset: 0, limit: limit - staticDataLength },
    resetKey: 0
  });
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [lazyFetch, setLazyFetch] = useState(lazy);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: lazy ? false : true,
    error: false,
    data: defaultData
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      try {
        const fetchUrl = addQueryToUrl(options.url, {
          limit: options.cursor.limit,
          skip: Math.max(0, options.cursor.offset * options.cursor.limit - staticDataLength)
        });
        const result = await fetcher(fetchUrl);
        if (result.errors) {
          dispatch({ type: 'FETCH_ERROR' });
        } else if (!didCancel) {
          if (!result.length || result.length < options.cursor.limit) {
            setShowLoadMore(false);
          }
          dispatch({ type: 'FETCH_SUCCESS', payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE' });
        }
      }
    };
    if (!lazyFetch) {
      fetchData();
    } else {
      dispatch({ type: 'FETCH_SUCCESS', payload: [] });
      setLazyFetch(false);
    }
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.url, options.cursor.limit, options.cursor.offset, options.resetKey]);

  const loadMoreData = () => {
    setOptions(options => ({
      ...options,
      cursor: { ...options.cursor, limit: limit, offset: options.cursor.offset + 1 }
    }));
  };

  const doFetch = (newEndPoint, reset = false) => {
    dispatch({ type: 'UPDATE_DATA', payload: [] });
    setOptions({
      url: `${newEndPoint}`,
      cursor: { offset: 0, limit: limit - staticDataLength },
      resetKey: reset ? options.resetKey + 1 : options.resetKey
    });
    setShowLoadMore(true);
  };

  const updateData = newData => {
    dispatch({ type: 'UPDATE_DATA', payload: newData });
  };

  return {
    ...state,
    limit: limit,
    showLoadMore,
    loadMoreData,
    doFetch,
    dispatch,
    updateData
  };
};

export default useFetcher;
