import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import useFetcher, { getSearchStringFromObject, fetcher } from 'hooks/useFetcher';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { get } from 'lodash';

import Modal from 'atoms/Modal';
import IconWrapper from 'atoms/IconWrapper';
import ThumbnailCardInfo from 'atoms/ThumbnailCardInfo';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { checkNaNPercent } from 'utils/calculationUtilities';
import { HistoryPropTypesShape } from 'utils/core-proptypes';
import { dispatchCustomAction, shareModule, shareModuleCopy } from './actions';
import { getGroupList, getOrganisationDetails } from '../../../actions';
import ModuleContentActionRow from '../ModuleContentActionRow';
import ModuleAddCard from './ModuleAddCard';
import ShareModuleCopy from '../../ShareModuleCopy';

import { useModuleContentSetupState } from '../ModuleContentProvider';
import { ModuleCardLoader, ModuleListLoader } from './ModuleLoader';
import './module-setup-view.scss';

const ModuleSetupView = ({ history, organizationList, selectedOrg, user, getOrganisationDetails, ...props }) => {
  const t = useFormatMessage();
  const { layout, setLayout, sortType, setSortType } = useModuleContentSetupState();
  const [shareModuleCopyModal, setShareModuleCopyModal] = useState(false);
  const [moduleDeleteModal, setModuleDeleteModal] = useState(false);
  const [shareModuleModalOrgs, setShareModuleModalOrgs] = useState([]);
  const [moduleCloneId, setModuleCloneId] = useState(false);
  const [deleteModuleDetails, setDeleteModuleDetails] = useState({});
  const [moduleStatusId, setModuleStatusId] = useState('false');
  const { loading, data: allModules, showLoadMore: hasNextPage, error: itemsError, loadMoreData: loadMore, doFetch, updateData } = useFetcher(
    `/organizations/${selectedOrg}/modules${getSearchStringFromObject({ sort: sortType, progress: true })}`,
    12,
    true,
    1
  );

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!itemsError,
    rootMargin: '0px 0px 100px 0px'
  });

  useEffect(() => {
    doFetch(`/organizations/${selectedOrg}/modules${getSearchStringFromObject({ sort: sortType, progress: true })}`, true);
    props.getGroupList(selectedOrg);
    if (selectedOrg) {
      getOrganisationDetails(selectedOrg);
    }
  }, [selectedOrg]);

  useEffect(() => {
    doFetch(`/organizations/${selectedOrg}/modules${getSearchStringFromObject({ sort: sortType, progress: true })}`, true);
  }, [sortType]);

  const { user: { role = '' } = {} } = user;
  const selectedOrgDetails = organizationList.find(org => org._id === selectedOrg);
  const accountType = selectedOrgDetails ? selectedOrgDetails.accountType : '';

  const modifiedData = allModules.map(module => {
    const {
      _id,
      moduleName,
      status,
      thumbnail: thumbnailImage,
      moduleDeleteMessageCode,
      isModuleAssigned,
      progress: { usersAlloted, usersStartedAnyContent } = { usersAlloted: 0, usersStartedAnyContent: 0 }
    } = module;

    const userActivePercent = checkNaNPercent((usersStartedAnyContent / usersAlloted) * 100);
    const userInactivePercent = checkNaNPercent(((usersAlloted - usersStartedAnyContent) / usersAlloted) * 100);
    const userInactive = usersAlloted - usersStartedAnyContent;

    return {
      heading: moduleName,
      usersAlloted,
      thumbnailImage,
      status,
      _id,
      isModuleAssigned,
      moduleDeleteMessageCode,
      details: [
        { type: 'ContentBlock', value: module.contents.length },
        { type: 'User', value: `${usersAlloted}` },
        {
          type: 'UserActive',
          value: `${usersStartedAnyContent} (${userActivePercent}%)`
        },
        {
          type: 'UserInactive',
          value: `${userInactive} (${userInactivePercent}%)`
        }
      ]
    };
  });

  const handleEdit = ({ _id, selectedOrg }) => {
    props.dispatchCustomAction('INITIALIZE_CURRENT_MODULE');
    history.push(`/org/${selectedOrg}/module/${_id}`);
  };

  const handleAddModule = () => {
    props.dispatchCustomAction('INITIALIZE_CURRENT_MODULE');
    history.push(`/org/${selectedOrg}/module`);
  };

  const handleModuleDelete = ({ isModuleAssigned, _id, moduleDeleteMessageCode }) => {
    if (isModuleAssigned) setDeleteModuleDetails({ moduleId: null, moduleDeleteMessageCode });
    else setDeleteModuleDetails({ moduleId: _id, moduleDeleteMessageCode });
    setModuleDeleteModal(true);
  };

  const moduleDeleteModalSave = async () => {
    if (deleteModuleDetails.moduleId) {
      try {
        const result = await fetcher(`/organizations/${selectedOrg}/modules/${deleteModuleDetails.moduleId}`, 'DELETE', {}, JSON.stringify({}));
        if (result.errors) {
          props.dispatchCustomAction('DELETE_MODULE_FAILURE');
        } else {
          updateData(allModules.filter(item => item._id !== result.deletedModuleId));
          props.dispatchCustomAction('DELETE_MODULE_SUCCESS');
        }
      } catch (err) {
        props.dispatchCustomAction('DELETE_MODULE_FAILURE');
      }
    }
    setModuleDeleteModal(false);
  };

  const getOptions = ({ _id, isModuleAssigned, moduleDeleteMessageCode }) => [
    {
      name: t('common/edit'),
      order: { grid: 1, list: 4 },
      showInListView: true,
      handler: () => {
        handleEdit({ _id, selectedOrg });
      }
    },
    {
      name: t('common/delete'),
      order: { grid: 3, list: 3 },
      showInListView: true,
      handler: () => {
        handleModuleDelete({ isModuleAssigned, _id, moduleDeleteMessageCode });
      }
    },
    {
      name: t('module/share-copy'),
      order: { grid: 4, list: 2 },
      showInListView: true,
      handler: () => {
        setModuleCloneId(_id);
      }
    },
    ...(role === 'ADMIN' || (role === 'PARTNER' && accountType === 'PARTNER')
      ? [
          {
            name: t('common/share-copy'),
            order: { grid: 6, list: -1 },
            showInListView: false,
            handler: () => {
              setShareModuleCopyModal(true);
              // set org details
              setShareModuleModalOrgs(
                organizationList.map(org => ({
                  name: org.name,
                  _id: org._id,
                  isChecked: false
                }))
              );
              setModuleStatusId(_id);
            }
          }
        ]
      : [])
  ];

  const saveShareModuleCopy = () => {
    props.shareModuleCopy({
      moduleId: moduleStatusId,
      selectedOrg,
      payload: { organizations: shareModuleModalOrgs.filter(org => org.isChecked).map(org => org._id) }
    });
    setShareModuleCopyModal(false);
  };

  const handleCloneModule = async () => {
    try {
      const result = await fetcher(`/organizations/${selectedOrg}/modules/${moduleCloneId}/clone`, 'POST', {}, JSON.stringify({}));
      if (result.errors) {
        props.dispatchCustomAction('CLONE_MODULE_FAILURE');
      } else {
        updateData([...allModules, result.module]);
        props.dispatchCustomAction('CLONE_MODULE_SUCCESS');
      }
    } catch (err) {
      props.dispatchCustomAction('CLONE_MODULE_FAILURE');
    }
    setModuleCloneId(false);
  };

  const handleChangeView = layout => {
    setLayout(layout);
  };

  const handleChangeSort = sortType => {
    setSortType(sortType);
  };

  return (
    <React.Fragment>
      <ModuleContentActionRow
        left={<IconWrapper type="Plus" onClick={handleAddModule} />}
        layout={layout}
        sortType={sortType}
        handleChangeLayout={handleChangeView}
        handleChangeSort={handleChangeSort}
      />
      <Container className={`module-setup-view-container ${layout}`}>
        {modifiedData.map(module => (
          <ThumbnailCardInfo key={module._id} type="module" layout={layout} cardInfo={module} options={getOptions(module)} />
        ))}
        {layout === 'grid' && (!loading || !!modifiedData.length) && <ModuleAddCard onClick={handleAddModule} />}
        {(loading || hasNextPage) && <div ref={sentryRef}></div>}
      </Container>
      {loading && layout === 'grid' && (
        <Container className={`module-setup-view-container ${layout}`} style={{ marginTop: 0 }}>
          <ModuleCardLoader />
          <ModuleCardLoader />
          <ModuleCardLoader />
          <ModuleCardLoader />
        </Container>
      )}
      {loading && layout === 'list' && (
        <Container className={`module-setup-view-container ${layout}`} style={{ marginTop: 0 }}>
          <ModuleListLoader />
          <ModuleListLoader />
          <ModuleListLoader />
          <ModuleListLoader />
          <ModuleListLoader />
          <ModuleListLoader />
          <ModuleListLoader />
          <ModuleListLoader />
          <ModuleListLoader />
          <ModuleListLoader />
        </Container>
      )}
      <Modal
        heading={t('common/modal/please-confirm-text')}
        modalBody={<p>{t('module/share-copy-modal-text')}</p>}
        show={moduleCloneId}
        isAlert
        onSave={handleCloneModule}
        onHide={() => setModuleCloneId(false)}
      />
      {deleteModuleDetails.moduleDeleteMessageCode && (
        <Modal
          heading={t('common/modal/please-confirm-text')}
          modalBody={<p>{t(`module-delete/${deleteModuleDetails.moduleDeleteMessageCode}`)}</p>}
          show={moduleDeleteModal}
          isAlert
          onSave={moduleDeleteModalSave}
          onHide={() => setModuleDeleteModal(false)}
        />
      )}
      <Modal
        heading={`${t('common/modal/share-module-copy')} : ${get(
          allModules.find(content => content._id === moduleStatusId),
          'moduleName'
        )}`}
        modalBody={<ShareModuleCopy shareModuleModalOrgs={shareModuleModalOrgs} setShareModuleModalOrgs={setShareModuleModalOrgs} />}
        show={shareModuleCopyModal}
        onSave={saveShareModuleCopy}
        onHide={() => setShareModuleCopyModal(false)}
      />
    </React.Fragment>
  );
};

ModuleSetupView.propTypes = {
  history: HistoryPropTypesShape,
  user: PropTypes.shape().isRequired,
  shareModuleCopy: PropTypes.func.isRequired,
  selectedOrg: PropTypes.string.isRequired,
  shareModule: PropTypes.func.isRequired,
  getGroupList: PropTypes.func.isRequired,
  getOrganisationDetails: PropTypes.func.isRequired,
  dispatchCustomAction: PropTypes.func.isRequired,
  organizationList: PropTypes.arrayOf(PropTypes.object)
};

ModuleSetupView.defaultProps = {};

const mapStateToProps = ({ adminState, userState }) => {
  return {
    localLanguages: adminState.localLanguages,
    user: userState.user,
    organizationList: adminState.organizations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchCustomAction: bindActionCreators(dispatchCustomAction, dispatch),
    shareModule: bindActionCreators(shareModule, dispatch),
    shareModuleCopy: bindActionCreators(shareModuleCopy, dispatch),
    getGroupList: bindActionCreators(getGroupList, dispatch),
    getOrganisationDetails: bindActionCreators(getOrganisationDetails, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleSetupView));
