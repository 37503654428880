export const validatePassword = string => {
  if (!string || string.length < 8) {
    return false;
  }

  const rules = [
    /[A-Z]/, // letters (upper-case)
    /\d/, // numbers (similar to /[0-9]/)
    /[!@#$&*]/ // some symbols
  ];

  return (
    rules.filter(function(rule) {
      return rule.test(string);
    }).length >= 3
  );
};
