import React from 'react';
import PropTypes from 'prop-types';
import sn from 'classnames';
import { uniqueId } from 'utils/unique-id';
import './radio.scss';

const Radio = ({ disabled, label, name, description, labelClass, value, ...rest }) => {
  const id = uniqueId();
  return (
    <div className="radio-container">
      <input type="radio" defaultValue={value} disabled={disabled} name={name || 'radio-group'} className={sn('radio-container__button')} id={id} {...rest} />
      <label htmlFor={id} className={sn('radio-container__label', labelClass)}>
        {label}
      </label>
      {description && <small className={sn('radio-container__description')}>{description}</small>}
    </div>
  );
};

Radio.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  labelClass: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string
};

Radio.defaultProps = {
  label: '',
  labelClass: '',
  description: '',
  disabled: false,
  value: ''
};
export default Radio;
