import React from 'react';
import PropTypes from 'prop-types';
import sn from 'classnames';
import IconWrapper from 'atoms/IconWrapper';
import { LanguagesPropTypesShape } from 'utils/core-proptypes';
import './assigned-module-card.scss';

const AssignedModuleCard = ({ title, languages, thumbnail, status, statusText, statusIcon, ...props }) => {
  return (
    <div
      role="button"
      className={sn('assigned-module-card-container', {
        'assigned-module-card-container--disabled': status === 'inactive'
      })}
      onClick={() => props.onClick()}
    >
      <div className={`assigned-module-card-container__course-overview ${status}`}>
        <div style={{ paddingTop: '56.25%', position: 'relative' }}>
          <img src={thumbnail} alt={title} className="img-fluid assigned-module-card-container__card-image rounded-top" />
        </div>

        <div className="assigned-module-card-container__content-container">
          <h3 className="assigned-module-card-container__content-container__h3">{title}</h3>
          <div className="assigned-module-card-container__content-container__languages-front">
            {languages.map((lang, index) => {
              const cond = index === languages.length - 1;
              const display = cond ? 'none' : '';
              return (
                <span key={lang}>
                  {lang}
                  <span className="assigned-module-card-container__content-container__languages-front__dot" style={{ display }}>
                    {' . '}
                  </span>
                </span>
              );
            })}
          </div>
          <div className="assigned-module-card-container__content-container__row-overview-state">
            <div className="assigned-module-card-container__content-container__row-overview-state__status">{statusText}</div>
            <IconWrapper type={statusIcon} alt="statusIcon" className="assigned-module-card-container__content-container__row-overview-state__status-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

AssignedModuleCard.defaultProps = {};

AssignedModuleCard.propTypes = {
  title: PropTypes.string.isRequired,
  languages: LanguagesPropTypesShape,
  thumbnail: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
  statusIcon: PropTypes.string.isRequired
};
export default AssignedModuleCard;
