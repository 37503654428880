import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { noop } from 'lodash';
import sn from 'classnames';

import Icon from 'atoms/Icon';
import crossOutIcon from 'assets/icons/crossout1@2x.png';
import './input.scss';

const Input = ({ placeholder, disabled, icon, iconImage, isClearable, onClear, value, ...restProps }) => {
  const blockName = 'input-container';
  const handleClear = () => {
    if (onClear) {
      onClear();
    }
  };
  return (
    <React.Fragment>
      <FormControl
        className={sn(blockName, { [`${blockName}__icon`]: icon }, { [`${blockName}--image`]: iconImage }, { [`${blockName}--clearable`]: isClearable })}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        type="text"
        {...restProps}
      />
      {icon && <Icon icon={icon} />}
      {iconImage && <img className={sn({ [`${blockName}__icon-image`]: iconImage })} src={iconImage} alt="input icon" />}
      {isClearable && value && <img onClick={handleClear} className={sn({ [`${blockName}__icon-clear`]: isClearable })} src={crossOutIcon} alt="clear icon" />}
    </React.Fragment>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconImage: PropTypes.string,
  isClearable: PropTypes.bool,
  onClear: PropTypes.func
};

Input.defaultProps = {
  placeholder: '',
  disabled: false,
  value: '',
  icon: '',
  iconImage: '',
  isClearable: false,
  onClear: noop
};

export default Input;
