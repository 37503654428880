import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import Checkbox from 'atoms/Checkbox';

const ShareContentCopy = ({ shareContentModalOrgs = [], setShareContentModalOrgs }) => {
  return (
    <Container style={{ padding: '0' }}>
      <Row className="share-content__tabs justify-content-center" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
        <Col style={{ paddingRight: '0' }}>
          {shareContentModalOrgs.map(org => (
            <Row key={org.organizationId} className="shared-content__row">
              <Col className="shared-content__row__switch-label">{org.name}</Col>
              <Checkbox
                checked={org.isChecked}
                onChange={() => {
                  setShareContentModalOrgs(shareContentModalOrgs.map(o => (org._id === o._id ? { ...o, isChecked: !org.isChecked } : o)));
                }}
              />
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

ShareContentCopy.propTypes = {
  shareContentModalOrgs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setShareContentModalOrgs: PropTypes.func.isRequired
};

ShareContentCopy.defaultProps = {};

export default ShareContentCopy;
