import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import Modal from 'atoms/Modal';
import { useFormatMessage } from 'hooks/useFormatMessage';
import DiplomaDownload from './public-diploma';

import './diploma-download.scss';

const PublicDiplomaDownload = ({ currentContentTitle, currentModuleTitle, setShowDiploma, showDiploma }) => {
  const t = useFormatMessage();

  return (
    <Container className="diploma-download">
      <Col className="diploma-download__row"></Col>
      <Modal
        heading={t('public-text/tell-us-your-name-so-we-can-write-it-on-your-diploma')}
        modalBody={<DiplomaDownload setDiplomaModal={setShowDiploma} currentContentTitle={currentContentTitle} currentModuleTitle={currentModuleTitle} />}
        show={showDiploma}
        onHide={() => setShowDiploma(false)}
        showSave={false}
        showCancel={false}
        isAlert
        className="language-selector"
      />
    </Container>
  );
};

PublicDiplomaDownload.propTypes = {
  showDiploma: PropTypes.bool,
  currentContentTitle: PropTypes.string,
  currentModuleTitle: PropTypes.string,
  setShowDiploma: PropTypes.func
};

PublicDiplomaDownload.defaultProps = {
  show: false
};

const mapStateToProps = ({ userActivityState }) => {
  const currentContentTitle = get(userActivityState, 'currentContent.content.data.details.contentTitle', '');
  const currentModuleTitle = get(userActivityState, 'currentModule.data[0].moduleTitle', '');
  return {
    currentContentTitle,
    currentModuleTitle
  };
};

export default connect(mapStateToProps, {})(PublicDiplomaDownload);
