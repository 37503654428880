import React from 'react';
import PropTypes from 'prop-types';
import { Container, Tab, Nav } from 'react-bootstrap';

import { useFormatMessage } from 'hooks/useFormatMessage';
import UserManagementGroup from './UserManagement';
import UserImport from './UserImport';
import GroupManagementGroup from './GroupManagement';

const UserDashboard = ({ ...props }) => {
  const t = useFormatMessage();

  return (
    <React.Fragment>
      <Container fluid="lg">
        <Tab.Container mountOnEnter={true} defaultActiveKey={'users'} id="users-group-tab">
          <div className="nav-wrapper">
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey="users">{t('user-dashboard/user-management')}</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="users-import">{t('user-dashboard/user-import')}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="groups">{t('user-dashboard/group-management')}</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <Tab.Content>
            <Tab.Pane eventKey="users">
              <UserManagementGroup {...props} />
            </Tab.Pane>

            <Tab.Pane eventKey="users-import">
              <UserImport {...props} />
            </Tab.Pane>

            <Tab.Pane eventKey="groups">
              <GroupManagementGroup {...props} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </React.Fragment>
  );
};

UserDashboard.propTypes = {
  selectedOrg: PropTypes.string,
  role: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired
};

UserDashboard.defaultProps = {};
export default UserDashboard;
