import React from 'react';
import PropTypes from 'prop-types';
import sn from 'classnames';
import { Modal as BootStrapModal } from 'react-bootstrap';

import Button from 'atoms/Button';
import { useFormatMessage } from 'hooks/useFormatMessage';
import './modal.scss';

const Modal = ({
  heading,
  saveText,
  noBodyPadding = false,
  showSaveFooterDisabled,
  modalBody,
  isAlert,
  onSave,
  showSave,
  showCancel,
  showHeader,
  showFooter,
  showSaveFooter,
  notPristine,
  ...props
}) => {
  const t = useFormatMessage();
  return (
    <BootStrapModal {...props} aria-labelledby="contained-BootStrapModal-title-vcenter" centered>
      {
        <BootStrapModal.Header closeButton className={sn({ 'modal-header--alert': isAlert, 'modal-header--empty': !showHeader })}>
          {showHeader && <BootStrapModal.Title id="contained-BootStrapModal-title-vcenter">{heading}</BootStrapModal.Title>}
        </BootStrapModal.Header>
      }
      <BootStrapModal.Body className={sn({ 'modal-body--alert': isAlert, 'modal-body--p0': noBodyPadding })}>{modalBody}</BootStrapModal.Body>
      {showFooter && (
        <BootStrapModal.Footer className={sn({ 'justify-content-between': !isAlert }, { 'justify-content-start  modal-footer--alert': isAlert })}>
          {showSave && (
            <Button notPristine={notPristine} onClick={onSave}>
              {saveText ? saveText : isAlert ? t('common/ok') : t('common/save')}
            </Button>
          )}
          {showCancel && (
            <Button variant="secondary" onClick={props.onHide}>
              {t('common/cancel')}
            </Button>
          )}
        </BootStrapModal.Footer>
      )}
      {showSaveFooter && (
        <div className={'justify-content-between'}>
          <Button disabled={showSaveFooterDisabled} style={{ height: '50px', width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0 }} onClick={onSave}>
            {saveText ? saveText : t('common/save')}
          </Button>
        </div>
      )}
    </BootStrapModal>
  );
};

Modal.propTypes = {
  heading: PropTypes.string,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
  isAlert: PropTypes.bool,
  showSave: PropTypes.bool,
  noBodyPadding: PropTypes.bool,
  notPristine: PropTypes.bool,
  showCancel: PropTypes.bool,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  showSaveFooter: PropTypes.bool,
  showSaveFooterDisabled: PropTypes.bool,
  saveText: PropTypes.string,
  modalBody: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
Modal.defaultProps = {
  heading: '',
  onHide: () => {},
  onSave: () => {},
  showSave: true,
  notPristine: false,
  showCancel: true,
  showFooter: true,
  showHeader: true,
  showSaveFooter: false,
  showSaveFooterDisabled: false,
  isAlert: false
};
export default Modal;
