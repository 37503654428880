import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Container } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import AppContext from '../../app-context';

import DropDown from 'atoms/DropDown';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { UserPropTypesShape } from 'utils/core-proptypes';
import Statistics from './Statistics';
import RoleCheck from '../RoleCheck';
import { getOrganisationList } from '../../actions';
import './statistics.scss';

const StatsDashboard = ({ user = {}, organizationList, getOrganisationList }) => {
  const t = useFormatMessage();
  const context = useContext(AppContext);
  const { user: { role = '', organizationId } = {} } = user;

  useEffect(() => {
    if (!context.selectedOrg) {
      context.setContextState({ selectedOrg: organizationId });
    }
  }, []);
  useEffect(() => {
    if (role === 'ADMIN' || role === 'PARTNER') {
      isEmpty(organizationList) && getOrganisationList();
    }
  }, [organizationList.length]);

  const selectedOrg = context.selectedOrg || organizationId;
  const handleSelect = eventKey => {
    context.setContextState({ selectedOrg: eventKey.id });
  };

  return (
    // <div>
    <div className="white-color-container">
      <Container>
        <Row className="stats-dashboard__top-row">
          <RoleCheck
            allowed={['ADMIN', 'PARTNER']}
            component={
              <DropDown
                onSelect={handleSelect}
                isSearchable={true}
                heading={selectedOrg || organizationId}
                placeholder={t('common/select-organization')}
                values={organizationList.map(org => {
                  return { value: org.name, id: org._id };
                })}
              />
            } // default organisationId in case of no selectedOrg and selected org valid only for admin
          />
        </Row>
      </Container>
      <Statistics selectedOrg={selectedOrg} />
    </div>
    // </div>
  );
};

StatsDashboard.propTypes = {
  user: UserPropTypesShape,
  organizationList: PropTypes.arrayOf(PropTypes.object),
  getOrganisationList: PropTypes.func.isRequired
};

StatsDashboard.defaultProps = {
  organizationList: []
};

const mapStateToProps = ({ userState, adminState, accountSettings }) => {
  const { orgName } = accountSettings;

  return {
    user: userState.user,
    organizationList: adminState.organizations,
    orgName
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getOrganisationList: bindActionCreators(getOrganisationList, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsDashboard);
